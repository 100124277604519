import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';


export default function AddManualWaybill({
    obj,
    setShowModal,
    fetchData
}) {
    const prefix = 'LOG';
    const [updatedWaybill, setUpdatedWaybill] = useState(prefix);

    async function submitReference() {
        let tempObj = obj;
        tempObj.waybill = {
            label: obj.waybill.label + " " + updatedWaybill,
            button1: {
                btnColor: "danger",
                btnIcon: "addOutline",
                tooltip: "Create manual waybill",
            },
            button2: {
                btnColor: "success",
                btnIcon: "downloadOutline",
                tooltip: "Download waybill",
            }
        };

        await updateItem('returns', ['data'], [tempObj], { id: obj.id });
        
        fetchData();
        setShowModal(false);
    }

    return (
        <div style={{ marginTop: '2em', margin: '5%' }}>
            <input className="general" style={{width: '100%', marginBottom: '2em'}} placeholder="Official waybill from Aramex system" value={updatedWaybill} onChange={(e) => setUpdatedWaybill(e.target.value.length > 2 ? e.target.value : updatedWaybill)}/>
            <IonButton style={{width: '100%'}} onClick={submitReference}>Add</IonButton>
        </div>
    )
}
