import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonItem,
  IonList,
  IonLabel,
} from "@ionic/react";
import * as icons from "ionicons/icons";
import Modal from "../Modal";
import { generatePdf } from "../../apis/logictrials";

import "./TableList.css";

const actions = [
  "Destruction",
  "Over Supply",
  "Recall",
  "Quarantine",
  "Reject",
  "Collection",
  "TT4 Return",
  "Quarantined due to damaged box",
  "Awaiting Destruction",
  "Destroyed",
];

export default function TableList({
  items,
  headings,
  labels,
  functions,
  className,
}) {
  const [expanded] = useState({});
  const [showModal, setShowModal] = useState();
  const [expandedItems, setExpandedItems] = useState();
  const [expandedObj, setExpandedObj] = useState();
  const [actionActive, setActionActive] = useState(false);
  function renderButton(
    label,
    fieldLabel,
    icon,
    color,
    key,
    index,
    tooltip,
    item
  ) {
    if(key === 'arrivalDate') {
      console.log('label', label)
      console.log('fieldLabel', fieldLabel)
      console.log('icon', icon)
      console.log('color', color)
      console.log('key', key)
      console.log('index', index)
      console.log('tooltip', tooltip)
      console.log('item', item)
      if(item.SOPS === "NEW STUDY 123456 1234 122"){
        console.log(item)
      }


    }
  
    let action = functions[key] ? functions[key] : () => {};
    const jsx = (
      <div
        style={{
          position: "relative",
          paddingRight: key === "sss" && functions["delete"] ? "4px" : "4px",
        }}
      >
        {tooltip && (
          <span
            className={`tooltiptext ${color ? "button-tooltip" : ""} ${key}`}
          >
            {tooltip}
          </span>
        )}
        {!fieldLabel ? (
          <IonButton
            className={`btn`}
            onClick={() => action(fieldLabel, index, item)}
            color={color ? color : "primary"}
          >
            {icon && label && <IonIcon icon={icon} slot="start" />}
            {label && label}
            {icon && !label && <IonIcon icon={icon} />}
          </IonButton>
        ) : icon ? (
          <div className="hover-action">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IonButton
                color={color}
                onClick={() => action(fieldLabel, index, item)}
              >
                {icon && label && <IonIcon icon={icon} slot="start" />}
                {label && label}
                {icon && !label && <IonIcon icon={icon} />}
              </IonButton>
            </div>
          </div>
        ) : (
          <>
            {label && (
              <div className="hover-action">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IonButton
                    color={color}
                    onClick={() => action(fieldLabel, index, item)}
                  >
                    {label && label}
                  </IonButton>
                </div>
              </div>
            )}
          </>
        )}
        <p class="label-button">
          {fieldLabel}
          {key === "TT4" && item.tt4Excursion === "yes" && (
            <i
              style={{ color: "var(--ion-color-danger)", marginLeft: "3px" }}
              className="fas fa-exclamation-triangle"
            ></i>
          )}
        </p>
      </div>
    );

    return jsx;
  }

  function render2Buttons(item, key, obj) {
    if (key !== "aramexRef") {
      return (
        <div style={{ position: "relative" }}>
          {item.label && <p className="label-button">{item.label}</p>}
          <div className={item.label ? "hover-action" : "label-button"}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="tooltip">
                {item.button1.tooltip && (
                  <span className={`tooltiptext ${key}`}>
                    {item.button1.tooltip}
                  </span>
                )}
                <IonButton
                  className={`btn-1`}
                  onClick={() => functions[key].button1(obj, item, key)}
                  color={item.button1.btnColor}
                  size="small"
                >
                  <IonIcon icon={icons[item.button1.btnIcon]} />
                </IonButton>
              </div>
              {/* <br /> */}
              <div className="tooltip">
                {item.button2.tooltip && (
                  <span className={`tooltiptext ${key}`}>
                    {item.button2.tooltip}
                  </span>
                )}
                <IonButton
                  className={`btn-2`}
                  onClick={() => functions[key].button2(obj, item, key)}
                  color={item.button2.btnColor}
                  size="small"
                >
                  <IonIcon icon={icons[item.button2.btnIcon]} />
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative" }}>
          {item.label && <p className="label-button">{item.label}</p>}
          <div className={item.label ? "hover-action" : "label-button"}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="tooltip">
                {item.button2.tooltip && (
                  <span className={`tooltiptext ${key}`}>
                    {item.button2.tooltip}
                  </span>
                )}
                <IonButton
                  className={`btn-2`}
                  onClick={() => functions[key].button2(obj, item, key)}
                  color={item.button2.btnColor}
                  size="small"
                >
                  <IonIcon icon={icons[item.button2.btnIcon]} />
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderLabelButton(item, key, obj, index, tooltip) {
    if (typeof item === "string" && key !== "action") {
      return (
        <p
          style={{
            color:
              key === "tt4Excursion" && item === "yes"
                ? "var(--ion-color-danger)"
                : "",
          }}
        >
          {item}
        </p>
      );
    }

    if (typeof item === "object") {
      if (item.button1) {
        return render2Buttons(item, key, obj);
      }
      if (item.button) {
        return renderButton(
          item.button.btnLabel ? item.button.btnLabel : "",
          item.button.label,
          item.button.btnIcon ? icons[item.button.btnIcon] : "",
          item.button.btnColor ? item.button.btnColor : "",
          key,
          index,
          tooltip,
          obj
        );
      }
      if (key === "referenceNo") {
        return renderButton(
          item.btnLabel ? item.btnLabel : "",
          item.label,
          item.btnIcon ? icons[item.btnIcon] : "",
          item.btnColor ? item.btnColor : "",
          key,
          index,
          tooltip,
          obj
        );
      }
      if (item && item.icon) {
        return renderIcon(
          item.icon,
          item.iconAction,
          obj,
          item.label,
          key,
          index,
          tooltip
        );
      }
      if (item) {
        return renderButton(
          item.btnLabel ? item.btnLabel : "",
          item.label,
          item.btnIcon ? icons[item.btnIcon] : "",
          item.btnColor ? item.btnColor : "",
          key,
          index,
          tooltip,
          obj
        );
      }
    }
  }

  function renderIcon(icon, action, obj, label, key, index, tooltip) {
    action = functions[key] ? functions[key] : () => {};

    return (
      <div className="table-icon">
        {tooltip && <span className={`tooltiptext ${key}`}>{tooltip}</span>}
        {label}
        <IonIcon
          style={{ marginLeft: 5, cursor: "pointer", fontSize: 10 }}
          icon={icons[icon]}
          onClick={() => {
            action(obj, index);
          }}
        />
      </div>
    );
  }

  function renderArray(items) {
    //Removing includes CM
    // if (!items.includes("_")) {
    if (items) {
      return (
        <IonList style={{ marginTop: "2em" }}>
          {items.map((i) => (
            <IonItem className="tooltip">
              <IonLabel>{i}</IonLabel>
              <IonButton
                color="warning"
                size="default"
                style={{ marginRight: "1em" }}
                onClick={() => {
                  const showLoading = new Event("display-loading");
                  window.dispatchEvent(showLoading);
                  const dismissLoading = new Event("dismiss-loading");
                  i.trim();
                  generatePdf(
                    "confirmationofdestructionpdf",
                    expandedObj.orderNumber,
                    i.split(":")[0]
                  ).then((res) => {
                    setTimeout(() => {
                      window.dispatchEvent(dismissLoading);
                      window.open(res);
                    }, 2000);
                  });
                }}
              >
                <IonIcon slot="start" icon={icons.documentOutline} />
                print confirmation doc
              </IonButton>
              <IonButton
                size="default"
                onClick={() => {
                  // console.log(i, i.split(' ')[0]);
                  const showLoading = new Event("display-loading");
                  window.dispatchEvent(showLoading);
                  const dismissLoading = new Event("dismiss-loading");
                  generatePdf(
                    "destructionsummerypdf",
                    expandedObj.orderNumber,
                    i.split(":")[0]
                  ).then((res) => {
                    setTimeout(() => {
                      window.dispatchEvent(dismissLoading);
                      window.open(res);
                    }, 2000);
                  });
                }}
              >
                <IonIcon slot="start" icon={icons.documentOutline} />
                print destruction list doc
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      );
    } else {
      return (
        <IonList style={{ marginTop: "2em" }}>
          {items.map((i) => (
            <IonItem>
              {i}
              <IonLabel color="danger" style={{ marginLeft: "0.5em" }}>
                Item could not be found
              </IonLabel>
              <IonButton
                color="medium"
                size="default"
                style={{ marginRight: "1em" }}
              >
                <IonIcon slot="start" icon={icons.documentOutline} />
                print confirmation doc
              </IonButton>
              <IonButton color="medium" size="default">
                <IonIcon slot="start" icon={icons.documentOutline} />
                print destruction list doc
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      );
    }
  }

  const showItems = (item, itemObj) => {
    setExpandedObj(itemObj);
    setExpandedItems(item);
    setShowModal(true);
  };

  function renderList(collection, headings, labels) {
    return (
      <div className="table-container">
        <table className={`the-table ${className}`}>
          <thead>
            <tr>
              {labels.map((l, i) => (
                <>
                  {i === 0 ? (
                    <th
                      key={i}
                      className={`first-col-head ${
                        l === "ID" ? "pr-50p" : "pr-responsive"
                      }`}
                    >
                      {l}
                    </th>
                  ) : (
                    <th
                      key={i}
                      style={l === "" ? { paddingRight: 50 } : {}}
                      className={`table-head th-${headings[i]}`}
                    >
                      {l}
                    </th>
                  )}
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {collection.map((item, i) => {
              item["sendToDestruction"] = {
                btnIcon: "sendOutline",
                btnColor: "danger",
                tooltip: "Send to destruction list",
              };
              item["view"] = {
                btnIcon: "eyeOutline",
                btnColor: "success",
                tooltip: "view",
              };
              item["edit"] = {
                btnIcon: "pencilOutline",
                btnColor: "success",
                tooltip: "Edit item",
              };
              item["delete"] = {
                btnIcon: "trashBinOutline",
                btnColor: "danger",
                tooltip: "Delete item",
              };
              item["confirm"] = {
                btnIcon: "checkmarkOutline",
                btnColor: "primary",
                tooltip: "Complete",
              };
              if (item["weight"]) {
                item["weight"] = {
                  label:
                    typeof item["weight"] === "string"
                      ? item["weight"]
                      : item["weight"].label,
                  btnColor: "warning",
                  btnIcon: "addOutline",
                  tooltip: "Add weight",
                };
              }

              if (
                item &&
                item.sent &&
                item.sent.btnColor === "primary" &&
                typeof item.confirmDestruction !== "string"
              ) {
                item["confirmDestruction"] = {
                  btnIcon: "checkmarkOutline",
                  btnColor: "tertiary",
                  tooltip: "Confirm destruction",
                };
              }

              // if(item && typeof item.confirmDestruction === 'string') {
              //     item["retryConfirm"] = {
              //         btnIcon: 'reloadOutline',
              //         btnColor: "danger",
              //         tooltip: "Retry confirmation"
              //     };
              // }

              if (item && item.hasUploadedDocs) {
                item["viewDocuments"] = {
                  btnIcon: "eyeOutline",
                  btnColor: "primary",
                  tooltip: "View info & certificates",
                };
              }

              if (item && item.TT4 && item.tt4Excursion === "yes") {
                console.log(item.TT4);
                const originalLabel = item.TT4.label;

                item.TT4 = {
                  ...item.TT4,
                  btnColor: "danger",
                  btnIcon: "barChartOutline",
                  tooltip: "Excursion",
                  label: originalLabel,
                };
              }

              if (item && item.TT4 && typeof item.TT4 === "string") {
                item.TT4 = {
                  ...item.TT4,
                  btnColor: "warning",
                  btnIcon: "barChartOutline",
                  tooltip: "Confirm TT4",
                  label: item.TT4,
                };
              }

              return (
                <>
                  <tr key={i}>
                    {headings.map((h, ii) => {
                      return (
                        <>
                          {item[h] && (
                            <>
                              {ii === 0 ? (
                                <th
                                  style={{ fontSize: 11, cursor: "pointer" }}
                                  onClick={() => functions[h](item)}
                                  className="first-col-data"
                                >
                                  <p>{item[h]}</p>
                                </th>
                              ) : (
                                <td
                                  style={{ fontSize: 11 }}
                                  className={`${
                                    item[h].button1 ? "" : "tooltip"
                                  } table-field td-${h}`}
                                >
                                  {!Array.isArray(item[h]) ? (
                                    <>
                                      {h !== "inventoryAdded" &&
                                        h !== "action" &&
                                        h !== "qaCheck" &&
                                        h !== "sss" &&
                                        renderLabelButton(
                                          item[h],
                                          h,
                                          item,
                                          item.index ? item.index : i,
                                          item[h] && item[h].tooltip
                                            ? item[h].tooltip
                                            : ""
                                        )}
                                      {h === "sss" &&
                                        typeof item[h] === "object" &&
                                        item[h].link && (
                                          <IonButton
                                            color="success"
                                            onClick={() => functions[h](item)}
                                          >
                                            SSS
                                          </IonButton>
                                        )}
                                      {h === "sss" && !item[h].link && (
                                        <IonButton
                                          color="warning"
                                          onClick={() => functions[h](item)}
                                        >
                                          SSS
                                        </IonButton>
                                      )}

                                      {h === "qaCheck" && item[h] && (
                                        <button
                                          style={{ cursor: "pointer" }}
                                          className="border"
                                          onClick={() => {
                                            functions[h](item);
                                          }}
                                        >
                                          {typeof item[h] === "string"
                                            ? item[h]
                                            : "Select Status"}
                                        </button>
                                      )}
                                      {h === "action" && (
                                        <IonSelect
                                          className="border"
                                          placeholder="Select action"
                                          interface="popover"
                                          onClick={() => setActionActive(true)}
                                          value={item[h] ? item[h] : ""}
                                          okText="Okay"
                                          cancelText="Dismiss"
                                          onIonChange={(e) => {
                                            if (actionActive) {
                                              functions[h](
                                                e.detail.value,
                                                i,
                                                item
                                              );
                                              setActionActive(false);
                                            }
                                          }}
                                        >
                                          {actions.map((a, i) => (
                                            <IonSelectOption key={i} value={a}>
                                              {a}
                                            </IonSelectOption>
                                          ))}
                                        </IonSelect>
                                      )}
                                      {h === "inventoryAdded" && (
                                        <IonToggle
                                          checked={
                                            item[h] === "true" ||
                                            item[h] === "false"
                                              ? JSON.parse(item[h])
                                              : false
                                          }
                                          color="secondary"
                                          onIonChange={(e) =>
                                            functions[h](item, e.detail.checked)
                                          }
                                        />
                                      )}
                                      {h === "temperatureDeviation" && (
                                        <IonToggle
                                          checked={true}
                                          color="danger"
                                          onIonChange={(e) =>
                                            functions[h](item, e.detail.checked)
                                          }
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {typeof item[h][0] === "object" ? (
                                        item[h].length
                                      ) : (
                                        <>
                                          {expanded[i] !== i + 1 && (
                                            <IonButton
                                              color="success"
                                              onClick={
                                                functions[h]
                                                  ? () =>
                                                      functions[h](
                                                        item[h],
                                                        item
                                                      )
                                                  : () =>
                                                      showItems(item[h], item)
                                              }
                                            >
                                              {item[h][0]}{" "}
                                              {item[h].length > 1 && (
                                                <i>
                                                  &nbsp; &nbsp; +
                                                  {item[h].length - 1}
                                                </i>
                                              )}
                                            </IonButton>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                          {!item[h] && (
                            <td style={{ fontSize: 11 }} className={`td-${h}`}>
                              {h === "temperatureDeviation" && (
                                <IonToggle checked={false} color="danger" />
                              )}
                              {h === "action" && (
                                <IonSelect
                                  className="border"
                                  placeholder="Select action"
                                  interface="popover"
                                  onClick={() => setActionActive(true)}
                                  value={item[h] ? item[h] : ""}
                                  okText="Okay"
                                  cancelText="Dismiss"
                                  onIonChange={(e) => {
                                    if (actionActive) {
                                      functions[h](e.detail.value, i, item);
                                      setActionActive(false);
                                    }
                                  }}
                                >
                                  {actions.map((a, i) => (
                                    <IonSelectOption key={i} value={a}>
                                      {a}
                                    </IonSelectOption>
                                  ))}
                                </IonSelect>
                              )}
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <>
      {items && items.length > 0 && (
        <>
          <div
            className="table-list"
            style={{ textAlign: "center", width: "100%" }}
          >
            {renderList(items, headings, labels)}
          </div>
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            className="destruction-modal"
          >
            {expandedItems && renderArray(expandedItems)}
          </Modal>
        </>
      )}
    </>
  );
}
