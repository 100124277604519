import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonItem, IonButton, IonTextarea, IonModal, IonInput } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';

export default function ConfirmReceipt({ returnObject, setShowModal }) {
    const [selectedDate, setSelectedDate] = useState();
    const [showSelectedDate, setShowSelectedDate] = useState({ showPopover: false, event: undefined });

    const [comments, setComments] = useState('');
    const [emailReceived, setEmailReceived] = useState(false);
    const [waybillsPrinted, setWaybillsPrinted] = useState(false);

    async function submit() {
        const value = {
            comments,
            emailReceived,
            waybillsPrinted,
            tooltip: new Date(selectedDate).toISOString().slice(0, 20),
            label: 'Done',
        };
        let tempReturn = { ...returnObject, confirmReceipt: value };

        if (tempReturn && tempReturn.parcels && typeof tempReturn.parcels === 'string') {
            tempReturn.parcels = JSON.parse(tempReturn.parcels);
        }

        const { id } = returnObject;
        await updateItem('returns', ['data'], [tempReturn], { id });
        setShowModal(false);
        window.location.reload();
    }

    return (
        <div style={{ marginTop: '2em' }}>
            <IonGrid>
                <IonRow>
                    {/* <IonCol>
                        <IonItem>
                            <IonLabel>D MMM YYYY H:mm</IonLabel>
                            <IonDatetime displayFormat="D MMM YYYY H:mm" min="1997" max="2022" value={selectedDate} onIonChange={e => setSelectedDate(e.detail.value)}></IonDatetime>
                        </IonItem>
                    </IonCol> */}
                    <IonCol>
                        <IonInput onClick={(e) => setShowSelectedDate({ showPopover: true, event: e })} placeholder="Pickup Date" value={selectedDate ? format(new Date(selectedDate), 'MM d y') : ''} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonTextarea value={comments} onIonChange={e => setComments(e.detail.value)} placeholder="Comments" />
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>
                                Email received
                            </IonLabel>
                            <IonCheckbox
                                checked={emailReceived} onIonChange={e => setEmailReceived(e.detail.checked)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>
                                Waybills printed
                            </IonLabel>
                            <IonCheckbox
                                checked={waybillsPrinted} onIonChange={e => setWaybillsPrinted(e.detail.checked)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow style={{ marginTop: '2em' }}>
                    <IonCol size="9">

                    </IonCol>
                    <IonCol size="3" style={{ textAlign: 'right' }}>
                        <IonButton color="warning" onClick={submit}>
                            Update
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showSelectedDate.showPopover}
                event={showSelectedDate.event}
                onDidDismiss={() => setShowSelectedDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedDate(day.toString()); setShowSelectedDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowSelectedDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
        </div>
    )
}
