import React, { useEffect } from "react";
import { downloadOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import Chart from 'chart.js';

export default function PieChart({ data, pieid, title, labels }) {

    useEffect(() => {
        var config = {
            type: "doughnut",
            data: {
                datasets: [
                    {
                        data,
                        backgroundColor: ['#2dd36f', '#eb445a'],
                        label: "Member Types Access",
                    },
                ],
                labels,
            },
            options: {
                responsive: true,
                legend: {
                    display: window.innerWidth < 600 ? false : true
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem) {
                                return data[tooltipItem.index];
                        }
                    }
                }
            },
        };

        var ctx = document.getElementById(`pie-chart-${pieid}`).getContext("2d");

        var myPieChart = new Chart(ctx, config);

        console.log(myPieChart);

        //Download Chart Image
        document
            .getElementById(`download-pie-chart-${pieid}`)
            .addEventListener("click", function () {
                var url_base64jp = document
                    .getElementById(`pie-chart-${pieid}`)
                    .toDataURL("image/jpg");
                var a = document.getElementById(`download-pie-chart-${pieid}`);
                a.href = url_base64jp;
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div
                className="px-4 md:px-10 mx-auto w-full -m-24"
                style={{
                    width: window.innerWidth > 990 ? "147%" : "100%",
                }}
            >
                <div className="">
                    <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4" >
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded" style={{backgroundColor: '#ffffff'}}>
                            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative w-full max-w-full flex-grow flex-1">
                                        <h6 className="uppercase text-gray-600 mb-1 text-xs font-semibold">
                                            {new Date().toLocaleDateString()} {new Date().toLocaleTimeString('en-gb')}
                                        </h6>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a
                                            id={`download-pie-chart-${pieid}`}
                                            download={`member-types-currently-inside-${new Date().toLocaleDateString()}${new Date().toLocaleTimeString()}-chart.jpg`}
                                            className="float-right"
                                            href="#"
                                        >
                                            <IonIcon
                                                icon={downloadOutline}
                                                style={{ cursor: "pointer", margin: "0 0.5em", fontSize: 24 }}
                                                className="text-gray-600"
                                            ></IonIcon>
                                        </a>
                                        <h2 className=" text-xl font-semibold">
                                            {title}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="relative"
                                style={{ height: "auto", paddingBottom: "1em" }}
                            >
                                <canvas id={`pie-chart-${pieid}`}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
