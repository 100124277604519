import React, { useState, useEffect } from 'react';
import QRcode from 'qrcode.react';

function QRgenerator(value) {
    const [qr, setQr] = useState(`${window.location.origin}?id=${value.link}`);

    useEffect(() => {
        if(value.link) {
            setQr(`${value.link}`);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <QRcode 
            className="shadow-l"
            id="myqr"
            value={qr} 
            size={value.type && (value.type === "returns") ? 100 : 200}
            includeMargin={false}
            style={{position: 'static', float: 'left', marginRight: '2em'}}
        />
    );
}

export default QRgenerator;
