import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { refreshOutline } from "ionicons/icons";
import { changePassword } from "../apis/logictrials";

export default function ResetPassword({ editUser, setShowModal }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [resetError, setResetError] = useState("");

  function reset() {
    if (editUser && editUser.id) {
      changePassword(editUser.id, newPassword, confirmNewPassword).then(
        (res) => {
          if (res && res.statusCode === "OK") {
            setIsSuccess(true);
            setResetError("");
          } else {
            setResetError(res.statusCode);
          }
        }
      );
    }
  }

  return (
    <div>
      <IonGrid>
        {!isSuccess && (
          <IonRow>
            <IonCol size="12">
              <h2>Please create a new password below</h2>
            </IonCol>
            <IonCol size="12">
              <IonItem lines="none">
                <IonInput
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onIonChange={(e) => setNewPassword(e.detail.value)}
                />
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <IonItem lines="none">
                <IonInput
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onIonChange={(e) => setConfirmNewPassword(e.detail.value)}
                />
              </IonItem>
            </IonCol>
            {resetError !== "" && (
              <IonCol size="12" expand="full">
                <IonItem lines="none">
                  <p style={{ color: "red" }}>{resetError}</p>
                </IonItem>
              </IonCol>
            )}
            <IonCol>
              <IonButton color="success" expand="full" onClick={reset}>
                <IonIcon icon={refreshOutline} slot="start" />
                Reset
              </IonButton>
            </IonCol>
          </IonRow>
        )}

        {isSuccess && (
          <div className="mt-40">
            <IonRow>
              <IonCol size="12">
                <IonItem lines="none">
                  <p>
                    Password for {editUser && `${editUser.firstname}`} reset
                    successfully.
                  </p>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonButton
                  color="success"
                  expand="full"
                  onClick={() => setShowModal(false)}
                >
                  Okay
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        )}
      </IonGrid>
    </div>
  );
}
