import React, { useState } from 'react';
import  { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';

export default function ConfirmArrival({setShowModal}) {
    const [packingListNumber, setPackingListNumber] = useState('');
    const [externalOrderNumber, setExternalOrderNumber] = useState('');
    const history = useHistory();

    function submit() {
        sessionStorage.setItem('lt-temp-add-order-data', JSON.stringify(
            {
                packingListNumber,
                externalOrderNumber
            }
        ));
        setShowModal(false);
        history.push('/return-processing');
    }

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonInput placeholder="External Order Number" value={externalOrderNumber} onIonChange={e => setExternalOrderNumber(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonInput placeholder="Packing List Number" value={packingListNumber} onIonChange={e => setPackingListNumber(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{textAlign: 'right'}}>
                        <IonButton color="warning" onClick={submit}>
                            Next
                        </IonButton>    
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
