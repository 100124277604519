import React, { useState } from 'react';
import  { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonItem, IonButton, IonTextarea } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';

export default function AorDocsFollowup({updateOrder, fetchData, setShowModal}) {
    const [checkDocs, setCheckDocs] = useState(false);
    const [comments, setComments] = useState('');

    async function submit() {
        const value = {
            checkDocs, 
            comments, 
            label: 'Done',
            tooltip: checkDocs ? 'Docs received' : 'Docs not received'
        };

        let tempOrder = updateOrder;

        tempOrder.aorDocsComments = comments;
        tempOrder.aorDocsReceived = checkDocs ? "true" : "false";
        tempOrder.aorDocs = value;

        if(tempOrder && tempOrder.parcels && typeof tempOrder.parcels === 'string') {
            tempOrder.parcels = JSON.parse(tempOrder.parcels);
        }

        const { id } = updateOrder;
        await updateItem('orders', ['data'], [tempOrder], { id });

        setShowModal(false);
        window.location.href = '/orders-and-dispatch';
    }

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonTextarea placeholder="Comments" value={comments} onIonChange={e => setComments(e.detail.value)} />
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>
                                Docs received: 
                            </IonLabel>
                            <IonCheckbox 
                                checked={checkDocs} onIonChange={e => setCheckDocs(e.detail.checked)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow style={{marginTop: '2em'}}>
                    <IonCol size="9">
                        
                    </IonCol>
                    <IonCol size="3" style={{textAlign: 'right'}}>
                        <IonButton color="warning" onClick={submit}>
                            Update
                        </IonButton>    
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
