import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import FileUploadApi from './FileUploadApi';
import { sendEmailWithAttachment, updateItem } from '../../apis/logictrials';
import PrimaryButton from '../inputs/PrimaryButton';

export default function CreateEmail({ setShowModal, orderNumber, updatedInbound }) {
    const [files, setFiles] = useState('');
    const [alertMessage, setAlertMessage] = useState();
    const [sent, setSent] = useState();

    function sendEmail() {

        setAlertMessage('');
        console.log(files)
        if (files && Array.isArray(files) && files.length > 0) {
            let html = `
            <div>
            </div>
            `;
            let tempFiles = [];

            files.forEach(f => tempFiles.push({
                content: f.content.slice(28, f.content.length),
                filename: orderNumber + '_' + f.fullName
            }));

            sendEmailWithAttachment(tempFiles, 'Logic Trials SSS - Attached', html, orderNumber).then(res => {

                let updated = updatedInbound;
                if (updated && updated.email && typeof updated.email.btnColor === "string") updated.email.btnColor = "success"

                updateItem('inbound', ['data'], [updated], { id: updatedInbound.id })
                setSent(true);
            });

            return;
        }

        setAlertMessage('please attach a file to send.');
        setTimeout(() => { setAlertMessage('') }, 3000);
    }

    return (
        <div>
            <IonGrid>
                <FileUploadApi protocol="inbound-email" setEmailFiles={setFiles} orderNumber={orderNumber} />
                <IonRow>
                    <IonCol>
                        {alertMessage && <p style={{ color: 'var(--ion-color-danger)' }}>{alertMessage}</p>}
                    </IonCol>
                </IonRow>
                {!sent && <IonRow>
                    <IonCol>
                        <PrimaryButton text="Send Email" action={sendEmail} width="100%" />
                    </IonCol>
                </IonRow>}
                {sent && <>
                    <IonRow>
                        <IonCol>
                            <p style={{ Color: 'var(--ion-color-success)' }}>Email with attached documents sent to all relevant parties.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="success" onClick={() => {
                                setShowModal(false);
                                setSent(false);
                                setAlertMessage(false);
                            }}>
                                close
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>}
            </IonGrid>
        </div>
    )
}
