import React, { useState, useEffect, useRef } from "react";
import {
    IonAlert,
    IonGrid,
    IonCol,
    IonRow,
    IonDatetime,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonToast,
    IonModal,
    IonPopover
} from "@ionic/react";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import TableList from "../../components/TableList/TableList";
import Search from "../../components/Search/Search";
import Modal from '../../components/Modal';
import Loading from "../../components/Loading";
import OrdersProcessing from "../OrdersProcessing/OrdersProcessing";
import { exportArrayToExcel, isValidDate } from '../../utils';
import BoxLabel from '../BoxLabel';
import { getData, deleteItem, generatePdf, getOrderWaybill, addOrderArchiveFix } from '../../apis/logictrials';
import ConfirmTt4 from "../../components/actionButtonForms/ConfirmTt4";
import ConfirmArrival from "../../components/actionButtonForms/ConfirmArrival";
import IWRSFollowup from "../../components/actionButtonForms/IWRSFollowup";
import AorTelTimeEmailFollowup from "../../components/actionButtonForms/AorTelTimeEmailFollowup";
import AorDocsFollowup from "../../components/actionButtonForms/AorDocsFollowup";
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';
import { stringify } from "postcss";

const PER_PAGE = 15;

const headings = [
    "SOPS",
    "orderNumber",
    "requestDate",
    "TT4",
    "STEM",
    "dispatchDate",
    "boxes",
    "waybill",
    "arrivalDate",
    "IWRS",
    "aorTelTime",
    "aorDocs",
    "sss",
];

const labels = [
    "SOPS",
    "Order Number",
    "Request Date",
    "TT4",
    "STEM",
    "Dispatch Date",
    "Boxes",
    "Waybill",
    "Arrival Date",
    "IWRS",
    "AOR Tel Time",
    "AOR Docs",
    "SSS",
];

function Users() {
    const [updateReturn, setUpdateReturn] = useState({});
    const [error, setError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [ordersDispatch, setOrdersDispatch] = useState([]);
    const [unchangedOrdersDispatch, setUnchangedOrdersDispatch] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [arrivalDateChangeIndex, setArrivalDateChangeIndex] = useState(null);
    const [displayOrder, setDisplayOrder] = useState(null);
    const [filterType, setFilterType] = useState('none');
    const [boxLabelSops, setBoxLabelSops] = useState();
    const [showBoxLabelModal, setShowBoxLabelModal] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setDate(today.getDate() - 30);

    const [selectedStartDate, setSelectedStartDate] = useState(lastMonth.toISOString());
    const [prevSelectedStartDate] = useState(lastMonth.toISOString());
    const [showStartDate, setShowStartDate] = useState({ showPopover: false, event: undefined });

    const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
    const [showEndDate, setShowEndDate] = useState({ showPopover: false, event: undefined });

    const [showTt4Modal, setShowTt4Modal] = useState(false);
    const [showConfirmArrivalModal, setShowConfirmArrivalModal] = useState(false);
    const [showIwrsModal, setShowIwrsModal] = useState(false);
    const [showAorTelTimeModal, setShowAorTelTimeModal] = useState(false);
    const [showAorDocsModal, setShowAorDocsModal] = useState(false);
    const [confirmTt4Data, setConfirmTt4Data] = useState({});
    const [confirmArrivalData, setConfirmArrivalData] = useState({});
    const [aorTelTimeData, setAorTelTimeData] = useState({});
    const dateTimeRef = useRef();
    const [updateItem, setUpdateItem] = useState({});
    const [showToast, setShowToast] = useState(false);

    const [toastMessage] = useState('');

    useEffect(() => {

        if (filterType === 'none') {
            setOrdersDispatch(unchangedOrdersDispatch);
        }
        if (selectedStartDate && selectedEndDate && (filterType === 'Arrival Date' || filterType === 'Request Date' || filterType === 'Dispatch Date')) {
            if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
                setSelectedStartDate(prevSelectedStartDate);
                setAlertMessage("start date must be before end date");
                setShowAlert(true);
            }
            if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
                let ed = new Date(selectedEndDate).getTime();
                let sd = new Date(selectedStartDate).getTime();
                let key = '';
                switch (filterType) {
                    case "Request Date": {
                        key = 'requestDate';
                        break;
                    }
                    case "Dispatch Date": {
                        key = 'dispatchDate';
                        break;
                    }
                    case "Arrival Date": {
                        key = 'arrivalDate';
                        break;
                    }
                    default: return false;
                }

                const result = unchangedOrdersDispatch
                    .filter(d => {
                        let time;

                        if (filterType === 'Request Date') {
                            time = new Date(d[key]).getTime();
                        }

                        if (filterType === 'Dispatch Date') {
                            time = new Date(d[key]).getTime();
                        }

                        return (sd < time && time < ed);
                    });
                setLoading(false);
                setOrdersDispatch(result);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStartDate, selectedEndDate, filterType]);

    useEffect(() => {
        if (ordersDispatch.length === 0) {
            fetchData();

            // addOrderArchiveFix({
            //     "SOPS": "MadePharma SO-220616-288735 20172692 ZA003",
            //     "requestDate": "2022-06-27",
            //     "TT4": "not required",
            //     "STEM": "0",
            //     "TAG": "0",
            //     "dispatchDate": "2022-06-27",
            //     "boxes": {
            //         "label": 2,
            //         "btnIcon": "qrCodeOutline",
            //         "tooltip": "Check Label",
            //         "btnColor": "warning"
            //     },
            //     "boxTypes": [
            //         {
            //             "number": 1,
            //             "boxType": "STD CARBORD STK5 (5KG)",
            //             "boxTemperature": "Uncontrolled",
            //             "tempMonitorType": "",
            //             "tt4Serial": "",
            //             "width": "30",
            //             "height": "30",
            //             "length": "45",
            //             "weight": "8",
            //             "IsActive": "0",
            //             "quantity": 0,
            //             "VolWeight": "5.779",
            //             "ReportName": "STK5",
            //             "IsReturnable": "0",
            //             "name": "STD CARBORD STK5 (5KG)"
            //         },
            //         {
            //             "number": 1,
            //             "boxType": "STD CARBORD STK5 (5KG)",
            //             "boxTemperature": "Uncontrolled",
            //             "tempMonitorType": "",
            //             "tt4Serial": "",
            //             "width": "30",
            //             "height": "30",
            //             "length": "45",
            //             "weight": "8",
            //             "IsActive": "0",
            //             "quantity": 0,
            //             "VolWeight": "5.779",
            //             "ReportName": "STK5",
            //             "IsReturnable": "0",
            //             "name": "STD CARBORD STK5 (5KG)"
            //         }
            //     ],
            //     "waybill": {
            //         "label": "",
            //         "btnIcon": "documentOutline",
            //         "tooltip": "view shipping label PDF"
            //     },
            //     "arrivalDate": {
            //         "label": "",
            //         "btnIcon": "calendarOutline",
            //         "tooltip": "Confirm arrival",
            //         "btnColor": "warning"
            //     },
            //     "IWRS": {
            //         "label": "",
            //         "btnIcon": "flagOutline",
            //         "tooltip": "Waiting for IWRS Followup",
            //         "btnColor": "warning"
            //     },
            //     "aorTelTime": {
            //         "label": "",
            //         "btnIcon": "timeOutline",
            //         "tooltip": "Follow up call",
            //         "btnColor": "warning"
            //     },
            //     "aorDocs": {
            //         "icon": "documentOutline",
            //         "label": "",
            //         "tooltip": "Confirm AOR docs"
            //     },
            //     "sss": {
            //         "icon": "documentOutline",
            //         "label": "",
            //         "tooltip": "Reprint line clearance"
            //     },
            //     "Sponsor": "MADE Pharma",
            //     "StudyID": 326,
            //     "aramexNeeded": false,
            //     "sponsorName": "MADE Pharma",
            //     "sponsorShortName": "MADE Pharma",
            //     "siteNumber": "ZA003",
            //     "siteID": 1800,
            //     "studyNumber": "MadePharma",
            //     "studyID": 326,
            //     "externalOrderNumber": "SO-220616-288735",
            //     "cspl": "20172692",
            //     "packingListNumber": "20172692",
            //     "dispatchData": "2022-06-27",
            //     "wayBill": {
            //         "label": "",
            //         "btnIcon": "documentOutline",
            //         "tooltip": "view shipping label PDF"
            //     },
            //     "serviceType": "PEC",
            //     "boxReturns": false,
            //     "boxPickUp": false,
            //     "parcels": [
            //         {
            //             "parcel_number": "1",
            //             "quantity": 1,
            //             "length": 45,
            //             "width": 30,
            //             "height": 30,
            //             "weight": 8
            //         },
            //         {
            //             "parcel_number": "2",
            //             "quantity": 1,
            //             "length": 45,
            //             "width": 30,
            //             "height": 30,
            //             "weight": 8
            //         }
            //     ],
            //     "siteId": 1800
            // });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = () => {
        // getData('orders', 0, 99999999).then(res => {
        //     const data = res
        //     .map(({ data, id }) => {
        //         let tempData = JSON.parse(data);

        //         if(tempData && tempData.dispatchDate && isValidDate(new Date(tempData.dispatchDate))) {
        //             tempData.dispatchDate = format(new Date(tempData.dispatchDate), 'yyyy-MM-dd');
        //         }

        //         if(tempData && tempData.arrivalDate && tempData.arrivalDate.tooltip && isValidDate(new Date("20" + tempData.arrivalDate.tooltip))) {
        //             const arrivalDate = new Date("20" + tempData.arrivalDate.tooltip);

        //             const arrivalDateMonth = arrivalDate.getMonth();

        //             let correctedArrivalDate = arrivalDate;

        //             if(tempData && tempData.dispatchDate && isValidDate(new Date(tempData.dispatchDate))) {
        //                 const dispatchDate = new Date(tempData.dispatchDate);

        //                 const dispatchDateMonth = dispatchDate.getMonth();

        //                 if(dispatchDateMonth !== arrivalDateMonth) {
        //                     correctedArrivalDate = arrivalDate.setMonth(arrivalDateMonth + 1);
        //                 }
        //             }

        //             tempData.arrivalDate.tooltip = format(new Date(correctedArrivalDate), 'yyyy-MM-dd HH:mm');
        //         }

        //         tempData.id = id ? id : tempData.orderNumber;
        //         return tempData;
        //     })
        // .filter(o => o.isArchived);


        getData('archive_orders', 0, 99999999).then(res => {
            const data = res
                .map(({ data, id }) => {
                    let tempData = JSON.parse(data);

                    if (tempData && tempData.dispatchDate && isValidDate(new Date(tempData.dispatchDate))) {
                        tempData.dispatchDate = format(new Date(tempData.dispatchDate), 'yyyy-MM-dd');
                    }

                    if (tempData && tempData.arrivalDate && tempData.arrivalDate.tooltip && isValidDate(new Date("20" + tempData.arrivalDate.tooltip))) {
                        const arrivalDate = new Date("20" + tempData.arrivalDate.tooltip);

                        const arrivalDateMonth = arrivalDate.getMonth();

                        let correctedArrivalDate = arrivalDate;

                        if (tempData && tempData.dispatchDate && isValidDate(new Date(tempData.dispatchDate))) {
                            const dispatchDate = new Date(tempData.dispatchDate);

                            const dispatchDateMonth = dispatchDate.getMonth();

                            if (dispatchDateMonth !== arrivalDateMonth) {
                                correctedArrivalDate = arrivalDate.setMonth(arrivalDateMonth + 1);
                            }
                        }

                        tempData.arrivalDate.tooltip = format(new Date(correctedArrivalDate), 'yyyy-MM-dd HH:mm');
                    }

                    tempData.id = id ? id : tempData.orderNumber;

                    return tempData;
                });

            // console.log([...data]);

            setLoading(false);
            setOrdersDispatch([...data]);
            setUnchangedOrdersDispatch([...data]);
        });

        // });
    }

    // useEffect(() => {
    //     if(unchangedOrdersDispatch){
    //         const order = unchangedOrdersDispatch.filter((val) => {
    //             try{
    //                return val.boxTypes[0];
    //             } catch (e){
    //                 console.log('No orders found')
    //                 return false;
    //             } 
    //         })
    //         try{
    //            console.log('order found:', order) 
    //         } catch (e){

    //         }

    //     }
    // },[unchangedOrdersDispatch])

    useEffect(() => {

        const filterArr = ordersDispatch;
        if (searchQuery) {
            let tempOrders = filterArr.filter(od => {

                if (od && od.SOPS && od.SOPS.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (od && od.orderNumber && od.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (od && od.id && typeof id === 'string' && od.id.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (od && od.waybill && od.waybill.label && od.waybill.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (od && od.aramexReturn && od.aramexReturn.request && typeof od.aramexReturn.request === 'string' && od.aramexReturn.request.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                try {
                    if (od && od.boxTypes[0].aramexReturn && od.boxTypes[0].aramexReturn.request && typeof od.boxTypes[0].aramexReturn.request === 'string' && od.boxTypes[0].aramexReturn.request.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                    // for (var x in od.boxTypes){
                    //     if ('aramexReturn' in od.boxTypes[x]){

                    //     }
                    // }
                } catch (e) {
                    return false
                }

                return false;

            });

            setSearchResults(tempOrders);
            setCurrentPage(0)
        } else {
            setSearchResults(ordersDispatch);
            setCurrentPage(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, ordersDispatch]);

    const revisedOrdersDispatch = searchResults;

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    function handleArrivalDateChange(index) {
        let tempOrders = ordersDispatch;
        tempOrders[index].arrivalDate = "Arrived";
        setOrdersDispatch(tempOrders);
        sessionStorage.setItem('lt-orders-dispatch', JSON.stringify(tempOrders));
        setArrivalDateChangeIndex(null);
    }

    const offset = currentPage * PER_PAGE;

    const currentPageData = revisedOrdersDispatch ? revisedOrdersDispatch.slice(offset, offset + PER_PAGE) : [];

    const pageCount = revisedOrdersDispatch ? Math.ceil(revisedOrdersDispatch.length / PER_PAGE) : 0;

    const functions = {
        arrivalDate: (_, __, obj) => {
            const data = {
                sponsor: '',
                study: '',
                site: '',
                investigator: '',
                contact: '',
                devices: ['']
            };
            setShowConfirmArrivalModal(true);
            setUpdateReturn(obj);
            setConfirmArrivalData(data);
        },
        IWRS: (_, __, obj) => {
            setShowIwrsModal(true);
            setUpdateItem(obj);
        },
        sss: function (obj, _, c) {

            if (c && c.sss && c.sss.link) {
                setTimeout(() => {
                    window.open(c.sss.link);
                }, 3000);
                return;
            }

            if (obj && obj.sss && obj.sss.link) {
                setTimeout(() => {
                    window.open(obj.sss.link);
                }, 3000);
                return;
            } else if (obj && obj.orderNumber) {
                generatePdf("lineclearpdf", obj.orderNumber).then(res => {
                    setTimeout(() => {
                        window.open(res);
                    }, 3000);
                });
            }
        },
        TT4: (_, __, c) => {
            const tt4Data = {
                sponsor: '',
                study: '',
                site: '',
                investigator: '',
                contact: '',
                devices: ['']
            };
            setUpdateItem(c);
            setShowTt4Modal(true);
            setConfirmTt4Data(tt4Data);
        },
        SOPS: () => { },
        waybill: async (waybill, _, orderObject) => {
            if (orderObject && orderObject.aramex && orderObject.aramex.received) {
                window.open(orderObject.aramex.received);
                return;
            }
            if (orderObject && orderObject.orderNumber) {
                const res = await getOrderWaybill(orderObject.orderNumber);
                if (res && typeof res === 'string' && res !== "waybill not found") window.open(res);

                if (res && typeof res === 'object' && res.data !== false) {
                    window.open(res.data);
                } else {
                    window.open(`https://nservice.aramex.co.za/Home/GeneratePDFTemplateOne/?waybill_number=${waybill}`);
                };
                return;
            }
        },
        boxes: (_, index) => {
            const SOPS = ordersDispatch[index].SOPS.split(' ');
            setBoxLabelSops({
                study: SOPS[0],
                order: SOPS[1],
                cspl: SOPS[2],
                site: SOPS[3],
                boxes: ordersDispatch[index].boxes.label,
                boxIndex: 1
            });
            console.log(boxLabelSops, "passing sops")
            setShowBoxLabelModal(true);
        },
        aorTelTime: (a, b, c) => {
            setUpdateItem(c);

            setAorTelTimeData({
                sponsor: '',
                study: '',
                site: '',
                investigator: '',
                contact: '',
                devices: ['']
            });
            setShowAorTelTimeModal(true);
        },
        aorDocs: (obj) => {
            setUpdateItem(obj);
            setShowAorDocsModal(true);
        },
        delete: (_, __, obj) => {
            const { id } = obj;
            deleteItem('orders', { id }).then(res => fetchData());
        }
    };

    function excelArray() {
        const temp = [...revisedOrdersDispatch];

        const result = temp.map(r => {
            let keys = Object.keys(r);
            r = { ...r };
            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    if (k !== 'arrivalDate') r[k] = (r[k] && r[k].label) ? r[k].label : r[k];
                }
            });

            if (r['boxTypes'] && Array.isArray(r['boxTypes']) && r['boxTypes'].length > 0) {
                for (let i = 0; i < r['boxTypes'].length; i++) {
                    if (r['Temp Range']) r['Temp Range'] += ', ' + r['boxTypes'][i].boxTemperature;
                    if (!r['Temp Range']) r['Temp Range'] = r['boxTypes'][i].boxTemperature;

                    if (r['Type of Temp Monitor']) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                    if (!r['Type of Temp Monitor']) r['Type of Temp Monitor'] = r['boxTypes'][i].tempMonitorType;

                    if (r['Box Types']) r['Box Types'] += ', ' + r['boxTypes'][i].name;
                    if (!r['Box Types']) r['Box Types'] = r['boxTypes'][i].name;
                }

                r['Number of Temp Monitors'] = r['boxTypes'].length;
            }

            if (r['boxes']) r['Number of Boxes'] = r['boxes'];

            if (r['arrivalDate'] && r['arrivalDate'].tooltip) {
                r['Arrival Date'] = r['arrivalDate'].tooltip !== 'Confirm arrival' ? r['arrivalDate'] : 'N/A';
            } else {
                r['Arrival Date'] = "N/A";
            };

            const ad = r['arrivalDate'] && r['arrivalDate'].tooltip ? r['arrivalDate'].tooltip : 'N/A';

            delete r['arrivalDate'];
            delete r['edit'];
            delete r['id'];
            delete r['confirm'];
            delete r['view'];
            delete r['delete'];
            delete r['sss'];
            delete r['wayBill'];
            delete r['cspl'];
            delete r['boxes'];
            delete r['siteId'];
            delete r['siteID'];
            delete r['StudyID'];
            delete r['studyID'];
            delete r['parcels'];
            delete r['STEM'];
            delete r['TAG'];
            delete r['SOPS'];
            delete r['IWRS'];
            delete r['aorDocs'];
            delete r['boxTypes'];
            delete r['aorTelTime'];
            delete r['serviceType'];
            delete r['aramex'];
            delete r['iwrsConfirm'];
            delete r['iwrsComments'];
            delete r['aorDocsComments'];
            delete r['aorDocsReceived'];
            delete r['mawb'];
            delete r['sponsorID'];
            delete r['SponsorID'];
            delete r['tt4Serial'];
            delete r['tt4Received'];
            delete r['tt4PickupDate'];
            delete r['tt4LatestEnrouteDate'];
            delete r['aorTelTimeComments'];
            delete r['aorTelTimeNoDocsReceived'];
            delete r['dispatchData'];
            delete r['aramexNeeded'];
            delete r['sponsorShortName'];
            delete r['tt4Enroute'];
            delete r['statusCode'];
            delete r['retryStatus'];

            if (!r['tt4Excursion']) r['tt4Excursion'] = "no";
            if (r['tt4Excursion'] === "N/A") r['tt4Excursion'] = "no";
            if (!r['TT4']) r['TT4'] = "N/A";
            if (!r['siteNumber']) r['siteNumber'] = "N/A";
            if (!r['requestDate']) r['requestDate'] = "N/A";
            if (!r['sponsorName']) r['sponsorName'] = "N/A";
            if (!r['studyNumber']) r['studyNumber'] = "N/A";
            if (!r['packingListNumber']) r['packingListNumber'] = "N/A";
            if (!r['externalOrderNumber']) r['externalOrderNumber'] = "N/A";

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1").trim();
                const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });

            delete r['T T4'];
            delete r["Retry Status"];
            delete r["Status Code"];
            delete r["Tt4call Made"];
            delete r["Send To Destruction"];

            if (ad && typeof ad === 'string' && ad.includes('-0-')) {
                let tempArrival = ad;
                // tempArrival = '20' + r["Arrival  Date"];
                let tempArrivalArr = tempArrival.split('-');

                if (tempArrivalArr && Array.isArray(tempArrivalArr)) {
                    tempArrivalArr[1] = tempArrivalArr[1] + '1';

                    r["Arrival  Date"] = tempArrivalArr[0] + '-' + tempArrivalArr[1] + '-' + tempArrivalArr[2];
                }
            }

            if (ad && typeof ad === 'string' && ad.includes('20202')) {
                if (ad.length === 18) {
                    r["Arrival  Date"] = ad.slice(2, 12)
                } else if (r["Dispatch Date"] && r["Dispatch Date"].includes('202')) {
                    r["Arrival  Date"] = r["Dispatch Date"].slice(0, 7);
                }
            }

            const final = {
                "Sponsor Name": r["Sponsor Name"],
                "Study Number": r["Study Number"],
                "Order Number": r["Order Number"],
                "Waybill": r["Waybill"],
                "Site Number": r["Site Number"],
                "Request Date": r["Request Date"],
                "Dispatch Date": r["Dispatch Date"],
                "Packing List Number": r["Packing List Number"],
                "External Order Number": r["External Order Number"],
                "Temp Range": r["Temp  Range"],
                "Type of Temp Monitor": r["Type of  Temp  Monitor"],
                "Box Types": r["Box  Types"],
                "Number of Temp Monitors": r["Number of  Temp  Monitors"],
                "Number of Boxes": r["Number of  Boxes"],
                "Arrival Date": r["Arrival  Date"],
                "Tt4 Excursion": r["Tt4 Excursion"]
            };

            return final;
        });

        return result;
    }

    function clearFilters() {
        setFilterType('none');
    }

    return (
        <>
            {!displayOrder && <BaseTemplate title="Orders Archive">
                <div>
                    <Search
                        setSearchQuery={setSearchQuery}
                        placeholder="Search"
                    />
                    <IonGrid>
                        <IonRow>
                            <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"} offset-md="1">
                                <IonItem className="shadow-xl">
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        Filter Type
                                    </IonLabel>
                                    <IonSelect interface="popover" value={filterType} onIonChange={e => setFilterType(e.detail.value)}>
                                        <IonSelectOption value="none">
                                            None
                                        </IonSelectOption>
                                        <IonSelectOption value="Request Date">
                                            Request Date
                                        </IonSelectOption>
                                        <IonSelectOption value="Dispatch Date">
                                            Dispatch Date
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                            {filterType !== 'none' && <>
                                <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                    <IonItem
                                        className="shadow-xl"
                                        onClick={(e) => setShowStartDate({ showPopover: true, event: e })}
                                    >
                                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                            Start Date
                                        </IonLabel>
                                        <IonDatetime
                                            displayFormat="D MMM YYYY H:mm"
                                            min="1997"
                                            max="2021"
                                            value={selectedStartDate}
                                            readonly={true}
                                        ></IonDatetime>
                                    </IonItem>
                                </IonCol>
                                <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                    <IonItem
                                        className="shadow-xl"
                                        onClick={(e) => setShowEndDate({ showPopover: true, event: e })}
                                    >
                                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                            End Date
                                        </IonLabel>
                                        <IonDatetime
                                            displayFormat="D MMM YYYY H:mm"
                                            min="1997"
                                            max="2021"
                                            value={selectedEndDate}
                                            readonly={true}
                                        ></IonDatetime>
                                    </IonItem>
                                </IonCol></>}
                            <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"}>
                                <IonButton expand="full" style={{ height: '50px', marginTop: 0 }} onClick={() => exportArrayToExcel(excelArray(), `orders-archive-${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`)}>
                                    export to excel
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        {filterType !== 'none' && <IonRow>
                            <IonCol offset-md="1.05">
                                <span style={{ cursor: 'pointer', color: 'var(--ion-color-primary)' }} onClick={() => clearFilters()}>clear filters</span>
                            </IonCol>
                        </IonRow>}
                    </IonGrid>
                </div>
                <div className="users" style={{ paddingBottom: "5em" }}>
                    <IonGrid>
                        <IonRow style={{ boxShadow: "none" }}>
                            <IonCol offset-xl="1" size-xl="10">
                                {loading && <Loading loading={loading} />}
                                {!loading && (
                                    <>
                                        {currentPageData && currentPageData.length > 0 ? <TableList
                                            headings={headings}
                                            items={currentPageData}
                                            labels={labels}
                                            functions={functions}
                                        /> : <h1>No search results</h1>}
                                    </>
                                )}
                                <div style={{ margin: "1em 1em" }}>
                                    <ReactPaginate
                                        previousLabel={"←"}
                                        nextLabel={"→"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                    />
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonAlert
                        isOpen={error !== ""}
                        onDidDismiss={() => setError("")}
                        header={"Error"}
                        message={error}
                        buttons={["OK"]}
                    />
                </div>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass="my-custom-class"
                    header={"Alert"}
                    message={alertMessage}
                    buttons={["OK"]}
                />
                <Modal showModal={showBoxLabelModal} setShowModal={setShowBoxLabelModal} onDidDismiss={() => {
                    setShowBoxLabelModal(false);
                }}>
                    <BoxLabel sops={boxLabelSops} />
                    {boxLabelSops && (Number(boxLabelSops.boxes) > 1) &&
                        <div style={{ margin: "1em 1em" }}>
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={Number(boxLabelSops.boxes)}
                                onPageChange={({ selected: selectedPage }) => {
                                    setBoxLabelSops({
                                        ...boxLabelSops,
                                        boxIndex: selectedPage + 1
                                    });
                                }}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                        </div>}
                </Modal>
                <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{ visibility: 'hidden' }} onIonChange={e => handleArrivalDateChange(arrivalDateChangeIndex, e.detail.value)}></IonDatetime>

                <Modal showModal={showTt4Modal} setShowModal={setShowTt4Modal}>
                    <ConfirmTt4 setShowModal={setShowTt4Modal} data={confirmTt4Data} updateReturn={updateItem} />
                </Modal>
                <Modal
                    showModal={showConfirmArrivalModal}
                    setShowModal={setShowConfirmArrivalModal}
                >
                    <ConfirmArrival
                        data={confirmArrivalData}
                        setShowModal={setShowConfirmArrivalModal}
                        orderObject={updateReturn}
                    />
                </Modal>
                <Modal showModal={showIwrsModal} setShowModal={setShowIwrsModal}>
                    <IWRSFollowup setShowModal={setShowIwrsModal} updateReturn={updateItem} showModal={showIwrsModal} />
                </Modal>
                <Modal
                    showModal={showAorTelTimeModal}
                    setShowModal={setShowAorTelTimeModal}
                >
                    <AorTelTimeEmailFollowup
                        data={aorTelTimeData}
                        updateReturn={updateItem}
                        setShowModal={setShowAorTelTimeModal}
                    />
                </Modal>
                <Modal
                    showModal={showAorDocsModal}
                    setShowModal={setShowAorDocsModal}
                >
                    <AorDocsFollowup
                        updateOrder={updateItem}
                        fetchData={fetchData}
                        setShowModal={setShowAorDocsModal}
                    />
                </Modal>
            </BaseTemplate>}
            {displayOrder && <OrdersProcessing orderObject={displayOrder} setDisplayOrder={setDisplayOrder} />}
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                position="bottom"
                duration={2000}
                color="success"
            />
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showStartDate.showPopover}
                event={showStartDate.event}
                onDidDismiss={() => setShowStartDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedStartDate(day.toString()); setShowStartDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowStartDate({ showPopover: false, event: undefined })}>Close</IonButton>

            </IonPopover>
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showEndDate.showPopover}
                event={showEndDate.event}
                onDidDismiss={() => setShowEndDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedEndDate(day.toString()); setShowEndDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowEndDate({ showPopover: false, event: undefined })}>Close</IonButton>

            </IonPopover>
        </>
    );
}

export default Users;
