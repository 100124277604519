import React, { useState, useEffect, useRef } from "react";
import {
IonAlert,
IonFab,
IonFabButton,
IonIcon,
IonGrid,
IonCol,
IonRow,
IonDatetime,
IonToast
} from "@ionic/react";
import Fuse from "fuse.js";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import Search from "../../components/Search/Search";
import { add } from "ionicons/icons";
import Loading from "../../components/Loading";
import TableList from "../../components/TableList/TableList";
import Modal from '../../components/Modal';
import NewSite from '../../components/NewSite';
import { getData, deleteItem } from '../../apis/logictrials';

import decode from 'jwt-decode';

const PER_PAGE = 15;

function ManageShipments() {
const [error, setError] = useState("");
const [searchQuery, setSearchQuery] = useState("");
const [searchResults, setSearchResults] = useState([]);
const [sites, setSites] = useState([]);
const [currentPage, setCurrentPage] = useState(0);
const [loading, setLoading] = useState(true);
const [showModal, setShowModal] = useState(false);
const dateTimeRef = useRef();
const [showToast, setShowToast] = useState(false);
const [toastMessage, setToastMessage] = useState();
const [editSite, setEditSite] = useState();
const [initialSites, setInitialSites] = useState([]);

// delete
const [showConfirmDelete, setShowConfirmDelete] = useState(false);
const [deleteId, setDeleteId] = useState(null);

let headings = [
    "sponsor", 
    "study",
    "number",
    "contact",
    "email",
    "tel",
    "investigator",
    "buildingBlockName",
    "streetName",
    "suburb",
    "city",
    "code",
    "specialDelivery",
    "delete"
];

let labels = [
    "Sponsor", 
    "Study",
    "Number",
    "Contact",
    "Email",
    "Tel",
    "Investigator",
    "Building/Block Name",
    "Street Name",
    "Suburb",
    "City",
    "Code",
    "Special Delivery",
    ""
];

const accessToken = sessionStorage.getItem('lt-access-token');

const decoded = accessToken ? decode(accessToken) : false;

let formattedPermissions = [];

if (decoded && decoded.perms) {
    const permissions = decoded.perms;

    formattedPermissions = permissions.map(p => {
        const toIndex = p.lastIndexOf('_') + 1;
        return p.slice(0, toIndex);
    });
}

if(!formattedPermissions.includes('sites_d_')) headings = headings.filter(h => h !== "delete");

if(!formattedPermissions.includes('sites_d_')) labels.pop();

useEffect(() => {
    setLoading(false);
    fetchData();

// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
    if(!sites || !initialSites || (sites.length !== initialSites.length)) {
        setToastMessage('Sites updated');
        setShowToast(true);
        setInitialSites(sites);
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [sites]);

const fetchData = () => {
    getData('sites', 0, 9999).then(res => {
        const data = res.map(({ data, id }) => {
            let tempData = JSON.parse(data);
            tempData.id = id;
            return tempData;
        });
        setLoading(false);
        setSites(data);
        setInitialSites(data);
    });
}

useEffect(() => {
    if(sites && sites.length > 0) {
        const sitesFuse = new Fuse(sites, {
            keys: [
                "sponsor",
                "study",
                "number",
                "contact",
                "email",
                "tel",
                "investigator",
                "buildingBlockName",
                "streetName",
                "suburb",
                "city",
                "code",
            ],
        });
        setSearchResults(sitesFuse.search(searchQuery));
    }
}, [searchQuery, sites]);

const revisedSites =
    searchResults.length > 0 ? searchResults.map((item) => item.item) : sites;

function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
}

const offset = currentPage * PER_PAGE;

const currentPageData = revisedSites ? revisedSites.slice(offset, offset + PER_PAGE) : [];

const pageCount = revisedSites ?  Math.ceil(revisedSites.length / PER_PAGE) : 1;

return (
    <BaseTemplate title="Manage Sites">
        <div>
            <Search
            setSearchQuery={setSearchQuery}
            placeholder="Search"
            />
        </div>
        <div className="users" style={{ paddingBottom: "5em" }}>
            <IonGrid>
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol offset="0" size="12">
                {loading && <Loading loading={loading} />}
                {!loading && (
                    <TableList
                    className="sites"
                        headings={headings}
                        items={currentPageData}
                        labels={labels}
                        functions={{
                            sponsor: (obj) => {
                                setEditSite(obj);
                                setShowModal(true);
                            },
                            delete: (_, __, obj) => {
                                setShowConfirmDelete(true);
                                const { id } = obj;
                    
                                setDeleteId(id);
                            }
                        }}
                    />
                )}
                <div style={{ margin: "1em 1em" }}>
                    <ReactPaginate
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
                </IonCol>
            </IonRow>
            </IonGrid>
            <IonAlert
            isOpen={error !== ""}
            onDidDismiss={() => setError("")}
            header={"Error"}
            message={error}
            buttons={["OK"]}
            />
        </div>
        {formattedPermissions && formattedPermissions.includes('sites_c_') && <IonFab
            vertical="bottom"
            horizontal="start"
            slot="fixed"
            style={{
                marginBottom: window.innerWidth < 900 ? "3em" : "5em",
                position: "fixed",
                marginRight: "2em",
            }}
        >
            <IonFabButton onClick={() => setShowModal(true)}>
                <IonIcon icon={add}></IonIcon>
            </IonFabButton>
        </IonFab>}
    {showModal && <Modal showModal={showModal} setShowModal={setShowModal} className="sites-modal">
        <NewSite editSite={editSite} setShowModal={setShowModal} fetchData={fetchData} setEditSite={setEditSite} />
    </Modal>}
    <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{visibility: 'hidden'}}></IonDatetime>
        <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            position="bottom"
            duration={2000}            
            color="success"
        />
        <IonAlert
            isOpen={showConfirmDelete}
            onDidDismiss={() => setShowConfirmDelete(false)}
            message={'Are you sure you want to delete<br /> this item?'}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => setShowConfirmDelete(false)
                },
                {
                    text: 'Delete',
                    cssClass: 'danger',
                    handler: () => {
                        if(deleteId) deleteItem('sites', { id: deleteId }).then(res => fetchData());
                    }
                }
            ]}
        />
    </BaseTemplate>
);
}

export default ManageShipments;
