import React, { useState, useEffect, useRef } from "react";
import {
    IonAlert,
    IonFab,
    IonFabButton,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonDatetime,
} from "@ionic/react";
import Fuse from "fuse.js";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import Search from "../../components/Search/Search";
import { add } from "ionicons/icons";
import Loading from "../../components/Loading";
import TableList from "../../components/TableList/TableList";
import Modal from '../../components/Modal';
import NewStudy from '../../components/NewStudy';
import { getData, deleteItem } from '../../apis/logictrials';

import decode from 'jwt-decode';

const PER_PAGE = 15;

function ManageStudies() {
const [error, setError] = useState("");
const [searchQuery, setSearchQuery] = useState("");
const [searchResults, setSearchResults] = useState([]);
const [studies, setStudies] = useState([]);
const [currentPage, setCurrentPage] = useState(0);
const [loading, setLoading] = useState(true);
const [showModal, setShowModal] = useState(false);
const [editStudy, setEditStudy] = useState();
const dateTimeRef = useRef();

// delete
const [showConfirmDelete, setShowConfirmDelete] = useState(false);
const [deleteId, setDeleteId] = useState(null);

const headings = [
    "studyNumber",
    "sponsor",
    "contactName",
    "sponsorNumber",
    "contactEmail",
    "groupEmails",
    "delete"
];

const labels = [
    "Study Number",
    "Sponsor",
    "Contact Name",
    "Sponsor Number",
    "Contact Email",
    "Group Emails",
    ""
];

const accessToken = sessionStorage.getItem('lt-access-token');

const decoded = accessToken ? decode(accessToken) : false;

let formattedPermissions = [];

if (decoded && decoded.perms) {
    const permissions = decoded.perms;

    formattedPermissions = permissions.map(p => {
        const toIndex = p.lastIndexOf('_') + 1;
        return p.slice(0, toIndex);
    });
}

if(!formattedPermissions.includes('study_d_')) headings = headings.filter(h => h !== "delete");

if(!formattedPermissions.includes('study_d_')) labels.pop();

useEffect(() => {
    if (studies.length === 0) {
        fetchData();
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

function fetchData() {
    getData('study', 0, 9999).then(res => {
        const data = res.map(({ data, id }) => {
            let td = JSON.parse(data);

            td.id = id;

            return td;
        });
        setStudies(data);
        setLoading(false);
    });
}

useEffect(() => {
    const studiesFuse = new Fuse(studies, {
        keys: [
            "studyNumber",
            "sponsor",
            "contactName",
            "sponsorNumber",
            "contactEmail",
            "groupEmails",
        ],
    });
    setSearchResults(studiesFuse.search(searchQuery));

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchQuery, studies]);

const studiesFuse =
    searchResults.length > 0 ? searchResults.map((item) => item.item) : studies;

function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
}

const offset = currentPage * PER_PAGE;

const currentPageData = studiesFuse.slice(offset, offset + PER_PAGE);

const pageCount = Math.ceil(studiesFuse.length / PER_PAGE);

return (
    <BaseTemplate title="Manage Studies">
        <div>
            <Search
            setSearchQuery={setSearchQuery}
            placeholder="Search"
            />
        </div>
        <div className="users" style={{ paddingBottom: "5em" }}>
            <IonGrid>
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol offset="1" size="10">
                {loading && <Loading loading={loading} />}
                {!loading && (
                    <TableList
                        headings={headings}
                        items={currentPageData}
                        labels={labels}
                        functions={{
                            studyNumber: (obj) => {
                                setEditStudy(obj);
                                setShowModal(true);
                            },
                            delete: (_, __, obj) => {
                                setShowConfirmDelete(true);
                                const { id } = obj;
                    
                                setDeleteId(id);
                            }
                        }}
                    />
                )}
                <div style={{ margin: "1em 1em" }}>
                    <ReactPaginate
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
                </IonCol>
            </IonRow>
            </IonGrid>
            <IonAlert
            isOpen={error !== ""}
            onDidDismiss={() => setError("")}
            header={"Error"}
            message={error}
            buttons={["OK"]}
            />
        </div>
        {formattedPermissions && formattedPermissions.includes('study_c_') && <IonFab
            vertical="bottom"
            horizontal="start"
            slot="fixed"
            style={{
                marginBottom: window.innerWidth < 900 ? "3em" : "5em",
                position: "fixed",
                marginRight: "2em",
            }}
        >
            <IonFabButton onClick={() => setShowModal(true)}>
                <IonIcon icon={add}></IonIcon>
            </IonFabButton>
        </IonFab>}
    {showModal && <Modal showModal={showModal} setShowModal={setShowModal} setEditStudy={setEditStudy} >
        <NewStudy editStudy={editStudy} setEditStudy={setEditStudy} />
    </Modal>}
    <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{visibility: 'hidden'}}></IonDatetime>
    <IonAlert
        isOpen={showConfirmDelete}
        onDidDismiss={() => setShowConfirmDelete(false)}
        message={'Are you sure you want to delete<br /> this item?'}
        buttons={[
            {
                text: 'Cancel',
                role: 'cancel',
                handler: () => setShowConfirmDelete(false)
            },
            {
                text: 'Delete',
                cssClass: 'danger',
                handler: () => {
                    if(deleteId) deleteItem('study', { id: deleteId }).then(res => fetchData());
                }
            }
        ]}
    />
    </BaseTemplate>
);
}

export default ManageStudies;
