import "./SecondaryButton.css";

import { IonButton, IonIcon } from "@ionic/react";

const SecondaryButton = ({
    text,
    action,
    width,
    link,
    className,
    color,
    icon,
}) => {
    return (
        <IonButton
            onClick={action}
            className={`btn-secondary ${className}`}
            style={
                width
                    ? { width: width, color: color, outline: 'none' }
                    : { color: color, outline: 'none' }
            }
            routerLink={link}
            color={color}
            fill="outline"
        >
            {icon && <IonIcon icon={icon.svg} slot={icon.slot} />}
            {text}
        </IonButton>
    );
};

export default SecondaryButton;
