import React, { useState, useEffect } from "react";
import {
  IonCard,
  IonButton,
  IonCardContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { changePassword, passwordChangeTokenCheck } from "../apis/logictrials";
import { parseQueryString } from "../utils";

export default function ChangePassword({ id }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const urlQueries = parseQueryString();

    const { token } = urlQueries;

    if (token && id) {
      passwordChangeTokenCheck(id, token).then((res) => {
        console.log(res);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function reset() {
    if (id) {
      changePassword(id, newPassword, confirmNewPassword).then((res) => {
        console.log(res.statusCode);
        if (res) {
          if (res.statusCode === "OK") {
            setIsSuccess(true);
          } else {
            setErrorMessage(res.statusCode);
          }
        } else {
          setErrorMessage("There was an error changing the password");
        }
      });
    }
  }

  return (
    <IonGrid>
      {!isSuccess && (
        <IonRow style={{ boxShadow: "none" }}>
          <IonCol offset-md="3" size-md="6">
            <IonCard className="shadow-xl">
              <IonCardContent>
                <div style={{ textAlign: "center", margin: "1.5em 0" }}>
                  <h2 style={{ fontSize: "2.5em" }}>Reset Password</h2>
                </div>
                <div style={{ marginTop: "2em" }}>
                  <IonItem lines="none">
                    <IonInput
                      type="password"
                      required
                      value={newPassword}
                      onIonChange={(e) => setNewPassword(e.detail.value)}
                      placeholder="New password"
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" style={{ marginTop: "1em" }}>
                    <IonInput
                      type="password"
                      required
                      value={confirmNewPassword}
                      onIonChange={(e) => setConfirmNewPassword(e.detail.value)}
                      placeholder="Confirm new password"
                    ></IonInput>
                  </IonItem>
                  <IonCol>
                    {errorMessage !== "" && (
                      <IonItem lines="none">
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      </IonItem>
                    )}
                  </IonCol>
                  <IonButton
                    onClick={reset}
                    type="submit"
                    expand="full"
                    // style={{ marginTop: "3em" }}
                  >
                    Reset
                  </IonButton>
                </div>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      )}
      {isSuccess && (
        <IonRow style={{ boxShadow: "none" }}>
          <IonCol offset-md="3" size-md="6">
            <IonCard className="shadow-xl">
              <IonCardContent>
                <div
                  style={{ textAlign: "center", margin: "1.5em 0" }}
                  className="flex items-center justify-center w-full"
                >
                  <p>Password changed successfully</p>
                </div>
                <div style={{ marginTop: "2em" }}>
                  <IonButton
                    href="/login"
                    type="submit"
                    expand="full"
                    style={{ marginTop: "3em" }}
                  >
                    Log in
                  </IonButton>
                </div>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
}
