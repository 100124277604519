import React, { useState, useEffect } from 'react';
import { IonToast } from '@ionic/react';

const OfflineMode = () => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(true);
    setTimeout(() => {
        setShowToast(false);
    }, [1]);

    window.addEventListener('online', () => setShowToast(false));
    window.addEventListener('offline', () => setShowToast(true));

  }, []);

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="No network connection"
        position="bottom"
        buttons={[
            {
              text: 'Okay',
              role: 'cancel',
              handler: () => {
                setShowToast(false)
              }
            }
          ]}
      />
    </>
  );
};

export default OfflineMode;