import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonIcon, IonInput, IonItem, IonButton, IonSelectOption, IonSelect, IonModal, IonToast, IonAlert, IonPopover } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { updateItem } from '../../apis/logictrials';
import { isValidDate } from '../../utils';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';

import './ConfirmArrivalInbound.css';

const EMPTY_DEVICE = {
    tempDeviceNumber: '',
    excursion: 'No Excursion'
};

export default function ConfirmArrivalInbound({ inboundObject, setShowConfirmArrivalModal, fetchData }) {

    const [step, setStep] = useState(1);
    const [certAnalysis, setCertAnalysis] = useState(false);
    const [drugAccountability, setDrugAccountability] = useState();
    const [time, setTime] = useState(null);

    const [selectedDate, setSelectedDate] = useState();
    const [showSelectedDate, setShowSelectedDate] = useState({ showPopover: false, event: undefined });

    const [devices, setDevices] = useState([...Array((inboundObject && inboundObject.boxes && !isNaN(Number(inboundObject.boxes))) ? Number(inboundObject.boxes) : 1)].map(() => EMPTY_DEVICE));

    const submit = () => {
        let tempInbound = inboundObject;

        let dateWithTime;
        if ((selectedDate && isValidDate(new Date(selectedDate))) && time) {
            const hoursMins = time.split(':');

            let tempDate = new Date(selectedDate);
            dateWithTime = tempDate.setHours(hoursMins[0]);
            dateWithTime = tempDate.setMinutes(hoursMins[1]);

            dateWithTime = new Date(dateWithTime).toISOString();
        }

        if ((dateWithTime && isValidDate(new Date(dateWithTime))) && tempInbound) {
            tempInbound.confirmArrival = format(new Date(dateWithTime), 'yyyy-MM-dd HH:mm');
        } else if (isValidDate(new Date(selectedDate)) && tempInbound) {
            tempInbound.confirmArrival = format(new Date(selectedDate), 'yyyy-MM-dd HH:mm');
        }

        const filtered = devices.filter(d => d.tempDeviceNumber !== '');

        tempInbound.devices = filtered;

        tempInbound.certAnalysis = certAnalysis;
        tempInbound.drugAccountability = drugAccountability;
        updateItem('inbound', ['data'], [tempInbound], { id: inboundObject.db_id }).then(res => fetchData());

        setShowConfirmArrivalModal(false);
    }

    function setTempDeviceNumber(index, value) {
        let tempDevices = devices;

        tempDevices[index] = { ...tempDevices[index], tempDeviceNumber: value };

        setDevices([...tempDevices]);
    }

    function setExcursion(index, value) {
        let tempDevices = devices;

        tempDevices[index] = { ...tempDevices[index], excursion: value };

        setDevices([...tempDevices]);
    }

    function addDevice() {
        setDevices([...devices, EMPTY_DEVICE]);
    }

    return (
        <div className="confirm-arrival-i">
            {step === 1 && <IonGrid style={{ marginTop: '2em' }}>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Cert Analysis</IonLabel>
                            <IonCheckbox checked={certAnalysis} onIonChange={e => setCertAnalysis(e.detail.checked)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Drug Accountability</IonLabel>
                            <IonCheckbox checked={drugAccountability} onIonChange={e => setDrugAccountability(e.detail.checked)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <IonItem lines="none">
                            <IonInput onClick={(e) => setShowSelectedDate({ showPopover: true, event: e })} placeholder="Confirm date" value={selectedDate ? format(new Date(selectedDate), 'MM d y') : ''} />
                        </IonItem>
                    </IonCol>
                    <IonCol size="6">
                        <IonItem lines="none">
                            Confirm time: <span style={{ width: 20 }} /> <IonInput min="08:00" max="17:00" placeholder="Collection Time" type="time" value={time} onIonChange={e => setTime(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{ textAlign: 'right' }}>
                        <IonButton color="warning" onClick={() => setStep(2)}>
                            Next
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>}
            {step === 2 && <IonGrid className="step-2">
                <IonRow>
                    <IonCol>
                        <h2 className="text-2xl my-0">What has arrived?</h2>
                    </IonCol>
                </IonRow>
                <IonRow size="6">
                    {devices.map((d, i) => (
                        <IonCol size="6" style={{ padding: '80px 40px' }}>
                            Shipper {i + 1} - <i>{inboundObject && inboundObject.boxTemperature}</i>
                            <IonInput placeholder="Temp Device Number" value={d.tempDeviceNumber} onIonChange={e => setTempDeviceNumber(i, e.detail.value)} />
                            <IonSelect interface="popover" value={d.excursion} onIonChange={e => setExcursion(i, e.detail.value)}>
                                <IonSelectOption value="No Excursion">No Excursion</IonSelectOption>
                                <IonSelectOption value="Excursion">Excursion</IonSelectOption>
                            </IonSelect>
                        </IonCol>
                    ))}
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton color="primary" onClick={addDevice}><IonIcon icon={addOutline} slot="start" />Add another device</IonButton>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol style={{ textAlign: 'right', marginTop: '2em' }}>
                        <IonButton color="warning" expand="full" onClick={submit}>
                            Complete
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>}

            <IonModal
                cssClass="day-modal-picker"
                isOpen={showSelectedDate.showPopover}
                event={showSelectedDate.event}
                onDidDismiss={() => setShowSelectedDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedDate(day.toString()); setShowSelectedDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowSelectedDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
        </div>
    )
}
