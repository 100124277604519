import React, { useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';

export default function AddWeight({
    returnObject,
    setShowModal,
    fetchData
}) {
    const [updatedWeight, setUpdatedWeight] = useState('');

    useEffect(() => {
        if(returnObject && returnObject.weight && typeof returnObject.weight === 'string') setUpdatedWeight(returnObject.weight.replace('kg', ''));

        if(returnObject && returnObject.weight && typeof returnObject.weight.label === 'string') setUpdatedWeight(returnObject.weight.label.replace('kg', ''));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function submit() {
        let tempObj = returnObject;
        tempObj.weight = {
            label: updatedWeight + 'kg',
            btnColor: "warning",
            btnIcon: "addOutline",
            tooltip: "Add weight",
        };

        await updateItem('returns', ['data'], [tempObj], { id: returnObject.id });
        
        fetchData();
        setShowModal(false);
    }

    return (
        <div style={{ marginTop: '2em', margin: '5%' }}>
            <input className="general" style={{width: '100%', marginBottom: '2em'}} placeholder="Weight (kg)" value={updatedWeight} onChange={(e) => setUpdatedWeight(e.target.value)}/>
            <IonButton style={{width: '100%'}} onClick={submit}>Add</IonButton>
        </div>
    )
}
