import React from "react";
import {
  IonModal,
  IonIcon,
  IonContent
} from "@ionic/react";
import { close } from "ionicons/icons";

import './Modal.css';

const Modal = ({
  children,
  showModal,
  setShowModal,
  onDidDismiss,
  className
}) => {

  return (
    <IonModal isOpen={showModal} cssClass={`generic-modal ${className ? className : ''}`} onDidDismiss={onDidDismiss ? onDidDismiss : () => setShowModal(false)}>
      <IonContent>
      <div className="close-button">
        <IonIcon
          onClick={() => { 
            setShowModal(false);
          }}
          size="large"
          style={{ position: "absolute", right: "0.5em" }}
          icon={close}
        ></IonIcon>
      </div>
      <div style={{ padding: 24, position: "relative", height: "92.5%", marginTop: '2em', width: '90%' }}>
        <div style={{ position: "absolute", top: "0", width: "100%", height: '85%' }}>
            {children}
        </div>
      </div>
      </IonContent>
    </IonModal>
  );
};

export default Modal;
