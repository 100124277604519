const ia = [
    {
        "id": "IN21-06_17",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-657363764",
        "zarValue": "R2128279.56",
        "receivingDate": "2021-06-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-05 07:59",
        "qaCheck": "2021-07-05 10:14",
        "inventoryAdded ": "2021-07-05 08:57",
        "sss": "2021-07-05 10:14"
    },
    {
        "id": "IN21-06_19",
        "sponsor": "CATALENT",
        "study": "BP40657",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "020-94250590",
        "zarValue": "R0.00",
        "receivingDate": "2021-07-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-13 07:22",
        "qaCheck": "2021-07-13 08:30",
        "inventoryAdded ": "2021-07-13 08:32",
        "sss": "2021-07-13 08:34"
    },
    {
        "id": "IN21-07_02",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-65736720",
        "zarValue": "R0.00",
        "receivingDate": "2021-07-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-02 09:04",
        "qaCheck": "2021-07-02 10:23",
        "inventoryAdded ": "2021-07-02 09:12",
        "sss": "2021-07-02 10:24"
    },
    {
        "id": "IN21-07_03",
        "sponsor": "CATALENT",
        "study": "GA29145",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-65736775",
        "zarValue": "R177745.00",
        "receivingDate": "2021-07-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-05 08:00",
        "qaCheck": "2021-07-05 10:18",
        "inventoryAdded ": "2021-07-05 09:00",
        "sss": "2021-07-05 10:18"
    },
    {
        "id": "IN21-07_04",
        "sponsor": "ROCHE SA",
        "study": "BO41423",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-11810886",
        "zarValue": "R6263821.67",
        "receivingDate": "2021-07-10                           ",
        "email": "2021-07-07 11:51",
        "confirmArrival": "2021-07-14 09:25",
        "qaCheck": "2021-07-14 09:44",
        "inventoryAdded ": "2021-07-14 09:44",
        "sss": "2021-07-14 09:44"
    },
    {
        "id": "IN21-07_05",
        "sponsor": "ALMAC",
        "study": "C4591031 ",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16624585",
        "zarValue": "R558073.00",
        "receivingDate": "2021-07-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-16 09:55",
        "qaCheck": "2021-07-20 15:12",
        "inventoryAdded ": "2021-07-20 15:32",
        "sss": "2021-07-20 15:34"
    },
    {
        "id": "IN21-07_06",
        "sponsor": "ALMAC",
        "study": "C4591031 ",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16624185",
        "zarValue": "R69241.70",
        "receivingDate": "2021-07-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-16 08:34",
        "qaCheck": "2021-07-23 08:25",
        "inventoryAdded ": "2021-07-23 08:29",
        "sss": "2021-07-23 08:31"
    },
    {
        "id": "IN21-07_07",
        "sponsor": "ALMAC",
        "study": "RPC01-3102",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QUICKSTAT",
        "mawb": "195540092",
        "zarValue": "R13813.40",
        "receivingDate": "2021-07-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-21 11:24",
        "qaCheck": "2021-07-23 08:34",
        "inventoryAdded ": "2021-07-23 08:36",
        "sss": "2021-07-23 08:37"
    },
    {
        "id": "IN21-07_08",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "711738008",
        "zarValue": "R281118.42",
        "receivingDate": "2021-07-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-16 08:28",
        "qaCheck": "2021-07-23 10:13",
        "inventoryAdded ": "2021-07-16 08:44",
        "sss": "2021-07-23 10:14"
    },
    {
        "id": "IN21-07_10",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-22205083",
        "zarValue": "R1406515.36",
        "receivingDate": "2021-07-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-26 13:01",
        "qaCheck": "2021-07-27 09:05",
        "inventoryAdded ": "2021-07-27 09:05",
        "sss": "2021-07-27 09:06"
    },
    {
        "id": "IN21-06_03",
        "sponsor": "CATALENT",
        "study": "MO41787",
        "clearingAgent": "TRANSPHARM",
        "courierCoy": "TRANSPHARM",
        "mawb": "TRANSPHARM DELIVERY",
        "zarValue": "R966.77",
        "receivingDate": "2021-06-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-07 13:56",
        "qaCheck": "2021-06-07 13:57",
        "inventoryAdded ": "2021-06-07 13:57",
        "sss": "2021-06-23 13:06"
    },
    {
        "id": "IN21-06_04",
        "sponsor": "CATALENT",
        "study": "MO41787",
        "clearingAgent": "HENDRICK COLLECTED",
        "courierCoy": "HENDRICK COLLECTED",
        "mawb": "HENDRICK COLLECTED",
        "zarValue": "R0.00",
        "receivingDate": "2021-06-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-07 14:27",
        "qaCheck": "2021-06-07 14:27",
        "inventoryAdded ": "2021-06-07 14:28",
        "sss": "2021-06-10 12:06"
    },
    {
        "id": "IN21-06_05",
        "sponsor": "ALMAC",
        "study": "OBI-822-011 GLORIA STUDY ",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "639x16120370",
        "zarValue": "R1617.37",
        "receivingDate": "2021-06-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-08 15:20",
        "qaCheck": "2021-06-09 10:40",
        "inventoryAdded ": "2021-06-08 15:21",
        "sss": "2021-06-10 13:15"
    },
    {
        "id": "IN21-06_06",
        "sponsor": "CATALENT",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60124326",
        "zarValue": "R0.00",
        "receivingDate": "2021-06-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-17 09:42",
        "qaCheck": "2021-06-17 09:02",
        "inventoryAdded ": "2021-06-17 09:42",
        "sss": "2021-06-17 09:43"
    },
    {
        "id": "IN21-06_07",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV DELIVERY",
        "zarValue": "R0.00",
        "receivingDate": "2021-06-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-09 14:57",
        "qaCheck": "2021-06-10 09:23",
        "inventoryAdded ": "2021-06-09 14:57",
        "sss": "2021-06-10 11:50"
    },
    {
        "id": "IN21-06_08",
        "sponsor": "CATALENT",
        "study": "MO41787",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV DELIVERY",
        "zarValue": "R0.00",
        "receivingDate": "2021-06-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-09 15:02",
        "qaCheck": "2021-06-10 09:12",
        "inventoryAdded ": "2021-06-09 15:02",
        "sss": "2021-06-10 11:50"
    },
    {
        "id": "IN21-06_09",
        "sponsor": "ALMAC",
        "study": "CLI-06001AA1-04",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "195210591w",
        "zarValue": "R32197.90",
        "receivingDate": "2021-06-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-18 07:13",
        "qaCheck": "2021-06-30 09:03",
        "inventoryAdded ": "2021-06-18 07:14",
        "sss": "2021-06-30 09:05"
    },
    {
        "id": "IN21-06_10",
        "sponsor": "MARKEN",
        "study": "EFC15935",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "604X16281118",
        "zarValue": "R3.00",
        "receivingDate": "2021-06-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-21 13:13",
        "qaCheck": "2021-06-23 08:42",
        "inventoryAdded ": "2021-06-21 13:13",
        "sss": "2021-06-23 13:06"
    },
    {
        "id": "IN21-06_11",
        "sponsor": "ALMAC",
        "study": "RPC01-3102",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QUICKSTAT",
        "mawb": "195050214W",
        "zarValue": "R928.00",
        "receivingDate": "2021-06-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-21 13:50",
        "qaCheck": "2021-06-22 08:17",
        "inventoryAdded ": "2021-06-21 13:50",
        "sss": "2021-06-23 13:06"
    },
    {
        "id": "IN21-06_14",
        "sponsor": "ROCHE SA",
        "study": "PAA_RISDI",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-65736646",
        "zarValue": "R978277.00",
        "receivingDate": "2021-06-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-25 07:33",
        "qaCheck": "2021-06-29 10:13",
        "inventoryAdded ": "2021-06-25 07:34",
        "sss": "2021-07-01 08:15"
    },
    {
        "id": "IN21-05_01",
        "sponsor": "CATALENT",
        "study": "WA42293",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123976",
        "zarValue": "R724778.00",
        "receivingDate": "2021-05-10                           ",
        "email": "2021-05-11 12:58",
        "confirmArrival": "2021-05-12 12:06",
        "qaCheck": "2021-05-13 10:59",
        "inventoryAdded ": "2021-05-12 12:07",
        "sss": "2021-05-24 14:50"
    },
    {
        "id": "IN21-05_02",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "911055854",
        "zarValue": "R2343.19",
        "receivingDate": "2021-05-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-10 11:24",
        "qaCheck": "2021-05-10 11:55",
        "inventoryAdded ": "2021-05-10 11:26",
        "sss": "2021-05-12 12:39"
    },
    {
        "id": "IN21-05_03",
        "sponsor": "MADE Pharma",
        "study": "UrSure",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "928344950931",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-24 12:30",
        "qaCheck": "2021-05-24 12:30",
        "inventoryAdded ": "2021-05-24 12:31",
        "sss": "2021-05-24 12:31"
    },
    {
        "id": "IN21-05_04",
        "sponsor": "CATALENT",
        "study": "BP40657",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-20710955",
        "zarValue": "R2260040.00",
        "receivingDate": "2021-05-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-12 10:50",
        "qaCheck": "2021-05-12 11:59",
        "inventoryAdded ": "2021-05-12 12:08",
        "sss": "2021-05-12 12:08"
    },
    {
        "id": "IN21-05_05",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "QUESTMED",
        "courierCoy": "QUESMED",
        "mawb": "QUESTMED",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:18",
        "qaCheck": "2021-05-17 11:44",
        "inventoryAdded ": "2021-05-17 11:18",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-05_06",
        "sponsor": "N/A",
        "study": "NORTH WEST UNIVERSITY",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "7052485543",
        "zarValue": "R1.00",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:36",
        "qaCheck": "2021-05-17 11:50",
        "inventoryAdded ": "2021-05-17 11:36",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-05_07",
        "sponsor": "ROCHE SA",
        "study": "ROCHE SA DONATION STOCK",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "DSV DELIVERY",
        "zarValue": "R29311.80",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:44",
        "qaCheck": "2021-05-17 11:56",
        "inventoryAdded ": "2021-05-17 11:42",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-05_08",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123696",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-24 15:05",
        "qaCheck": "2021-05-25 12:14",
        "inventoryAdded ": "2021-05-25 12:08",
        "sss": "2021-05-25 12:30"
    },
    {
        "id": "IN21-05_09",
        "sponsor": "ALMAC",
        "study": "C4591015",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16029989",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-18 15:06",
        "qaCheck": "2021-05-19 12:05",
        "inventoryAdded ": "2021-05-19 10:46",
        "sss": "2021-05-24 14:51"
    },
    {
        "id": "IN21-05_10",
        "sponsor": "ALMAC",
        "study": "C4591015",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16020025",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-18 15:07",
        "qaCheck": "2021-05-19 12:05",
        "inventoryAdded ": "2021-05-19 10:45",
        "sss": "2021-05-24 14:42"
    },
    {
        "id": "IN21-05_11",
        "sponsor": "MARKEN",
        "study": "EFC16819",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X16074825",
        "zarValue": "R263563.00",
        "receivingDate": "2021-05-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-24 15:05",
        "qaCheck": "2021-05-27 12:22",
        "inventoryAdded ": "2021-05-25 15:27",
        "sss": "2021-06-01 09:24"
    },
    {
        "id": "IN21-03_12",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "SPX",
        "courierCoy": "DSV",
        "mawb": "176-25629973",
        "zarValue": "R68299.70",
        "receivingDate": "2021-03-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-07 10:59",
        "qaCheck": "2021-04-07 10:33",
        "inventoryAdded ": "2021-04-07 11:00",
        "sss": "2021-04-07 11:01"
    },
    {
        "id": "IN21-03_15",
        "sponsor": "CATALENT",
        "study": "BO42162",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-25630010",
        "zarValue": "R496573.00",
        "receivingDate": "2021-03-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-07 11:49",
        "qaCheck": "2021-04-07 11:26",
        "inventoryAdded ": "2021-04-07 11:51",
        "sss": "2021-04-07 11:58"
    },
    {
        "id": "IN21-03_16",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-25630032",
        "zarValue": "R48504.45",
        "receivingDate": "2021-04-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-07 12:05",
        "qaCheck": "2021-04-07 11:35",
        "inventoryAdded ": "2021-04-07 12:06",
        "sss": "2021-04-07 12:08"
    },
    {
        "id": "IN21-04_02",
        "sponsor": "CATALENT",
        "study": "BO42162",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "618-86102122",
        "zarValue": "R3124369.00",
        "receivingDate": "2021-04-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-09 10:53",
        "qaCheck": "2021-04-09 10:54",
        "inventoryAdded ": "2021-04-09 10:54",
        "sss": "2021-04-09 11:32"
    },
    {
        "id": "IN21-04_04",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123512",
        "zarValue": "R2128417.41",
        "receivingDate": "2021-04-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-15 12:05",
        "qaCheck": "2021-04-19 12:51",
        "inventoryAdded ": "2021-04-15 12:06",
        "sss": "2021-04-20 13:55"
    },
    {
        "id": "IN21-04_05",
        "sponsor": "ROCHE SA",
        "study": "AG41991",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123545",
        "zarValue": "R2128434.42",
        "receivingDate": "2021-04-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-16 10:57",
        "qaCheck": "2021-04-19 12:52",
        "inventoryAdded ": "2021-04-16 10:58",
        "sss": "2021-04-20 13:56"
    },
    {
        "id": "IN21-04_06",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "503097112826",
        "zarValue": "R101520.00",
        "receivingDate": "2021-04-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-23 09:43",
        "qaCheck": "2021-04-26 11:25",
        "inventoryAdded ": "2021-04-26 12:45",
        "sss": "2021-04-26 12:45"
    },
    {
        "id": "IN21-04_07",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "020-88650004",
        "zarValue": "R623146.73",
        "receivingDate": "2021-04-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-21 11:40",
        "qaCheck": "2021-04-21 12:48",
        "inventoryAdded ": "2021-04-21 11:56",
        "sss": "2021-04-26 12:48"
    },
    {
        "id": "IN21-04_08",
        "sponsor": "N/A",
        "study": "NORTH WEST UNIVERSITY",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "5664967803",
        "zarValue": "R0.00",
        "receivingDate": "2021-04-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-20 13:53",
        "qaCheck": "2021-04-20 13:53",
        "inventoryAdded ": "2021-04-20 13:54",
        "sss": "2021-04-20 13:54"
    },
    {
        "id": "IN21-04_09",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123685",
        "zarValue": "R12508046.08",
        "receivingDate": "2021-04-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-23 14:22",
        "qaCheck": "2021-04-26 11:18",
        "inventoryAdded ": "2021-04-26 07:59",
        "sss": "2021-04-26 12:44"
    },
    {
        "id": "IN21-04_10",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "3058960",
        "zarValue": "R33875.40",
        "receivingDate": "2021-04-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-16 10:26",
        "qaCheck": "2021-04-19 14:12",
        "inventoryAdded ": "2021-04-16 10:29",
        "sss": "2021-04-20 13:58"
    },
    {
        "id": "IN21-04_12",
        "sponsor": "ALMAC",
        "study": "C4591015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "3062340",
        "zarValue": "R50390.00",
        "receivingDate": "2021-04-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-28 11:26",
        "qaCheck": "2021-04-28 12:49",
        "inventoryAdded ": "2021-04-28 11:27",
        "sss": "2021-04-29 09:05"
    },
    {
        "id": "IN21-04_13",
        "sponsor": "Biovian",
        "study": "MVX0005",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "711485096",
        "zarValue": "R3431.96",
        "receivingDate": "2021-04-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-22 14:32",
        "qaCheck": "2021-04-26 11:26",
        "inventoryAdded ": "2021-04-22 14:33",
        "sss": "2021-04-26 12:45"
    },
    {
        "id": "IN21-04_14",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "Bioweb",
        "courierCoy": "Pathteq",
        "mawb": "BWB1292",
        "zarValue": "R25642.96",
        "receivingDate": "2021-04-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-23 09:49",
        "qaCheck": "2021-04-26 11:22",
        "inventoryAdded ": "2021-04-26 08:07",
        "sss": "2021-04-26 12:44"
    },
    {
        "id": "IN21-04_15",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "DB Schenker",
        "courierCoy": "DB Schenker",
        "mawb": "125-988 30211 / 125-9876 3980",
        "zarValue": "R0.00",
        "receivingDate": "2021-04-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-23 09:53",
        "qaCheck": "2021-04-26 11:20",
        "inventoryAdded ": "2021-04-26 08:27",
        "sss": "2021-04-26 12:43"
    },
    {
        "id": "IN21-04_16",
        "sponsor": "CATALENT",
        "study": "BP40657",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123803",
        "zarValue": "R4246312.22",
        "receivingDate": "2021-04-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-29 11:13",
        "qaCheck": "2021-04-29 13:18",
        "inventoryAdded ": "2021-04-29 11:15",
        "sss": "2021-04-30 08:19"
    },
    {
        "id": "IN21-04_18",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "911055826",
        "zarValue": "R777.87",
        "receivingDate": "2021-04-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-29 10:49",
        "qaCheck": "2021-04-29 13:20",
        "inventoryAdded ": "2021-04-29 10:50",
        "sss": "2021-04-30 08:18"
    },
    {
        "id": "IN21-03_01",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "46451639091",
        "zarValue": "R1.00",
        "receivingDate": "2021-03-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-01 12:58",
        "qaCheck": "2021-03-01 13:22",
        "inventoryAdded ": "2021-03-01 12:59",
        "sss": "2021-03-02 10:50"
    },
    {
        "id": "IN21-03_02",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X15237359",
        "zarValue": "R0.00",
        "receivingDate": "2021-03-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-02 10:52",
        "qaCheck": "2021-03-02 12:39",
        "inventoryAdded ": "2021-03-02 10:53",
        "sss": "2021-03-08 11:29"
    },
    {
        "id": "IN21-03_03",
        "sponsor": "ROCHE SA",
        "study": "AG40852",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "15718934882",
        "zarValue": "R4356.35",
        "receivingDate": "2021-03-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-11 11:12",
        "qaCheck": "2021-03-11 12:18",
        "inventoryAdded ": "2021-03-11 11:18",
        "sss": "2021-03-15 14:06"
    },
    {
        "id": "IN21-03_05",
        "sponsor": "CATALENT",
        "study": "MO41787",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "15718934963",
        "zarValue": "R3232225.29",
        "receivingDate": "2021-03-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-15 11:13",
        "qaCheck": "2021-03-15 12:13",
        "inventoryAdded ": "2021-03-15 11:44",
        "sss": "2021-03-16 13:21"
    },
    {
        "id": "IN21-03_06",
        "sponsor": "ROCHE SA",
        "study": "ROCHE SA DONATION STOCK",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV DELIVERY",
        "zarValue": "R9770.60",
        "receivingDate": "2021-03-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-10 11:11",
        "qaCheck": "2021-03-10 11:48",
        "inventoryAdded ": "2021-03-10 11:14",
        "sss": "2021-03-15 14:30"
    },
    {
        "id": "IN21-03_07",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15714890816",
        "zarValue": "R21418.90",
        "receivingDate": "2021-03-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-16 14:34",
        "qaCheck": "2021-03-17 10:36",
        "inventoryAdded ": "2021-03-16 14:39",
        "sss": "2021-03-19 09:16"
    },
    {
        "id": "IN21-03_08",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "9947",
        "zarValue": "R327.06",
        "receivingDate": "2021-03-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-15 13:37",
        "qaCheck": "2021-03-16 12:06",
        "inventoryAdded ": "2021-03-15 13:37",
        "sss": "2021-03-16 13:19"
    },
    {
        "id": "IN21-03_09",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "MED Q",
        "zarValue": "R24380.00",
        "receivingDate": "2021-03-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-16 11:39",
        "qaCheck": "2021-03-16 12:07",
        "inventoryAdded ": "2021-03-16 11:42",
        "sss": "2021-03-16 12:34"
    },
    {
        "id": "IN21-03_10",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "HENDRICK COLLECTED",
        "zarValue": "R0.00",
        "receivingDate": "2021-03-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-18 14:30",
        "qaCheck": "2021-03-29 12:37",
        "inventoryAdded ": "2021-03-18 14:30",
        "sss": "2021-04-07 12:00"
    },
    {
        "id": "IN21-03_13",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "711438299",
        "zarValue": "R849034.42",
        "receivingDate": "2021-03-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-26 12:19",
        "qaCheck": "2021-03-29 12:35",
        "inventoryAdded ": "2021-03-26 12:20",
        "sss": "2021-04-07 09:25"
    },
    {
        "id": "IN21-03_17",
        "sponsor": "N/A",
        "study": "Derek Manne",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "1570006292",
        "zarValue": "R0.00",
        "receivingDate": "2021-03-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-31 15:09",
        "qaCheck": "2021-04-07 12:08",
        "inventoryAdded ": "2021-03-31 15:10",
        "sss": "2021-04-07 12:09"
    },
    {
        "id": "IN21-01_10",
        "sponsor": "ROCHE SA",
        "study": "BO41423",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15716930303",
        "zarValue": "R3133661.06",
        "receivingDate": "2021-02-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-05 14:13",
        "qaCheck": "2021-02-05 14:22",
        "inventoryAdded ": "2021-02-05 14:13",
        "sss": "2021-02-05 14:24"
    },
    {
        "id": "IN21-02_01",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "DSV",
        "mawb": "DSV DELIVERY",
        "zarValue": "R1566.26",
        "receivingDate": "2021-02-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-01 14:18",
        "qaCheck": "2021-02-01 14:36",
        "inventoryAdded ": "2021-02-01 14:36",
        "sss": "2021-02-01 16:07"
    },
    {
        "id": "IN21-02_02",
        "sponsor": "CATALENT",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15717356684",
        "zarValue": "R159061.00",
        "receivingDate": "2021-02-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-08 11:38",
        "qaCheck": "2021-02-08 12:21",
        "inventoryAdded ": "2021-02-08 12:06",
        "sss": "2021-02-09 14:16"
    },
    {
        "id": "IN21-02_04",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "QESTMED DELIVERY",
        "zarValue": "R18225.20",
        "receivingDate": "2021-02-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-08 15:04",
        "qaCheck": "2021-02-09 12:51",
        "inventoryAdded ": "2021-02-08 15:06",
        "sss": "2021-02-09 14:16"
    },
    {
        "id": "IN21-02_05",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "KAWARI DELIVERY",
        "zarValue": "R9112.60",
        "receivingDate": "2021-02-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-09 14:18",
        "qaCheck": "2021-02-09 14:45",
        "inventoryAdded ": "2021-02-09 14:40",
        "sss": "2021-02-22 11:09"
    },
    {
        "id": "IN21-02_06",
        "sponsor": "MARKEN",
        "study": "LPS15023",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X15175917",
        "zarValue": "R2.00",
        "receivingDate": "2021-02-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-18 14:54",
        "qaCheck": "2021-02-22 11:01",
        "inventoryAdded ": "2021-02-18 14:57",
        "sss": "2021-02-22 11:08"
    },
    {
        "id": "IN21-02_07",
        "sponsor": "N/A",
        "study": "MADE PHARMA ",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "772881821780",
        "zarValue": "R954634.00",
        "receivingDate": "2021-02-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-22 11:12",
        "qaCheck": "2021-02-22 11:39",
        "inventoryAdded ": "2021-02-22 11:13",
        "sss": "2021-02-23 10:12"
    },
    {
        "id": "IN21-02_08",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "QESTMED DELIVERY",
        "zarValue": "R1822520.00",
        "receivingDate": "2021-02-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-23 10:13",
        "qaCheck": "2021-02-23 10:35",
        "inventoryAdded ": "2021-02-23 10:14",
        "sss": "2021-02-25 10:42"
    },
    {
        "id": "IN21-02_09",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "911055626",
        "zarValue": "R1410.36",
        "receivingDate": "2021-02-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-25 11:58",
        "qaCheck": "2021-02-25 12:50",
        "inventoryAdded ": "2021-02-25 12:18",
        "sss": "2021-02-26 10:35"
    },
    {
        "id": "IN21-02_10",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "World Courier",
        "courierCoy": "DSV",
        "mawb": "DSV DELIVERY",
        "zarValue": "R1941.66",
        "receivingDate": "2021-02-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-25 12:37",
        "qaCheck": "2021-02-25 12:55",
        "inventoryAdded ": "2021-02-25 12:49",
        "sss": "2021-03-02 10:50"
    },
    {
        "id": "IN20-12_05",
        "sponsor": "N/A",
        "study": "Derek Manne",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "6543598353",
        "zarValue": "R836.68",
        "receivingDate": "2021-01-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-03 15:13",
        "qaCheck": "2021-01-03 15:13",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2021-01-19 09:31"
    },
    {
        "id": "IN20-12_07",
        "sponsor": "ALMAC",
        "study": "RPC01-3102",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QUICKSTAT",
        "mawb": "1Z97X3840466595079",
        "zarValue": "R16671.50",
        "receivingDate": "2020-12-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-03 15:11",
        "qaCheck": "2021-01-05 14:12",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2021-01-06 13:35"
    },
    {
        "id": "IN21-01_01",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X14883367",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-04 16:04",
        "qaCheck": "2021-01-05 08:53",
        "inventoryAdded ": "2021-01-04 16:04",
        "sss": "2021-01-05 08:59"
    },
    {
        "id": "IN21-01_02",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "N/A",
        "courierCoy": "MARKEN",
        "mawb": "621x14873658",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-04 16:16",
        "qaCheck": "2021-01-06 15:05",
        "inventoryAdded ": "2021-01-04 16:17",
        "sss": "2021-01-06 15:20"
    },
    {
        "id": "IN21-01_03",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X14723174",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-05 15:06",
        "qaCheck": "2021-01-06 13:30",
        "inventoryAdded ": "2021-01-06 13:30",
        "sss": "2021-01-06 13:33"
    },
    {
        "id": "IN21-01_04",
        "sponsor": "N/A",
        "study": "RX MEDICALS ",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "2620870033",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-11 13:06",
        "qaCheck": "2021-01-12 08:20",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2021-01-12 10:13"
    },
    {
        "id": "IN21-01_05",
        "sponsor": "CATALENT",
        "study": "GO40241",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17356220",
        "zarValue": "R301153.72",
        "receivingDate": "2021-01-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-20 14:35",
        "qaCheck": "2021-01-21 13:21",
        "inventoryAdded ": "2021-01-21 13:23",
        "sss": "2021-01-21 13:25"
    },
    {
        "id": "IN21-01_06",
        "sponsor": "CATALENT",
        "study": "CO40016",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17356360",
        "zarValue": "R421676.00",
        "receivingDate": "2021-01-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-25 12:01",
        "qaCheck": "2021-01-26 09:46",
        "inventoryAdded ": "2021-01-25 13:16",
        "sss": "2021-01-26 09:47"
    },
    {
        "id": "IN21-01_07",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17356463",
        "zarValue": "R55597.70",
        "receivingDate": "2021-01-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-28 13:25",
        "qaCheck": "2021-01-28 14:47",
        "inventoryAdded ": "2021-01-28 13:24",
        "sss": "2021-01-28 14:48"
    },
    {
        "id": "IN21-01_08",
        "sponsor": "MARKEN",
        "study": "EFC15805",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X15014796",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-27 13:38",
        "qaCheck": "2021-01-28 11:23",
        "inventoryAdded ": "2021-01-27 14:12",
        "sss": "2021-01-28 11:26"
    },
    {
        "id": "IN21-01_09",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "DSV",
        "mawb": "34278056",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-28 08:38",
        "qaCheck": "2021-01-28 12:53",
        "inventoryAdded ": "2021-01-28 12:55",
        "sss": "2021-01-28 12:56"
    },
    {
        "id": "IN20-11_10",
        "sponsor": "ROCHE SA",
        "study": "BO41423",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-10188905",
        "zarValue": "R9384661.43",
        "receivingDate": "2020-12-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-08 10:09",
        "qaCheck": "2020-12-08 15:07",
        "inventoryAdded ": "2020-12-09 16:22",
        "sss": "2020-12-09 16:22"
    },
    {
        "id": "IN20-11_11",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-10188894",
        "zarValue": "R15628731.43",
        "receivingDate": "2020-12-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-08 10:05",
        "qaCheck": "2020-12-08 14:05",
        "inventoryAdded ": "2020-12-09 16:23",
        "sss": "2020-12-09 16:25"
    },
    {
        "id": "IN20-12_01",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X14693859",
        "zarValue": "R0.00",
        "receivingDate": "2020-12-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-14 08:23",
        "qaCheck": "2020-12-15 13:04",
        "inventoryAdded ": "2020-12-15 12:52",
        "sss": "2020-12-15 13:06"
    },
    {
        "id": "IN20-12_02",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "34242595",
        "zarValue": "R0.00",
        "receivingDate": "2020-12-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-07 14:07",
        "qaCheck": "2020-12-08 13:57",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2020-12-09 16:19"
    },
    {
        "id": "IN20-12_03",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "34242595",
        "zarValue": "R0.00",
        "receivingDate": "2020-12-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-07 14:06",
        "qaCheck": "2020-12-08 13:47",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2020-12-09 16:20"
    },
    {
        "id": "IN20-12_04",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "HAF391201",
        "zarValue": "R0.00",
        "receivingDate": "2020-12-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-09 14:30",
        "qaCheck": "2020-12-09 16:26",
        "inventoryAdded ": "2020-12-09 14:30",
        "sss": "2020-12-09 16:30"
    },
    {
        "id": "IN20-12_06",
        "sponsor": "MARKEN",
        "study": "MET 55",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "05794240650",
        "zarValue": "R500558.00",
        "receivingDate": "2020-12-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-17 15:15",
        "qaCheck": "2020-12-18 09:49",
        "inventoryAdded ": "2020-12-17 15:24",
        "sss": "2020-12-21 11:30"
    },
    {
        "id": "IN20-12_09",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "615X14720630",
        "zarValue": "R8.00",
        "receivingDate": "2020-12-17                           ",
        "email": "2020-12-17 14:36",
        "confirmArrival": "2020-12-17 14:35",
        "qaCheck": "2020-12-18 09:50",
        "inventoryAdded ": "2020-12-18 09:50",
        "sss": "2020-12-21 12:28"
    },
    {
        "id": "IN20-12_10",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17332383",
        "zarValue": "R82163.00",
        "receivingDate": "2020-12-21                           ",
        "email": "2020-12-20 21:15",
        "confirmArrival": "2020-12-22 13:17",
        "qaCheck": "2020-12-22 13:18",
        "inventoryAdded ": "2020-12-22 13:21",
        "sss": "2020-12-22 13:22"
    },
    {
        "id": "IN20-12_12",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "911055441",
        "zarValue": "R2828.52",
        "receivingDate": "2020-12-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-21 09:53",
        "qaCheck": "2020-12-21 09:53",
        "inventoryAdded ": "2020-12-21 12:29",
        "sss": "2020-12-21 12:29"
    },
    {
        "id": "IN20-12_13",
        "sponsor": "ROCHE SA",
        "study": "AG40852",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17332350",
        "zarValue": "R63927.30",
        "receivingDate": "2020-12-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-23 10:24",
        "qaCheck": "2020-12-23 10:25",
        "inventoryAdded ": "2020-12-23 10:25",
        "sss": "2020-12-23 10:25"
    },
    {
        "id": "IN20-12_14",
        "sponsor": "MARKEN",
        "study": "EFC15805",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X14702255",
        "zarValue": "R128943.00",
        "receivingDate": "2020-12-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-21 11:45",
        "qaCheck": "2020-12-21 11:46",
        "inventoryAdded ": "2020-12-23 10:22",
        "sss": "2020-12-23 10:23"
    },
    {
        "id": "IN20-11_01",
        "sponsor": "N/A",
        "study": "RX MEDICALS ",
        "clearingAgent": "RX MEDICAL",
        "courierCoy": "HAZMAT",
        "mawb": "J2035215",
        "zarValue": "R566727.00",
        "receivingDate": "2020-11-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-03 10:08",
        "qaCheck": "2020-11-06 14:30",
        "inventoryAdded ": "2020-11-06 14:30",
        "sss": "2020-11-06 14:32"
    },
    {
        "id": "IN20-11_02",
        "sponsor": "CATALENT",
        "study": "YO42137",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-68373885",
        "zarValue": "R921511.00",
        "receivingDate": "2020-11-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-09 12:00",
        "qaCheck": "2020-11-10 10:03",
        "inventoryAdded ": "2020-11-09 15:35",
        "sss": "2020-11-10 10:04"
    },
    {
        "id": "IN20-11_03",
        "sponsor": "CATALENT",
        "study": "YO42137",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-68373874",
        "zarValue": "R4272920.16",
        "receivingDate": "2020-11-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-09 11:59",
        "qaCheck": "2020-11-10 10:04",
        "inventoryAdded ": "2020-11-09 15:35",
        "sss": "2020-11-10 10:04"
    },
    {
        "id": "IN20-11_04",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "DSV",
        "mawb": "32032694",
        "zarValue": "R0.00",
        "receivingDate": "2020-11-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-18 14:37",
        "qaCheck": "2020-11-19 14:44",
        "inventoryAdded ": "2020-11-20 09:59",
        "sss": "2020-11-20 10:00"
    },
    {
        "id": "IN20-11_06",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "DSV",
        "mawb": "601790366006",
        "zarValue": "R0.00",
        "receivingDate": "2020-11-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-19 14:30",
        "qaCheck": "2020-11-20 10:08",
        "inventoryAdded ": "2020-11-20 10:09",
        "sss": "2020-11-20 10:09"
    },
    {
        "id": "IN20-11_07",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15710188743",
        "zarValue": "R70620.20",
        "receivingDate": "2020-11-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-30 11:52",
        "qaCheck": "2020-11-30 13:42",
        "inventoryAdded ": "2020-11-30 13:53",
        "sss": "2020-11-30 15:13"
    },
    {
        "id": "IN20-11_08",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "Citi-Sprint",
        "mawb": "CS2262403",
        "zarValue": "R0.00",
        "receivingDate": "2020-11-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-25 11:02",
        "qaCheck": "2020-11-26 10:31",
        "inventoryAdded ": "2020-11-25 11:05",
        "sss": "2020-11-26 10:31"
    },
    {
        "id": "IN20-11_09",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15710188754",
        "zarValue": "R40214.40",
        "receivingDate": "2020-11-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-30 12:16",
        "qaCheck": "2020-11-30 15:10",
        "inventoryAdded ": "2020-11-30 13:53",
        "sss": "2020-11-30 15:11"
    },
    {
        "id": "IN20-09_11",
        "sponsor": "Biovian",
        "study": "MVX0005",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "120004386",
        "zarValue": "R788.99",
        "receivingDate": "2020-10-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-01 16:00",
        "qaCheck": "2020-10-02 10:46",
        "inventoryAdded ": "2020-10-02 10:44",
        "sss": "2020-10-02 10:47"
    },
    {
        "id": "IN20-10_01",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15761735796",
        "zarValue": "R0.00",
        "receivingDate": "2020-10-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-08 14:03",
        "qaCheck": "2020-10-09 14:24",
        "inventoryAdded ": "2020-10-09 09:16",
        "sss": "2020-10-09 14:27"
    },
    {
        "id": "IN20-10_03",
        "sponsor": "CATALENT",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-61735726",
        "zarValue": "R81368.30",
        "receivingDate": "2020-10-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-06 14:36",
        "qaCheck": "2020-10-07 10:32",
        "inventoryAdded ": "2020-10-06 16:09",
        "sss": "2020-10-07 10:32"
    },
    {
        "id": "IN20-10_04",
        "sponsor": "MARKEN",
        "study": "LTS14424",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X14147438",
        "zarValue": "R346010.00",
        "receivingDate": "2020-10-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-09 14:34",
        "qaCheck": "2020-10-12 14:42",
        "inventoryAdded ": "2020-10-09 14:47",
        "sss": "2020-10-12 15:40"
    },
    {
        "id": "IN20-10_05",
        "sponsor": "Biovian",
        "study": "MVX0005",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "120004872",
        "zarValue": "R0.00",
        "receivingDate": "2020-10-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-14 09:07",
        "qaCheck": "2020-10-14 15:03",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2020-10-16 08:20"
    },
    {
        "id": "IN20-10_06",
        "sponsor": "ROCHE SA",
        "study": "AG40852",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-61736393",
        "zarValue": "R54360.70",
        "receivingDate": "2020-10-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-20 09:49",
        "qaCheck": "2020-10-20 14:38",
        "inventoryAdded ": "2020-10-20 13:39",
        "sss": "2020-10-20 14:39"
    },
    {
        "id": "IN20-10_07",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "3032818",
        "zarValue": "R283985.00",
        "receivingDate": "2020-10-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-16 13:45",
        "qaCheck": "2020-10-20 14:18",
        "inventoryAdded ": "2020-10-20 12:42",
        "sss": "2020-10-20 14:18"
    },
    {
        "id": "IN20-10_08",
        "sponsor": "ROCHE SA",
        "study": "VB95670 Commercial Stock",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "542535-1",
        "zarValue": "R14588168.82",
        "receivingDate": "2020-10-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-19 14:27",
        "qaCheck": "2020-10-20 08:19",
        "inventoryAdded ": "2020-10-19 14:28",
        "sss": "2020-10-20 09:26"
    },
    {
        "id": "IN20-10_09",
        "sponsor": "MARKEN",
        "study": "LPS15023",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X14209095",
        "zarValue": "R0.00",
        "receivingDate": "2020-10-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-23 12:20",
        "qaCheck": "2020-10-26 10:53",
        "inventoryAdded ": "2020-10-23 13:56",
        "sss": "2020-10-26 10:53"
    },
    {
        "id": "IN20-09_01",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15729653562",
        "zarValue": "R4525827.77",
        "receivingDate": "2020-09-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-10 14:37",
        "qaCheck": "2020-09-11 09:00",
        "inventoryAdded ": "2020-09-11 09:01",
        "sss": "2020-09-11 09:06"
    },
    {
        "id": "IN20-09_02",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "192381562W",
        "zarValue": "R0.00",
        "receivingDate": "2020-09-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-10 08:24",
        "qaCheck": "2020-09-10 15:12",
        "inventoryAdded ": "2020-09-11 12:16",
        "sss": "2020-09-11 12:17"
    },
    {
        "id": "IN20-09_03",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X13946586",
        "zarValue": "R0.00",
        "receivingDate": "2020-09-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-08 14:43",
        "qaCheck": "2020-09-09 13:41",
        "inventoryAdded ": "2020-09-09 15:08",
        "sss": "2020-09-09 15:35"
    },
    {
        "id": "IN20-09_04",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X13946768",
        "zarValue": "R17697.80",
        "receivingDate": "2020-09-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-09 15:07",
        "qaCheck": "2020-09-09 16:09",
        "inventoryAdded ": "2020-09-09 15:07",
        "sss": "2020-09-09 16:10"
    },
    {
        "id": "IN20-09_05",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X13946715",
        "zarValue": "R141582.00",
        "receivingDate": "2020-09-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-08 14:32",
        "qaCheck": "2020-09-09 13:24",
        "inventoryAdded ": "2020-09-09 15:08",
        "sss": "2020-09-09 15:33"
    },
    {
        "id": "IN20-09_07",
        "sponsor": "ROCHE SA",
        "study": "CA41705",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-07396410",
        "zarValue": "R143040.63",
        "receivingDate": "2020-09-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-15 15:01",
        "qaCheck": "2020-09-15 15:34",
        "inventoryAdded ": "2020-09-15 15:20",
        "sss": "2020-09-15 15:56"
    },
    {
        "id": "IN20-09_08",
        "sponsor": "ROCHE SA",
        "study": "CA41705",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-07396406",
        "zarValue": "R1962755.94",
        "receivingDate": "2020-09-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-15 13:39",
        "qaCheck": "2020-09-15 15:55",
        "inventoryAdded ": "2020-09-15 15:06",
        "sss": "2020-09-15 15:56"
    },
    {
        "id": "IN20-09_09",
        "sponsor": "N/A",
        "study": "RX MEDICALS ",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "3082794094",
        "zarValue": "R0.00",
        "receivingDate": "2020-09-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-14 08:33",
        "qaCheck": "2020-09-14 08:47",
        "inventoryAdded ": "2020-09-14 08:34",
        "sss": "2020-09-14 08:51"
    },
    {
        "id": "IN20-09_10",
        "sponsor": "CATALENT",
        "study": "K-877-302 STUDY",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X13709063",
        "zarValue": "R377360.00",
        "receivingDate": "2020-09-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-09-15 12:11",
        "qaCheck": "2020-09-23 09:57",
        "inventoryAdded ": "2020-09-21 08:47",
        "sss": "2020-09-23 10:45"
    },
    {
        "id": "IN20-07_16",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "071-36654785",
        "zarValue": "R8502.16",
        "receivingDate": "2020-07-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-03 13:20",
        "qaCheck": "2020-08-07 13:08",
        "inventoryAdded ": "2020-08-07 11:55",
        "sss": "2020-08-07 13:08"
    },
    {
        "id": "IN20-07_19",
        "sponsor": "ROCHE SA",
        "study": "BO41423",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-49712445",
        "zarValue": "R3132433.51",
        "receivingDate": "2020-08-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-05 14:09",
        "qaCheck": "2020-08-07 13:15",
        "inventoryAdded ": "2020-08-05 15:38",
        "sss": "2020-08-07 13:21"
    },
    {
        "id": "IN20-08_01",
        "sponsor": "ROCHE SA",
        "study": "AG40852",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-28590811",
        "zarValue": "R56490.00",
        "receivingDate": "2020-08-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-11 15:47",
        "qaCheck": "2020-08-12 16:12",
        "inventoryAdded ": "2020-08-11 15:48",
        "sss": "2020-08-12 16:27"
    },
    {
        "id": "IN20-08_02",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-28590844",
        "zarValue": "R42373.18",
        "receivingDate": "2020-08-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-11 15:46",
        "qaCheck": "2020-08-12 16:17",
        "inventoryAdded ": "2020-08-11 15:48",
        "sss": "2020-08-18 14:49"
    },
    {
        "id": "IN20-08_03",
        "sponsor": "MMV",
        "study": "Covid-Prophylaxis Study",
        "clearingAgent": "SPX",
        "courierCoy": "0",
        "mawb": "071-36654785",
        "zarValue": "R654375.00",
        "receivingDate": "2020-08-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-07 11:01",
        "qaCheck": "2020-08-07 13:10",
        "inventoryAdded ": "2020-08-07 11:01",
        "sss": "2020-08-07 13:21"
    },
    {
        "id": "IN20-08_04",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "3017014",
        "zarValue": "R6496.94",
        "receivingDate": "2020-08-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-12 15:43",
        "qaCheck": "2020-08-12 15:43",
        "inventoryAdded ": "2020-08-12 13:44",
        "sss": "2020-08-12 16:24"
    },
    {
        "id": "IN20-08_05",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-18002036",
        "zarValue": "R12196.50",
        "receivingDate": "2020-08-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-25 13:37",
        "qaCheck": "2020-08-28 11:29",
        "inventoryAdded ": "2020-08-28 11:29",
        "sss": "2020-08-28 11:49"
    },
    {
        "id": "IN20-08_06",
        "sponsor": "MARKEN",
        "study": "EFC15805",
        "clearingAgent": "CoolCare",
        "courierCoy": "Coolcare",
        "mawb": "Coolcare",
        "zarValue": "R54682.50",
        "receivingDate": "2020-08-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-24 13:16",
        "qaCheck": "2020-08-24 13:32",
        "inventoryAdded ": "2020-08-24 13:16",
        "sss": "2020-08-24 13:34"
    },
    {
        "id": "IN20-08_07",
        "sponsor": "MARKEN",
        "study": "EFC14828",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "125 98241883",
        "zarValue": "R0.00",
        "receivingDate": "2020-08-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-28 15:02",
        "qaCheck": "2020-08-31 15:52",
        "inventoryAdded ": "2020-08-31 14:37",
        "sss": "2020-09-02 08:56"
    },
    {
        "id": "IN20-06_13",
        "sponsor": "ONQ",
        "study": "RETSA-19FDCHYPA",
        "clearingAgent": "ALPHAMED",
        "courierCoy": "WORLD COURIER",
        "mawb": "911056632",
        "zarValue": "R40.80",
        "receivingDate": "2020-06-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-02 09:19",
        "qaCheck": "2020-07-02 09:39",
        "inventoryAdded ": "2020-07-09 12:49",
        "sss": "2020-07-09 12:50"
    },
    {
        "id": "IN20-07_01",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-26749520",
        "zarValue": "R3132449.44",
        "receivingDate": "2020-07-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-08 14:15",
        "qaCheck": "2020-07-09 12:43",
        "inventoryAdded ": "2020-07-09 12:51",
        "sss": "2020-07-09 14:13"
    },
    {
        "id": "IN20-07_04",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-17033332",
        "zarValue": "R515776.00",
        "receivingDate": "2020-07-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-21 13:36",
        "qaCheck": "2020-07-23 10:28",
        "inventoryAdded ": "2020-07-21 14:30",
        "sss": "2020-07-23 12:15"
    },
    {
        "id": "IN20-07_05",
        "sponsor": "ROCHE SA",
        "study": "CO41101",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15726749483",
        "zarValue": "R2655152.74",
        "receivingDate": "2020-07-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-16 14:30",
        "qaCheck": "2020-07-16 14:57",
        "inventoryAdded ": "2020-07-16 12:44",
        "sss": "2020-07-17 14:06"
    },
    {
        "id": "IN20-07_06",
        "sponsor": "ONQ",
        "study": "RETSA-19FDCHYPA",
        "clearingAgent": "ALPHAMED",
        "courierCoy": "WORLD COURIER",
        "mawb": "911056771",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-15 10:12",
        "qaCheck": "2020-07-16 12:44",
        "inventoryAdded ": "2020-07-16 12:45",
        "sss": "2020-07-16 12:53"
    },
    {
        "id": "IN20-07_07",
        "sponsor": "MARKEN",
        "study": "EFC15805",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X13504038",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-14 13:15",
        "qaCheck": "2020-07-16 13:12",
        "inventoryAdded ": "2020-07-24 10:10",
        "sss": "2020-07-24 10:11"
    },
    {
        "id": "IN20-07_08",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "1471717203",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-20 15:05",
        "qaCheck": "2020-07-23 10:29",
        "inventoryAdded ": "2020-07-21 14:27",
        "sss": "2020-07-23 12:15"
    },
    {
        "id": "IN20-07_09",
        "sponsor": "CATALENT",
        "study": "K-877-302 STUDY",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X13494753",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-20 14:08",
        "qaCheck": "2020-07-22 13:24",
        "inventoryAdded ": "2020-07-21 15:09",
        "sss": "2020-07-22 13:25"
    },
    {
        "id": "IN20-07_10",
        "sponsor": "ROCHE SA",
        "study": "AG41991",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-27441131",
        "zarValue": "R2130636.85",
        "receivingDate": "2020-07-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-17 13:31",
        "qaCheck": "2020-07-17 13:56",
        "inventoryAdded ": "2020-07-17 13:59",
        "sss": "2020-07-17 13:59"
    },
    {
        "id": "IN20-07_11",
        "sponsor": "N/A",
        "study": "RX MEDICALS ",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "5479870572",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-17 12:55",
        "qaCheck": "2020-07-17 13:56",
        "inventoryAdded ": "2020-07-17 13:52",
        "sss": "2020-07-17 14:01"
    },
    {
        "id": "IN20-07_12",
        "sponsor": "CATALENT",
        "study": "GO40241",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-27441525",
        "zarValue": "R4245825.11",
        "receivingDate": "2020-07-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-28 11:11",
        "qaCheck": "2020-07-29 12:17",
        "inventoryAdded ": "2020-07-29 08:18",
        "sss": "2020-07-29 11:08"
    },
    {
        "id": "IN20-07_13",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-27441444",
        "zarValue": "R6263846.96",
        "receivingDate": "2020-07-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-28 11:22",
        "qaCheck": "2020-07-29 12:18",
        "inventoryAdded ": "2020-07-29 08:20",
        "sss": "2020-07-29 12:21"
    },
    {
        "id": "IN20-07_14",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "618-83302645",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-28 13:06",
        "qaCheck": "2020-07-29 08:25",
        "inventoryAdded ": "2020-07-29 08:21",
        "sss": "2020-07-29 11:09"
    },
    {
        "id": "IN20-07_17",
        "sponsor": "MARKEN",
        "study": "LPS15023",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X13619176",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-29 08:14",
        "qaCheck": "2020-07-29 08:58",
        "inventoryAdded ": "2020-07-29 08:14",
        "sss": "2020-07-29 12:18"
    },
    {
        "id": "IN20-07_18",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "8856536323",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-30 12:22",
        "qaCheck": "2020-07-31 08:17",
        "inventoryAdded ": "2020-08-03 10:16",
        "sss": "2020-08-04 13:45"
    },
    {
        "id": "IN16-09_01",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X05981052",
        "zarValue": "R171809.00",
        "receivingDate": "2016-09-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-02 09:18",
        "qaCheck": "2016-09-06 10:37",
        "inventoryAdded ": "2016-10-05 08:28",
        "sss": "2017-01-17 14:48"
    },
    {
        "id": "IN16-10_03",
        "sponsor": "SANOFI",
        "study": "LTS13463",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-48359754",
        "zarValue": "R5090960.00",
        "receivingDate": "2016-10-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-18 08:30",
        "qaCheck": "2016-10-19 10:23",
        "inventoryAdded ": "2016-10-19 10:24",
        "sss": "2016-10-19 10:27"
    },
    {
        "id": "IN16-10_07",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 1100",
        "zarValue": "R57661.70",
        "receivingDate": "2016-10-20                           ",
        "email": "2016-10-18 13:42",
        "confirmArrival": "2016-10-31 15:14",
        "qaCheck": "2016-10-31 15:14",
        "inventoryAdded ": "2016-10-31 15:14",
        "sss": "2016-10-31 15:21"
    },
    {
        "id": "IN17-01_11",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "125-39439816",
        "zarValue": "R116186.00",
        "receivingDate": "2017-01-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-18 15:06",
        "qaCheck": "2017-01-18 15:10",
        "inventoryAdded ": "2017-01-18 15:10",
        "sss": "2017-01-18 15:14"
    },
    {
        "id": "IN17-04_02",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-1110 9840",
        "zarValue": "R719592.00",
        "receivingDate": "2017-04-09                           ",
        "email": "2017-04-05 09:06",
        "confirmArrival": "2017-04-18 15:29",
        "qaCheck": "2017-04-25 11:04",
        "inventoryAdded ": "2017-04-25 11:05",
        "sss": "2017-04-25 11:09"
    },
    {
        "id": "IN17-04_05",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-1111 0326",
        "zarValue": "R341557.00",
        "receivingDate": "2017-04-13                           ",
        "email": "2017-04-10 15:59",
        "confirmArrival": "2017-04-18 16:17",
        "qaCheck": "2017-04-19 08:21",
        "inventoryAdded ": "2017-04-19 08:30",
        "sss": "2017-04-19 08:32"
    },
    {
        "id": "IN17-04_10",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Dischem",
        "mawb": "007410",
        "zarValue": "R1231.20",
        "receivingDate": "2017-04-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-18 12:52",
        "qaCheck": "2017-04-18 12:52",
        "inventoryAdded ": "2017-04-18 12:53",
        "sss": "2017-04-18 13:02"
    },
    {
        "id": "IN17-07_02",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-5168 0764",
        "zarValue": "R134340.00",
        "receivingDate": "2017-07-09                           ",
        "email": "2017-07-07 14:02",
        "confirmArrival": "2017-07-12 13:55",
        "qaCheck": "2017-07-12 14:05",
        "inventoryAdded ": "2017-07-12 14:05",
        "sss": "2017-07-12 14:08"
    },
    {
        "id": "IN17-08_11",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 3743",
        "zarValue": "R108592.00",
        "receivingDate": "2017-08-17                           ",
        "email": "2017-08-15 15:35",
        "confirmArrival": "2017-08-18 14:10",
        "qaCheck": "2017-08-18 14:11",
        "inventoryAdded ": "2017-08-18 14:18",
        "sss": "2017-08-18 14:22"
    },
    {
        "id": "IN17-08_13",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "X5PRYA",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-18 14:27",
        "qaCheck": "2017-08-18 14:27",
        "inventoryAdded ": "2017-08-18 14:28",
        "sss": "2017-08-18 14:28"
    },
    {
        "id": "IN17-08_18",
        "sponsor": "CATALENT",
        "study": "GO25632",
        "clearingAgent": "NONE",
        "courierCoy": "QESTMED",
        "mawb": "4701210 LOGOQD",
        "zarValue": "R19117.80",
        "receivingDate": "2017-08-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-22 14:18",
        "qaCheck": "2017-08-22 14:18",
        "inventoryAdded ": "2017-08-22 14:18",
        "sss": "2017-08-22 14:19"
    },
    {
        "id": "IN17-08_25",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-62344380",
        "zarValue": "R9639.43",
        "receivingDate": "2017-09-01                           ",
        "email": "2017-08-31 11:01",
        "confirmArrival": "2017-09-18 14:29",
        "qaCheck": "2017-09-19 06:27",
        "inventoryAdded ": "2017-09-21 10:08",
        "sss": "2017-09-21 10:21"
    },
    {
        "id": "IN17-11_27",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "N/A",
        "courierCoy": "Morne de Bruin",
        "mawb": "None",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-27 16:04",
        "qaCheck": "2017-11-27 16:04",
        "inventoryAdded ": "2017-11-27 16:04",
        "sss": "2017-11-27 16:07"
    },
    {
        "id": "IN17-12_04",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 6249 9544",
        "zarValue": "R1374550.00",
        "receivingDate": "2017-12-13                           ",
        "email": "2017-12-13 08:48",
        "confirmArrival": "2018-01-09 11:58",
        "qaCheck": "2018-01-09 11:58",
        "inventoryAdded ": "2018-01-09 11:58",
        "sss": "2018-01-09 12:05"
    },
    {
        "id": "IN17-12_05",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 6059 3422",
        "zarValue": "R101719.00",
        "receivingDate": "2017-12-17                           ",
        "email": "2017-12-13 09:30",
        "confirmArrival": "2018-01-11 09:44",
        "qaCheck": "2018-01-11 09:44",
        "inventoryAdded ": "2018-01-11 09:44",
        "sss": "2018-01-11 09:45"
    },
    {
        "id": "IN17-12_07",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6059 3503",
        "zarValue": "R1668390.00",
        "receivingDate": "2017-12-17                           ",
        "email": "2017-12-21 07:44",
        "confirmArrival": "2018-01-11 09:32",
        "qaCheck": "2018-01-11 09:32",
        "inventoryAdded ": "2018-01-11 09:33",
        "sss": "2018-01-11 09:38"
    },
    {
        "id": "IN18-01_01",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 6393 0580",
        "zarValue": "R92908.70",
        "receivingDate": "2018-01-10                           ",
        "email": "2018-01-11 10:37",
        "confirmArrival": "2018-01-15 11:12",
        "qaCheck": "2018-01-15 11:38",
        "inventoryAdded ": "2018-01-15 11:58",
        "sss": "2018-01-15 12:00"
    },
    {
        "id": "IN18-01_02",
        "sponsor": "SANOFI",
        "study": "EFC14153",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 6249 9603",
        "zarValue": "R268171.00",
        "receivingDate": "2018-01-08                           ",
        "email": "2018-01-08 12:18",
        "confirmArrival": "2018-01-08 16:24",
        "qaCheck": "2018-01-09 11:52",
        "inventoryAdded ": "2018-01-09 11:52",
        "sss": "2018-01-09 11:53"
    },
    {
        "id": "IN18-01_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2806122",
        "zarValue": "R159749.00",
        "receivingDate": "2018-01-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-12 14:23",
        "qaCheck": "2018-01-12 14:58",
        "inventoryAdded ": "2018-01-12 14:58",
        "sss": "2018-01-12 14:58"
    },
    {
        "id": "IN18-01_04",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6059 3956",
        "zarValue": "R121508.00",
        "receivingDate": "2018-01-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-08 16:29",
        "qaCheck": "2018-01-11 10:41",
        "inventoryAdded ": "2018-01-11 11:00",
        "sss": "2018-01-11 11:02"
    },
    {
        "id": "IN18-01_05",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X08274264",
        "zarValue": "R300718.00",
        "receivingDate": "2018-01-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-08 20:05",
        "qaCheck": "2018-01-11 11:03",
        "inventoryAdded ": "2018-01-11 11:03",
        "sss": "2018-01-11 11:04"
    },
    {
        "id": "IN18-01_06",
        "sponsor": "SANOFI",
        "study": "EFC14837",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "083-9070-5156",
        "zarValue": "R661248.00",
        "receivingDate": "2018-01-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-12 07:54",
        "qaCheck": "2018-01-12 07:55",
        "inventoryAdded ": "2018-01-12 07:55",
        "sss": "2018-01-12 08:01"
    },
    {
        "id": "IN18-01_08",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "057-63269511",
        "zarValue": "R1582250.00",
        "receivingDate": "2018-01-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-18 10:42",
        "qaCheck": "2018-01-26 10:45",
        "inventoryAdded ": "2018-01-26 10:45",
        "sss": "2018-01-26 10:52"
    },
    {
        "id": "IN18-01_09",
        "sponsor": "CATALENT",
        "study": "MO25757",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 0624",
        "zarValue": "R323882.45",
        "receivingDate": "2018-01-09                           ",
        "email": "2018-01-11 08:51",
        "confirmArrival": "2018-01-12 13:56",
        "qaCheck": "2018-01-12 14:41",
        "inventoryAdded ": "2018-01-12 14:41",
        "sss": "2018-01-12 14:42"
    },
    {
        "id": "IN18-01_10",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "0",
        "courierCoy": "Aspen",
        "mawb": "38344203",
        "zarValue": "R52509.80",
        "receivingDate": "2018-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-11 09:24",
        "qaCheck": "2018-01-11 09:24",
        "inventoryAdded ": "2018-01-11 09:24",
        "sss": "2018-01-11 09:25"
    },
    {
        "id": "IN18-01_11",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "771000755177",
        "zarValue": "R0.00",
        "receivingDate": "2018-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-11 10:06",
        "qaCheck": "2018-01-11 10:06",
        "inventoryAdded ": "2018-01-11 10:07",
        "sss": "2018-01-11 10:14"
    },
    {
        "id": "IN18-01_12",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7710 0035 1663",
        "zarValue": "R0.00",
        "receivingDate": "2018-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-11 10:31",
        "qaCheck": "2018-01-11 10:31",
        "inventoryAdded ": "2018-01-11 10:31",
        "sss": "2018-01-11 10:33"
    },
    {
        "id": "IN18-01_13",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "601129696",
        "zarValue": "R309882.60",
        "receivingDate": "2018-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-11 11:06",
        "qaCheck": "2018-01-11 11:07",
        "inventoryAdded ": "2018-01-11 11:07",
        "sss": "2018-01-11 11:10"
    },
    {
        "id": "IN18-01_14",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "724 6315 6741",
        "zarValue": "R142041.00",
        "receivingDate": "2018-01-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-12 08:15",
        "qaCheck": "2018-01-12 08:15",
        "inventoryAdded ": "2018-01-12 14:42",
        "sss": "2018-01-12 14:43"
    },
    {
        "id": "IN18-01_15",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "32334515",
        "zarValue": "R26250.00",
        "receivingDate": "2018-01-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-12 13:06",
        "qaCheck": "2018-01-12 13:07",
        "inventoryAdded ": "2018-01-12 13:06",
        "sss": "2018-01-12 13:08"
    },
    {
        "id": "IN18-01_18",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "182720076W",
        "zarValue": "R31629.00",
        "receivingDate": "2018-01-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-15 13:10",
        "qaCheck": "2018-01-15 13:36",
        "inventoryAdded ": "2018-01-15 13:36",
        "sss": "2018-01-15 13:39"
    },
    {
        "id": "IN18-01_19",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2806926",
        "zarValue": "R123706.00",
        "receivingDate": "2018-01-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-17 14:32",
        "qaCheck": "2018-01-17 14:39",
        "inventoryAdded ": "2018-01-17 14:39",
        "sss": "2018-01-17 14:43"
    },
    {
        "id": "IN18-01_20",
        "sponsor": "CATALENT",
        "study": "GA29145",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 1501",
        "zarValue": "R72630.20",
        "receivingDate": "2018-01-21                           ",
        "email": "2018-01-18 13:12",
        "confirmArrival": "2018-01-23 15:51",
        "qaCheck": "2018-01-23 16:24",
        "inventoryAdded ": "2018-01-23 16:18",
        "sss": "2018-01-23 16:24"
    },
    {
        "id": "IN18-01_21",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "604X08368385",
        "zarValue": "R80799.60",
        "receivingDate": "2018-01-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-19 11:08",
        "qaCheck": "2018-01-19 11:19",
        "inventoryAdded ": "2018-01-19 11:09",
        "sss": "2018-01-19 11:22"
    },
    {
        "id": "IN18-01_22",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "0",
        "courierCoy": "SPX",
        "mawb": "057-63269511",
        "zarValue": "R75870.00",
        "receivingDate": "2018-01-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-19 13:39",
        "qaCheck": "2018-01-26 10:45",
        "inventoryAdded ": "2018-01-26 10:46",
        "sss": "2018-01-26 10:50"
    },
    {
        "id": "IN18-01_23",
        "sponsor": "CATALENT",
        "study": "CO39721",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 1383",
        "zarValue": "R14995875.81",
        "receivingDate": "2018-01-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-01 09:53",
        "qaCheck": "2018-02-01 11:35",
        "inventoryAdded ": "2018-02-01 11:33",
        "sss": "2018-02-01 11:36"
    },
    {
        "id": "IN18-01_24",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 1980",
        "zarValue": "R3760567.69",
        "receivingDate": "2018-01-27                           ",
        "email": "2018-01-24 12:15",
        "confirmArrival": "2018-02-01 15:25",
        "qaCheck": "2018-02-01 15:47",
        "inventoryAdded ": "2018-02-01 15:43",
        "sss": "2018-02-01 15:47"
    },
    {
        "id": "IN18-01_25",
        "sponsor": "CATALENT",
        "study": "GB39242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "074-10684450",
        "zarValue": "R647087.00",
        "receivingDate": "2018-02-05                           ",
        "email": "2018-01-24 12:27",
        "confirmArrival": "2018-02-07 15:28",
        "qaCheck": "2018-02-08 16:00",
        "inventoryAdded ": "2018-02-08 15:59",
        "sss": "2018-02-08 16:01"
    },
    {
        "id": "IN18-01_26",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "32358267",
        "zarValue": "R369882.00",
        "receivingDate": "2018-01-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-25 09:38",
        "qaCheck": "2018-01-25 09:44",
        "inventoryAdded ": "2018-01-25 09:38",
        "sss": "2018-01-25 09:47"
    },
    {
        "id": "IN18-01_27",
        "sponsor": "ALMAC",
        "study": "RPC01-3101",
        "clearingAgent": "Seabourne ",
        "courierCoy": "QuickSTAT",
        "mawb": "182800231W",
        "zarValue": "R9779.49",
        "receivingDate": "2018-01-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-26 10:41",
        "qaCheck": "2018-02-26 10:02",
        "inventoryAdded ": "2018-02-26 10:02",
        "sss": "2018-02-26 10:08"
    },
    {
        "id": "IN18-01_28",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "SPX",
        "courierCoy": "DSV",
        "mawb": "32364187",
        "zarValue": "R2441.25",
        "receivingDate": "2018-01-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-29 13:47",
        "qaCheck": "2018-01-29 13:54",
        "inventoryAdded ": "2018-01-29 13:47",
        "sss": "2018-01-29 13:55"
    },
    {
        "id": "IN18-01_29",
        "sponsor": "CATALENT",
        "study": "WO29479",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 2411",
        "zarValue": "R766540.00",
        "receivingDate": "2018-01-31                           ",
        "email": "2018-01-30 15:20",
        "confirmArrival": "2018-02-05 15:52",
        "qaCheck": "2018-02-06 12:03",
        "inventoryAdded ": "2018-02-06 11:58",
        "sss": "2018-02-06 12:03"
    },
    {
        "id": "IN18-01_30",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Dis-Chem",
        "mawb": "I0033303",
        "zarValue": "R5633.98",
        "receivingDate": "2018-01-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-31 11:24",
        "qaCheck": "2018-01-31 11:25",
        "inventoryAdded ": "2018-01-31 11:25",
        "sss": "2018-01-31 11:30"
    },
    {
        "id": "IN18-01_31",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "INV11236509",
        "zarValue": "R977.09",
        "receivingDate": "2018-01-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-31 13:16",
        "qaCheck": "2018-01-31 13:16",
        "inventoryAdded ": "2018-01-31 13:17",
        "sss": "2018-01-31 13:22"
    },
    {
        "id": "IN18-02_01",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "548975668380",
        "zarValue": "R0.00",
        "receivingDate": "2018-02-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-01 11:36",
        "qaCheck": "2018-02-01 11:37",
        "inventoryAdded ": "2018-02-01 11:37",
        "sss": "2018-02-01 11:37"
    },
    {
        "id": "IN18-02_03",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 2853",
        "zarValue": "R29033.70",
        "receivingDate": "2018-02-06                           ",
        "email": "2018-02-05 14:24",
        "confirmArrival": "2018-02-08 11:49",
        "qaCheck": "2018-02-08 14:12",
        "inventoryAdded ": "2018-02-08 13:27",
        "sss": "2018-02-08 14:12"
    },
    {
        "id": "IN18-02_04",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 2724",
        "zarValue": "R459623.00",
        "receivingDate": "2018-02-07                           ",
        "email": "2018-02-05 16:16",
        "confirmArrival": "2018-02-07 15:25",
        "qaCheck": "2018-02-08 16:07",
        "inventoryAdded ": "2018-02-08 16:07",
        "sss": "2018-02-08 16:09"
    },
    {
        "id": "IN18-02_05",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2810639",
        "zarValue": "R46982.90",
        "receivingDate": "2018-02-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-07 08:57",
        "qaCheck": "2018-02-08 09:10",
        "inventoryAdded ": "2018-02-07 08:57",
        "sss": "2018-02-08 09:11"
    },
    {
        "id": "IN18-02_06",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6393 3376",
        "zarValue": "R75357.30",
        "receivingDate": "2018-02-14                           ",
        "email": "2018-02-09 10:38",
        "confirmArrival": "2018-02-14 15:23",
        "qaCheck": "2018-02-15 10:41",
        "inventoryAdded ": "2018-02-15 10:41",
        "sss": "2018-02-15 10:42"
    },
    {
        "id": "IN18-02_07",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Dis-Chem",
        "mawb": "PI04705",
        "zarValue": "R12587.70",
        "receivingDate": "2018-02-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-22 08:24",
        "qaCheck": "2018-02-22 08:24",
        "inventoryAdded ": "2018-02-22 08:25",
        "sss": "2018-02-22 08:53"
    },
    {
        "id": "IN18-02_08",
        "sponsor": "CATALENT",
        "study": "K-877-302 STUDY",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2811959",
        "zarValue": "R324560.00",
        "receivingDate": "2018-02-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-22 12:12",
        "qaCheck": "2018-02-23 10:42",
        "inventoryAdded ": "2018-02-23 10:42",
        "sss": "2018-02-23 10:49"
    },
    {
        "id": "IN18-02_09",
        "sponsor": "SANOFI",
        "study": "EFC15166",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 6249 9916",
        "zarValue": "R277259.00",
        "receivingDate": "2018-02-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-23 11:01",
        "qaCheck": "2018-02-26 09:59",
        "inventoryAdded ": "2018-02-26 10:00",
        "sss": "2018-02-26 10:01"
    },
    {
        "id": "IN18-02_10",
        "sponsor": "ALMAC",
        "study": "RPC01-3101",
        "clearingAgent": "SPX",
        "courierCoy": "QuickSTAT",
        "mawb": "183170279W",
        "zarValue": "R9517.39",
        "receivingDate": "2018-02-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-02-27 14:59",
        "qaCheck": "2018-02-27 15:11",
        "inventoryAdded ": "2018-02-27 15:11",
        "sss": "2018-02-28 15:29"
    },
    {
        "id": "IN18-02_11",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057 64836726",
        "zarValue": "R76973.80",
        "receivingDate": "2018-02-28                           ",
        "email": "2018-02-28 15:47",
        "confirmArrival": "2018-03-06 15:12",
        "qaCheck": "2018-03-09 10:28",
        "inventoryAdded ": "2018-03-06 15:28",
        "sss": "2018-03-09 10:35"
    },
    {
        "id": "IN18-02_12",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72465000213",
        "zarValue": "R161051.00",
        "receivingDate": "2018-03-07                           ",
        "email": "2018-02-28 15:58",
        "confirmArrival": "2018-03-09 14:49",
        "qaCheck": "2018-03-12 15:59",
        "inventoryAdded ": "2018-03-12 15:59",
        "sss": "2018-03-12 16:00"
    },
    {
        "id": "IN18-03_02",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "Seabourne ",
        "courierCoy": "QuickSTAT",
        "mawb": "183310017W",
        "zarValue": "R82060.80",
        "receivingDate": "2018-03-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-03-13 14:45",
        "qaCheck": "2018-03-13 15:25",
        "inventoryAdded ": "2018-03-13 15:21",
        "sss": "2018-03-13 15:26"
    },
    {
        "id": "IN18-03_03",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-65000751",
        "zarValue": "R1450415.22",
        "receivingDate": "2018-03-20                           ",
        "email": "2018-03-15 10:25",
        "confirmArrival": "2018-03-23 11:35",
        "qaCheck": "2018-03-26 15:22",
        "inventoryAdded ": "2018-03-26 15:22",
        "sss": "2018-03-26 15:23"
    },
    {
        "id": "IN18-03_04",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-64107385",
        "zarValue": "R114890.00",
        "receivingDate": "2018-03-19                           ",
        "email": "2018-03-16 09:51",
        "confirmArrival": "2018-03-27 13:38",
        "qaCheck": "2018-03-27 13:38",
        "inventoryAdded ": "2018-04-03 12:08",
        "sss": "2018-04-03 12:10"
    },
    {
        "id": "IN18-03_05",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6410 7632",
        "zarValue": "R179799.00",
        "receivingDate": "2018-03-23                           ",
        "email": "2018-03-23 11:20",
        "confirmArrival": "2018-03-23 15:35",
        "qaCheck": "2018-03-26 13:14",
        "inventoryAdded ": "2018-03-26 13:13",
        "sss": "2018-03-26 13:15"
    },
    {
        "id": "IN18-03_06",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "083-37021320",
        "zarValue": "R32777.20",
        "receivingDate": "2018-03-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-03-23 15:46",
        "qaCheck": "2018-03-26 15:41",
        "inventoryAdded ": "2018-03-26 15:41",
        "sss": "2018-03-26 15:44"
    },
    {
        "id": "IN18-03_07",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "771780421041",
        "zarValue": "R234.06",
        "receivingDate": "2018-03-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-03-26 12:51",
        "qaCheck": "2018-03-26 12:51",
        "inventoryAdded ": "2018-03-26 12:51",
        "sss": "2018-03-26 12:54"
    },
    {
        "id": "IN18-03_08",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "7800 2885 1631",
        "zarValue": "R2330.82",
        "receivingDate": "2018-03-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-03-27 10:43",
        "qaCheck": "2018-03-27 10:46",
        "inventoryAdded ": "2018-03-27 10:44",
        "sss": "2018-03-27 10:46"
    },
    {
        "id": "IN18-03_09",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2819795",
        "zarValue": "R69894.30",
        "receivingDate": "2018-03-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-03-28 14:45",
        "qaCheck": "2018-03-28 15:05",
        "inventoryAdded ": "2018-03-28 14:55",
        "sss": "2018-03-28 15:05"
    },
    {
        "id": "IN18-04_01",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6410 8096",
        "zarValue": "R4407190.96",
        "receivingDate": "2018-04-03                           ",
        "email": "2018-04-03 12:30",
        "confirmArrival": "2018-04-06 14:59",
        "qaCheck": "2018-04-06 15:00",
        "inventoryAdded ": "2018-04-06 15:00",
        "sss": "2018-04-06 15:01"
    },
    {
        "id": "IN18-04_02",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724-6614 7675",
        "zarValue": "R709362.00",
        "receivingDate": "2018-04-03                           ",
        "email": "2018-04-03 12:46",
        "confirmArrival": "2018-04-04 15:50",
        "qaCheck": "2018-04-04 16:18",
        "inventoryAdded ": "2018-04-04 15:50",
        "sss": "2018-04-04 16:17"
    },
    {
        "id": "IN18-04_03",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "32507712",
        "zarValue": "R123294.00",
        "receivingDate": "2018-04-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-05 15:30",
        "qaCheck": "2018-04-05 15:30",
        "inventoryAdded ": "2018-04-05 15:30",
        "sss": "2018-04-06 14:58"
    },
    {
        "id": "IN18-04_04",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "SPX",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "INV3233190",
        "zarValue": "R85717.44",
        "receivingDate": "2018-04-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-06 15:05",
        "qaCheck": "2018-04-06 15:05",
        "inventoryAdded ": "2018-04-06 15:05",
        "sss": "2018-04-06 15:07"
    },
    {
        "id": "IN18-04_05",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Dis-Chem",
        "mawb": "DISCHEM DIRECT",
        "zarValue": "R4079.51",
        "receivingDate": "2018-04-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-10 10:10",
        "qaCheck": "2018-04-10 10:10",
        "inventoryAdded ": "2018-04-10 10:10",
        "sss": "2018-04-10 10:10"
    },
    {
        "id": "IN18-04_06",
        "sponsor": "CATALENT",
        "study": "WO29479",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 6410 8866",
        "zarValue": "R571550.00",
        "receivingDate": "2018-04-12                           ",
        "email": "2018-04-10 14:27",
        "confirmArrival": "2018-04-16 11:32",
        "qaCheck": "2018-04-16 12:04",
        "inventoryAdded ": "2018-04-16 12:04",
        "sss": "2018-04-16 12:05"
    },
    {
        "id": "IN18-04_07",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6410 8973",
        "zarValue": "R291355.43",
        "receivingDate": "2018-04-13                           ",
        "email": "2018-04-11 11:13",
        "confirmArrival": "2018-04-13 14:39",
        "qaCheck": "2018-04-16 10:41",
        "inventoryAdded ": "2018-04-16 10:41",
        "sss": "2018-04-16 10:44"
    },
    {
        "id": "IN18-04_08",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "932-535 87730",
        "zarValue": "R9357.47",
        "receivingDate": "2018-04-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-12 10:06",
        "qaCheck": "2018-04-12 10:12",
        "inventoryAdded ": "2018-04-13 14:37",
        "sss": "2018-04-13 14:38"
    },
    {
        "id": "IN18-04_09",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "LogicTrials",
        "mawb": "152260",
        "zarValue": "R3095.90",
        "receivingDate": "2018-04-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-13 14:52",
        "qaCheck": "2018-04-16 09:49",
        "inventoryAdded ": "2018-04-16 09:49",
        "sss": "2018-04-16 09:58"
    },
    {
        "id": "IN18-04_10",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "32530015",
        "zarValue": "R383664.30",
        "receivingDate": "2018-04-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-13 14:52",
        "qaCheck": "2018-04-16 10:35",
        "inventoryAdded ": "2018-04-16 10:35",
        "sss": "2018-04-16 10:38"
    },
    {
        "id": "IN18-04_11",
        "sponsor": "SANOFI",
        "study": "EFC14153",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "437879",
        "zarValue": "R185901.00",
        "receivingDate": "2018-04-16                           ",
        "email": "2018-04-16 09:36",
        "confirmArrival": "2018-04-19 15:11",
        "qaCheck": "2018-04-23 09:15",
        "inventoryAdded ": "2018-04-23 09:15",
        "sss": "2018-04-24 09:42"
    },
    {
        "id": "IN18-04_12",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Hilda Mostert",
        "mawb": "HAND CARRIED",
        "zarValue": "R0.00",
        "receivingDate": "2018-04-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-17 10:22",
        "qaCheck": "2018-04-19 11:15",
        "inventoryAdded ": "2018-04-17 10:25",
        "sss": "2018-04-19 11:15"
    },
    {
        "id": "IN18-04_13",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2823687",
        "zarValue": "R214032.00",
        "receivingDate": "2018-04-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-17 15:03",
        "qaCheck": "2018-04-19 11:21",
        "inventoryAdded ": "2018-04-17 15:03",
        "sss": "2018-04-19 11:21"
    },
    {
        "id": "IN18-04_14",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6410 9511",
        "zarValue": "R87212.70",
        "receivingDate": "2018-04-20                           ",
        "email": "2018-04-18 15:42",
        "confirmArrival": "2018-04-23 14:29",
        "qaCheck": "2018-04-24 10:32",
        "inventoryAdded ": "2018-04-24 10:32",
        "sss": "2018-05-02 11:23"
    },
    {
        "id": "IN18-04_15",
        "sponsor": "ALMAC",
        "study": "RPC01-3101",
        "clearingAgent": "QI LOGISTICS",
        "courierCoy": "QuickSTAT",
        "mawb": "183690250W",
        "zarValue": "R12125.00",
        "receivingDate": "2018-04-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-20 11:53",
        "qaCheck": "2018-04-23 08:50",
        "inventoryAdded ": "2018-04-23 09:08",
        "sss": "2018-04-23 09:12"
    },
    {
        "id": "IN18-04_16",
        "sponsor": "CATALENT",
        "study": "WO40242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-66165271",
        "zarValue": "R2700297.46",
        "receivingDate": "2018-04-25                           ",
        "email": "2018-04-20 12:01",
        "confirmArrival": "2018-05-04 11:00",
        "qaCheck": "2018-05-10 15:23",
        "inventoryAdded ": "2018-05-10 15:05",
        "sss": "2018-05-10 15:23"
    },
    {
        "id": "IN18-04_17",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6410 9570",
        "zarValue": "R184155.00",
        "receivingDate": "2018-04-21                           ",
        "email": "2018-04-20 12:05",
        "confirmArrival": "2018-04-26 11:54",
        "qaCheck": "2018-05-04 12:09",
        "inventoryAdded ": "2018-04-26 14:47",
        "sss": "2018-05-04 12:10"
    },
    {
        "id": "IN18-04_19",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6410 9640",
        "zarValue": "R437385.00",
        "receivingDate": "2018-04-22                           ",
        "email": "2018-04-20 15:57",
        "confirmArrival": "2018-04-26 13:26",
        "qaCheck": "2018-05-04 12:10",
        "inventoryAdded ": "2018-04-26 13:53",
        "sss": "2018-05-04 12:10"
    },
    {
        "id": "IN18-04_20",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "157-32463130",
        "zarValue": "R158057.00",
        "receivingDate": "2018-04-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-23 14:35",
        "qaCheck": "2018-04-23 15:13",
        "inventoryAdded ": "2018-04-23 15:12",
        "sss": "2018-04-23 15:13"
    },
    {
        "id": "IN18-04_21",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6616 5374",
        "zarValue": "R1544174.84",
        "receivingDate": "2018-05-02                           ",
        "email": "2018-04-25 14:50",
        "confirmArrival": "2018-05-04 10:58",
        "qaCheck": "2018-05-10 15:26",
        "inventoryAdded ": "2018-05-10 15:05",
        "sss": "2018-05-10 15:26"
    },
    {
        "id": "IN18-04_22",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2825762",
        "zarValue": "R121250.00",
        "receivingDate": "2018-04-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-02 16:00",
        "qaCheck": "2018-05-04 08:45",
        "inventoryAdded ": "2018-05-04 08:45",
        "sss": "2018-05-04 09:02"
    },
    {
        "id": "IN18-04_23",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "72464109684",
        "zarValue": "R779331.38",
        "receivingDate": "2018-04-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-26 15:23",
        "qaCheck": "2018-05-02 13:25",
        "inventoryAdded ": "2018-04-26 15:24",
        "sss": "2018-05-02 13:26"
    },
    {
        "id": "IN18-05_01",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "772019034050",
        "zarValue": "R2513.16",
        "receivingDate": "2018-05-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-02 10:52",
        "qaCheck": "2018-05-02 10:52",
        "inventoryAdded ": "2018-05-02 10:52",
        "sss": "2018-05-02 10:58"
    },
    {
        "id": "IN18-05_02",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "KIM DU",
        "mawb": "HAND CARRIED",
        "zarValue": "R0.00",
        "receivingDate": "2018-05-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-02 13:34",
        "qaCheck": "2018-05-02 13:42",
        "inventoryAdded ": "2018-05-02 13:34",
        "sss": "2018-05-02 13:42"
    },
    {
        "id": "IN18-05_03",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6411 0126",
        "zarValue": "R3771374.99",
        "receivingDate": "2018-05-02                           ",
        "email": "2018-05-02 15:49",
        "confirmArrival": "2018-05-09 09:42",
        "qaCheck": "2018-05-09 11:52",
        "inventoryAdded ": "2018-05-09 11:50",
        "sss": "2018-05-09 11:53"
    },
    {
        "id": "IN18-05_04",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6691 6533",
        "zarValue": "R232912.47",
        "receivingDate": "2018-05-06                           ",
        "email": "2018-05-02 15:58",
        "confirmArrival": "2018-05-09 14:32",
        "qaCheck": "2018-05-09 14:51",
        "inventoryAdded ": "2018-05-09 14:44",
        "sss": "2018-05-09 14:52"
    },
    {
        "id": "IN18-05_05",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6691 6544",
        "zarValue": "R187984.57",
        "receivingDate": "2018-05-06                           ",
        "email": "2018-05-02 15:59",
        "confirmArrival": "2018-05-09 09:43",
        "qaCheck": "2018-05-09 11:56",
        "inventoryAdded ": "2018-05-09 11:56",
        "sss": "2018-05-09 11:58"
    },
    {
        "id": "IN18-05_07",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "057-6569 9174",
        "zarValue": "R52660.15",
        "receivingDate": "2018-05-04                           ",
        "email": "2018-05-03 12:56",
        "confirmArrival": "2018-05-10 14:44",
        "qaCheck": "2018-05-10 14:59",
        "inventoryAdded ": "2018-05-10 14:58",
        "sss": "2018-05-10 14:59"
    },
    {
        "id": "IN18-05_08",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6691 6743",
        "zarValue": "R449222.00",
        "receivingDate": "2018-05-06                           ",
        "email": "2018-05-04 15:49",
        "confirmArrival": "2018-05-10 11:30",
        "qaCheck": "2018-05-10 11:34",
        "inventoryAdded ": "2018-05-10 11:33",
        "sss": "2018-05-10 11:34"
    },
    {
        "id": "IN18-05_09",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Morne de Bruin",
        "mawb": "HAND CARRIED",
        "zarValue": "R0.00",
        "receivingDate": "2018-05-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-07 12:13",
        "qaCheck": "2018-05-07 12:27",
        "inventoryAdded ": "2018-05-07 12:13",
        "sss": "2018-05-07 12:27"
    },
    {
        "id": "IN18-05_11",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "5489 7567 0920",
        "zarValue": "R14933.20",
        "receivingDate": "2018-05-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-09 15:10",
        "qaCheck": "2018-05-09 15:18",
        "inventoryAdded ": "2018-05-09 15:12",
        "sss": "2018-05-09 15:18"
    },
    {
        "id": "IN18-05_12",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "LogicTrials",
        "mawb": "VISTPHARM ",
        "zarValue": "R8712.55",
        "receivingDate": "2018-05-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-10 12:50",
        "qaCheck": "2018-05-10 12:51",
        "inventoryAdded ": "2018-05-10 12:52",
        "sss": "2018-05-10 12:52"
    },
    {
        "id": "IN18-05_13",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "LogicTrials",
        "mawb": "4950031",
        "zarValue": "R681.72",
        "receivingDate": "2018-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-22 15:32",
        "qaCheck": "2018-05-22 15:32",
        "inventoryAdded ": "2018-05-22 15:32",
        "sss": "2018-05-22 15:36"
    },
    {
        "id": "IN18-05_14",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Transpharm",
        "mawb": "INV12000397",
        "zarValue": "R117.23",
        "receivingDate": "2018-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-22 15:40",
        "qaCheck": "2018-05-22 15:40",
        "inventoryAdded ": "2018-05-22 15:40",
        "sss": "2018-05-22 15:40"
    },
    {
        "id": "IN18-05_15",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "32610131",
        "zarValue": "R123294.00",
        "receivingDate": "2018-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-30 12:28",
        "qaCheck": "2018-05-30 12:28",
        "inventoryAdded ": "2018-05-30 12:28",
        "sss": "2018-05-30 12:35"
    },
    {
        "id": "IN18-05_16",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "32610572",
        "zarValue": "R26570.00",
        "receivingDate": "2018-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-30 12:37",
        "qaCheck": "2018-05-30 12:38",
        "inventoryAdded ": "2018-05-30 12:38",
        "sss": "2018-05-30 13:06"
    },
    {
        "id": "IN18-05_17",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "Transpharm",
        "courierCoy": "Transpharm",
        "mawb": "INV12000396",
        "zarValue": "R985.67",
        "receivingDate": "2018-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-30 12:20",
        "qaCheck": "2018-05-30 12:20",
        "inventoryAdded ": "2018-05-30 12:21",
        "sss": "2018-05-30 12:23"
    },
    {
        "id": "IN18-05_18",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2830270",
        "zarValue": "R124377.00",
        "receivingDate": "2018-05-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-24 16:15",
        "qaCheck": "2018-05-25 12:25",
        "inventoryAdded ": "2018-05-25 12:19",
        "sss": "2018-05-25 12:25"
    },
    {
        "id": "IN18-05_19",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "932-53588975",
        "zarValue": "R74626.40",
        "receivingDate": "2018-05-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-25 10:10",
        "qaCheck": "2018-05-25 12:30",
        "inventoryAdded ": "2018-05-25 12:30",
        "sss": "2018-05-25 12:30"
    },
    {
        "id": "IN18-05_20",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "N/A",
        "courierCoy": "Aramex",
        "mawb": "40475962516",
        "zarValue": "R0.00",
        "receivingDate": "2018-05-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-25 11:52",
        "qaCheck": "2018-05-25 11:53",
        "inventoryAdded ": "2018-05-25 11:53",
        "sss": "2018-05-25 11:53"
    },
    {
        "id": "IN18-05_21",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "SPX",
        "courierCoy": "Aspen",
        "mawb": "39158319",
        "zarValue": "R233132.60",
        "receivingDate": "2018-05-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-30 13:17",
        "qaCheck": "2018-05-30 13:20",
        "inventoryAdded ": "2018-05-30 13:20",
        "sss": "2018-05-30 13:32"
    },
    {
        "id": "IN18-06_01",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "724-6691 8331",
        "zarValue": "R8373.20",
        "receivingDate": "2018-06-02                           ",
        "email": "2018-06-01 10:30",
        "confirmArrival": "2018-06-13 11:05",
        "qaCheck": "2018-06-18 15:34",
        "inventoryAdded ": "2018-06-13 12:49",
        "sss": "2018-06-19 15:16"
    },
    {
        "id": "IN18-06_02",
        "sponsor": "SANOFI",
        "study": "EFC15166",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "083-9070 9266",
        "zarValue": "R417976.00",
        "receivingDate": "2018-06-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-06 15:49",
        "qaCheck": "2018-06-07 09:41",
        "inventoryAdded ": "2018-06-07 09:41",
        "sss": "2018-06-07 09:45"
    },
    {
        "id": "IN18-06_03",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "742-6691 8751",
        "zarValue": "R78464.18",
        "receivingDate": "2018-06-10                           ",
        "email": "2018-06-08 14:06",
        "confirmArrival": "2018-06-13 16:07",
        "qaCheck": "2018-06-18 15:34",
        "inventoryAdded ": "2018-06-14 10:35",
        "sss": "2018-06-20 09:13"
    },
    {
        "id": "IN18-06_04",
        "sponsor": "CATALENT",
        "study": "BN29553",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "074-15246965",
        "zarValue": "R2119440.00",
        "receivingDate": "2018-06-11                           ",
        "email": "2018-06-08 14:30",
        "confirmArrival": "2018-06-15 15:46",
        "qaCheck": "2018-06-18 15:35",
        "inventoryAdded ": "2018-06-18 13:52",
        "sss": "2018-06-26 13:39"
    },
    {
        "id": "IN18-06_05",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "32641218",
        "zarValue": "R369882.00",
        "receivingDate": "2018-06-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-10 20:14",
        "qaCheck": "2018-06-10 20:14",
        "inventoryAdded ": "2018-06-10 20:14",
        "sss": "2018-06-11 08:47"
    },
    {
        "id": "IN18-06_06",
        "sponsor": "ALMAC",
        "study": "EFC14837",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "32634788",
        "zarValue": "R249016.42",
        "receivingDate": "2018-06-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-11 09:22",
        "qaCheck": "2018-06-11 09:22",
        "inventoryAdded ": "2018-06-11 09:23",
        "sss": "2018-06-11 09:24"
    },
    {
        "id": "IN18-06_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2834445",
        "zarValue": "R90478.20",
        "receivingDate": "2018-06-14                           ",
        "email": "2018-06-11 15:47",
        "confirmArrival": "2018-06-19 13:54",
        "qaCheck": "2018-06-28 11:35",
        "inventoryAdded ": "2018-06-22 15:45",
        "sss": "2018-06-28 11:48"
    },
    {
        "id": "IN18-06_08",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-66166166",
        "zarValue": "R152100.00",
        "receivingDate": "2018-06-13                           ",
        "email": "2018-06-13 13:28",
        "confirmArrival": "2018-06-15 11:21",
        "qaCheck": "2018-06-18 15:35",
        "inventoryAdded ": "2018-06-15 13:45",
        "sss": "2018-06-26 13:52"
    },
    {
        "id": "IN18-06_09",
        "sponsor": "ALMAC",
        "study": "RPC01-3102",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "UPS SCS South Africa",
        "mawb": "1Z97X3840450048341",
        "zarValue": "R23269.30",
        "receivingDate": "2018-06-20                           ",
        "email": "2018-06-13 14:08",
        "confirmArrival": "2018-06-19 13:52",
        "qaCheck": "2018-06-28 11:48",
        "inventoryAdded ": "2018-06-26 14:07",
        "sss": "2018-06-28 11:55"
    },
    {
        "id": "IN18-06_10",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "057-67160811",
        "zarValue": "R164437.00",
        "receivingDate": "2018-06-15                           ",
        "email": "2018-06-13 14:38",
        "confirmArrival": "2018-06-15 11:16",
        "qaCheck": "2018-06-18 15:35",
        "inventoryAdded ": "2018-06-15 13:46",
        "sss": "2018-06-26 13:56"
    },
    {
        "id": "IN18-06_11",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6691 9016",
        "zarValue": "R4408256.53",
        "receivingDate": "2018-06-19                           ",
        "email": "2018-06-15 09:38",
        "confirmArrival": "2018-06-21 14:03",
        "qaCheck": "2018-06-28 11:40",
        "inventoryAdded ": "2018-06-21 15:30",
        "sss": "2018-06-28 11:46"
    },
    {
        "id": "IN18-06_12",
        "sponsor": "SANOFI",
        "study": "LPS15328",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "604X09180949",
        "zarValue": "R179230.00",
        "receivingDate": "2018-06-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-15 11:55",
        "qaCheck": "2018-06-18 15:35",
        "inventoryAdded ": "2018-06-15 13:45",
        "sss": "2018-06-26 13:47"
    },
    {
        "id": "IN18-06_13",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "32670960",
        "zarValue": "R177075.90",
        "receivingDate": "2018-06-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-19 16:14",
        "qaCheck": "2018-06-28 11:37",
        "inventoryAdded ": "2018-06-22 15:18",
        "sss": "2018-06-28 11:48"
    },
    {
        "id": "IN18-06_14",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "S.BUYS",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "S.BUYS WHOLESALER",
        "zarValue": "R134614.30",
        "receivingDate": "2018-06-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-20 14:18",
        "qaCheck": "2018-06-28 11:10",
        "inventoryAdded ": "2018-06-20 14:18",
        "sss": "2018-06-28 11:51"
    },
    {
        "id": "IN18-06_15",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "604X09205476",
        "zarValue": "R20475.40",
        "receivingDate": "2018-06-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-21 13:33",
        "qaCheck": "2018-06-28 11:06",
        "inventoryAdded ": "2018-06-21 13:33",
        "sss": "2018-06-28 11:53"
    },
    {
        "id": "IN18-06_16",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6702 8485",
        "zarValue": "R143871.00",
        "receivingDate": "2018-06-27                           ",
        "email": "2018-06-22 16:09",
        "confirmArrival": "2018-06-29 12:09",
        "qaCheck": "2018-07-04 11:10",
        "inventoryAdded ": "2018-06-29 12:14",
        "sss": "2018-07-04 13:35"
    },
    {
        "id": "IN18-06_17",
        "sponsor": "SANOFI",
        "study": "EFC14643",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X09216780",
        "zarValue": "R124602.00",
        "receivingDate": "2018-06-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-25 08:37",
        "qaCheck": "2018-06-28 11:33",
        "inventoryAdded ": "2018-06-25 08:37",
        "sss": "2018-06-28 11:49"
    },
    {
        "id": "IN18-06_18",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "184321124W",
        "zarValue": "R234496.00",
        "receivingDate": "2018-06-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-25 09:39",
        "qaCheck": "2018-06-28 12:01",
        "inventoryAdded ": "2018-06-26 14:20",
        "sss": "2018-06-28 12:05"
    },
    {
        "id": "IN18-06_19",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "Lekoko",
        "zarValue": "R0.00",
        "receivingDate": "2018-06-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-26 09:46",
        "qaCheck": "2018-06-28 11:22",
        "inventoryAdded ": "2018-06-26 09:46",
        "sss": "2018-06-28 11:50"
    },
    {
        "id": "IN18-06_20",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X09216767",
        "zarValue": "R3456.45",
        "receivingDate": "2018-06-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-26 13:18",
        "qaCheck": "2018-06-28 10:07",
        "inventoryAdded ": "2018-06-26 13:18",
        "sss": "2018-06-28 10:25"
    },
    {
        "id": "IN18-06_21",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "728-6702 8673",
        "zarValue": "R3216523.18",
        "receivingDate": "2018-07-02                           ",
        "email": "2018-06-29 15:54",
        "confirmArrival": "2018-07-04 13:15",
        "qaCheck": "2018-10-09 13:46",
        "inventoryAdded ": "2018-07-04 13:21",
        "sss": "2018-10-09 13:46"
    },
    {
        "id": "IN18-07_01",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Dis-Chem",
        "mawb": "Collected from Dischem",
        "zarValue": "R342.12",
        "receivingDate": "2018-07-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-02 11:22",
        "qaCheck": "2018-07-04 11:09",
        "inventoryAdded ": "2018-07-02 11:22",
        "sss": "2018-07-04 13:35"
    },
    {
        "id": "IN18-07_03",
        "sponsor": "ALMAC",
        "study": "EFC14837",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "601239957",
        "zarValue": "R47698.00",
        "receivingDate": "2018-07-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-03 10:11",
        "qaCheck": "2018-07-04 10:56",
        "inventoryAdded ": "2018-07-03 10:11",
        "sss": "2018-07-04 13:35"
    },
    {
        "id": "IN18-07_04",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-6702 8710",
        "zarValue": "R99402.75",
        "receivingDate": "2018-07-04                           ",
        "email": "2018-07-03 10:33",
        "confirmArrival": "2018-07-05 16:19",
        "qaCheck": "2018-07-12 21:26",
        "inventoryAdded ": "2018-07-06 10:41",
        "sss": "2018-07-17 13:30"
    },
    {
        "id": "IN18-07_05",
        "sponsor": "SANOFI",
        "study": "EFC14837",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "083-9070-9920",
        "zarValue": "R336965.00",
        "receivingDate": "2018-07-04                           ",
        "email": "2018-07-03 10:48",
        "confirmArrival": "2018-07-05 16:19",
        "qaCheck": "2018-07-16 12:56",
        "inventoryAdded ": "2018-07-05 16:20",
        "sss": "2018-07-16 12:57"
    },
    {
        "id": "IN18-07_06",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Collected",
        "mawb": "PHARMACY",
        "zarValue": "R0.00",
        "receivingDate": "2018-07-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-03 10:57",
        "qaCheck": "2018-07-04 10:51",
        "inventoryAdded ": "2018-07-03 10:57",
        "sss": "2018-07-04 13:34"
    },
    {
        "id": "IN18-07_07",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "125-8729-5946",
        "zarValue": "R237043.00",
        "receivingDate": "2018-07-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-03 14:02",
        "qaCheck": "2018-07-12 21:25",
        "inventoryAdded ": "2018-07-03 14:02",
        "sss": "2018-07-16 14:53"
    },
    {
        "id": "IN18-07_09",
        "sponsor": "ALMAC",
        "study": "EFC14837",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "601247667",
        "zarValue": "R3577.35",
        "receivingDate": "2018-07-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-04 15:07",
        "qaCheck": "2018-07-06 11:55",
        "inventoryAdded ": "2018-07-04 15:08",
        "sss": "2018-07-09 09:35"
    },
    {
        "id": "IN18-07_10",
        "sponsor": "ALMAC",
        "study": "EFC14837",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "601250099",
        "zarValue": "R12035.40",
        "receivingDate": "2018-07-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-06 11:35",
        "qaCheck": "2018-07-06 11:55",
        "inventoryAdded ": "2018-07-06 11:35",
        "sss": "2018-07-09 09:35"
    },
    {
        "id": "IN18-07_11",
        "sponsor": "BIOCAIR",
        "study": "Q203-TB-P2-ZA001",
        "clearingAgent": "0",
        "courierCoy": "BIOCAIR",
        "mawb": "40114999",
        "zarValue": "R1501.34",
        "receivingDate": "2018-07-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-09 16:19",
        "qaCheck": "2018-07-17 21:33",
        "inventoryAdded ": "2018-07-12 09:40",
        "sss": "2018-07-23 10:29"
    },
    {
        "id": "IN18-07_12",
        "sponsor": "ALMAC",
        "study": "EFC15166",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV",
        "zarValue": "R65715.89",
        "receivingDate": "2018-07-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-10 10:37",
        "qaCheck": "2018-07-12 21:25",
        "inventoryAdded ": "2018-07-10 10:37",
        "sss": "2018-07-16 15:09"
    },
    {
        "id": "IN18-07_13",
        "sponsor": "ALMAC",
        "study": "RPC01-3101",
        "clearingAgent": "Seabourne ",
        "courierCoy": "QuickSTAT",
        "mawb": "184520407W",
        "zarValue": "R2564.28",
        "receivingDate": "2018-07-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-11 14:02",
        "qaCheck": "2018-07-12 21:28",
        "inventoryAdded ": "2018-07-12 09:35",
        "sss": "2018-07-16 16:07"
    },
    {
        "id": "IN18-07_14",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-66166844",
        "zarValue": "R197484.00",
        "receivingDate": "2018-07-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-13 15:11",
        "qaCheck": "2018-07-17 13:39",
        "inventoryAdded ": "2018-07-17 11:29",
        "sss": "2018-07-17 13:57"
    },
    {
        "id": "IN18-07_15",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "742-6703 0316",
        "zarValue": "R52362.70",
        "receivingDate": "2018-07-22                           ",
        "email": "2018-07-17 21:41",
        "confirmArrival": "2018-07-24 15:35",
        "qaCheck": "2018-07-31 11:18",
        "inventoryAdded ": "2018-07-25 10:11",
        "sss": "2018-08-06 12:22"
    },
    {
        "id": "IN18-07_16",
        "sponsor": "CATALENT",
        "study": "CO39721",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6703 0552",
        "zarValue": "R7506482.55",
        "receivingDate": "2018-07-22                           ",
        "email": "2018-07-20 09:27",
        "confirmArrival": "2018-07-24 15:36",
        "qaCheck": "2018-07-27 09:12",
        "inventoryAdded ": "2018-07-25 10:10",
        "sss": "2018-07-27 10:13"
    },
    {
        "id": "IN18-07_17",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "742-6703 0725",
        "zarValue": "R18091.62",
        "receivingDate": "2018-07-25                           ",
        "email": "2018-07-20 14:27",
        "confirmArrival": "2018-07-31 10:36",
        "qaCheck": "2018-08-07 14:16",
        "inventoryAdded ": "2018-08-06 12:39",
        "sss": "2018-08-07 15:10"
    },
    {
        "id": "IN18-07_18",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "0",
        "courierCoy": "Dis-Chem",
        "mawb": "DISCHEM DIRECT",
        "zarValue": "R91.66",
        "receivingDate": "2018-07-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-23 10:39",
        "qaCheck": "2018-07-24 11:20",
        "inventoryAdded ": "2018-07-23 11:26",
        "sss": "2018-07-27 10:59"
    },
    {
        "id": "IN18-07_19",
        "sponsor": "ALMAC",
        "study": "EFC14837",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV",
        "zarValue": "R1192.45",
        "receivingDate": "2018-07-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-23 10:44",
        "qaCheck": "2018-07-24 11:20",
        "inventoryAdded ": "2018-07-23 11:46",
        "sss": "2018-07-27 10:59"
    },
    {
        "id": "IN18-07_20",
        "sponsor": "ALMAC",
        "study": "EFC15166",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV",
        "zarValue": "R1192.45",
        "receivingDate": "2018-07-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-23 10:46",
        "qaCheck": "2018-07-24 11:20",
        "inventoryAdded ": "2018-07-23 12:04",
        "sss": "2018-07-27 11:00"
    },
    {
        "id": "IN18-07_21",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "S.BUYS",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "S.BUYS WHOLESALER",
        "zarValue": "R151.27",
        "receivingDate": "2018-07-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-23 10:51",
        "qaCheck": "2018-07-24 11:19",
        "inventoryAdded ": "2018-07-23 11:11",
        "sss": "2018-07-27 11:00"
    },
    {
        "id": "IN18-07_22",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6703 1230",
        "zarValue": "R234934.00",
        "receivingDate": "2018-08-01                           ",
        "email": "2018-07-26 15:20",
        "confirmArrival": "2018-08-02 15:08",
        "qaCheck": "2018-08-07 14:16",
        "inventoryAdded ": "2018-08-06 12:03",
        "sss": "2018-08-07 15:10"
    },
    {
        "id": "IN18-07_23",
        "sponsor": "CATALENT",
        "study": "MO25757",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6703 1226",
        "zarValue": "R324272.84",
        "receivingDate": "2018-08-01                           ",
        "email": "2018-07-26 15:37",
        "confirmArrival": "2018-08-02 15:07",
        "qaCheck": "2018-08-07 14:16",
        "inventoryAdded ": "2018-08-06 12:08",
        "sss": "2018-08-07 15:10"
    },
    {
        "id": "IN18-07_25",
        "sponsor": "SANOFI",
        "study": "EFC14153",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "441461",
        "zarValue": "R448522.00",
        "receivingDate": "2018-07-31                           ",
        "email": "2018-07-30 13:20",
        "confirmArrival": "2018-08-03 11:40",
        "qaCheck": "2018-08-07 14:15",
        "inventoryAdded ": "2018-08-06 11:50",
        "sss": "2018-08-07 15:10"
    },
    {
        "id": "IN18-07_26",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "DSV",
        "zarValue": "R486958.50",
        "receivingDate": "2018-07-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-30 13:26",
        "qaCheck": "2018-07-31 11:19",
        "inventoryAdded ": "2018-07-30 13:26",
        "sss": "2018-08-06 12:24"
    },
    {
        "id": "IN18-08_01",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "083-38208225",
        "zarValue": "R18873.90",
        "receivingDate": "2018-08-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-03 14:03",
        "qaCheck": "2018-08-07 14:15",
        "inventoryAdded ": "2018-08-03 14:06",
        "sss": "2018-08-07 15:10"
    },
    {
        "id": "IN18-08_02",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6703 2125",
        "zarValue": "R717434.00",
        "receivingDate": "2018-08-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 15:02",
        "qaCheck": "2018-08-20 12:06",
        "inventoryAdded ": "2018-08-16 15:07",
        "sss": "2018-08-20 12:16"
    },
    {
        "id": "IN18-08_03",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6876155",
        "zarValue": "R77443.90",
        "receivingDate": "2018-08-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-20 14:19",
        "qaCheck": "2018-08-27 12:08",
        "inventoryAdded ": "2018-08-24 10:36",
        "sss": "2018-08-28 14:28"
    },
    {
        "id": "IN18-08_04",
        "sponsor": "MARKEN",
        "study": "17-AVP-786-305",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "606X09404081",
        "zarValue": "R303558.00",
        "receivingDate": "2018-08-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-13 09:16",
        "qaCheck": "2018-08-13 16:10",
        "inventoryAdded ": "2018-08-13 09:18",
        "sss": "2018-08-13 16:21"
    },
    {
        "id": "IN18-08_05",
        "sponsor": "ALMAC",
        "study": "EFC14837",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV",
        "zarValue": "R0.00",
        "receivingDate": "2018-08-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-15 14:35",
        "qaCheck": "2018-08-16 13:12",
        "inventoryAdded ": "2018-08-15 14:36",
        "sss": "2018-08-16 15:42"
    },
    {
        "id": "IN18-08_06",
        "sponsor": "ALMAC",
        "study": "EFC15166",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "DSV",
        "zarValue": "R0.00",
        "receivingDate": "2018-08-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-15 14:55",
        "qaCheck": "2018-08-16 13:12",
        "inventoryAdded ": "2018-08-15 14:55",
        "sss": "2018-08-16 15:42"
    },
    {
        "id": "IN18-08_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2843982",
        "zarValue": "R238480.00",
        "receivingDate": "2018-08-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 14:33",
        "qaCheck": "2018-08-21 13:30",
        "inventoryAdded ": "2018-08-16 15:02",
        "sss": "2018-08-23 11:22"
    },
    {
        "id": "IN18-08_08",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "932-54529285",
        "zarValue": "R72437.00",
        "receivingDate": "2018-08-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 15:29",
        "qaCheck": "2018-08-20 12:07",
        "inventoryAdded ": "2018-08-16 15:30",
        "sss": "2018-08-20 12:15"
    },
    {
        "id": "IN18-08_09",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "SPX",
        "courierCoy": "BIOCAIR",
        "mawb": "72468730793",
        "zarValue": "R10756.90",
        "receivingDate": "2018-08-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 15:47",
        "qaCheck": "2018-08-22 11:05",
        "inventoryAdded ": "2018-08-16 15:47",
        "sss": "2018-08-23 11:22"
    },
    {
        "id": "IN18-08_10",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "S.BUYS",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "S. BUYS",
        "zarValue": "R9916.30",
        "receivingDate": "2018-08-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-21 14:54",
        "qaCheck": "2018-08-27 12:08",
        "inventoryAdded ": "2018-08-21 14:56",
        "sss": "2018-08-28 14:28"
    },
    {
        "id": "IN18-08_11",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "S.BUYS",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "1109226 (S. BUYS)",
        "zarValue": "R85077.23",
        "receivingDate": "2018-08-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-24 12:41",
        "qaCheck": "2018-08-27 13:20",
        "inventoryAdded ": "2018-08-24 12:41",
        "sss": "2018-08-28 14:29"
    },
    {
        "id": "IN18-08_12",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6717 6701",
        "zarValue": "R96409.27",
        "receivingDate": "2018-08-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-06 11:49",
        "qaCheck": "2018-09-11 10:03",
        "inventoryAdded ": "2018-09-06 11:49",
        "sss": "2018-09-12 10:52"
    },
    {
        "id": "IN18-08_13",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "DISCHEM",
        "courierCoy": "CLICKS",
        "mawb": "CLICKS",
        "zarValue": "R324.13",
        "receivingDate": "2018-08-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-28 14:31",
        "qaCheck": "2018-08-30 11:46",
        "inventoryAdded ": "2018-08-28 14:32",
        "sss": "2018-09-04 14:55"
    },
    {
        "id": "IN18-08_14",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "CLICKS",
        "courierCoy": "CLICKS",
        "mawb": "CLICKS",
        "zarValue": "R319.80",
        "receivingDate": "2018-08-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-29 11:49",
        "qaCheck": "2018-08-30 11:47",
        "inventoryAdded ": "2018-08-29 11:50",
        "sss": "2018-09-04 14:55"
    },
    {
        "id": "IN18-08_16",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "DISCHEM",
        "courierCoy": "Pharmacy",
        "mawb": "PHARMACY",
        "zarValue": "R1204.43",
        "receivingDate": "2018-08-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-29 15:13",
        "qaCheck": "2018-08-30 11:46",
        "inventoryAdded ": "2018-08-29 15:13",
        "sss": "2018-09-04 14:55"
    },
    {
        "id": "IN18-09_01",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "601289551006",
        "zarValue": "R493176.00",
        "receivingDate": "2018-09-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-04 14:58",
        "qaCheck": "2018-09-05 10:41",
        "inventoryAdded ": "2018-09-04 15:28",
        "sss": "2018-09-06 11:25"
    },
    {
        "id": "IN18-09_02",
        "sponsor": "Bayer",
        "study": "ASTEROID 3-15787",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "VISTPHARM",
        "zarValue": "R6321.40",
        "receivingDate": "2018-09-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-06 10:03",
        "qaCheck": "2018-09-06 11:46",
        "inventoryAdded ": "2018-09-06 10:03",
        "sss": "2018-09-11 09:11"
    },
    {
        "id": "IN18-09_03",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "72468756995",
        "zarValue": "R51148.50",
        "receivingDate": "2018-09-17                           ",
        "email": "2018-09-10 16:09",
        "confirmArrival": "2018-09-19 15:01",
        "qaCheck": "2018-09-20 13:49",
        "inventoryAdded ": "2018-09-20 13:04",
        "sss": "2018-09-20 15:16"
    },
    {
        "id": "IN18-09_04",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72467177331",
        "zarValue": "R4411277.65",
        "receivingDate": "2018-09-12                           ",
        "email": "2018-09-10 16:16",
        "confirmArrival": "2018-09-14 12:55",
        "qaCheck": "2018-09-17 13:40",
        "inventoryAdded ": "2018-09-14 12:55",
        "sss": "2018-09-18 10:47"
    },
    {
        "id": "IN18-09_05",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72467177364",
        "zarValue": "R234724.00",
        "receivingDate": "2018-09-11                           ",
        "email": "2018-09-10 16:19",
        "confirmArrival": "2018-09-14 14:53",
        "qaCheck": "2018-09-17 13:39",
        "inventoryAdded ": "2018-09-14 14:49",
        "sss": "2018-09-18 10:47"
    },
    {
        "id": "IN18-09_06",
        "sponsor": "SANOFI",
        "study": "EFC14643",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "176-94170871",
        "zarValue": "R113899.00",
        "receivingDate": "2018-09-14                           ",
        "email": "2018-09-12 11:16",
        "confirmArrival": "2018-09-17 13:21",
        "qaCheck": "2018-09-19 10:28",
        "inventoryAdded ": "2018-09-18 13:53",
        "sss": "2018-09-19 11:02"
    },
    {
        "id": "IN18-09_07",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "S.BUYS",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "1109752",
        "zarValue": "R117435.36",
        "receivingDate": "2018-09-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-12 13:11",
        "qaCheck": "2018-09-28 12:57",
        "inventoryAdded ": "2018-09-12 13:11",
        "sss": "2018-09-28 12:57"
    },
    {
        "id": "IN18-09_08",
        "sponsor": "Bayer",
        "study": "ASTEROID 3-15787",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "VISTPHARM",
        "zarValue": "R10875.00",
        "receivingDate": "2018-09-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-17 12:32",
        "qaCheck": "2018-09-19 10:28",
        "inventoryAdded ": "2018-09-17 12:33",
        "sss": "2018-09-19 11:02"
    },
    {
        "id": "IN18-09_09",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "93255419556",
        "zarValue": "R31554.00",
        "receivingDate": "2018-09-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-19 11:56",
        "qaCheck": "2018-09-20 13:41",
        "inventoryAdded ": "2018-09-19 11:59",
        "sss": "2018-09-20 15:10"
    },
    {
        "id": "IN18-09_10",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "93255419545",
        "zarValue": "R199021.00",
        "receivingDate": "2018-09-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-19 13:13",
        "qaCheck": "2018-09-20 13:40",
        "inventoryAdded ": "2018-09-19 13:13",
        "sss": "2018-09-20 15:11"
    },
    {
        "id": "IN18-09_11",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6717 7983",
        "zarValue": "R532758.00",
        "receivingDate": "2018-09-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-28 15:13",
        "qaCheck": "2018-10-01 12:07",
        "inventoryAdded ": "2018-09-29 10:13",
        "sss": "2018-10-03 09:01"
    },
    {
        "id": "IN18-09_12",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2855371",
        "zarValue": "R167042.00",
        "receivingDate": "2018-09-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-28 11:49",
        "qaCheck": "2018-10-01 12:06",
        "inventoryAdded ": "2018-09-28 11:49",
        "sss": "2018-10-03 09:01"
    },
    {
        "id": "IN18-09_13",
        "sponsor": "CATALENT",
        "study": "GB39242",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "72468757430",
        "zarValue": "R0.00",
        "receivingDate": "2018-09-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-29 10:29",
        "qaCheck": "2018-10-01 12:06",
        "inventoryAdded ": "2018-09-29 10:30",
        "sss": "2018-10-03 09:01"
    },
    {
        "id": "IN18-10_01",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "VIST PHARM",
        "zarValue": "R3006.06",
        "receivingDate": "2018-10-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-02 11:23",
        "qaCheck": "2018-10-03 10:35",
        "inventoryAdded ": "2018-10-02 11:22",
        "sss": "2018-10-09 13:47"
    },
    {
        "id": "IN18-10_02",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "636X09682584",
        "zarValue": "R1091840.00",
        "receivingDate": "2018-10-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-03 09:22",
        "qaCheck": "2018-10-03 16:11",
        "inventoryAdded ": "2018-10-03 09:22",
        "sss": "2018-10-03 16:17"
    },
    {
        "id": "IN18-10_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "08338710980",
        "zarValue": "R115219.00",
        "receivingDate": "2018-10-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-04 09:16",
        "qaCheck": "2018-10-04 09:34",
        "inventoryAdded ": "2018-10-04 09:16",
        "sss": "2018-10-04 10:39"
    },
    {
        "id": "IN18-10_04",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "VISTPHARM",
        "zarValue": "R3195.00",
        "receivingDate": "2018-10-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-09 09:33",
        "qaCheck": "2018-10-10 10:23",
        "inventoryAdded ": "2018-10-09 09:33",
        "sss": "2018-10-10 11:31"
    },
    {
        "id": "IN18-10_05",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "185381592W",
        "zarValue": "R320169.00",
        "receivingDate": "2018-10-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-09 13:52",
        "qaCheck": "2018-10-10 10:23",
        "inventoryAdded ": "2018-10-09 13:52",
        "sss": "2018-10-10 11:31"
    },
    {
        "id": "IN18-10_06",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "083-38711175",
        "zarValue": "R116510.00",
        "receivingDate": "2018-10-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-10 09:11",
        "qaCheck": "2018-10-11 11:55",
        "inventoryAdded ": "2018-10-10 10:03",
        "sss": "2018-10-17 11:15"
    },
    {
        "id": "IN18-10_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "08338711164",
        "zarValue": "R120428.00",
        "receivingDate": "2018-10-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-10 11:00",
        "qaCheck": "2018-10-11 12:00",
        "inventoryAdded ": "2018-10-17 11:14",
        "sss": "2018-10-17 11:15"
    },
    {
        "id": "IN18-10_08",
        "sponsor": "CATALENT",
        "study": "MO25757",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "724-6717 8963",
        "zarValue": "R324920.02",
        "receivingDate": "2018-10-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-10 12:47",
        "qaCheck": "2018-10-11 11:54",
        "inventoryAdded ": "2018-10-10 13:02",
        "sss": "2018-10-17 11:16"
    },
    {
        "id": "IN18-10_10",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "DSV",
        "zarValue": "R177075.90",
        "receivingDate": "2018-10-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-16 14:23",
        "qaCheck": "2018-10-30 10:46",
        "inventoryAdded ": "2018-10-17 11:16",
        "sss": "2018-10-30 11:19"
    },
    {
        "id": "IN18-10_11",
        "sponsor": "SANOFI",
        "study": "LTS14424",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "125-8796 1720",
        "zarValue": "R317009.00",
        "receivingDate": "2018-10-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-22 15:42",
        "qaCheck": "2018-10-23 12:33",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2018-10-29 13:42"
    },
    {
        "id": "IN18-10_12",
        "sponsor": "Bayer",
        "study": "ASTEROID 3-15787",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "Pharmacy",
        "zarValue": "R8127.53",
        "receivingDate": "2018-10-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-31 11:10",
        "qaCheck": "2018-11-05 12:08",
        "inventoryAdded ": "2018-10-31 11:10",
        "sss": "2018-11-05 12:27"
    },
    {
        "id": "IN18-11_01",
        "sponsor": "PowerVision",
        "study": "CLEAR STUDY",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "7735 1195 1001",
        "zarValue": "R57015.00",
        "receivingDate": "2018-11-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-01 10:58",
        "qaCheck": "2018-11-05 12:09",
        "inventoryAdded ": "2018-11-01 10:58",
        "sss": "2018-11-06 15:06"
    },
    {
        "id": "IN18-11_02",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "SEABOURNE",
        "mawb": "2865694",
        "zarValue": "R40206.70",
        "receivingDate": "2018-11-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-12 15:34",
        "qaCheck": "2018-11-13 12:00",
        "inventoryAdded ": "2018-11-12 15:41",
        "sss": "2018-11-14 09:24"
    },
    {
        "id": "IN18-11_03",
        "sponsor": "PowerVision",
        "study": "CLEAR STUDY",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "773650854328",
        "zarValue": "R22811.00",
        "receivingDate": "2018-11-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-16 09:20",
        "qaCheck": "2018-11-21 11:14",
        "inventoryAdded ": "2018-11-16 09:20",
        "sss": "2018-11-22 12:26"
    },
    {
        "id": "IN18-11_04",
        "sponsor": "CATALENT",
        "study": "GO40241",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17600814030",
        "zarValue": "R7490000.00",
        "receivingDate": "2018-11-21                           ",
        "email": "2018-11-20 15:53",
        "confirmArrival": "2018-11-27 10:39",
        "qaCheck": "2018-11-27 11:37",
        "inventoryAdded ": "2018-11-27 12:00",
        "sss": "2018-11-27 13:17"
    },
    {
        "id": "IN18-11_05",
        "sponsor": "CATALENT",
        "study": "GO40241",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-00814170",
        "zarValue": "R1786954.24",
        "receivingDate": "2018-11-22                           ",
        "email": "2018-11-20 15:57",
        "confirmArrival": "2018-11-29 12:50",
        "qaCheck": "2018-12-04 10:40",
        "inventoryAdded ": "2018-11-29 12:50",
        "sss": "2018-12-06 08:41"
    },
    {
        "id": "IN18-11_06",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17600814192",
        "zarValue": "R980597.00",
        "receivingDate": "2018-11-23                           ",
        "email": "2018-11-20 16:04",
        "confirmArrival": "2018-11-27 10:40",
        "qaCheck": "2018-11-28 13:20",
        "inventoryAdded ": "2018-11-27 14:07",
        "sss": "2018-11-29 09:59"
    },
    {
        "id": "IN18-11_07",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-00814225",
        "zarValue": "R93367.81",
        "receivingDate": "2018-11-23                           ",
        "email": "2018-11-20 16:10",
        "confirmArrival": "2018-11-28 13:14",
        "qaCheck": "2018-11-29 10:23",
        "inventoryAdded ": "2018-11-29 12:27",
        "sss": "2018-11-29 12:27"
    },
    {
        "id": "IN18-11_08",
        "sponsor": "PowerVision",
        "study": "CLEAR STUDY",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Morne de Bruin",
        "mawb": "HAND CARRIED",
        "zarValue": "R0.00",
        "receivingDate": "2018-11-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-21 10:28",
        "qaCheck": "2018-11-26 10:38",
        "inventoryAdded ": "2018-11-21 10:29",
        "sss": "2018-11-26 11:06"
    },
    {
        "id": "IN18-11_09",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6958 2494",
        "zarValue": "R70391.87",
        "receivingDate": "2018-11-26                           ",
        "email": "2018-11-26 08:49",
        "confirmArrival": "2018-12-03 12:31",
        "qaCheck": "2018-12-04 10:41",
        "inventoryAdded ": "2018-12-03 13:07",
        "sss": "2018-12-06 08:44"
    },
    {
        "id": "IN18-11_10",
        "sponsor": "SANOFI",
        "study": "EFC14837",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "125-8815 3096",
        "zarValue": "R337155.00",
        "receivingDate": "2018-11-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-26 11:53",
        "qaCheck": "2018-11-27 11:37",
        "inventoryAdded ": "2018-11-26 11:53",
        "sss": "2018-11-27 14:50"
    },
    {
        "id": "IN18-11_11",
        "sponsor": "CATALENT",
        "study": "GO40241",
        "clearingAgent": "NONE",
        "courierCoy": "DHL",
        "mawb": "72400243471",
        "zarValue": "R2705153.00",
        "receivingDate": "2018-11-27                           ",
        "email": "2018-11-27 14:33",
        "confirmArrival": "2018-12-06 08:34",
        "qaCheck": "2018-12-14 14:12",
        "inventoryAdded ": "2018-12-06 08:35",
        "sss": "2018-12-14 14:14"
    },
    {
        "id": "IN18-11_12",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI",
        "mawb": "185890721W",
        "zarValue": "R176977.00",
        "receivingDate": "2018-11-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-29 13:36",
        "qaCheck": "2018-12-03 10:41",
        "inventoryAdded ": "2018-11-29 13:36",
        "sss": "2018-12-03 15:12"
    },
    {
        "id": "IN18-12_01",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-80519375",
        "zarValue": "R15021.00",
        "receivingDate": "2018-12-07                           ",
        "email": "2018-12-05 09:30",
        "confirmArrival": "2018-12-11 16:13",
        "qaCheck": "2018-12-14 14:16",
        "inventoryAdded ": "2018-12-11 16:13",
        "sss": "2018-12-14 14:22"
    },
    {
        "id": "IN18-12_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2872574",
        "zarValue": "R84780.00",
        "receivingDate": "2018-12-10                           ",
        "email": "2018-12-10 12:08",
        "confirmArrival": "2018-12-14 14:26",
        "qaCheck": "2018-12-14 14:26",
        "inventoryAdded ": "2018-12-14 14:26",
        "sss": "2018-12-14 14:27"
    },
    {
        "id": "IN18-12_05",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2871647",
        "zarValue": "R56175.00",
        "receivingDate": "2018-12-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-12-11 15:53",
        "qaCheck": "2018-12-14 14:28",
        "inventoryAdded ": "2018-12-11 15:53",
        "sss": "2019-01-04 10:38"
    },
    {
        "id": "IN18-12_06",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "Pharmacy",
        "courierCoy": "CLICKS",
        "mawb": "PHARMACY",
        "zarValue": "R576.33",
        "receivingDate": "2018-12-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-12-13 14:21",
        "qaCheck": "2018-12-14 14:23",
        "inventoryAdded ": "2018-12-13 14:21",
        "sss": "2018-12-14 14:23"
    },
    {
        "id": "IN19-01_06",
        "sponsor": "SANOFI",
        "study": "EFC14643",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "057-81159326",
        "zarValue": "R199257.00",
        "receivingDate": "2019-01-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-01-08 12:35",
        "qaCheck": "2019-01-10 13:39",
        "inventoryAdded ": "2019-01-08 12:35",
        "sss": "2019-01-11 10:21"
    },
    {
        "id": "IN19-01_12",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X10237528",
        "zarValue": "R20584.00",
        "receivingDate": "2019-01-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-01-18 12:52",
        "qaCheck": "2019-01-21 13:52",
        "inventoryAdded ": "2019-01-18 12:52",
        "sss": "2019-01-28 09:41"
    },
    {
        "id": "IN19-02_04",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2884929",
        "zarValue": "R64394.00",
        "receivingDate": "2019-02-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-02-13 11:18",
        "qaCheck": "2019-02-13 13:03",
        "inventoryAdded ": "2019-02-13 11:19",
        "sss": "2019-02-14 09:26"
    },
    {
        "id": "IN19-04_03",
        "sponsor": "CATALENT",
        "study": "WO40242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-01323685",
        "zarValue": "R1082773.16",
        "receivingDate": "2019-04-10                           ",
        "email": "2019-04-02 10:51",
        "confirmArrival": "2019-04-10 10:18",
        "qaCheck": "2019-04-10 15:23",
        "inventoryAdded ": "2019-04-10 10:18",
        "sss": "2019-04-10 16:09"
    },
    {
        "id": "IN19-04_07",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-09894393",
        "zarValue": "R44475.77",
        "receivingDate": "2019-04-15                           ",
        "email": "2019-04-10 09:34",
        "confirmArrival": "2019-04-18 14:39",
        "qaCheck": "2019-04-23 15:18",
        "inventoryAdded ": "2019-04-18 14:39",
        "sss": "2019-04-30 09:06"
    },
    {
        "id": "IN19-04_10",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0989 4430",
        "zarValue": "R62063.50",
        "receivingDate": "2019-04-15                           ",
        "email": "2019-04-11 09:42",
        "confirmArrival": "2019-04-18 14:39",
        "qaCheck": "2019-04-23 15:27",
        "inventoryAdded ": "2019-04-18 14:40",
        "sss": "2019-04-30 09:05"
    },
    {
        "id": "IN19-06_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2914986",
        "zarValue": "R339123.00",
        "receivingDate": "2019-06-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-06-24 18:56",
        "qaCheck": "2019-06-25 13:00",
        "inventoryAdded ": "2019-06-25 10:35",
        "sss": "2019-07-02 09:31"
    },
    {
        "id": "IN19-07_05",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-73555355",
        "zarValue": "R4243157.12",
        "receivingDate": "2019-07-15                           ",
        "email": "2019-07-10 14:54",
        "confirmArrival": "2019-07-18 15:40",
        "qaCheck": "2019-07-19 14:52",
        "inventoryAdded ": "2019-07-19 14:54",
        "sss": "2019-07-23 08:24"
    },
    {
        "id": "IN19-08_06",
        "sponsor": "ROCHE SA",
        "study": "MA39950",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-03388162",
        "zarValue": "R2837151.62",
        "receivingDate": "2019-08-21                           ",
        "email": "2019-08-16 09:44",
        "confirmArrival": "2019-08-20 13:57",
        "qaCheck": "2019-08-20 15:35",
        "inventoryAdded ": "2019-08-20 15:36",
        "sss": "2019-08-20 15:36"
    },
    {
        "id": "IN19-08_11",
        "sponsor": "ROCHE SA",
        "study": "MV40618",
        "clearingAgent": "SCHENKER",
        "courierCoy": "SCHENKER",
        "mawb": "618-66420071",
        "zarValue": "R687728.00",
        "receivingDate": "2019-08-28                           ",
        "email": "2019-08-27 11:14",
        "confirmArrival": "2019-09-02 11:23",
        "qaCheck": "2019-09-02 15:22",
        "inventoryAdded ": "2019-09-02 11:32",
        "sss": "2019-09-02 15:24"
    },
    {
        "id": "IN19-09_01",
        "sponsor": "CATALENT",
        "study": "GA29145",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-72742865",
        "zarValue": "R97680.20",
        "receivingDate": "2019-09-06                           ",
        "email": "2019-09-02 12:56",
        "confirmArrival": "2019-09-10 16:18",
        "qaCheck": "2019-09-11 08:30",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2019-09-11 12:13"
    },
    {
        "id": "IN19-09_07",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-72743005",
        "zarValue": "R19554.00",
        "receivingDate": "2019-09-12                           ",
        "email": "2019-09-06 08:20",
        "confirmArrival": "2019-09-12 09:41",
        "qaCheck": "2019-09-13 14:43",
        "inventoryAdded ": "2019-09-13 14:44",
        "sss": "2019-09-16 08:56"
    },
    {
        "id": "IN20-01_06",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "615x12460743",
        "zarValue": "R21752.70",
        "receivingDate": "2020-01-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-01-22 12:41",
        "qaCheck": "2020-01-27 15:17",
        "inventoryAdded ": "2020-01-27 11:29",
        "sss": "2020-01-28 08:29"
    },
    {
        "id": "IN20-02_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2975526",
        "zarValue": "R30025.00",
        "receivingDate": "2020-02-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-19 16:18",
        "qaCheck": "2020-02-21 10:24",
        "inventoryAdded ": "2020-02-20 14:34",
        "sss": "2020-02-21 11:16"
    },
    {
        "id": "IN20-04_03",
        "sponsor": "ROCHE SA",
        "study": "BO41423",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "083-16920035",
        "zarValue": "R3132498.00",
        "receivingDate": "2020-04-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-04-15 12:18",
        "qaCheck": "2020-04-16 12:43",
        "inventoryAdded ": "2020-04-15 12:18",
        "sss": "2020-04-16 12:50"
    },
    {
        "id": "IN20-04_17",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "074-36466323",
        "zarValue": "R813933.15",
        "receivingDate": "2020-05-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-05-06 10:18",
        "qaCheck": "2020-05-07 08:53",
        "inventoryAdded ": "2020-05-08 11:04",
        "sss": "2020-05-08 11:05"
    },
    {
        "id": "IN20-05_13",
        "sponsor": "MARKEN",
        "study": "LTS14424",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636x13183652",
        "zarValue": "R556721.00",
        "receivingDate": "2020-05-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-05-18 15:23",
        "qaCheck": "2020-05-29 12:42",
        "inventoryAdded ": "2020-05-29 12:42",
        "sss": "2020-05-29 12:50"
    },
    {
        "id": "IN20-06_06",
        "sponsor": "N/A",
        "study": "RX MEDICALS ",
        "clearingAgent": "TBC",
        "courierCoy": "TBC",
        "mawb": "8091840540",
        "zarValue": "R0.00",
        "receivingDate": "2020-06-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-06-18 10:23",
        "qaCheck": "2020-06-18 12:04",
        "inventoryAdded ": "2020-06-18 10:23",
        "sss": "2020-06-19 14:16"
    },
    {
        "id": "IN20-11_04",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "DSV",
        "mawb": "32032694",
        "zarValue": "R0.00",
        "receivingDate": "2020-11-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-18 14:37",
        "qaCheck": "2020-11-19 14:44",
        "inventoryAdded ": "2020-11-20 09:59",
        "sss": "2020-11-20 10:00"
    },
    {
        "id": "IN21-01_07",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17356463",
        "zarValue": "R55597.70",
        "receivingDate": "2021-01-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-28 13:25",
        "qaCheck": "2021-01-28 14:47",
        "inventoryAdded ": "2021-01-28 13:24",
        "sss": "2021-01-28 14:48"
    },
    {
        "id": "IN21-02_01",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "N/A",
        "courierCoy": "DSV",
        "mawb": "DSV DELIVERY",
        "zarValue": "R1566.26",
        "receivingDate": "2021-02-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-01 14:18",
        "qaCheck": "2021-02-01 14:36",
        "inventoryAdded ": "2021-02-01 14:36",
        "sss": "2021-02-01 16:07"
    },
    {
        "id": "IN21-02_05",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "KAWARI DELIVERY",
        "zarValue": "R9112.60",
        "receivingDate": "2021-02-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-09 14:18",
        "qaCheck": "2021-02-09 14:45",
        "inventoryAdded ": "2021-02-09 14:40",
        "sss": "2021-02-22 11:09"
    },
    {
        "id": "IN21-02_06",
        "sponsor": "MARKEN",
        "study": "LPS15023",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X15175917",
        "zarValue": "R2.00",
        "receivingDate": "2021-02-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-02-18 14:54",
        "qaCheck": "2021-02-22 11:01",
        "inventoryAdded ": "2021-02-18 14:57",
        "sss": "2021-02-22 11:08"
    },
    {
        "id": "IN21-03_10",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "HENDRICK COLLECTED",
        "zarValue": "R0.00",
        "receivingDate": "2021-03-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-18 14:30",
        "qaCheck": "2021-03-29 12:37",
        "inventoryAdded ": "2021-03-18 14:30",
        "sss": "2021-04-07 12:00"
    },
    {
        "id": "IN21-04_14",
        "sponsor": "SEVEILLAR",
        "study": "VPM1002-MN-3.05TB",
        "clearingAgent": "Bioweb",
        "courierCoy": "Pathteq",
        "mawb": "BWB1292",
        "zarValue": "R25642.96",
        "receivingDate": "2021-04-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-23 09:49",
        "qaCheck": "2021-04-26 11:22",
        "inventoryAdded ": "2021-04-26 08:07",
        "sss": "2021-04-26 12:44"
    },
    {
        "id": "IN21-05_05",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "QUESTMED",
        "courierCoy": "QUESMED",
        "mawb": "QUESTMED",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:18",
        "qaCheck": "2021-05-17 11:44",
        "inventoryAdded ": "2021-05-17 11:18",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-05_09",
        "sponsor": "ALMAC",
        "study": "C4591015",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16029989",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-18 15:06",
        "qaCheck": "2021-05-19 12:05",
        "inventoryAdded ": "2021-05-19 10:46",
        "sss": "2021-05-24 14:51"
    },
    {
        "id": "IN21-05_10",
        "sponsor": "ALMAC",
        "study": "C4591015",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16020025",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-18 15:07",
        "qaCheck": "2021-05-19 12:05",
        "inventoryAdded ": "2021-05-19 10:45",
        "sss": "2021-05-24 14:42"
    },
    {
        "id": "IN21-06_09",
        "sponsor": "ALMAC",
        "study": "CLI-06001AA1-04",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "195210591w",
        "zarValue": "R32197.90",
        "receivingDate": "2021-06-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-18 07:13",
        "qaCheck": "2021-06-30 09:03",
        "inventoryAdded ": "2021-06-18 07:14",
        "sss": "2021-06-30 09:05"
    },
    {
        "id": "IN21-07_02",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-65736720",
        "zarValue": "R0.00",
        "receivingDate": "2021-07-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-02 09:04",
        "qaCheck": "2021-07-02 10:23",
        "inventoryAdded ": "2021-07-02 09:12",
        "sss": "2021-07-02 10:24"
    },
    {
        "id": "IN16-07_02",
        "sponsor": "CATALENT",
        "study": "NV25719",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4816 7873",
        "zarValue": "R7641.00",
        "receivingDate": "2016-07-13                           ",
        "email": "2016-07-14 13:32",
        "confirmArrival": "2016-07-14 09:33",
        "qaCheck": "2016-07-15 08:44",
        "inventoryAdded ": "2016-07-14 16:11",
        "sss": "2016-08-02 10:05"
    },
    {
        "id": "IN16-07_06",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QI LOGISTICS",
        "courierCoy": "SPX",
        "mawb": "177340012W",
        "zarValue": "R370479.00",
        "receivingDate": "2016-07-27                           ",
        "email": "2016-07-27 14:00",
        "confirmArrival": "2016-07-27 13:59",
        "qaCheck": "2016-07-27 14:00",
        "inventoryAdded ": "2016-08-02 10:04",
        "sss": "2016-08-02 10:04"
    },
    {
        "id": "IN16-08_08",
        "sponsor": "CATALENT",
        "study": "NV20234",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4816 9995",
        "zarValue": "R10128.00",
        "receivingDate": "2016-08-17                           ",
        "email": "2016-08-17 16:20",
        "confirmArrival": "2016-08-17 09:25",
        "qaCheck": "2016-08-17 11:21",
        "inventoryAdded ": "2016-08-17 16:10",
        "sss": "2016-08-17 16:10"
    },
    {
        "id": "IN16-10_13",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 1553",
        "zarValue": "R190781.00",
        "receivingDate": "2016-10-27                           ",
        "email": "2016-10-24 10:40",
        "confirmArrival": "2016-11-01 16:15",
        "qaCheck": "2016-11-08 11:00",
        "inventoryAdded ": "2016-11-08 11:00",
        "sss": "2016-12-09 16:47"
    },
    {
        "id": "IN16-11_02",
        "sponsor": "ALMAC",
        "study": "1218.22",
        "clearingAgent": "SPX",
        "courierCoy": "SPEC TRANS",
        "mawb": "125-31807182",
        "zarValue": "R13749.00",
        "receivingDate": "2016-11-03                           ",
        "email": "2016-11-03 17:04",
        "confirmArrival": "2016-11-03 17:02",
        "qaCheck": "2016-11-03 17:03",
        "inventoryAdded ": "2016-11-03 17:03",
        "sss": "2016-12-09 16:50"
    },
    {
        "id": "IN16-12_07",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5689 3734",
        "zarValue": "R480875.00",
        "receivingDate": "2016-12-30                           ",
        "email": "2016-12-29 13:06",
        "confirmArrival": "2017-01-17 14:47",
        "qaCheck": "2017-01-17 14:47",
        "inventoryAdded ": "2017-01-17 14:46",
        "sss": "2017-01-17 14:47"
    },
    {
        "id": "IN17-01_01",
        "sponsor": "CATALENT",
        "study": "NV20234",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5689 3782",
        "zarValue": "R37697.00",
        "receivingDate": "2017-01-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-17 14:51",
        "qaCheck": "1970-01-01 00:00",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-17 14:51"
    },
    {
        "id": "IN17-01_02",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "MWAB 724-5689 3804",
        "zarValue": "R47097.00",
        "receivingDate": "2017-01-03                           ",
        "email": "2017-01-03 09:23",
        "confirmArrival": "2017-01-06 12:14",
        "qaCheck": "2017-01-06 12:14",
        "inventoryAdded ": "2017-01-06 12:14",
        "sss": "2017-01-06 12:21"
    },
    {
        "id": "IN17-01_04",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5689 3826",
        "zarValue": "R628606.00",
        "receivingDate": "2017-01-04                           ",
        "email": "2017-01-03 10:49",
        "confirmArrival": "2017-01-17 12:56",
        "qaCheck": "2017-01-17 12:56",
        "inventoryAdded ": "2017-01-17 12:56",
        "sss": "2017-01-17 12:56"
    },
    {
        "id": "IN17-01_05",
        "sponsor": "CATALENT",
        "study": "NV25719",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5689 3830",
        "zarValue": "R7180.00",
        "receivingDate": "2017-01-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-06 12:25",
        "qaCheck": "2017-01-06 12:27",
        "inventoryAdded ": "2017-01-06 12:28",
        "sss": "2017-01-06 12:31"
    },
    {
        "id": "IN17-01_06",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5689 4320",
        "zarValue": "R15850.00",
        "receivingDate": "2017-01-07                           ",
        "email": "2017-01-06 11:51",
        "confirmArrival": "2017-01-10 15:59",
        "qaCheck": "2017-01-10 15:59",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-10 16:05"
    },
    {
        "id": "IN17-01_07",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5804 1550",
        "zarValue": "R575397.00",
        "receivingDate": "2017-01-15                           ",
        "email": "2017-01-06 15:26",
        "confirmArrival": "2017-01-17 12:46",
        "qaCheck": "2017-01-17 12:47",
        "inventoryAdded ": "2017-01-17 12:47",
        "sss": "2017-01-17 12:48"
    },
    {
        "id": "IN17-01_08",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724-5568 8124",
        "zarValue": "R102807.00",
        "receivingDate": "2017-01-09                           ",
        "email": "2017-01-09 16:19",
        "confirmArrival": "2017-01-10 15:43",
        "qaCheck": "2017-01-10 15:43",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-10 15:45"
    },
    {
        "id": "IN17-01_09",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "KEY ROAD TRANSPORT",
        "courierCoy": "KEI Road Transport",
        "mawb": "179040001W",
        "zarValue": "R126417.00",
        "receivingDate": "2017-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-12 08:36",
        "qaCheck": "2017-01-12 11:26",
        "inventoryAdded ": "2017-01-12 11:26",
        "sss": "2017-01-12 11:27"
    },
    {
        "id": "IN17-01_10",
        "sponsor": "ALMAC",
        "study": "1199.93",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "179080296W",
        "zarValue": "R43407.40",
        "receivingDate": "2017-01-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-13 11:21",
        "qaCheck": "2017-01-13 16:09",
        "inventoryAdded ": "2017-01-16 08:30",
        "sss": "2017-01-16 08:31"
    },
    {
        "id": "IN17-01_11",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "125-39439816",
        "zarValue": "R116186.00",
        "receivingDate": "2017-01-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-18 15:06",
        "qaCheck": "2017-01-18 15:10",
        "inventoryAdded ": "2017-01-18 15:10",
        "sss": "2017-01-18 15:14"
    },
    {
        "id": "IN17-01_12",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "SEABOURNE",
        "mawb": "632X06654629",
        "zarValue": "R347345.00",
        "receivingDate": "2017-01-23                           ",
        "email": "2017-01-23 12:23",
        "confirmArrival": "2017-01-23 12:23",
        "qaCheck": "2017-01-23 15:39",
        "inventoryAdded ": "2017-01-23 15:39",
        "sss": "2017-01-23 15:43"
    },
    {
        "id": "IN17-01_13",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "000000600876833002",
        "zarValue": "R60480.00",
        "receivingDate": "2017-01-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-25 15:37",
        "qaCheck": "2017-01-26 11:35",
        "inventoryAdded ": "2017-01-26 11:35",
        "sss": "2017-01-26 11:35"
    },
    {
        "id": "IN17-01_14",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-1110 4590",
        "zarValue": "R519306.00",
        "receivingDate": "2017-02-01                           ",
        "email": "2017-01-27 14:19",
        "confirmArrival": "2017-02-10 15:33",
        "qaCheck": "2017-02-10 15:33",
        "inventoryAdded ": "2017-02-10 15:34",
        "sss": "2017-02-10 15:52"
    },
    {
        "id": "IN17-01_15",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "125-3943-9945",
        "zarValue": "R151950.00",
        "receivingDate": "2017-01-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-01-27 14:26",
        "qaCheck": "2017-01-31 15:22",
        "inventoryAdded ": "2017-01-31 15:23",
        "sss": "2017-01-31 15:24"
    },
    {
        "id": "IN17-01_16",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-1110 4822",
        "zarValue": "R817048.00",
        "receivingDate": "2017-02-02                           ",
        "email": "2017-01-31 08:19",
        "confirmArrival": "2017-02-03 15:44",
        "qaCheck": "2017-02-03 15:45",
        "inventoryAdded ": "2017-02-03 15:45",
        "sss": "2017-02-03 15:47"
    },
    {
        "id": "IN17-02_01",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-1110 5345",
        "zarValue": "R470955.00",
        "receivingDate": "2017-02-08                           ",
        "email": "2017-02-06 16:40",
        "confirmArrival": "2017-02-09 10:03",
        "qaCheck": "2017-02-09 10:23",
        "inventoryAdded ": "2017-02-09 10:23",
        "sss": "2017-02-09 10:24"
    },
    {
        "id": "IN17-02_02",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-1110 6410",
        "zarValue": "R31137.00",
        "receivingDate": "2017-02-18                           ",
        "email": "2017-02-16 15:18",
        "confirmArrival": "2017-02-20 15:46",
        "qaCheck": "2017-02-20 15:46",
        "inventoryAdded ": "2017-02-20 16:01",
        "sss": "2017-02-20 16:02"
    },
    {
        "id": "IN17-02_03",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-1110 6465",
        "zarValue": "R33567.50",
        "receivingDate": "2017-02-19                           ",
        "email": "2017-02-17 11:19",
        "confirmArrival": "2017-02-21 12:19",
        "qaCheck": "2017-02-21 12:33",
        "inventoryAdded ": "2017-02-21 12:59",
        "sss": "2017-02-21 13:00"
    },
    {
        "id": "IN17-02_04",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 5568 8496",
        "zarValue": "R207875.00",
        "receivingDate": "2017-02-27                           ",
        "email": "2017-02-17 11:55",
        "confirmArrival": "2017-02-21 11:36",
        "qaCheck": "2017-02-21 11:37",
        "inventoryAdded ": "2017-02-21 11:37",
        "sss": "2017-02-21 11:40"
    },
    {
        "id": "IN17-02_05",
        "sponsor": "CATALENT",
        "study": "GB39242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "074-52577932",
        "zarValue": "R627684.00",
        "receivingDate": "2017-02-26                           ",
        "email": "2017-02-22 14:00",
        "confirmArrival": "2017-02-28 09:50",
        "qaCheck": "2017-02-28 09:50",
        "inventoryAdded ": "2017-02-28 10:04",
        "sss": "2017-02-28 10:06"
    },
    {
        "id": "IN17-02_06",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-11106723",
        "zarValue": "R459672.00",
        "receivingDate": "2017-02-24                           ",
        "email": "2017-02-22 14:34",
        "confirmArrival": "2017-02-24 15:17",
        "qaCheck": "2017-02-24 15:18",
        "inventoryAdded ": "2017-02-24 15:25",
        "sss": "2017-02-27 15:17"
    },
    {
        "id": "IN17-02_07",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-58043064",
        "zarValue": "R67589.90",
        "receivingDate": "2017-03-03                           ",
        "email": "2017-02-23 11:16",
        "confirmArrival": "2017-03-07 15:42",
        "qaCheck": "2017-03-07 15:58",
        "inventoryAdded ": "2017-03-07 15:59",
        "sss": "2017-03-07 16:01"
    },
    {
        "id": "IN17-02_08",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-58043053",
        "zarValue": "R350984.59",
        "receivingDate": "2017-03-05                           ",
        "email": "2017-02-23 12:09",
        "confirmArrival": "2017-03-07 15:43",
        "qaCheck": "2017-03-07 16:01",
        "inventoryAdded ": "2017-03-07 16:03",
        "sss": "2017-03-07 16:03"
    },
    {
        "id": "IN17-02_09",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X06803643",
        "zarValue": "R1701.92",
        "receivingDate": "2017-02-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-02-23 16:07",
        "qaCheck": "2017-02-23 16:07",
        "inventoryAdded ": "2017-03-01 09:28",
        "sss": "2017-03-01 09:29"
    },
    {
        "id": "IN17-03_01",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-11107051",
        "zarValue": "R65633.40",
        "receivingDate": "2017-03-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-01 09:29",
        "qaCheck": "2017-03-01 09:29",
        "inventoryAdded ": "2017-03-01 16:09",
        "sss": "2017-03-01 16:10"
    },
    {
        "id": "IN17-03_02",
        "sponsor": "CATALENT",
        "study": "NV25719",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-11107375",
        "zarValue": "R10819.09",
        "receivingDate": "2017-03-04                           ",
        "email": "2017-03-01 09:54",
        "confirmArrival": "2017-03-06 14:04",
        "qaCheck": "2017-03-06 14:04",
        "inventoryAdded ": "2017-03-06 14:14",
        "sss": "2017-03-06 14:23"
    },
    {
        "id": "IN17-03_03",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X06835493",
        "zarValue": "R224460.00",
        "receivingDate": "2017-03-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-02 14:44",
        "qaCheck": "2017-03-02 14:44",
        "inventoryAdded ": "2017-03-02 14:45",
        "sss": "2017-03-02 14:50"
    },
    {
        "id": "IN17-03_04",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-11108381",
        "zarValue": "R24320.40",
        "receivingDate": "2017-03-16                           ",
        "email": "2017-03-14 12:46",
        "confirmArrival": "2017-03-22 13:46",
        "qaCheck": "2017-03-22 13:55",
        "inventoryAdded ": "2017-03-22 13:46",
        "sss": "2017-03-22 15:23"
    },
    {
        "id": "IN17-03_05",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "31619776",
        "zarValue": "R6788.76",
        "receivingDate": "2017-03-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-14 13:30",
        "qaCheck": "2017-03-14 14:46",
        "inventoryAdded ": "2017-03-14 14:46",
        "sss": "2017-03-14 15:16"
    },
    {
        "id": "IN17-03_06",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "0",
        "courierCoy": "Dischem",
        "mawb": "I0005634",
        "zarValue": "R273.60",
        "receivingDate": "2017-03-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-16 16:02",
        "qaCheck": "2017-03-16 16:03",
        "inventoryAdded ": "2017-03-16 16:04",
        "sss": "2017-03-16 16:08"
    },
    {
        "id": "IN17-03_07",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "DSV PHARMA",
        "mawb": "600919277",
        "zarValue": "R5421.24",
        "receivingDate": "2017-03-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-20 10:08",
        "qaCheck": "2017-03-20 10:09",
        "inventoryAdded ": "2017-03-20 10:09",
        "sss": "2017-03-20 10:12"
    },
    {
        "id": "IN17-03_08",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-59193890",
        "zarValue": "R214203.26",
        "receivingDate": "2017-03-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-30 14:17",
        "qaCheck": "2017-03-30 14:17",
        "inventoryAdded ": "2017-03-30 14:27",
        "sss": "2017-03-30 14:33"
    },
    {
        "id": "IN17-03_09",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "31642948",
        "zarValue": "R206588.40",
        "receivingDate": "2017-03-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-23 13:10",
        "qaCheck": "2017-03-23 13:27",
        "inventoryAdded ": "2017-03-23 13:27",
        "sss": "2017-03-23 13:30"
    },
    {
        "id": "IN17-03_10",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "WO30070 10558335 724-1110 9486 MWAB",
        "zarValue": "R28175.00",
        "receivingDate": "2017-04-01                           ",
        "email": "2017-03-31 09:20",
        "confirmArrival": "2017-04-05 10:47",
        "qaCheck": "2017-04-10 08:56",
        "inventoryAdded ": "2017-04-10 08:57",
        "sss": "2017-04-10 09:12"
    },
    {
        "id": "IN17-03_11",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724-4032-4485",
        "zarValue": "R364638.00",
        "receivingDate": "2017-04-01                           ",
        "email": "2017-03-31 10:55",
        "confirmArrival": "2017-04-04 10:49",
        "qaCheck": "2017-04-04 10:50",
        "inventoryAdded ": "2017-04-04 10:50",
        "sss": "2017-04-04 10:53"
    },
    {
        "id": "IN17-04_01",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "600932815006",
        "zarValue": "R29304.00",
        "receivingDate": "2017-04-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-04 16:07",
        "qaCheck": "2017-04-04 16:07",
        "inventoryAdded ": "2017-04-04 16:07",
        "sss": "2017-04-04 16:09"
    },
    {
        "id": "IN17-04_02",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-1110 9840",
        "zarValue": "R719592.00",
        "receivingDate": "2017-04-09                           ",
        "email": "2017-04-05 09:06",
        "confirmArrival": "2017-04-18 15:29",
        "qaCheck": "2017-04-25 11:04",
        "inventoryAdded ": "2017-04-25 11:05",
        "sss": "2017-04-25 11:09"
    },
    {
        "id": "IN17-04_03",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "31678308",
        "zarValue": "R7326.00",
        "receivingDate": "2017-04-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-05 15:34",
        "qaCheck": "2017-04-10 15:53",
        "inventoryAdded ": "2017-04-10 15:53",
        "sss": "2017-04-10 15:54"
    },
    {
        "id": "IN17-04_04",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "31679475",
        "zarValue": "R369882.00",
        "receivingDate": "2017-04-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-05 15:38",
        "qaCheck": "2017-04-11 13:21",
        "inventoryAdded ": "2017-04-11 13:21",
        "sss": "2017-04-11 13:22"
    },
    {
        "id": "IN17-04_05",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-1111 0326",
        "zarValue": "R341557.00",
        "receivingDate": "2017-04-13                           ",
        "email": "2017-04-10 15:59",
        "confirmArrival": "2017-04-18 16:17",
        "qaCheck": "2017-04-19 08:21",
        "inventoryAdded ": "2017-04-19 08:30",
        "sss": "2017-04-19 08:32"
    },
    {
        "id": "IN17-04_08",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-59194590",
        "zarValue": "R107279.00",
        "receivingDate": "2017-04-26                           ",
        "email": "2017-04-11 15:05",
        "confirmArrival": "2017-05-02 13:45",
        "qaCheck": "2017-05-02 14:02",
        "inventoryAdded ": "2017-05-02 14:03",
        "sss": "2017-05-02 14:04"
    },
    {
        "id": "IN17-04_09",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-111 0433",
        "zarValue": "R29055.60",
        "receivingDate": "2017-04-13                           ",
        "email": "2017-04-11 15:13",
        "confirmArrival": "2017-04-20 09:10",
        "qaCheck": "2017-04-20 09:11",
        "inventoryAdded ": "2017-04-20 09:12",
        "sss": "2017-04-20 09:13"
    },
    {
        "id": "IN17-04_10",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Dischem",
        "mawb": "007410",
        "zarValue": "R1231.20",
        "receivingDate": "2017-04-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-18 12:52",
        "qaCheck": "2017-04-18 12:52",
        "inventoryAdded ": "2017-04-18 12:53",
        "sss": "2017-04-18 13:02"
    },
    {
        "id": "IN17-04_11",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "14448",
        "zarValue": "R1542.78",
        "receivingDate": "2017-04-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-21 15:59",
        "qaCheck": "2017-04-21 15:59",
        "inventoryAdded ": "2017-04-21 15:59",
        "sss": "2017-04-21 16:10"
    },
    {
        "id": "IN17-04_12",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "0",
        "courierCoy": "Hilda Mostert",
        "mawb": "FRM07548-B / 26 APR 2017",
        "zarValue": "R0.00",
        "receivingDate": "2017-04-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-26 11:15",
        "qaCheck": "2017-04-26 15:03",
        "inventoryAdded ": "2017-04-26 15:03",
        "sss": "2017-04-26 15:07"
    },
    {
        "id": "IN17-04_13",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X07094964",
        "zarValue": "R43156.20",
        "receivingDate": "2017-04-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-28 11:28",
        "qaCheck": "2017-04-28 11:28",
        "inventoryAdded ": "2017-04-28 11:28",
        "sss": "2017-04-28 11:34"
    },
    {
        "id": "IN17-05_01",
        "sponsor": "CATALENT",
        "study": "GO25632",
        "clearingAgent": "NONE",
        "courierCoy": "QESTMED",
        "mawb": "4594064",
        "zarValue": "R19117.80",
        "receivingDate": "2017-05-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-03 09:21",
        "qaCheck": "2017-05-03 09:22",
        "inventoryAdded ": "2017-05-03 09:22",
        "sss": "2017-05-03 12:59"
    },
    {
        "id": "IN17-05_02",
        "sponsor": "CATALENT",
        "study": "GA29102",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5723 3503",
        "zarValue": "R34840.20",
        "receivingDate": "2017-05-07                           ",
        "email": "2017-05-03 10:05",
        "confirmArrival": "2017-05-08 09:52",
        "qaCheck": "2017-05-08 11:48",
        "inventoryAdded ": "2017-05-08 11:49",
        "sss": "2017-05-08 11:49"
    },
    {
        "id": "IN17-05_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "SPX",
        "mawb": "632X07126365",
        "zarValue": "R119098.00",
        "receivingDate": "2017-05-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-03 10:31",
        "qaCheck": "2017-05-03 11:27",
        "inventoryAdded ": "2017-05-03 11:27",
        "sss": "2017-05-03 11:48"
    },
    {
        "id": "IN17-05_04",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "SPX",
        "mawb": "632X07125975",
        "zarValue": "R13336.80",
        "receivingDate": "2017-05-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-03 10:45",
        "qaCheck": "2017-05-03 10:45",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-05-03 11:49"
    },
    {
        "id": "IN17-05_06",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "MARKEN",
        "courierCoy": "SPX",
        "mawb": "632X07136046",
        "zarValue": "R13336.80",
        "receivingDate": "2017-05-05                           ",
        "email": "2017-05-03 12:58",
        "confirmArrival": "2017-05-09 15:07",
        "qaCheck": "2017-05-09 15:24",
        "inventoryAdded ": "2017-05-09 15:40",
        "sss": "2017-05-10 10:35"
    },
    {
        "id": "IN17-05_07",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "INV9417425",
        "zarValue": "R2442.74",
        "receivingDate": "2017-05-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-05 13:23",
        "qaCheck": "2017-05-05 13:23",
        "inventoryAdded ": "2017-05-05 13:23",
        "sss": "2017-05-05 13:26"
    },
    {
        "id": "IN17-05_08",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "31749388",
        "zarValue": "R29304.00",
        "receivingDate": "2017-05-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-05 13:28",
        "qaCheck": "2017-05-05 13:43",
        "inventoryAdded ": "2017-05-05 13:28",
        "sss": "2017-05-05 13:43"
    },
    {
        "id": "IN17-05_09",
        "sponsor": "CATALENT",
        "study": "GA29145",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-50004566",
        "zarValue": "R84293.20",
        "receivingDate": "2017-05-11                           ",
        "email": "2017-05-08 12:13",
        "confirmArrival": "2017-05-12 10:44",
        "qaCheck": "2017-05-12 10:52",
        "inventoryAdded ": "2017-05-12 10:44",
        "sss": "2017-05-12 10:53"
    },
    {
        "id": "IN17-05_10",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-5000 4570",
        "zarValue": "R479056.00",
        "receivingDate": "2017-05-11                           ",
        "email": "2017-05-08 12:16",
        "confirmArrival": "2017-05-12 10:44",
        "qaCheck": "2017-05-12 10:52",
        "inventoryAdded ": "2017-05-12 10:45",
        "sss": "2017-05-12 10:53"
    },
    {
        "id": "IN17-05_11",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "000000600956311004",
        "zarValue": "R24420.00",
        "receivingDate": "2017-05-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-09 14:52",
        "qaCheck": "2017-05-09 14:52",
        "inventoryAdded ": "2017-05-09 14:52",
        "sss": "2017-05-09 14:53"
    },
    {
        "id": "IN17-05_12",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "632X07143951",
        "zarValue": "R324019.00",
        "receivingDate": "2017-05-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-09 15:47",
        "qaCheck": "2017-05-09 15:49",
        "inventoryAdded ": "2017-05-09 15:49",
        "sss": "2017-05-10 10:38"
    },
    {
        "id": "IN17-05_13",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-59194951",
        "zarValue": "R0.00",
        "receivingDate": "2017-05-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-12 10:43",
        "qaCheck": "2017-05-12 10:53",
        "inventoryAdded ": "2017-05-12 10:43",
        "sss": "2017-05-12 10:53"
    },
    {
        "id": "IN17-05_14",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "TBS",
        "courierCoy": "DSV",
        "mawb": "724-59195113",
        "zarValue": "R0.00",
        "receivingDate": "2017-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-17 15:28",
        "qaCheck": "2017-05-22 15:38",
        "inventoryAdded ": "2017-05-22 15:38",
        "sss": "2017-05-22 15:43"
    },
    {
        "id": "IN17-05_15",
        "sponsor": "CATALENT",
        "study": "MO25757",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "MAWB 176-5000 4710",
        "zarValue": "R324374.17",
        "receivingDate": "2017-05-21                           ",
        "email": "2017-05-18 09:23",
        "confirmArrival": "2017-05-23 13:58",
        "qaCheck": "2017-05-23 14:37",
        "inventoryAdded ": "2017-05-23 14:37",
        "sss": "2017-05-23 14:37"
    },
    {
        "id": "IN17-05_16",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "MAWB 176-5000 4706",
        "zarValue": "R328222.00",
        "receivingDate": "2017-05-22                           ",
        "email": "2017-05-18 09:30",
        "confirmArrival": "2017-05-23 13:57",
        "qaCheck": "2017-05-23 14:38",
        "inventoryAdded ": "2017-05-23 14:38",
        "sss": "2017-05-23 14:38"
    },
    {
        "id": "IN17-05_17",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "SPX",
        "mawb": "779145499177",
        "zarValue": "R0.00",
        "receivingDate": "2017-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-22 15:36",
        "qaCheck": "2017-05-22 15:36",
        "inventoryAdded ": "2017-05-22 15:36",
        "sss": "2017-05-22 15:44"
    },
    {
        "id": "IN17-05_18",
        "sponsor": "CLINDEV",
        "study": "GV29893",
        "clearingAgent": "0",
        "courierCoy": "Dischem",
        "mawb": "RFM0010598537",
        "zarValue": "R2876.31",
        "receivingDate": "2017-05-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-23 14:24",
        "qaCheck": "2017-05-23 14:25",
        "inventoryAdded ": "2017-05-23 14:25",
        "sss": "2017-05-23 14:26"
    },
    {
        "id": "IN17-05_20",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-5723 5625",
        "zarValue": "R805417.00",
        "receivingDate": "2017-06-01                           ",
        "email": "2017-05-31 12:11",
        "confirmArrival": "2017-06-02 08:32",
        "qaCheck": "2017-06-06 13:07",
        "inventoryAdded ": "2017-06-06 13:07",
        "sss": "2017-06-06 13:09"
    },
    {
        "id": "IN17-05_21",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "DSV",
        "courierCoy": "Seabourne Express",
        "mawb": "632X07270345",
        "zarValue": "R31963.50",
        "receivingDate": "2017-05-31                           ",
        "email": "2017-05-31 12:27",
        "confirmArrival": "2017-06-05 11:44",
        "qaCheck": "2017-06-05 15:14",
        "inventoryAdded ": "2017-06-05 15:15",
        "sss": "2017-06-05 15:16"
    },
    {
        "id": "IN17-05_22",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "Seabourne Express",
        "mawb": "632X07268746",
        "zarValue": "R114175.00",
        "receivingDate": "2017-05-31                           ",
        "email": "2017-05-31 12:31",
        "confirmArrival": "2017-06-02 15:33",
        "qaCheck": "2017-06-05 15:17",
        "inventoryAdded ": "2017-06-05 15:17",
        "sss": "2017-06-05 15:19"
    },
    {
        "id": "IN17-05_23",
        "sponsor": "CLINDEV",
        "study": "GV29893",
        "clearingAgent": "Dischem",
        "courierCoy": "DSV",
        "mawb": "000000600972906006",
        "zarValue": "R90476.96",
        "receivingDate": "2017-05-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-05-31 12:33",
        "qaCheck": "2017-05-31 12:34",
        "inventoryAdded ": "2017-05-31 12:34",
        "sss": "2017-05-31 12:37"
    },
    {
        "id": "IN17-06_01",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "SPX",
        "mawb": "779219480494 779219308716",
        "zarValue": "R0.00",
        "receivingDate": "2017-06-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-06 15:56",
        "qaCheck": "2017-06-07 11:21",
        "inventoryAdded ": "2017-06-06 15:56",
        "sss": "2017-06-07 11:21"
    },
    {
        "id": "IN17-06_02",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "GA29103 10565822 176-5000 4916 MWAB",
        "zarValue": "R247939.00",
        "receivingDate": "2017-06-09                           ",
        "email": "2017-06-07 17:46",
        "confirmArrival": "2017-06-15 15:49",
        "qaCheck": "2017-06-20 09:32",
        "inventoryAdded ": "2017-06-20 09:32",
        "sss": "2017-06-20 09:34"
    },
    {
        "id": "IN17-06_03",
        "sponsor": "CATALENT",
        "study": "GB39242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-59196023",
        "zarValue": "R344445.00",
        "receivingDate": "2017-06-09                           ",
        "email": "2017-06-08 15:38",
        "confirmArrival": "2017-06-22 16:06",
        "qaCheck": "2017-06-27 10:23",
        "inventoryAdded ": "2017-06-27 11:19",
        "sss": "2017-06-27 11:22"
    },
    {
        "id": "IN17-06_04",
        "sponsor": "CATALENT",
        "study": "GV29893 - CATALENT",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "GV29893 10566305 074-57169910 MWAB",
        "zarValue": "R61235.00",
        "receivingDate": "2017-06-19                           ",
        "email": "2017-06-12 10:17",
        "confirmArrival": "2017-06-20 12:37",
        "qaCheck": "2017-06-21 15:38",
        "inventoryAdded ": "2017-06-21 15:41",
        "sss": "2017-06-21 15:42"
    },
    {
        "id": "IN17-06_06",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "29663127",
        "zarValue": "R147563.40",
        "receivingDate": "2017-06-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-14 14:11",
        "qaCheck": "2017-06-14 14:11",
        "inventoryAdded ": "2017-06-14 14:11",
        "sss": "2017-06-14 14:13"
    },
    {
        "id": "IN17-06_07",
        "sponsor": "CATALENT",
        "study": "BN29553",
        "clearingAgent": "XPRESSO",
        "courierCoy": "SPX",
        "mawb": "724-59196233",
        "zarValue": "R1018270.00",
        "receivingDate": "2017-06-28                           ",
        "email": "2017-06-14 15:16",
        "confirmArrival": "2017-06-28 10:22",
        "qaCheck": "2017-06-28 10:55",
        "inventoryAdded ": "2017-06-28 10:55",
        "sss": "2017-06-28 11:01"
    },
    {
        "id": "IN17-06_08",
        "sponsor": "SANOFI",
        "study": "EFC14153",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724-61150655",
        "zarValue": "R230055.00",
        "receivingDate": "2017-06-14                           ",
        "email": "2017-06-14 15:33",
        "confirmArrival": "2017-06-15 14:39",
        "qaCheck": "2017-06-20 09:26",
        "inventoryAdded ": "2017-06-20 09:27",
        "sss": "2017-06-20 09:28"
    },
    {
        "id": "IN17-06_09",
        "sponsor": "CATALENT",
        "study": "GV29893 - CATALENT",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": " 176-5168 0370",
        "zarValue": "R325000.00",
        "receivingDate": "2017-06-19                           ",
        "email": "2017-06-19 09:01",
        "confirmArrival": "2017-06-22 12:10",
        "qaCheck": "2017-06-22 14:56",
        "inventoryAdded ": "2017-06-22 14:57",
        "sss": "2017-06-22 14:57"
    },
    {
        "id": "IN17-06_10",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "057-57586351",
        "zarValue": "R3100920.00",
        "receivingDate": "2017-06-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-19 12:25",
        "qaCheck": "2017-06-20 09:15",
        "inventoryAdded ": "2017-06-20 09:16",
        "sss": "2017-06-20 09:21"
    },
    {
        "id": "IN17-06_11",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-56562424",
        "zarValue": "R4331.30",
        "receivingDate": "2017-06-19                           ",
        "email": "2017-06-19 12:47",
        "confirmArrival": "2017-06-19 15:25",
        "qaCheck": "2017-06-20 09:43",
        "inventoryAdded ": "2017-06-20 09:45",
        "sss": "2017-06-20 09:45"
    },
    {
        "id": "IN17-06_12",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-56562446",
        "zarValue": "R5681.12",
        "receivingDate": "2017-06-19                           ",
        "email": "2017-06-19 12:53",
        "confirmArrival": "2017-06-19 15:24",
        "qaCheck": "2017-06-20 09:45",
        "inventoryAdded ": "2017-06-20 09:45",
        "sss": "2017-06-20 09:46"
    },
    {
        "id": "IN17-06_13",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-56562435",
        "zarValue": "R16984.00",
        "receivingDate": "2017-06-19                           ",
        "email": "2017-06-19 12:52",
        "confirmArrival": "2017-06-19 16:10",
        "qaCheck": "2017-06-20 09:46",
        "inventoryAdded ": "2017-06-20 09:46",
        "sss": "2017-06-20 09:47"
    },
    {
        "id": "IN17-06_14",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "31861655",
        "zarValue": "R369882.00",
        "receivingDate": "2017-06-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-19 15:27",
        "qaCheck": "2017-06-20 09:24",
        "inventoryAdded ": "2017-06-20 09:24",
        "sss": "2017-06-20 09:25"
    },
    {
        "id": "IN17-06_15",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "MARKEN",
        "mawb": "632X07363435",
        "zarValue": "R43749.90",
        "receivingDate": "2017-06-21                           ",
        "email": "2017-06-20 10:10",
        "confirmArrival": "2017-06-22 14:21",
        "qaCheck": "2017-06-22 15:03",
        "inventoryAdded ": "2017-06-22 15:03",
        "sss": "2017-06-22 15:05"
    },
    {
        "id": "IN17-06_16",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "057-56562586",
        "zarValue": "R168391.00",
        "receivingDate": "2017-06-20                           ",
        "email": "2017-06-20 10:17",
        "confirmArrival": "2017-06-22 14:29",
        "qaCheck": "2017-06-22 15:02",
        "inventoryAdded ": "2017-06-22 15:02",
        "sss": "2017-06-22 15:03"
    },
    {
        "id": "IN17-06_17",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-51680462",
        "zarValue": "R110046.00",
        "receivingDate": "2017-06-22                           ",
        "email": "2017-06-23 10:07",
        "confirmArrival": "2017-06-23 15:11",
        "qaCheck": "2017-06-23 15:11",
        "inventoryAdded ": "2017-06-23 15:11",
        "sss": "2017-06-28 09:35"
    },
    {
        "id": "IN17-06_18",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "31871758",
        "zarValue": "R6142.50",
        "receivingDate": "2017-06-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-22 15:07",
        "qaCheck": "2017-06-22 15:08",
        "inventoryAdded ": "2017-06-22 15:08",
        "sss": "2017-06-22 15:08"
    },
    {
        "id": "IN17-06_19",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "INV9750247",
        "zarValue": "R1714.20",
        "receivingDate": "2017-06-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-22 15:10",
        "qaCheck": "2017-06-22 15:10",
        "inventoryAdded ": "2017-06-22 15:10",
        "sss": "2017-06-22 15:11"
    },
    {
        "id": "IN17-06_20",
        "sponsor": "CLINDEV",
        "study": "GV29893",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "31872491",
        "zarValue": "R3119.90",
        "receivingDate": "2017-06-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-22 15:12",
        "qaCheck": "2017-06-22 15:12",
        "inventoryAdded ": "2017-06-22 15:12",
        "sss": "2017-06-22 15:13"
    },
    {
        "id": "IN17-06_21",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-6027 4723",
        "zarValue": "R197841.00",
        "receivingDate": "2017-06-28                           ",
        "email": "2017-06-23 13:27",
        "confirmArrival": "2017-07-05 14:52",
        "qaCheck": "2017-07-05 14:52",
        "inventoryAdded ": "2017-07-06 09:13",
        "sss": "2017-07-06 12:00"
    },
    {
        "id": "IN17-06_22",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "31873852",
        "zarValue": "R33232.50",
        "receivingDate": "2017-06-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-23 13:38",
        "qaCheck": "2017-06-23 13:38",
        "inventoryAdded ": "2017-06-23 13:38",
        "sss": "2017-06-23 13:40"
    },
    {
        "id": "IN17-06_23",
        "sponsor": "CATALENT",
        "study": "DS107G-03",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2769716",
        "zarValue": "R635692.00",
        "receivingDate": "2017-06-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-29 15:26",
        "qaCheck": "2017-06-29 15:26",
        "inventoryAdded ": "2017-06-29 15:26",
        "sss": "2017-06-29 15:28"
    },
    {
        "id": "IN17-07_01",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X07401171",
        "zarValue": "R58732.80",
        "receivingDate": "2017-07-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-03 13:23",
        "qaCheck": "2017-07-03 14:48",
        "inventoryAdded ": "2017-07-03 14:48",
        "sss": "2017-07-03 14:49"
    },
    {
        "id": "IN17-07_02",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-5168 0764",
        "zarValue": "R134340.00",
        "receivingDate": "2017-07-09                           ",
        "email": "2017-07-07 14:02",
        "confirmArrival": "2017-07-12 13:55",
        "qaCheck": "2017-07-12 14:05",
        "inventoryAdded ": "2017-07-12 14:05",
        "sss": "2017-07-12 14:08"
    },
    {
        "id": "IN17-07_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "SEABOURNE",
        "mawb": "632X07416298",
        "zarValue": "R48283.00",
        "receivingDate": "2017-07-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-07 16:57",
        "qaCheck": "2017-07-07 16:58",
        "inventoryAdded ": "2017-07-07 16:58",
        "sss": "2017-07-07 16:59"
    },
    {
        "id": "IN17-07_04",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6027 6366",
        "zarValue": "R54540.00",
        "receivingDate": "2017-07-15                           ",
        "email": "2017-07-14 15:48",
        "confirmArrival": "2017-07-19 17:01",
        "qaCheck": "2017-07-19 17:01",
        "inventoryAdded ": "2017-07-20 15:55",
        "sss": "2017-07-20 15:56"
    },
    {
        "id": "IN17-07_05",
        "sponsor": "CATALENT",
        "study": "K-877-302 STUDY",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2769467",
        "zarValue": "R235225.00",
        "receivingDate": "2017-07-17                           ",
        "email": "2017-07-17 14:10",
        "confirmArrival": "2017-07-17 14:11",
        "qaCheck": "2017-07-17 14:11",
        "inventoryAdded ": "2017-07-17 14:11",
        "sss": "2017-07-17 14:12"
    },
    {
        "id": "IN17-07_07",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6027 6775",
        "zarValue": "R153363.00",
        "receivingDate": "2017-07-21                           ",
        "email": "2017-07-19 16:34",
        "confirmArrival": "2017-07-25 14:39",
        "qaCheck": "2017-07-25 15:21",
        "inventoryAdded ": "2017-07-25 15:21",
        "sss": "2017-07-25 15:22"
    },
    {
        "id": "IN17-07_08",
        "sponsor": "CATALENT",
        "study": "GA29102",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6027 6694",
        "zarValue": "R98553.60",
        "receivingDate": "2017-07-21                           ",
        "email": "2017-07-19 16:43",
        "confirmArrival": "2017-07-25 14:43",
        "qaCheck": "2017-07-25 15:19",
        "inventoryAdded ": "2017-07-25 15:20",
        "sss": "2017-07-25 15:21"
    },
    {
        "id": "IN17-07_09",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X07488580",
        "zarValue": "R278378.00",
        "receivingDate": "2017-07-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-21 09:45",
        "qaCheck": "2017-07-21 09:45",
        "inventoryAdded ": "2017-07-21 09:45",
        "sss": "2017-07-21 10:10"
    },
    {
        "id": "IN17-07_10",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-5168 1103",
        "zarValue": "R480546.00",
        "receivingDate": "2017-07-25                           ",
        "email": "2017-07-21 15:31",
        "confirmArrival": "2017-07-28 15:57",
        "qaCheck": "2017-08-01 12:07",
        "inventoryAdded ": "2017-08-01 12:07",
        "sss": "2017-08-01 12:08"
    },
    {
        "id": "IN17-07_11",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "057-56563861",
        "zarValue": "R6986.73",
        "receivingDate": "2017-07-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-24 15:59",
        "qaCheck": "2017-07-24 15:59",
        "inventoryAdded ": "2017-07-24 16:00",
        "sss": "2017-07-24 16:01"
    },
    {
        "id": "IN17-07_12",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "31947972",
        "zarValue": "R177075.90",
        "receivingDate": "2017-07-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-25 10:00",
        "qaCheck": "2017-07-25 10:00",
        "inventoryAdded ": "2017-07-25 10:00",
        "sss": "2017-07-25 10:09"
    },
    {
        "id": "IN17-07_13",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6027 7276",
        "zarValue": "R115941.00",
        "receivingDate": "2017-07-26                           ",
        "email": "2017-07-25 13:28",
        "confirmArrival": "2017-07-28 15:53",
        "qaCheck": "2017-08-01 12:07",
        "inventoryAdded ": "2017-08-03 09:26",
        "sss": "2017-08-03 09:27"
    },
    {
        "id": "IN17-07_14",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "31958972",
        "zarValue": "R369882.00",
        "receivingDate": "2017-07-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-28 15:46",
        "qaCheck": "2017-07-28 15:46",
        "inventoryAdded ": "2017-07-28 15:46",
        "sss": "2017-07-28 15:47"
    },
    {
        "id": "IN17-07_15",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "INV10012929",
        "zarValue": "R2931.28",
        "receivingDate": "2017-07-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-31 11:28",
        "qaCheck": "2017-07-31 11:28",
        "inventoryAdded ": "2017-07-31 11:28",
        "sss": "2017-07-31 11:29"
    },
    {
        "id": "IN17-08_01",
        "sponsor": "CATALENT",
        "study": "GA29145",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-6041 2660",
        "zarValue": "R85051.50",
        "receivingDate": "2017-08-04                           ",
        "email": "2017-08-03 09:31",
        "confirmArrival": "2017-08-11 09:47",
        "qaCheck": "2017-08-11 10:07",
        "inventoryAdded ": "2017-08-11 10:07",
        "sss": "2017-08-11 10:08"
    },
    {
        "id": "IN17-08_02",
        "sponsor": "CLINDEV",
        "study": "GV29893",
        "clearingAgent": "Dischem",
        "courierCoy": "Dischem",
        "mawb": "DISD02-00061866",
        "zarValue": "R1096.74",
        "receivingDate": "2017-08-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-03 15:42",
        "qaCheck": "2017-08-03 15:42",
        "inventoryAdded ": "2017-08-03 15:42",
        "sss": "2017-08-03 15:43"
    },
    {
        "id": "IN17-08_03",
        "sponsor": "ALMAC",
        "study": "HGS1006-C1112",
        "clearingAgent": "SPX",
        "courierCoy": "World Courier",
        "mawb": "2778422",
        "zarValue": "R242800.00",
        "receivingDate": "2017-08-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-04 12:04",
        "qaCheck": "2017-08-04 12:04",
        "inventoryAdded ": "2017-08-04 12:08",
        "sss": "2017-08-04 12:26"
    },
    {
        "id": "IN17-08_04",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "181140093W",
        "zarValue": "R11725.80",
        "receivingDate": "2017-08-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-08 11:21",
        "qaCheck": "2017-08-08 11:23",
        "inventoryAdded ": "2017-08-08 11:23",
        "sss": "2017-08-08 11:25"
    },
    {
        "id": "IN17-08_05",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X07604676",
        "zarValue": "R53312.00",
        "receivingDate": "2017-08-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-08 14:20",
        "qaCheck": "2017-08-10 10:31",
        "inventoryAdded ": "2017-08-10 10:31",
        "sss": "2017-08-10 10:35"
    },
    {
        "id": "IN17-08_06",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 3161",
        "zarValue": "R109271.00",
        "receivingDate": "2017-08-10                           ",
        "email": "2017-08-10 08:54",
        "confirmArrival": "2017-08-15 15:29",
        "qaCheck": "2017-08-15 15:47",
        "inventoryAdded ": "2017-08-15 15:51",
        "sss": "2017-08-15 15:52"
    },
    {
        "id": "IN17-08_07",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6234 3702",
        "zarValue": "R294800.00",
        "receivingDate": "2017-08-15                           ",
        "email": "2017-08-10 09:02",
        "confirmArrival": "2017-08-16 15:54",
        "qaCheck": "2017-08-17 15:26",
        "inventoryAdded ": "2017-08-17 15:26",
        "sss": "2017-08-17 15:26"
    },
    {
        "id": "IN17-08_08",
        "sponsor": "ALMAC",
        "study": "RPC01-3102",
        "clearingAgent": "UPS SCS South Africa",
        "courierCoy": "UPS SCS South Africa",
        "mawb": "ZAF724 9-00",
        "zarValue": "R3839.41",
        "receivingDate": "2017-08-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-14 09:15",
        "qaCheck": "2017-08-14 09:42",
        "inventoryAdded ": "2017-08-14 09:31",
        "sss": "2017-08-14 09:44"
    },
    {
        "id": "IN17-08_09",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 3581",
        "zarValue": "R492770.00",
        "receivingDate": "2017-08-16                           ",
        "email": "2017-08-14 09:36",
        "confirmArrival": "2017-08-16 15:58",
        "qaCheck": "2017-08-17 15:27",
        "inventoryAdded ": "2017-08-17 15:27",
        "sss": "2017-08-17 15:27"
    },
    {
        "id": "IN17-08_10",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "7799 1571 4050",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-14 09:56",
        "qaCheck": "2017-08-14 10:06",
        "inventoryAdded ": "2017-08-14 10:03",
        "sss": "2017-08-14 10:06"
    },
    {
        "id": "IN17-08_11",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 3743",
        "zarValue": "R108592.00",
        "receivingDate": "2017-08-17                           ",
        "email": "2017-08-15 15:35",
        "confirmArrival": "2017-08-18 14:10",
        "qaCheck": "2017-08-18 14:11",
        "inventoryAdded ": "2017-08-18 14:18",
        "sss": "2017-08-18 14:22"
    },
    {
        "id": "IN17-08_12",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "Seabourne ",
        "courierCoy": "QuickSTAT",
        "mawb": "181210015W",
        "zarValue": "R15925.40",
        "receivingDate": "2017-08-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-15 16:07",
        "qaCheck": "2017-08-16 08:28",
        "inventoryAdded ": "2017-08-16 08:28",
        "sss": "2017-08-16 09:26"
    },
    {
        "id": "IN17-08_13",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "X5PRYA",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-18 14:27",
        "qaCheck": "2017-08-18 14:27",
        "inventoryAdded ": "2017-08-18 14:28",
        "sss": "2017-08-18 14:28"
    },
    {
        "id": "IN17-08_14",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "72461151113",
        "zarValue": "R78039.40",
        "receivingDate": "2017-08-21                           ",
        "email": "2017-08-21 14:37",
        "confirmArrival": "2017-08-23 15:49",
        "qaCheck": "2017-08-23 15:55",
        "inventoryAdded ": "2017-08-23 15:55",
        "sss": "2017-08-23 15:57"
    },
    {
        "id": "IN17-08_15",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7700 6212 4140",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-21 14:42",
        "qaCheck": "2017-08-21 14:42",
        "inventoryAdded ": "2017-08-21 14:42",
        "sss": "2017-08-21 14:43"
    },
    {
        "id": "IN17-08_16",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7700 4093 5914",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-21 14:45",
        "qaCheck": "2017-08-21 14:45",
        "inventoryAdded ": "2017-08-21 14:45",
        "sss": "2017-08-21 14:46"
    },
    {
        "id": "IN17-08_17",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7700 6151 4328",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-21 14:47",
        "qaCheck": "2017-08-21 14:47",
        "inventoryAdded ": "2017-08-21 14:47",
        "sss": "2017-08-21 14:48"
    },
    {
        "id": "IN17-08_18",
        "sponsor": "CATALENT",
        "study": "GO25632",
        "clearingAgent": "NONE",
        "courierCoy": "QESTMED",
        "mawb": "4701210 LOGOQD",
        "zarValue": "R19117.80",
        "receivingDate": "2017-08-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-22 14:18",
        "qaCheck": "2017-08-22 14:18",
        "inventoryAdded ": "2017-08-22 14:18",
        "sss": "2017-08-22 14:19"
    },
    {
        "id": "IN17-08_19",
        "sponsor": "CATALENT",
        "study": "GB39242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "074-5724655",
        "zarValue": "R1016860.00",
        "receivingDate": "2017-08-25                           ",
        "email": "2017-08-23 14:37",
        "confirmArrival": "2017-09-01 10:47",
        "qaCheck": "2017-09-01 10:54",
        "inventoryAdded ": "2017-09-01 11:22",
        "sss": "2017-09-01 11:35"
    },
    {
        "id": "IN17-08_20",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "32029549",
        "zarValue": "R516471.00",
        "receivingDate": "2017-08-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-24 13:42",
        "qaCheck": "2017-08-24 13:42",
        "inventoryAdded ": "2017-08-24 13:42",
        "sss": "2017-08-24 13:43"
    },
    {
        "id": "IN17-08_21",
        "sponsor": "ALMAC",
        "study": "RPC01-3101",
        "clearingAgent": "FelTra",
        "courierCoy": "SPEC TRANS",
        "mawb": "AIR70002601",
        "zarValue": "R1265.28",
        "receivingDate": "2017-08-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-25 11:31",
        "qaCheck": "2017-08-29 16:15",
        "inventoryAdded ": "2017-08-29 16:15",
        "sss": "2017-08-29 16:19"
    },
    {
        "id": "IN17-08_22",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 4675",
        "zarValue": "R4618.76",
        "receivingDate": "2017-08-25                           ",
        "email": "2017-08-25 14:38",
        "confirmArrival": "2017-08-31 10:37",
        "qaCheck": "2017-08-31 10:45",
        "inventoryAdded ": "2017-08-31 10:51",
        "sss": "2017-08-31 10:51"
    },
    {
        "id": "IN17-08_23",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7700 9422 0073",
        "zarValue": "R0.00",
        "receivingDate": "2017-08-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-28 12:19",
        "qaCheck": "2017-08-28 12:19",
        "inventoryAdded ": "2017-08-28 12:19",
        "sss": "2017-08-31 10:54"
    },
    {
        "id": "IN17-08_24",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "32039149",
        "zarValue": "R39375.00",
        "receivingDate": "2017-08-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-29 14:08",
        "qaCheck": "2017-08-29 14:08",
        "inventoryAdded ": "2017-08-29 14:08",
        "sss": "2017-08-29 14:09"
    },
    {
        "id": "IN17-08_25",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-62344380",
        "zarValue": "R9639.43",
        "receivingDate": "2017-09-01                           ",
        "email": "2017-08-31 11:01",
        "confirmArrival": "2017-09-18 14:29",
        "qaCheck": "2017-09-19 06:27",
        "inventoryAdded ": "2017-09-21 10:08",
        "sss": "2017-09-21 10:21"
    },
    {
        "id": "IN17-09_01",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2784913",
        "zarValue": "R149434.00",
        "receivingDate": "2017-09-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-05 10:59",
        "qaCheck": "2017-09-05 11:00",
        "inventoryAdded ": "2017-09-05 11:00",
        "sss": "2017-09-05 11:19"
    },
    {
        "id": "IN17-09_02",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 5294",
        "zarValue": "R26274.90",
        "receivingDate": "2017-09-07                           ",
        "email": "2017-09-05 14:07",
        "confirmArrival": "2017-09-08 12:10",
        "qaCheck": "2017-09-08 12:15",
        "inventoryAdded ": "2017-09-08 12:15",
        "sss": "2017-09-08 12:16"
    },
    {
        "id": "IN17-09_03",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2784523",
        "zarValue": "R96841.50",
        "receivingDate": "2017-09-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-08 10:24",
        "qaCheck": "2017-09-08 10:24",
        "inventoryAdded ": "2017-09-08 10:24",
        "sss": "2017-09-08 10:31"
    },
    {
        "id": "IN17-09_04",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2784023",
        "zarValue": "R10734.50",
        "receivingDate": "2017-09-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-08 10:38",
        "qaCheck": "2017-09-08 10:39",
        "inventoryAdded ": "2017-09-08 10:39",
        "sss": "2017-09-08 10:41"
    },
    {
        "id": "IN17-09_05",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "632X07731059",
        "zarValue": "R19214.60",
        "receivingDate": "2017-09-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-08 13:15",
        "qaCheck": "2017-09-08 13:42",
        "inventoryAdded ": "2017-09-08 13:42",
        "sss": "2017-09-08 13:42"
    },
    {
        "id": "IN17-09_06",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "181490754W",
        "zarValue": "R78219.30",
        "receivingDate": "2017-09-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-13 13:03",
        "qaCheck": "2017-09-13 13:03",
        "inventoryAdded ": "2017-09-13 13:56",
        "sss": "2017-09-13 13:58"
    },
    {
        "id": "IN17-09_07",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "601046358004",
        "zarValue": "R369882.00",
        "receivingDate": "2017-09-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-13 13:13",
        "qaCheck": "2017-09-13 13:13",
        "inventoryAdded ": "2017-09-13 13:14",
        "sss": "2017-09-13 13:19"
    },
    {
        "id": "IN17-09_08",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-5168 2002",
        "zarValue": "R4396819.13",
        "receivingDate": "2017-09-17                           ",
        "email": "2017-09-15 08:29",
        "confirmArrival": "2017-09-19 14:28",
        "qaCheck": "2017-09-21 10:32",
        "inventoryAdded ": "2017-09-21 10:33",
        "sss": "2017-09-21 10:39"
    },
    {
        "id": "IN17-09_09",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-5168 1976",
        "zarValue": "R78770.20",
        "receivingDate": "2017-09-18                           ",
        "email": "2017-09-15 09:08",
        "confirmArrival": "2017-09-19 14:30",
        "qaCheck": "2017-09-21 11:01",
        "inventoryAdded ": "2017-09-21 11:14",
        "sss": "2017-09-21 11:15"
    },
    {
        "id": "IN17-09_10",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "Seabourne Express",
        "mawb": "083-95737106",
        "zarValue": "R26571.50",
        "receivingDate": "2017-09-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-21 11:20",
        "qaCheck": "2017-09-21 11:20",
        "inventoryAdded ": "2017-09-21 11:26",
        "sss": "2017-09-21 11:39"
    },
    {
        "id": "IN17-09_11",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "604X07775723",
        "zarValue": "R2015.18",
        "receivingDate": "2017-09-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-21 11:46",
        "qaCheck": "2017-09-21 11:47",
        "inventoryAdded ": "2017-09-21 11:47",
        "sss": "2017-09-21 11:47"
    },
    {
        "id": "IN17-09_12",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "604X07783348",
        "zarValue": "R39033.20",
        "receivingDate": "2017-09-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-21 11:53",
        "qaCheck": "2017-09-21 11:53",
        "inventoryAdded ": "2017-09-21 11:54",
        "sss": "2017-09-21 12:02"
    },
    {
        "id": "IN17-09_13",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-62345032",
        "zarValue": "R942451.00",
        "receivingDate": "2017-10-04                           ",
        "email": "2017-09-27 15:15",
        "confirmArrival": "2017-10-04 16:26",
        "qaCheck": "2017-10-09 16:05",
        "inventoryAdded ": "2017-10-09 16:05",
        "sss": "2017-10-09 16:06"
    },
    {
        "id": "IN17-09_14",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "7703 0276 4842",
        "zarValue": "R0.00",
        "receivingDate": "2017-09-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-27 16:03",
        "qaCheck": "2017-09-27 16:03",
        "inventoryAdded ": "2017-09-27 16:03",
        "sss": "2017-09-27 16:04"
    },
    {
        "id": "IN17-09_15",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2788339",
        "zarValue": "R87339.30",
        "receivingDate": "2017-09-28                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-28 15:31",
        "qaCheck": "2017-09-28 15:31",
        "inventoryAdded ": "2017-09-28 15:40",
        "sss": "2017-09-28 15:41"
    },
    {
        "id": "IN17-10_01",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "72462345172",
        "zarValue": "R1407089.41",
        "receivingDate": "2017-10-07                           ",
        "email": "2017-10-02 14:56",
        "confirmArrival": "2017-10-09 16:03",
        "qaCheck": "2017-10-10 15:25",
        "inventoryAdded ": "2017-10-10 15:25",
        "sss": "2017-10-10 15:33"
    },
    {
        "id": "IN17-10_02",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-6058 5862",
        "zarValue": "R853504.00",
        "receivingDate": "2017-10-04                           ",
        "email": "2017-10-03 13:23",
        "confirmArrival": "2017-10-09 16:05",
        "qaCheck": "2017-10-10 15:24",
        "inventoryAdded ": "2017-10-10 15:24",
        "sss": "2017-10-10 15:25"
    },
    {
        "id": "IN17-10_03",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 6249 9135",
        "zarValue": "R1390620.00",
        "receivingDate": "2017-10-03                           ",
        "email": "2017-10-03 15:53",
        "confirmArrival": "2017-10-04 16:23",
        "qaCheck": "2017-10-05 10:14",
        "inventoryAdded ": "2017-10-05 10:14",
        "sss": "2017-10-05 10:15"
    },
    {
        "id": "IN17-10_04",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632x07838499",
        "zarValue": "R76439.70",
        "receivingDate": "2017-10-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-03 16:00",
        "qaCheck": "2017-10-04 16:07",
        "inventoryAdded ": "2017-10-04 16:08",
        "sss": "2017-10-04 16:10"
    },
    {
        "id": "IN17-10_05",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "1166487",
        "zarValue": "R1954.19",
        "receivingDate": "2017-10-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-06 08:08",
        "qaCheck": "2017-10-06 08:08",
        "inventoryAdded ": "2017-10-06 08:08",
        "sss": "2017-10-06 08:09"
    },
    {
        "id": "IN17-10_06",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2790162",
        "zarValue": "R107440.00",
        "receivingDate": "2017-10-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-11 09:21",
        "qaCheck": "2017-10-11 09:21",
        "inventoryAdded ": "2017-10-11 09:21",
        "sss": "2017-10-11 09:22"
    },
    {
        "id": "IN17-10_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "083-36011905",
        "zarValue": "R43477.00",
        "receivingDate": "2017-10-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-12 14:32",
        "qaCheck": "2017-10-12 14:44",
        "inventoryAdded ": "2017-10-12 14:44",
        "sss": "2017-10-12 14:45"
    },
    {
        "id": "IN17-10_08",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770494109835",
        "zarValue": "R0.00",
        "receivingDate": "2017-10-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-17 09:17",
        "qaCheck": "2017-10-17 11:25",
        "inventoryAdded ": "2017-10-17 09:18",
        "sss": "2017-10-17 11:26"
    },
    {
        "id": "IN17-10_09",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "ALCON",
        "mawb": "TRP197054930",
        "zarValue": "R0.00",
        "receivingDate": "2017-10-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-20 11:17",
        "qaCheck": "2017-10-20 11:39",
        "inventoryAdded ": "2017-10-20 11:40",
        "sss": "2017-10-25 09:50"
    },
    {
        "id": "IN17-10_10",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-5965 5260",
        "zarValue": "R721951.00",
        "receivingDate": "2017-10-21                           ",
        "email": "2017-10-26 10:28",
        "confirmArrival": "2017-11-01 14:46",
        "qaCheck": "2017-11-01 15:55",
        "inventoryAdded ": "2017-11-01 15:55",
        "sss": "2017-11-01 15:57"
    },
    {
        "id": "IN17-10_11",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7705 1069 7249",
        "zarValue": "R0.00",
        "receivingDate": "2017-10-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-20 11:19",
        "qaCheck": "2017-10-20 11:21",
        "inventoryAdded ": "2017-10-20 11:22",
        "sss": "2017-10-20 11:22"
    },
    {
        "id": "IN17-10_13",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "601076979004",
        "zarValue": "R26250.00",
        "receivingDate": "2017-10-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-25 14:19",
        "qaCheck": "2017-10-25 14:19",
        "inventoryAdded ": "2017-10-25 14:19",
        "sss": "2017-10-25 14:20"
    },
    {
        "id": "IN17-10_15",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6058 7925",
        "zarValue": "R176471.00",
        "receivingDate": "2017-10-27                           ",
        "email": "2017-10-26 09:54",
        "confirmArrival": "2017-11-02 19:29",
        "qaCheck": "2017-11-03 11:20",
        "inventoryAdded ": "2017-11-03 11:20",
        "sss": "2017-11-03 11:26"
    },
    {
        "id": "IN17-10_16",
        "sponsor": "CATALENT",
        "study": "WO29479",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-5965 5304",
        "zarValue": "R1309850.00",
        "receivingDate": "2017-10-26                           ",
        "email": "2017-10-26 10:05",
        "confirmArrival": "2017-10-31 10:15",
        "qaCheck": "2017-10-31 10:41",
        "inventoryAdded ": "2017-10-31 10:41",
        "sss": "2017-10-31 10:41"
    },
    {
        "id": "IN17-10_17",
        "sponsor": "CATALENT",
        "study": "WO29479",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-5965 5293",
        "zarValue": "R1193130.00",
        "receivingDate": "2017-10-26                           ",
        "email": "2017-10-26 10:12",
        "confirmArrival": "2017-11-02 19:28",
        "qaCheck": "2017-11-06 21:10",
        "inventoryAdded ": "2017-11-06 21:10",
        "sss": "2017-11-06 21:10"
    },
    {
        "id": "IN17-10_18",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7705 4321 2251",
        "zarValue": "R0.00",
        "receivingDate": "2017-10-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-26 10:23",
        "qaCheck": "2017-10-26 10:23",
        "inventoryAdded ": "2017-10-26 10:23",
        "sss": "2017-10-26 10:23"
    },
    {
        "id": "IN17-10_19",
        "sponsor": "CATALENT",
        "study": "BN29553",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-63155805",
        "zarValue": "R1621180.00",
        "receivingDate": "2017-11-05                           ",
        "email": "2017-10-30 10:47",
        "confirmArrival": "2017-11-07 16:07",
        "qaCheck": "2017-11-08 19:27",
        "inventoryAdded ": "2017-11-11 12:40",
        "sss": "2017-11-11 12:40"
    },
    {
        "id": "IN17-10_20",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72463155363",
        "zarValue": "R501263.00",
        "receivingDate": "2017-10-30                           ",
        "email": "2017-10-30 14:32",
        "confirmArrival": "2017-11-09 16:11",
        "qaCheck": "2017-11-11 12:41",
        "inventoryAdded ": "2017-11-11 12:41",
        "sss": "2017-11-11 12:41"
    },
    {
        "id": "IN17-10_22",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "32178377",
        "zarValue": "R177075.90",
        "receivingDate": "2017-10-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-30 15:11",
        "qaCheck": "2017-10-30 15:11",
        "inventoryAdded ": "2017-10-30 15:11",
        "sss": "2017-10-30 15:13"
    },
    {
        "id": "IN17-11_01",
        "sponsor": "ALMAC",
        "study": "1297.9",
        "clearingAgent": "SPX",
        "courierCoy": "World Courier",
        "mawb": "104891028",
        "zarValue": "R2407850.00",
        "receivingDate": "2017-11-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-01 15:20",
        "qaCheck": "2017-11-08 12:54",
        "inventoryAdded ": "2017-11-08 12:54",
        "sss": "2017-11-08 12:54"
    },
    {
        "id": "IN17-11_02",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "KIM DU",
        "mawb": "None",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-06 21:13",
        "qaCheck": "2017-11-06 21:13",
        "inventoryAdded ": "2017-11-06 21:14",
        "sss": "2017-11-10 15:55"
    },
    {
        "id": "IN17-11_03",
        "sponsor": "CATALENT",
        "study": "GA29102",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6059 1985",
        "zarValue": "R82313.70",
        "receivingDate": "2017-11-08                           ",
        "email": "2017-11-06 21:23",
        "confirmArrival": "2017-11-08 19:31",
        "qaCheck": "2017-11-11 12:45",
        "inventoryAdded ": "2017-11-11 12:46",
        "sss": "2017-11-11 12:51"
    },
    {
        "id": "IN17-11_04",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "0",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "1099643",
        "zarValue": "R245466.46",
        "receivingDate": "2017-11-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-07 13:39",
        "qaCheck": "2017-11-07 13:39",
        "inventoryAdded ": "2017-11-07 13:39",
        "sss": "2017-11-07 13:42"
    },
    {
        "id": "IN17-11_05",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "548975666479",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-08 19:42",
        "qaCheck": "2017-11-13 14:28",
        "inventoryAdded ": "2017-11-13 14:28",
        "sss": "2017-11-13 14:29"
    },
    {
        "id": "IN17-11_06",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770663974507",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-08 19:45",
        "qaCheck": "2017-11-10 15:46",
        "inventoryAdded ": "2017-11-10 15:46",
        "sss": "2017-11-10 15:46"
    },
    {
        "id": "IN17-11_07",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "72462345566",
        "zarValue": "R155488.00",
        "receivingDate": "2017-11-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-09 16:32",
        "qaCheck": "2017-11-11 12:51",
        "inventoryAdded ": "2017-11-11 12:52",
        "sss": "2017-11-11 12:52"
    },
    {
        "id": "IN17-11_08",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "Fedex",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-10 08:20",
        "qaCheck": "2017-11-10 15:33",
        "inventoryAdded ": "2017-11-10 15:33",
        "sss": "2017-11-10 15:36"
    },
    {
        "id": "IN17-11_09",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770690279845",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-10 15:29",
        "qaCheck": "2017-11-10 15:29",
        "inventoryAdded ": "2017-11-10 15:30",
        "sss": "2017-11-10 15:31"
    },
    {
        "id": "IN17-11_10",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "182050048W",
        "zarValue": "R302886.00",
        "receivingDate": "2017-11-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-11 12:39",
        "qaCheck": "2017-11-13 15:48",
        "inventoryAdded ": "2017-11-14 10:43",
        "sss": "2017-11-14 10:45"
    },
    {
        "id": "IN17-11_11",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7707 1091 20720",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-14 10:55",
        "qaCheck": "2017-11-14 10:55",
        "inventoryAdded ": "2017-11-14 10:55",
        "sss": "2017-11-14 10:56"
    },
    {
        "id": "IN17-11_12",
        "sponsor": "ALMAC",
        "study": "HGS1006-C1112",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2795661",
        "zarValue": "R29840.90",
        "receivingDate": "2017-11-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-14 11:11",
        "qaCheck": "2017-11-14 11:12",
        "inventoryAdded ": "2017-11-14 11:12",
        "sss": "2017-11-14 11:12"
    },
    {
        "id": "IN17-11_13",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770704768473 770653792598",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-16 16:01",
        "qaCheck": "2017-11-16 16:01",
        "inventoryAdded ": "2017-11-16 16:01",
        "sss": "2017-11-16 16:02"
    },
    {
        "id": "IN17-11_14",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "182140334W",
        "zarValue": "R270461.00",
        "receivingDate": "2017-11-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-16 16:06",
        "qaCheck": "2017-11-16 16:10",
        "inventoryAdded ": "2017-11-16 16:10",
        "sss": "2017-11-16 16:13"
    },
    {
        "id": "IN17-11_15",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "05761499185",
        "zarValue": "R54459.20",
        "receivingDate": "2017-11-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-21 16:15",
        "qaCheck": "2017-11-22 13:47",
        "inventoryAdded ": "2017-11-24 15:03",
        "sss": "2017-11-24 15:04"
    },
    {
        "id": "IN17-11_17",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770758506150",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-21 15:46",
        "qaCheck": "2017-11-21 15:46",
        "inventoryAdded ": "2017-11-21 15:46",
        "sss": "2017-11-21 15:47"
    },
    {
        "id": "IN17-11_18",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2798204",
        "zarValue": "R82777.70",
        "receivingDate": "2017-11-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-22 14:04",
        "qaCheck": "2017-11-24 15:10",
        "inventoryAdded ": "2017-11-24 15:11",
        "sss": "2017-11-24 15:13"
    },
    {
        "id": "IN17-11_20",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2798152",
        "zarValue": "R63079.00",
        "receivingDate": "2017-11-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-22 14:22",
        "qaCheck": "2017-11-24 15:10",
        "inventoryAdded ": "2017-11-24 15:11",
        "sss": "2017-11-24 15:16"
    },
    {
        "id": "IN17-11_21",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2798190",
        "zarValue": "R27280.00",
        "receivingDate": "2017-11-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-22 14:30",
        "qaCheck": "2017-11-24 15:11",
        "inventoryAdded ": "2017-11-24 15:11",
        "sss": "2017-11-24 15:17"
    },
    {
        "id": "IN17-11_22",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2798169",
        "zarValue": "R188337.00",
        "receivingDate": "2017-11-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-22 14:33",
        "qaCheck": "2017-11-24 15:11",
        "inventoryAdded ": "2017-11-24 15:12",
        "sss": "2017-11-24 15:18"
    },
    {
        "id": "IN17-11_23",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770783082571",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-23 09:06",
        "qaCheck": "2017-11-23 09:06",
        "inventoryAdded ": "2017-11-23 09:06",
        "sss": "2017-11-23 09:07"
    },
    {
        "id": "IN17-11_25",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2797918",
        "zarValue": "R91751.10",
        "receivingDate": "2017-11-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-23 10:03",
        "qaCheck": "2017-11-24 15:12",
        "inventoryAdded ": "2017-11-24 15:12",
        "sss": "2017-11-24 15:18"
    },
    {
        "id": "IN17-11_26",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2798162",
        "zarValue": "R114557.00",
        "receivingDate": "2017-11-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-23 10:09",
        "qaCheck": "2017-11-24 15:12",
        "inventoryAdded ": "2017-11-24 15:12",
        "sss": "2017-11-24 15:20"
    },
    {
        "id": "IN17-11_27",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "N/A",
        "courierCoy": "Morne de Bruin",
        "mawb": "None",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-27 16:04",
        "qaCheck": "2017-11-27 16:04",
        "inventoryAdded ": "2017-11-27 16:04",
        "sss": "2017-11-27 16:07"
    },
    {
        "id": "IN17-11_29",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 6059 2626",
        "zarValue": "R499857.00",
        "receivingDate": "2017-12-05                           ",
        "email": "2017-11-28 10:10",
        "confirmArrival": "2017-12-07 14:52",
        "qaCheck": "2017-12-07 14:53",
        "inventoryAdded ": "2017-12-07 15:57",
        "sss": "2017-12-07 15:58"
    },
    {
        "id": "IN17-11_31",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "182250013W",
        "zarValue": "R86717.40",
        "receivingDate": "2017-11-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-30 15:07",
        "qaCheck": "2017-11-30 15:07",
        "inventoryAdded ": "2017-11-30 15:09",
        "sss": "2017-11-30 15:14"
    },
    {
        "id": "IN17-11_32",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "057 59696781",
        "zarValue": "R178728.00",
        "receivingDate": "2017-11-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-12-01 13:08",
        "qaCheck": "2017-12-01 13:09",
        "inventoryAdded ": "2017-12-01 13:09",
        "sss": "2017-12-01 13:09"
    },
    {
        "id": "IN17-12_01",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770737274000",
        "zarValue": "R0.00",
        "receivingDate": "2017-12-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-12-01 13:26",
        "qaCheck": "2017-12-01 13:26",
        "inventoryAdded ": "2017-12-01 13:26",
        "sss": "2017-12-01 13:29"
    },
    {
        "id": "IN17-12_02",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "7707 4915 8942",
        "zarValue": "R0.00",
        "receivingDate": "2017-12-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-12-01 13:36",
        "qaCheck": "2017-12-01 13:36",
        "inventoryAdded ": "2017-12-01 13:36",
        "sss": "2017-12-01 13:37"
    },
    {
        "id": "IN17-12_03",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2799335",
        "zarValue": "R224477.00",
        "receivingDate": "2017-12-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-12-05 11:26",
        "qaCheck": "2017-12-05 11:27",
        "inventoryAdded ": "2017-12-05 11:27",
        "sss": "2017-12-05 11:28"
    },
    {
        "id": "IN17-12_06",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Hilda Mostert",
        "mawb": "13 Dec 2017",
        "zarValue": "R0.00",
        "receivingDate": "2017-12-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-12-13 12:22",
        "qaCheck": "2017-12-13 12:22",
        "inventoryAdded ": "2017-12-13 12:22",
        "sss": "2017-12-13 12:26"
    },
    {
        "id": "IN18-01_10",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "0",
        "courierCoy": "Aspen",
        "mawb": "38344203",
        "zarValue": "R52509.80",
        "receivingDate": "2018-01-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-11 09:24",
        "qaCheck": "2018-01-11 09:24",
        "inventoryAdded ": "2018-01-11 09:24",
        "sss": "2018-01-11 09:25"
    },
    {
        "id": "IN18-01_19",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2806926",
        "zarValue": "R123706.00",
        "receivingDate": "2018-01-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-17 14:32",
        "qaCheck": "2018-01-17 14:39",
        "inventoryAdded ": "2018-01-17 14:39",
        "sss": "2018-01-17 14:43"
    },
    {
        "id": "IN18-03_03",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-65000751",
        "zarValue": "R1450415.22",
        "receivingDate": "2018-03-20                           ",
        "email": "2018-03-15 10:25",
        "confirmArrival": "2018-03-23 11:35",
        "qaCheck": "2018-03-26 15:22",
        "inventoryAdded ": "2018-03-26 15:22",
        "sss": "2018-03-26 15:23"
    },
    {
        "id": "IN18-04_08",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "932-535 87730",
        "zarValue": "R9357.47",
        "receivingDate": "2018-04-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-12 10:06",
        "qaCheck": "2018-04-12 10:12",
        "inventoryAdded ": "2018-04-13 14:37",
        "sss": "2018-04-13 14:38"
    },
    {
        "id": "IN18-04_12",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Hilda Mostert",
        "mawb": "HAND CARRIED",
        "zarValue": "R0.00",
        "receivingDate": "2018-04-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-17 10:22",
        "qaCheck": "2018-04-19 11:15",
        "inventoryAdded ": "2018-04-17 10:25",
        "sss": "2018-04-19 11:15"
    },
    {
        "id": "IN18-04_13",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2823687",
        "zarValue": "R214032.00",
        "receivingDate": "2018-04-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-04-17 15:03",
        "qaCheck": "2018-04-19 11:21",
        "inventoryAdded ": "2018-04-17 15:03",
        "sss": "2018-04-19 11:21"
    },
    {
        "id": "IN18-05_15",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "32610131",
        "zarValue": "R123294.00",
        "receivingDate": "2018-05-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-30 12:28",
        "qaCheck": "2018-05-30 12:28",
        "inventoryAdded ": "2018-05-30 12:28",
        "sss": "2018-05-30 12:35"
    },
    {
        "id": "IN18-05_21",
        "sponsor": "Triclinium",
        "study": "3HP-DTG-AUR1-6-212",
        "clearingAgent": "SPX",
        "courierCoy": "Aspen",
        "mawb": "39158319",
        "zarValue": "R233132.60",
        "receivingDate": "2018-05-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-30 13:17",
        "qaCheck": "2018-05-30 13:20",
        "inventoryAdded ": "2018-05-30 13:20",
        "sss": "2018-05-30 13:32"
    },
    {
        "id": "IN18-06_17",
        "sponsor": "SANOFI",
        "study": "EFC14643",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X09216780",
        "zarValue": "R124602.00",
        "receivingDate": "2018-06-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-25 08:37",
        "qaCheck": "2018-06-28 11:33",
        "inventoryAdded ": "2018-06-25 08:37",
        "sss": "2018-06-28 11:49"
    },
    {
        "id": "IN18-09_06",
        "sponsor": "SANOFI",
        "study": "EFC14643",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "176-94170871",
        "zarValue": "R113899.00",
        "receivingDate": "2018-09-14                           ",
        "email": "2018-09-12 11:16",
        "confirmArrival": "2018-09-17 13:21",
        "qaCheck": "2018-09-19 10:28",
        "inventoryAdded ": "2018-09-18 13:53",
        "sss": "2018-09-19 11:02"
    },
    {
        "id": "IN18-09_08",
        "sponsor": "Bayer",
        "study": "ASTEROID 3-15787",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "VISTPHARM",
        "zarValue": "R10875.00",
        "receivingDate": "2018-09-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-09-17 12:32",
        "qaCheck": "2018-09-19 10:28",
        "inventoryAdded ": "2018-09-17 12:33",
        "sss": "2018-09-19 11:02"
    },
    {
        "id": "IN18-10_08",
        "sponsor": "CATALENT",
        "study": "MO25757",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "724-6717 8963",
        "zarValue": "R324920.02",
        "receivingDate": "2018-10-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-10 12:47",
        "qaCheck": "2018-10-11 11:54",
        "inventoryAdded ": "2018-10-10 13:02",
        "sss": "2018-10-17 11:16"
    },
    {
        "id": "IN19-01_03",
        "sponsor": "CATALENT",
        "study": "BO40336",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-02533554",
        "zarValue": "R136444.31",
        "receivingDate": "2019-01-07                           ",
        "email": "2019-01-07 15:32",
        "confirmArrival": "2019-01-10 16:17",
        "qaCheck": "2019-01-14 11:03",
        "inventoryAdded ": "2019-01-11 10:04",
        "sss": "2019-01-15 11:55"
    },
    {
        "id": "IN19-01_09",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0253 3742",
        "zarValue": "R497983.00",
        "receivingDate": "2019-01-17                           ",
        "email": "2019-01-14 10:26",
        "confirmArrival": "2019-01-17 15:21",
        "qaCheck": "2019-01-21 13:51",
        "inventoryAdded ": "2019-01-17 15:26",
        "sss": "2019-01-28 09:42"
    },
    {
        "id": "IN19-03_05",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0862 5772",
        "zarValue": "R5287839.38",
        "receivingDate": "2019-03-12                           ",
        "email": "2019-03-08 12:26",
        "confirmArrival": "2019-03-13 15:17",
        "qaCheck": "2019-03-14 11:48",
        "inventoryAdded ": "2019-03-13 15:17",
        "sss": "2019-03-14 12:12"
    },
    {
        "id": "IN19-04_12",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "17/04/2019",
        "zarValue": "R1004993.76",
        "receivingDate": "2019-04-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-04-17 10:21",
        "qaCheck": "2019-04-18 12:23",
        "inventoryAdded ": "2019-04-17 10:21",
        "sss": "2019-04-18 12:25"
    },
    {
        "id": "IN19-05_16",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0989 4990",
        "zarValue": "R17436.80",
        "receivingDate": "2019-05-25                           ",
        "email": "2019-05-23 11:57",
        "confirmArrival": "2019-05-28 15:31",
        "qaCheck": "2019-05-31 11:07",
        "inventoryAdded ": "2019-05-29 10:31",
        "sss": "2019-05-31 11:08"
    },
    {
        "id": "IN19-06_10",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "05784857371",
        "zarValue": "R11469.40",
        "receivingDate": "2019-06-28                           ",
        "email": "2019-06-27 09:43",
        "confirmArrival": "2019-06-27 15:17",
        "qaCheck": "2019-07-02 10:05",
        "inventoryAdded ": "2019-06-28 11:28",
        "sss": "2019-07-03 12:23"
    },
    {
        "id": "IN19-07_09",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "932-56870063",
        "zarValue": "R0.00",
        "receivingDate": "2019-07-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-07-17 15:23",
        "qaCheck": "2019-07-23 12:15",
        "inventoryAdded ": "2019-07-24 08:31",
        "sss": "2019-07-24 16:13"
    },
    {
        "id": "IN19-09_03",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17672742935",
        "zarValue": "R11909.20",
        "receivingDate": "2019-09-09                           ",
        "email": "2019-09-03 10:50",
        "confirmArrival": "2019-09-10 16:17",
        "qaCheck": "2019-09-11 10:53",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2019-09-11 12:14"
    },
    {
        "id": "IN19-09_08",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X11644700",
        "zarValue": "R1171310.00",
        "receivingDate": "2019-09-20                           ",
        "email": "2019-09-09 10:51",
        "confirmArrival": "2019-09-17 14:56",
        "qaCheck": "2019-09-19 08:52",
        "inventoryAdded ": "2019-09-20 09:19",
        "sss": "2019-09-20 09:20"
    },
    {
        "id": "IN19-09_10",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17672743090",
        "zarValue": "R843404.12",
        "receivingDate": "2019-09-18                           ",
        "email": "2019-09-13 14:30",
        "confirmArrival": "2019-09-17 15:01",
        "qaCheck": "2019-09-19 11:03",
        "inventoryAdded ": "2019-09-17 15:52",
        "sss": "2019-09-20 09:19"
    },
    {
        "id": "IN19-10_02",
        "sponsor": "SANOFI",
        "study": "MET 55",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X11761910",
        "zarValue": "R514326.00",
        "receivingDate": "2019-10-07                           ",
        "email": "2019-10-04 09:16",
        "confirmArrival": "2019-10-08 16:17",
        "qaCheck": "2019-10-14 16:20",
        "inventoryAdded ": "2019-10-10 10:56",
        "sss": "2019-10-14 16:21"
    },
    {
        "id": "IN19-10_07",
        "sponsor": "CATALENT",
        "study": "CO40016",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-72743510",
        "zarValue": "R298903.00",
        "receivingDate": "2019-10-21                           ",
        "email": "2019-10-11 10:42",
        "confirmArrival": "2019-10-17 14:33",
        "qaCheck": "2019-10-21 10:22",
        "inventoryAdded ": "2019-10-17 16:00",
        "sss": "2019-10-21 10:23"
    },
    {
        "id": "IN19-10_08",
        "sponsor": "ROCHE SA",
        "study": "CO41101",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-72743543",
        "zarValue": "R2649188.71",
        "receivingDate": "2019-10-21                           ",
        "email": "2019-10-11 14:37",
        "confirmArrival": "2019-10-17 16:13",
        "qaCheck": "2019-10-24 11:15",
        "inventoryAdded ": "2019-10-21 09:31",
        "sss": "2019-10-24 13:36"
    },
    {
        "id": "IN20-02_04",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17610390413",
        "zarValue": "R3130935.30",
        "receivingDate": "2020-02-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-17 14:27",
        "qaCheck": "2020-02-20 14:34",
        "inventoryAdded ": "2020-02-17 15:41",
        "sss": "2020-02-20 14:38"
    },
    {
        "id": "IN20-02_05",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "601616549005",
        "zarValue": "R7537456.80",
        "receivingDate": "2020-02-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-19 16:21",
        "qaCheck": "2020-02-20 14:42",
        "inventoryAdded ": "2020-02-20 14:43",
        "sss": "2020-02-20 14:48"
    },
    {
        "id": "IN20-04_05",
        "sponsor": "CATALENT",
        "study": "WO40242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15753851604",
        "zarValue": "R926214.99",
        "receivingDate": "2020-04-20                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-04-17 12:59",
        "qaCheck": "2020-04-23 13:16",
        "inventoryAdded ": "2020-05-08 11:12",
        "sss": "2020-05-08 11:12"
    },
    {
        "id": "IN20-06_03",
        "sponsor": "CATALENT",
        "study": "GO40241",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "125-98030446",
        "zarValue": "R3065057.28",
        "receivingDate": "2020-06-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-06-17 13:36",
        "qaCheck": "2020-06-26 12:58",
        "inventoryAdded ": "2020-07-02 10:12",
        "sss": "2020-07-02 10:12"
    },
    {
        "id": "IN20-07_09",
        "sponsor": "CATALENT",
        "study": "K-877-302 STUDY",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X13494753",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-20 14:08",
        "qaCheck": "2020-07-22 13:24",
        "inventoryAdded ": "2020-07-21 15:09",
        "sss": "2020-07-22 13:25"
    },
    {
        "id": "IN20-07_10",
        "sponsor": "ROCHE SA",
        "study": "AG41991",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-27441131",
        "zarValue": "R2130636.85",
        "receivingDate": "2020-07-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-17 13:31",
        "qaCheck": "2020-07-17 13:56",
        "inventoryAdded ": "2020-07-17 13:59",
        "sss": "2020-07-17 13:59"
    },
    {
        "id": "IN20-07_11",
        "sponsor": "N/A",
        "study": "RX MEDICALS ",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "5479870572",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-17 12:55",
        "qaCheck": "2020-07-17 13:56",
        "inventoryAdded ": "2020-07-17 13:52",
        "sss": "2020-07-17 14:01"
    },
    {
        "id": "IN20-10_03",
        "sponsor": "CATALENT",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-61735726",
        "zarValue": "R81368.30",
        "receivingDate": "2020-10-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-06 14:36",
        "qaCheck": "2020-10-07 10:32",
        "inventoryAdded ": "2020-10-06 16:09",
        "sss": "2020-10-07 10:32"
    },
    {
        "id": "IN20-12_06",
        "sponsor": "MARKEN",
        "study": "MET 55",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "05794240650",
        "zarValue": "R500558.00",
        "receivingDate": "2020-12-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-12-17 15:15",
        "qaCheck": "2020-12-18 09:49",
        "inventoryAdded ": "2020-12-17 15:24",
        "sss": "2020-12-21 11:30"
    },
    {
        "id": "IN20-12_09",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "615X14720630",
        "zarValue": "R8.00",
        "receivingDate": "2020-12-17                           ",
        "email": "2020-12-17 14:36",
        "confirmArrival": "2020-12-17 14:35",
        "qaCheck": "2020-12-18 09:50",
        "inventoryAdded ": "2020-12-18 09:50",
        "sss": "2020-12-21 12:28"
    },
    {
        "id": "IN20-12_10",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "157-17332383",
        "zarValue": "R82163.00",
        "receivingDate": "2020-12-21                           ",
        "email": "2020-12-20 21:15",
        "confirmArrival": "2020-12-22 13:17",
        "qaCheck": "2020-12-22 13:18",
        "inventoryAdded ": "2020-12-22 13:21",
        "sss": "2020-12-22 13:22"
    },
    {
        "id": "IN21-05_04",
        "sponsor": "CATALENT",
        "study": "BP40657",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-20710955",
        "zarValue": "R2260040.00",
        "receivingDate": "2021-05-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-12 10:50",
        "qaCheck": "2021-05-12 11:59",
        "inventoryAdded ": "2021-05-12 12:08",
        "sss": "2021-05-12 12:08"
    },
    {
        "id": "IN21-05_05",
        "sponsor": "ROCHE SA",
        "study": "WO42312",
        "clearingAgent": "QUESTMED",
        "courierCoy": "QUESMED",
        "mawb": "QUESTMED",
        "zarValue": "R0.00",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:18",
        "qaCheck": "2021-05-17 11:44",
        "inventoryAdded ": "2021-05-17 11:18",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-05_06",
        "sponsor": "N/A",
        "study": "NORTH WEST UNIVERSITY",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "7052485543",
        "zarValue": "R1.00",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:36",
        "qaCheck": "2021-05-17 11:50",
        "inventoryAdded ": "2021-05-17 11:36",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-05_07",
        "sponsor": "ROCHE SA",
        "study": "ROCHE SA DONATION STOCK",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "DSV DELIVERY",
        "zarValue": "R29311.80",
        "receivingDate": "2021-05-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-05-17 11:44",
        "qaCheck": "2021-05-17 11:56",
        "inventoryAdded ": "2021-05-17 11:42",
        "sss": "2021-05-18 09:26"
    },
    {
        "id": "IN21-06_06",
        "sponsor": "CATALENT",
        "study": "WO42312",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60124326",
        "zarValue": "R0.00",
        "receivingDate": "2021-06-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-06-17 09:42",
        "qaCheck": "2021-06-17 09:02",
        "inventoryAdded ": "2021-06-17 09:42",
        "sss": "2021-06-17 09:43"
    },
    {
        "id": "IN16-06_01",
        "sponsor": "ALMAC",
        "study": "1199.93",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724 5118 6155",
        "zarValue": "R500000.00",
        "receivingDate": "2016-06-29                           ",
        "email": "2016-06-27 09:56",
        "confirmArrival": "2016-06-27 10:06",
        "qaCheck": "2016-06-27 10:07",
        "inventoryAdded ": "2016-06-27 10:08",
        "sss": "2016-06-27 10:09"
    },
    {
        "id": "IN16-06_02",
        "sponsor": "ALMAC",
        "study": "1199.93",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724 5118 6155",
        "zarValue": "R5565464654.00",
        "receivingDate": "2016-07-01                           ",
        "email": "2016-06-29 09:18",
        "confirmArrival": "2016-06-29 09:28",
        "qaCheck": "2016-06-29 09:28",
        "inventoryAdded ": "2016-06-29 09:29",
        "sss": "2016-06-29 09:41"
    },
    {
        "id": "IN16-07_01",
        "sponsor": "ALMAC",
        "study": "1199.128",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724 5118 6155",
        "zarValue": "R150000.00",
        "receivingDate": "2016-07-07                           ",
        "email": "2016-07-05 18:58",
        "confirmArrival": "2016-07-05 19:02",
        "qaCheck": "2016-07-05 19:02",
        "inventoryAdded ": "2016-07-05 19:03",
        "sss": "2016-07-05 19:04"
    },
    {
        "id": "IN16-07_02",
        "sponsor": "CATALENT",
        "study": "NV25719",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4816 7873",
        "zarValue": "R7641.00",
        "receivingDate": "2016-07-13                           ",
        "email": "2016-07-14 13:32",
        "confirmArrival": "2016-07-14 09:33",
        "qaCheck": "2016-07-15 08:44",
        "inventoryAdded ": "2016-07-14 16:11",
        "sss": "2016-08-02 10:05"
    },
    {
        "id": "IN16-07_03",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4816 8164",
        "zarValue": "R27775.00",
        "receivingDate": "2016-07-17                           ",
        "email": "2016-07-15 11:17",
        "confirmArrival": "2016-07-19 14:51",
        "qaCheck": "2016-07-19 14:53",
        "inventoryAdded ": "2016-07-19 14:53",
        "sss": "2016-08-02 10:06"
    },
    {
        "id": "IN16-07_04",
        "sponsor": "ALMAC",
        "study": "1218.22",
        "clearingAgent": "FelTra",
        "courierCoy": "SPEC TRANS",
        "mawb": "125 3144 1163",
        "zarValue": "R7770672.00",
        "receivingDate": "2016-07-21                           ",
        "email": "2016-08-02 10:06",
        "confirmArrival": "2016-07-21 16:11",
        "qaCheck": "2016-08-02 10:05",
        "inventoryAdded ": "2016-08-02 10:05",
        "sss": "2016-08-02 10:06"
    },
    {
        "id": "IN16-07_06",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QI LOGISTICS",
        "courierCoy": "SPX",
        "mawb": "177340012W",
        "zarValue": "R370479.00",
        "receivingDate": "2016-07-27                           ",
        "email": "2016-07-27 14:00",
        "confirmArrival": "2016-07-27 13:59",
        "qaCheck": "2016-07-27 14:00",
        "inventoryAdded ": "2016-08-02 10:04",
        "sss": "2016-08-02 10:04"
    },
    {
        "id": "IN16-08_01",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "0",
        "courierCoy": "0",
        "mawb": "724 5568 6805",
        "zarValue": "R160000.00",
        "receivingDate": "2016-08-01                           ",
        "email": "2016-08-01 15:52",
        "confirmArrival": "2016-08-01 15:53",
        "qaCheck": "2016-08-01 15:53",
        "inventoryAdded ": "2016-08-01 15:53",
        "sss": "2016-08-01 15:53"
    },
    {
        "id": "IN16-08_02",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4816 9262",
        "zarValue": "R27001.00",
        "receivingDate": "2016-08-02                           ",
        "email": "2016-08-02 09:38",
        "confirmArrival": "2016-08-02 09:38",
        "qaCheck": "2016-08-02 10:03",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2016-08-02 10:03"
    },
    {
        "id": "IN16-08_03",
        "sponsor": "ROCHE SA",
        "study": "Roche SA HQ",
        "clearingAgent": "0",
        "courierCoy": "0",
        "mawb": "724 4816 9855",
        "zarValue": "R180464.00",
        "receivingDate": "2016-08-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-08-11 10:55",
        "qaCheck": "2016-08-11 10:56",
        "inventoryAdded ": "2016-08-11 10:56",
        "sss": "2016-08-11 10:55"
    },
    {
        "id": "IN16-08_04",
        "sponsor": "CATALENT",
        "study": "GA29102",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "724 4816 9671",
        "zarValue": "R44272.00",
        "receivingDate": "2016-08-11                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-08-11 16:03",
        "qaCheck": "2016-08-11 16:04",
        "inventoryAdded ": "2016-10-05 08:25",
        "sss": "2016-10-05 08:36"
    },
    {
        "id": "IN16-08_06",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4817 0371",
        "zarValue": "R830556.00",
        "receivingDate": "2016-08-17                           ",
        "email": "2016-08-11 17:54",
        "confirmArrival": "2016-08-19 10:13",
        "qaCheck": "2016-08-19 10:15",
        "inventoryAdded ": "2016-08-22 11:34",
        "sss": "2016-08-22 11:41"
    },
    {
        "id": "IN16-08_07",
        "sponsor": "ALMAC",
        "study": "RPC01-3101",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "QuickSTAT",
        "mawb": "177530883W",
        "zarValue": "R50000.00",
        "receivingDate": "2016-08-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-08-15 14:25",
        "qaCheck": "2016-08-16 12:21",
        "inventoryAdded ": "2016-08-16 12:28",
        "sss": "2016-09-27 17:10"
    },
    {
        "id": "IN16-08_08",
        "sponsor": "CATALENT",
        "study": "NV20234",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4816 9995",
        "zarValue": "R10128.00",
        "receivingDate": "2016-08-17                           ",
        "email": "2016-08-17 16:20",
        "confirmArrival": "2016-08-17 09:25",
        "qaCheck": "2016-08-17 11:21",
        "inventoryAdded ": "2016-08-17 16:10",
        "sss": "2016-08-17 16:10"
    },
    {
        "id": "IN16-08_15",
        "sponsor": "ALMAC",
        "study": "D5136C00007",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "177670566W",
        "zarValue": "R106364.00",
        "receivingDate": "2016-08-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-08-31 15:36",
        "qaCheck": "2016-08-31 15:40",
        "inventoryAdded ": "2016-10-05 08:25",
        "sss": "2016-10-05 08:34"
    },
    {
        "id": "IN16-08_18",
        "sponsor": "ALMAC",
        "study": "RPC01-3102",
        "clearingAgent": "Seabourne ",
        "courierCoy": "Seabourne Express",
        "mawb": "811781959",
        "zarValue": "R31039.00",
        "receivingDate": "2016-08-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-08-31 15:27",
        "qaCheck": "2016-09-01 09:39",
        "inventoryAdded ": "2016-10-05 08:25",
        "sss": "2016-10-05 08:35"
    },
    {
        "id": "IN16-08_19",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "72448171141",
        "zarValue": "R260814.00",
        "receivingDate": "2016-08-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-08-31 15:47",
        "qaCheck": "2016-08-31 15:48",
        "inventoryAdded ": "2016-10-05 08:26",
        "sss": "2016-10-05 08:36"
    },
    {
        "id": "IN16-09_01",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X05981052",
        "zarValue": "R171809.00",
        "receivingDate": "2016-09-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-02 09:18",
        "qaCheck": "2016-09-06 10:37",
        "inventoryAdded ": "2016-10-05 08:28",
        "sss": "2017-01-17 14:48"
    },
    {
        "id": "IN16-09_02",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "TB00085690",
        "zarValue": "R202201.00",
        "receivingDate": "2016-09-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-02 09:27",
        "qaCheck": "2016-09-02 09:28",
        "inventoryAdded ": "2016-10-05 08:29",
        "sss": "2016-10-05 08:35"
    },
    {
        "id": "IN16-09_03",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72448172121",
        "zarValue": "R108737.00",
        "receivingDate": "2016-09-07                           ",
        "email": "2016-09-06 10:08",
        "confirmArrival": "2016-09-15 14:43",
        "qaCheck": "2016-09-15 14:44",
        "inventoryAdded ": "2016-10-05 08:33",
        "sss": "2016-12-09 16:47"
    },
    {
        "id": "IN16-09_04",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72448172110",
        "zarValue": "R822803.00",
        "receivingDate": "2016-09-07                           ",
        "email": "2016-09-06 09:53",
        "confirmArrival": "2016-09-13 16:00",
        "qaCheck": "2016-09-14 16:07",
        "inventoryAdded ": "2016-09-14 16:09",
        "sss": "2017-01-17 14:48"
    },
    {
        "id": "IN16-09_06",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72448172541",
        "zarValue": "R281487.00",
        "receivingDate": "2016-09-11                           ",
        "email": "2016-09-09 11:14",
        "confirmArrival": "2016-09-21 15:51",
        "qaCheck": "2016-09-21 15:53",
        "inventoryAdded ": "2016-10-05 08:34",
        "sss": "2017-01-17 14:48"
    },
    {
        "id": "IN16-09_07",
        "sponsor": "CATALENT",
        "study": "GO29646",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72448172736",
        "zarValue": "R446783.00",
        "receivingDate": "2016-09-15                           ",
        "email": "2016-09-12 16:06",
        "confirmArrival": "2016-09-16 11:36",
        "qaCheck": "2016-09-16 11:36",
        "inventoryAdded ": "2016-09-16 11:36",
        "sss": "2016-12-09 16:44"
    },
    {
        "id": "IN16-09_08",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 5568 7310",
        "zarValue": "R119868.00",
        "receivingDate": "2016-09-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-14 16:24",
        "qaCheck": "2016-09-14 16:24",
        "inventoryAdded ": "2016-09-16 11:33",
        "sss": "2016-09-27 17:04"
    },
    {
        "id": "IN16-09_09",
        "sponsor": "ALMAC",
        "study": "D589SC00003",
        "clearingAgent": "FelTra",
        "courierCoy": "FELTRA",
        "mawb": "17669170732",
        "zarValue": "R21034.00",
        "receivingDate": "2016-09-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-21 15:32",
        "qaCheck": "1970-01-01 00:00",
        "inventoryAdded ": "2016-09-21 16:22",
        "sss": "2016-09-21 16:22"
    },
    {
        "id": "IN16-09_10",
        "sponsor": "BILCARE - INDIA",
        "study": "IPD CLI 15 003",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "625X06083998",
        "zarValue": "R1000000.00",
        "receivingDate": "2016-09-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-22 16:20",
        "qaCheck": "2016-09-22 16:20",
        "inventoryAdded ": "2016-09-27 17:09",
        "sss": "2016-09-27 17:09"
    },
    {
        "id": "IN16-09_11",
        "sponsor": "CATALENT",
        "study": "MO25757",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 4817 3414",
        "zarValue": "R325283.49",
        "receivingDate": "2016-09-25                           ",
        "email": "2016-09-23 09:45",
        "confirmArrival": "2016-09-28 13:57",
        "qaCheck": "2016-09-28 13:57",
        "inventoryAdded ": "2016-09-28 13:57",
        "sss": "2017-01-17 14:48"
    },
    {
        "id": "IN16-09_12",
        "sponsor": "ALMAC",
        "study": "1160.189",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "177951066W",
        "zarValue": "R1781830.00",
        "receivingDate": "2016-09-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-26 12:29",
        "qaCheck": "2016-09-26 12:29",
        "inventoryAdded ": "2016-09-26 12:29",
        "sss": "2016-09-27 17:05"
    },
    {
        "id": "IN16-09_13",
        "sponsor": "CATALENT",
        "study": "WA29767",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72454369696",
        "zarValue": "R285314.00",
        "receivingDate": "2016-10-02                           ",
        "email": "2016-09-27 16:57",
        "confirmArrival": "2016-10-04 13:06",
        "qaCheck": "2016-10-04 13:07",
        "inventoryAdded ": "2016-10-04 13:08",
        "sss": "2016-10-04 13:12"
    },
    {
        "id": "IN16-09_14",
        "sponsor": "CATALENT",
        "study": "GO25632",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 5436 9685",
        "zarValue": "R185271.00",
        "receivingDate": "2016-10-02                           ",
        "email": "2016-09-27 16:58",
        "confirmArrival": "2016-10-04 13:13",
        "qaCheck": "2016-10-04 13:13",
        "inventoryAdded ": "2016-10-04 13:13",
        "sss": "2016-10-04 13:14"
    },
    {
        "id": "IN16-09_15",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "MARKEN",
        "mawb": "932 47976401",
        "zarValue": "R538197.00",
        "receivingDate": "2016-10-02                           ",
        "email": "2016-09-27 17:01",
        "confirmArrival": "2016-09-29 11:23",
        "qaCheck": "2016-09-29 11:23",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2016-09-29 12:22"
    },
    {
        "id": "IN16-09_16",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "Seabourne",
        "courierCoy": "SEABOURNE",
        "mawb": "605X06130116",
        "zarValue": "R149.00",
        "receivingDate": "2016-09-30                           ",
        "email": "2016-09-30 11:22",
        "confirmArrival": "2016-09-30 11:19",
        "qaCheck": "2016-09-30 11:19",
        "inventoryAdded ": "2016-09-30 11:19",
        "sss": "2016-09-30 11:23"
    },
    {
        "id": "IN16-09_17",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "SEABOURNE",
        "mawb": "632X06146308",
        "zarValue": "R75000.00",
        "receivingDate": "2016-09-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-09-30 12:28",
        "qaCheck": "2016-09-30 12:28",
        "inventoryAdded ": "2016-09-30 12:29",
        "sss": "2016-09-30 12:34"
    },
    {
        "id": "IN16-10_01",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 5437 0201",
        "zarValue": "R247579.00",
        "receivingDate": "2016-10-09                           ",
        "email": "2016-10-05 08:25",
        "confirmArrival": "2016-10-10 15:51",
        "qaCheck": "2016-10-10 15:51",
        "inventoryAdded ": "2016-10-10 16:06",
        "sss": "2016-10-10 16:07"
    },
    {
        "id": "IN16-10_02",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "DSV 31187359",
        "zarValue": "R205490.00",
        "receivingDate": "2016-10-05                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-05 14:43",
        "qaCheck": "2016-10-05 14:44",
        "inventoryAdded ": "2016-10-05 14:44",
        "sss": "2016-10-05 14:48"
    },
    {
        "id": "IN16-10_03",
        "sponsor": "SANOFI",
        "study": "LTS13463",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-48359754",
        "zarValue": "R5090960.00",
        "receivingDate": "2016-10-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-18 08:30",
        "qaCheck": "2016-10-19 10:23",
        "inventoryAdded ": "2016-10-19 10:24",
        "sss": "2016-10-19 10:27"
    },
    {
        "id": "IN16-10_04",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 0960",
        "zarValue": "R286375.00",
        "receivingDate": "2016-10-19                           ",
        "email": "2016-10-18 10:45",
        "confirmArrival": "2016-10-25 13:53",
        "qaCheck": "2016-10-25 13:54",
        "inventoryAdded ": "2016-10-25 13:56",
        "sss": "2016-12-09 16:39"
    },
    {
        "id": "IN16-10_07",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 1100",
        "zarValue": "R57661.70",
        "receivingDate": "2016-10-20                           ",
        "email": "2016-10-18 13:42",
        "confirmArrival": "2016-10-31 15:14",
        "qaCheck": "2016-10-31 15:14",
        "inventoryAdded ": "2016-10-31 15:14",
        "sss": "2016-10-31 15:21"
    },
    {
        "id": "IN16-10_08",
        "sponsor": "CATALENT",
        "study": "GO29646",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 1321",
        "zarValue": "R522710.10",
        "receivingDate": "2016-10-23                           ",
        "email": "2016-10-20 12:37",
        "confirmArrival": "2016-10-27 16:32",
        "qaCheck": "2016-11-08 10:53",
        "inventoryAdded ": "2016-11-08 10:54",
        "sss": "2016-12-09 16:45"
    },
    {
        "id": "IN16-10_09",
        "sponsor": "BILCARE - INDIA",
        "study": "IPD CLI 15 003",
        "clearingAgent": "None",
        "courierCoy": "RAM",
        "mawb": "DISDO2-00053635",
        "zarValue": "R5762.40",
        "receivingDate": "2016-10-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-24 10:11",
        "qaCheck": "2016-10-24 09:56",
        "inventoryAdded ": "2016-10-25 14:02",
        "sss": "2016-12-09 16:49"
    },
    {
        "id": "IN16-10_10",
        "sponsor": "ALMAC",
        "study": "1245.72",
        "clearingAgent": "FelTra",
        "courierCoy": "SPEC TRANS",
        "mawb": "700022860001",
        "zarValue": "R98258.10",
        "receivingDate": "2016-10-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-24 09:58",
        "qaCheck": "2016-10-24 09:58",
        "inventoryAdded ": "2016-10-25 14:06",
        "sss": "2016-12-09 16:41"
    },
    {
        "id": "IN16-10_12",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "600812517005",
        "zarValue": "R123294.00",
        "receivingDate": "2016-10-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-21 12:39",
        "qaCheck": "2016-10-24 09:57",
        "inventoryAdded ": "2016-10-25 14:00",
        "sss": "2016-12-09 16:43"
    },
    {
        "id": "IN16-10_13",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 1553",
        "zarValue": "R190781.00",
        "receivingDate": "2016-10-27                           ",
        "email": "2016-10-24 10:40",
        "confirmArrival": "2016-11-01 16:15",
        "qaCheck": "2016-11-08 11:00",
        "inventoryAdded ": "2016-11-08 11:00",
        "sss": "2016-12-09 16:47"
    },
    {
        "id": "IN16-10_14",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72456519422",
        "zarValue": "R407621.00",
        "receivingDate": "2016-10-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-24 16:07",
        "qaCheck": "2016-10-25 13:47",
        "inventoryAdded ": "2016-10-25 13:47",
        "sss": "2016-12-09 16:40"
    },
    {
        "id": "IN16-10_16",
        "sponsor": "CATALENT",
        "study": "GO29646",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5651 9481",
        "zarValue": "R624681.00",
        "receivingDate": "2016-10-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-11-25 15:37",
        "qaCheck": "2016-11-25 15:41",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-17 14:49"
    },
    {
        "id": "IN16-10_17",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X06277323",
        "zarValue": "R73730.50",
        "receivingDate": "2016-10-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-10-26 15:52",
        "qaCheck": "2016-10-26 15:52",
        "inventoryAdded ": "2016-10-26 15:52",
        "sss": "2016-10-26 15:54"
    },
    {
        "id": "IN16-11_01",
        "sponsor": "ALMAC",
        "study": "HGS1006-C1112",
        "clearingAgent": "SPX",
        "courierCoy": "World Courier",
        "mawb": "2728072",
        "zarValue": "R8326.05",
        "receivingDate": "2016-11-01                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-11-08 10:58",
        "qaCheck": "2016-11-08 10:59",
        "inventoryAdded ": "2016-11-08 10:59",
        "sss": "2016-12-09 16:49"
    },
    {
        "id": "IN16-11_02",
        "sponsor": "ALMAC",
        "study": "1218.22",
        "clearingAgent": "SPX",
        "courierCoy": "SPEC TRANS",
        "mawb": "125-31807182",
        "zarValue": "R13749.00",
        "receivingDate": "2016-11-03                           ",
        "email": "2016-11-03 17:04",
        "confirmArrival": "2016-11-03 17:02",
        "qaCheck": "2016-11-03 17:03",
        "inventoryAdded ": "2016-11-03 17:03",
        "sss": "2016-12-09 16:50"
    },
    {
        "id": "IN16-11_03",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "057-48770142",
        "zarValue": "R3096414.00",
        "receivingDate": "2016-11-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-11-04 15:50",
        "qaCheck": "2016-11-08 10:43",
        "inventoryAdded ": "2016-11-04 15:51",
        "sss": "2016-11-09 16:58"
    },
    {
        "id": "IN16-11_04",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 2662",
        "zarValue": "R109937.00",
        "receivingDate": "2016-11-08                           ",
        "email": "2016-11-07 10:14",
        "confirmArrival": "2016-11-14 12:14",
        "qaCheck": "2016-11-14 12:14",
        "inventoryAdded ": "2016-11-14 12:20",
        "sss": "2016-11-14 12:23"
    },
    {
        "id": "IN16-11_05",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "DSV PHARMA",
        "mawb": "31295914",
        "zarValue": "R4320.00",
        "receivingDate": "2016-11-09                           ",
        "email": "2016-11-09 16:21",
        "confirmArrival": "2016-11-09 16:19",
        "qaCheck": "2016-11-09 16:20",
        "inventoryAdded ": "2016-11-09 16:20",
        "sss": "2016-11-09 16:57"
    },
    {
        "id": "IN16-11_06",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "RAM",
        "mawb": "DISD02-00054490",
        "zarValue": "R205.20",
        "receivingDate": "2016-11-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-11-14 11:53",
        "qaCheck": "2016-11-14 11:54",
        "inventoryAdded ": "2016-11-14 11:54",
        "sss": "2016-12-09 16:48"
    },
    {
        "id": "IN16-11_07",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5437 3270",
        "zarValue": "R62067.07",
        "receivingDate": "2016-11-16                           ",
        "email": "2016-11-14 12:46",
        "confirmArrival": "2016-11-21 11:07",
        "qaCheck": "2016-11-21 11:07",
        "inventoryAdded ": "2016-11-21 11:07",
        "sss": "2016-11-21 11:09"
    },
    {
        "id": "IN16-11_10",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-5689 0912",
        "zarValue": "R888242.00",
        "receivingDate": "2016-11-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-11-28 13:41",
        "qaCheck": "2016-11-28 13:41",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-17 14:49"
    },
    {
        "id": "IN16-11_11",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "600836153003",
        "zarValue": "R140555.16",
        "receivingDate": "2016-11-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-11-22 15:36",
        "qaCheck": "2016-11-23 14:29",
        "inventoryAdded ": "2016-11-23 14:29",
        "sss": "2016-12-09 16:43"
    },
    {
        "id": "IN16-12_01",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 5689 2242",
        "zarValue": "R155684.00",
        "receivingDate": "2016-12-07                           ",
        "email": "2016-12-05 10:28",
        "confirmArrival": "2016-12-19 09:45",
        "qaCheck": "2016-12-19 09:44",
        "inventoryAdded ": "2016-12-19 09:45",
        "sss": "2016-12-19 09:45"
    },
    {
        "id": "IN16-12_02",
        "sponsor": "CATALENT",
        "study": "GA29102",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 5689 2301",
        "zarValue": "R28268.00",
        "receivingDate": "2016-12-08                           ",
        "email": "2016-12-05 10:41",
        "confirmArrival": "2016-12-15 14:04",
        "qaCheck": "2016-12-15 14:04",
        "inventoryAdded ": "2016-12-15 14:04",
        "sss": "2016-12-15 14:05"
    },
    {
        "id": "IN16-12_03",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "600845842005",
        "zarValue": "R250857.60",
        "receivingDate": "2016-12-06                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-12-06 08:45",
        "qaCheck": "2016-12-06 08:46",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-17 14:50"
    },
    {
        "id": "IN16-12_04",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X06472014",
        "zarValue": "R117100.00",
        "receivingDate": "2016-12-12                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2016-12-12 15:58",
        "qaCheck": "2016-12-14 10:35",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2017-01-17 14:50"
    },
    {
        "id": "IN16-12_05",
        "sponsor": "CATALENT",
        "study": "GA29103",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 5689 1284",
        "zarValue": "R44189.00",
        "receivingDate": "2016-12-20                           ",
        "email": "2016-12-20 16:05",
        "confirmArrival": "2016-12-20 16:05",
        "qaCheck": "2016-12-20 16:05",
        "inventoryAdded ": "2016-12-20 16:05",
        "sss": "2016-12-20 16:09"
    },
    {
        "id": "IN16-12_06",
        "sponsor": "ALMAC",
        "study": "1218.22",
        "clearingAgent": "FelTra",
        "courierCoy": "FELTRA",
        "mawb": "083-22233562",
        "zarValue": "R83734.00",
        "receivingDate": "2016-12-21                           ",
        "email": "2016-12-21 14:56",
        "confirmArrival": "2016-12-21 14:55",
        "qaCheck": "2016-12-21 14:55",
        "inventoryAdded ": "2016-12-21 14:55",
        "sss": "2016-12-21 14:56"
    },
    {
        "id": "IN17-01_02",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "MWAB 724-5689 3804",
        "zarValue": "R47097.00",
        "receivingDate": "2017-01-03                           ",
        "email": "2017-01-03 09:23",
        "confirmArrival": "2017-01-06 12:14",
        "qaCheck": "2017-01-06 12:14",
        "inventoryAdded ": "2017-01-06 12:14",
        "sss": "2017-01-06 12:21"
    },
    {
        "id": "IN17-02_09",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632X06803643",
        "zarValue": "R1701.92",
        "receivingDate": "2017-02-23                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-02-23 16:07",
        "qaCheck": "2017-02-23 16:07",
        "inventoryAdded ": "2017-03-01 09:28",
        "sss": "2017-03-01 09:29"
    },
    {
        "id": "IN17-03_06",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "0",
        "courierCoy": "Dischem",
        "mawb": "I0005634",
        "zarValue": "R273.60",
        "receivingDate": "2017-03-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-03-16 16:02",
        "qaCheck": "2017-03-16 16:03",
        "inventoryAdded ": "2017-03-16 16:04",
        "sss": "2017-03-16 16:08"
    },
    {
        "id": "IN17-04_01",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "None",
        "courierCoy": "DSV PHARMA",
        "mawb": "600932815006",
        "zarValue": "R29304.00",
        "receivingDate": "2017-04-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-04-04 16:07",
        "qaCheck": "2017-04-04 16:07",
        "inventoryAdded ": "2017-04-04 16:07",
        "sss": "2017-04-04 16:09"
    },
    {
        "id": "IN17-04_05",
        "sponsor": "CATALENT",
        "study": "BH29992",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-1111 0326",
        "zarValue": "R341557.00",
        "receivingDate": "2017-04-13                           ",
        "email": "2017-04-10 15:59",
        "confirmArrival": "2017-04-18 16:17",
        "qaCheck": "2017-04-19 08:21",
        "inventoryAdded ": "2017-04-19 08:30",
        "sss": "2017-04-19 08:32"
    },
    {
        "id": "IN17-06_03",
        "sponsor": "CATALENT",
        "study": "GB39242",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-59196023",
        "zarValue": "R344445.00",
        "receivingDate": "2017-06-09                           ",
        "email": "2017-06-08 15:38",
        "confirmArrival": "2017-06-22 16:06",
        "qaCheck": "2017-06-27 10:23",
        "inventoryAdded ": "2017-06-27 11:19",
        "sss": "2017-06-27 11:22"
    },
    {
        "id": "IN17-06_13",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-56562435",
        "zarValue": "R16984.00",
        "receivingDate": "2017-06-19                           ",
        "email": "2017-06-19 12:52",
        "confirmArrival": "2017-06-19 16:10",
        "qaCheck": "2017-06-20 09:46",
        "inventoryAdded ": "2017-06-20 09:46",
        "sss": "2017-06-20 09:47"
    },
    {
        "id": "IN17-06_19",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "INV9750247",
        "zarValue": "R1714.20",
        "receivingDate": "2017-06-22                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-06-22 15:10",
        "qaCheck": "2017-06-22 15:10",
        "inventoryAdded ": "2017-06-22 15:10",
        "sss": "2017-06-22 15:11"
    },
    {
        "id": "IN17-07_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "SEABOURNE",
        "mawb": "632X07416298",
        "zarValue": "R48283.00",
        "receivingDate": "2017-07-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-07-07 16:57",
        "qaCheck": "2017-07-07 16:58",
        "inventoryAdded ": "2017-07-07 16:58",
        "sss": "2017-07-07 16:59"
    },
    {
        "id": "IN17-08_07",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6234 3702",
        "zarValue": "R294800.00",
        "receivingDate": "2017-08-15                           ",
        "email": "2017-08-10 09:02",
        "confirmArrival": "2017-08-16 15:54",
        "qaCheck": "2017-08-17 15:26",
        "inventoryAdded ": "2017-08-17 15:26",
        "sss": "2017-08-17 15:26"
    },
    {
        "id": "IN17-08_09",
        "sponsor": "CATALENT",
        "study": "BH29884",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6041 3581",
        "zarValue": "R492770.00",
        "receivingDate": "2017-08-16                           ",
        "email": "2017-08-14 09:36",
        "confirmArrival": "2017-08-16 15:58",
        "qaCheck": "2017-08-17 15:27",
        "inventoryAdded ": "2017-08-17 15:27",
        "sss": "2017-08-17 15:27"
    },
    {
        "id": "IN17-08_12",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "Seabourne ",
        "courierCoy": "QuickSTAT",
        "mawb": "181210015W",
        "zarValue": "R15925.40",
        "receivingDate": "2017-08-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-08-15 16:07",
        "qaCheck": "2017-08-16 08:28",
        "inventoryAdded ": "2017-08-16 08:28",
        "sss": "2017-08-16 09:26"
    },
    {
        "id": "IN17-09_13",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-62345032",
        "zarValue": "R942451.00",
        "receivingDate": "2017-10-04                           ",
        "email": "2017-09-27 15:15",
        "confirmArrival": "2017-10-04 16:26",
        "qaCheck": "2017-10-09 16:05",
        "inventoryAdded ": "2017-10-09 16:05",
        "sss": "2017-10-09 16:06"
    },
    {
        "id": "IN17-09_14",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "7703 0276 4842",
        "zarValue": "R0.00",
        "receivingDate": "2017-09-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-09-27 16:03",
        "qaCheck": "2017-09-27 16:03",
        "inventoryAdded ": "2017-09-27 16:03",
        "sss": "2017-09-27 16:04"
    },
    {
        "id": "IN17-10_01",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "72462345172",
        "zarValue": "R1407089.41",
        "receivingDate": "2017-10-07                           ",
        "email": "2017-10-02 14:56",
        "confirmArrival": "2017-10-09 16:03",
        "qaCheck": "2017-10-10 15:25",
        "inventoryAdded ": "2017-10-10 15:25",
        "sss": "2017-10-10 15:33"
    },
    {
        "id": "IN17-10_02",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-6058 5862",
        "zarValue": "R853504.00",
        "receivingDate": "2017-10-04                           ",
        "email": "2017-10-03 13:23",
        "confirmArrival": "2017-10-09 16:05",
        "qaCheck": "2017-10-10 15:24",
        "inventoryAdded ": "2017-10-10 15:24",
        "sss": "2017-10-10 15:25"
    },
    {
        "id": "IN17-10_03",
        "sponsor": "SANOFI",
        "study": "LTS12551",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 6249 9135",
        "zarValue": "R1390620.00",
        "receivingDate": "2017-10-03                           ",
        "email": "2017-10-03 15:53",
        "confirmArrival": "2017-10-04 16:23",
        "qaCheck": "2017-10-05 10:14",
        "inventoryAdded ": "2017-10-05 10:14",
        "sss": "2017-10-05 10:15"
    },
    {
        "id": "IN17-10_04",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "632x07838499",
        "zarValue": "R76439.70",
        "receivingDate": "2017-10-03                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-03 16:00",
        "qaCheck": "2017-10-04 16:07",
        "inventoryAdded ": "2017-10-04 16:08",
        "sss": "2017-10-04 16:10"
    },
    {
        "id": "IN17-10_13",
        "sponsor": "ALMAC",
        "study": "3HP-AUR1-1-170 STUDY",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "601076979004",
        "zarValue": "R26250.00",
        "receivingDate": "2017-10-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-10-25 14:19",
        "qaCheck": "2017-10-25 14:19",
        "inventoryAdded ": "2017-10-25 14:19",
        "sss": "2017-10-25 14:20"
    },
    {
        "id": "IN17-10_19",
        "sponsor": "CATALENT",
        "study": "BN29553",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-63155805",
        "zarValue": "R1621180.00",
        "receivingDate": "2017-11-05                           ",
        "email": "2017-10-30 10:47",
        "confirmArrival": "2017-11-07 16:07",
        "qaCheck": "2017-11-08 19:27",
        "inventoryAdded ": "2017-11-11 12:40",
        "sss": "2017-11-11 12:40"
    },
    {
        "id": "IN17-10_20",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72463155363",
        "zarValue": "R501263.00",
        "receivingDate": "2017-10-30                           ",
        "email": "2017-10-30 14:32",
        "confirmArrival": "2017-11-09 16:11",
        "qaCheck": "2017-11-11 12:41",
        "inventoryAdded ": "2017-11-11 12:41",
        "sss": "2017-11-11 12:41"
    },
    {
        "id": "IN17-11_07",
        "sponsor": "CATALENT",
        "study": "MA29957",
        "clearingAgent": "DSV",
        "courierCoy": "DSV",
        "mawb": "72462345566",
        "zarValue": "R155488.00",
        "receivingDate": "2017-11-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-09 16:32",
        "qaCheck": "2017-11-11 12:51",
        "inventoryAdded ": "2017-11-11 12:52",
        "sss": "2017-11-11 12:52"
    },
    {
        "id": "IN17-11_13",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "770704768473 770653792598",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-16 16:01",
        "qaCheck": "2017-11-16 16:01",
        "inventoryAdded ": "2017-11-16 16:01",
        "sss": "2017-11-16 16:02"
    },
    {
        "id": "IN17-11_14",
        "sponsor": "ALMAC",
        "study": "1245.110",
        "clearingAgent": "QUICKSTAT",
        "courierCoy": "KEI Road Transport",
        "mawb": "182140334W",
        "zarValue": "R270461.00",
        "receivingDate": "2017-11-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-16 16:06",
        "qaCheck": "2017-11-16 16:10",
        "inventoryAdded ": "2017-11-16 16:10",
        "sss": "2017-11-16 16:13"
    },
    {
        "id": "IN17-11_15",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "05761499185",
        "zarValue": "R54459.20",
        "receivingDate": "2017-11-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-21 16:15",
        "qaCheck": "2017-11-22 13:47",
        "inventoryAdded ": "2017-11-24 15:03",
        "sss": "2017-11-24 15:04"
    },
    {
        "id": "IN17-11_27",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "N/A",
        "courierCoy": "Morne de Bruin",
        "mawb": "None",
        "zarValue": "R0.00",
        "receivingDate": "2017-11-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2017-11-27 16:04",
        "qaCheck": "2017-11-27 16:04",
        "inventoryAdded ": "2017-11-27 16:04",
        "sss": "2017-11-27 16:07"
    },
    {
        "id": "IN18-01_02",
        "sponsor": "SANOFI",
        "study": "EFC14153",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "724 6249 9603",
        "zarValue": "R268171.00",
        "receivingDate": "2018-01-08                           ",
        "email": "2018-01-08 12:18",
        "confirmArrival": "2018-01-08 16:24",
        "qaCheck": "2018-01-09 11:52",
        "inventoryAdded ": "2018-01-09 11:52",
        "sss": "2018-01-09 11:53"
    },
    {
        "id": "IN18-01_04",
        "sponsor": "CATALENT",
        "study": "MO29594",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6059 3956",
        "zarValue": "R121508.00",
        "receivingDate": "2018-01-08                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-08 16:29",
        "qaCheck": "2018-01-11 10:41",
        "inventoryAdded ": "2018-01-11 11:00",
        "sss": "2018-01-11 11:02"
    },
    {
        "id": "IN18-01_31",
        "sponsor": "The Aurum Institute ",
        "study": "3HP-AUR1-1-170",
        "clearingAgent": "None",
        "courierCoy": "Transpharm",
        "mawb": "INV11236509",
        "zarValue": "R977.09",
        "receivingDate": "2018-01-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-01-31 13:16",
        "qaCheck": "2018-01-31 13:16",
        "inventoryAdded ": "2018-01-31 13:17",
        "sss": "2018-01-31 13:22"
    },
    {
        "id": "IN18-03_04",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-64107385",
        "zarValue": "R114890.00",
        "receivingDate": "2018-03-19                           ",
        "email": "2018-03-16 09:51",
        "confirmArrival": "2018-03-27 13:38",
        "qaCheck": "2018-03-27 13:38",
        "inventoryAdded ": "2018-04-03 12:08",
        "sss": "2018-04-03 12:10"
    },
    {
        "id": "IN18-03_07",
        "sponsor": "PowerVision",
        "study": "ORION",
        "clearingAgent": "POWERVISION",
        "courierCoy": "Fedex",
        "mawb": "771780421041",
        "zarValue": "R234.06",
        "receivingDate": "2018-03-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-03-26 12:51",
        "qaCheck": "2018-03-26 12:51",
        "inventoryAdded ": "2018-03-26 12:51",
        "sss": "2018-03-26 12:54"
    },
    {
        "id": "IN18-04_06",
        "sponsor": "CATALENT",
        "study": "WO29479",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724 6410 8866",
        "zarValue": "R571550.00",
        "receivingDate": "2018-04-12                           ",
        "email": "2018-04-10 14:27",
        "confirmArrival": "2018-04-16 11:32",
        "qaCheck": "2018-04-16 12:04",
        "inventoryAdded ": "2018-04-16 12:04",
        "sss": "2018-04-16 12:05"
    },
    {
        "id": "IN18-04_22",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2825762",
        "zarValue": "R121250.00",
        "receivingDate": "2018-04-26                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-02 16:00",
        "qaCheck": "2018-05-04 08:45",
        "inventoryAdded ": "2018-05-04 08:45",
        "sss": "2018-05-04 09:02"
    },
    {
        "id": "IN18-05_18",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2830270",
        "zarValue": "R124377.00",
        "receivingDate": "2018-05-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-05-24 16:15",
        "qaCheck": "2018-05-25 12:25",
        "inventoryAdded ": "2018-05-25 12:19",
        "sss": "2018-05-25 12:25"
    },
    {
        "id": "IN18-06_03",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "742-6691 8751",
        "zarValue": "R78464.18",
        "receivingDate": "2018-06-10                           ",
        "email": "2018-06-08 14:06",
        "confirmArrival": "2018-06-13 16:07",
        "qaCheck": "2018-06-18 15:34",
        "inventoryAdded ": "2018-06-14 10:35",
        "sss": "2018-06-20 09:13"
    },
    {
        "id": "IN18-06_10",
        "sponsor": "SANOFI",
        "study": "DFI14223 STUDY",
        "clearingAgent": "DHL Global",
        "courierCoy": "MARKEN",
        "mawb": "057-67160811",
        "zarValue": "R164437.00",
        "receivingDate": "2018-06-15                           ",
        "email": "2018-06-13 14:38",
        "confirmArrival": "2018-06-15 11:16",
        "qaCheck": "2018-06-18 15:35",
        "inventoryAdded ": "2018-06-15 13:46",
        "sss": "2018-06-26 13:56"
    },
    {
        "id": "IN18-06_13",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV PHARMA",
        "courierCoy": "DSV PHARMA",
        "mawb": "32670960",
        "zarValue": "R177075.90",
        "receivingDate": "2018-06-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-06-19 16:14",
        "qaCheck": "2018-06-28 11:37",
        "inventoryAdded ": "2018-06-22 15:18",
        "sss": "2018-06-28 11:48"
    },
    {
        "id": "IN18-07_04",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "724-6702 8710",
        "zarValue": "R99402.75",
        "receivingDate": "2018-07-04                           ",
        "email": "2018-07-03 10:33",
        "confirmArrival": "2018-07-05 16:19",
        "qaCheck": "2018-07-12 21:26",
        "inventoryAdded ": "2018-07-06 10:41",
        "sss": "2018-07-17 13:30"
    },
    {
        "id": "IN18-07_05",
        "sponsor": "SANOFI",
        "study": "EFC14837",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "083-9070-9920",
        "zarValue": "R336965.00",
        "receivingDate": "2018-07-04                           ",
        "email": "2018-07-03 10:48",
        "confirmArrival": "2018-07-05 16:19",
        "qaCheck": "2018-07-16 12:56",
        "inventoryAdded ": "2018-07-05 16:20",
        "sss": "2018-07-16 12:57"
    },
    {
        "id": "IN18-07_11",
        "sponsor": "BIOCAIR",
        "study": "Q203-TB-P2-ZA001",
        "clearingAgent": "0",
        "courierCoy": "BIOCAIR",
        "mawb": "40114999",
        "zarValue": "R1501.34",
        "receivingDate": "2018-07-09                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-07-09 16:19",
        "qaCheck": "2018-07-17 21:33",
        "inventoryAdded ": "2018-07-12 09:40",
        "sss": "2018-07-23 10:29"
    },
    {
        "id": "IN18-08_02",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-6703 2125",
        "zarValue": "R717434.00",
        "receivingDate": "2018-08-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 15:02",
        "qaCheck": "2018-08-20 12:06",
        "inventoryAdded ": "2018-08-16 15:07",
        "sss": "2018-08-20 12:16"
    },
    {
        "id": "IN18-08_04",
        "sponsor": "MARKEN",
        "study": "17-AVP-786-305",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "606X09404081",
        "zarValue": "R303558.00",
        "receivingDate": "2018-08-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-13 09:16",
        "qaCheck": "2018-08-13 16:10",
        "inventoryAdded ": "2018-08-13 09:18",
        "sss": "2018-08-13 16:21"
    },
    {
        "id": "IN18-08_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2843982",
        "zarValue": "R238480.00",
        "receivingDate": "2018-08-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 14:33",
        "qaCheck": "2018-08-21 13:30",
        "inventoryAdded ": "2018-08-16 15:02",
        "sss": "2018-08-23 11:22"
    },
    {
        "id": "IN18-08_08",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "932-54529285",
        "zarValue": "R72437.00",
        "receivingDate": "2018-08-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 15:29",
        "qaCheck": "2018-08-20 12:07",
        "inventoryAdded ": "2018-08-16 15:30",
        "sss": "2018-08-20 12:15"
    },
    {
        "id": "IN18-08_09",
        "sponsor": "Triclinium",
        "study": "AUR1-8-178",
        "clearingAgent": "SPX",
        "courierCoy": "BIOCAIR",
        "mawb": "72468730793",
        "zarValue": "R10756.90",
        "receivingDate": "2018-08-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-08-16 15:47",
        "qaCheck": "2018-08-22 11:05",
        "inventoryAdded ": "2018-08-16 15:47",
        "sss": "2018-08-23 11:22"
    },
    {
        "id": "IN18-10_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "08338710980",
        "zarValue": "R115219.00",
        "receivingDate": "2018-10-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-04 09:16",
        "qaCheck": "2018-10-04 09:34",
        "inventoryAdded ": "2018-10-04 09:16",
        "sss": "2018-10-04 10:39"
    },
    {
        "id": "IN18-10_10",
        "sponsor": "ROCHE SA",
        "study": "NHL001SA",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "DSV",
        "zarValue": "R177075.90",
        "receivingDate": "2018-10-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-10-16 14:23",
        "qaCheck": "2018-10-30 10:46",
        "inventoryAdded ": "2018-10-17 11:16",
        "sss": "2018-10-30 11:19"
    },
    {
        "id": "IN18-11_03",
        "sponsor": "PowerVision",
        "study": "CLEAR STUDY",
        "clearingAgent": "Fedex",
        "courierCoy": "Fedex",
        "mawb": "773650854328",
        "zarValue": "R22811.00",
        "receivingDate": "2018-11-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2018-11-16 09:20",
        "qaCheck": "2018-11-21 11:14",
        "inventoryAdded ": "2018-11-16 09:20",
        "sss": "2018-11-22 12:26"
    },
    {
        "id": "IN18-12_01",
        "sponsor": "SANOFI",
        "study": "TDR14311",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "057-80519375",
        "zarValue": "R15021.00",
        "receivingDate": "2018-12-07                           ",
        "email": "2018-12-05 09:30",
        "confirmArrival": "2018-12-11 16:13",
        "qaCheck": "2018-12-14 14:16",
        "inventoryAdded ": "2018-12-11 16:13",
        "sss": "2018-12-14 14:22"
    },
    {
        "id": "IN18-12_07",
        "sponsor": "CATALENT",
        "study": "BN29553",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "72400244086",
        "zarValue": "R58819.90",
        "receivingDate": "2018-12-14                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-01-16 14:23",
        "qaCheck": "2019-01-21 13:51",
        "inventoryAdded ": "2019-01-16 14:23",
        "sss": "2019-01-28 09:42"
    },
    {
        "id": "IN19-01_02",
        "sponsor": "CATALENT",
        "study": "WO30070",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0253 3506",
        "zarValue": "R78260.03",
        "receivingDate": "2019-01-09                           ",
        "email": "2019-01-07 10:46",
        "confirmArrival": "2019-01-10 16:11",
        "qaCheck": "2019-01-17 12:46",
        "inventoryAdded ": "2019-01-11 10:17",
        "sss": "2019-01-28 09:43"
    },
    {
        "id": "IN19-01_03",
        "sponsor": "CATALENT",
        "study": "BO40336",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-02533554",
        "zarValue": "R136444.31",
        "receivingDate": "2019-01-07                           ",
        "email": "2019-01-07 15:32",
        "confirmArrival": "2019-01-10 16:17",
        "qaCheck": "2019-01-14 11:03",
        "inventoryAdded ": "2019-01-11 10:04",
        "sss": "2019-01-15 11:55"
    },
    {
        "id": "IN19-01_11",
        "sponsor": "CATALENT",
        "study": "WA21092",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0253 3790",
        "zarValue": "R89893.00",
        "receivingDate": "2019-01-21                           ",
        "email": "2019-01-16 15:23",
        "confirmArrival": "2019-01-31 16:02",
        "qaCheck": "2019-02-04 11:31",
        "inventoryAdded ": "2019-02-01 10:43",
        "sss": "2019-02-04 12:25"
    },
    {
        "id": "IN19-01_16",
        "sponsor": "CATALENT",
        "study": "MO39193",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0349 6426",
        "zarValue": "R175530.59",
        "receivingDate": "2019-02-04                           ",
        "email": "2019-01-31 09:02",
        "confirmArrival": "2019-02-07 16:15",
        "qaCheck": "2019-02-08 11:04",
        "inventoryAdded ": "2019-02-07 16:15",
        "sss": "2019-02-08 11:18"
    },
    {
        "id": "IN19-01_17",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "083-39494350",
        "zarValue": "R138256.00",
        "receivingDate": "2019-01-31                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-01-31 16:06",
        "qaCheck": "2019-02-05 11:08",
        "inventoryAdded ": "2019-02-01 11:07",
        "sss": "2019-02-05 13:40"
    },
    {
        "id": "IN19-02_10",
        "sponsor": "Bayer",
        "study": "16953 - Asteroid 6",
        "clearingAgent": "Pharmacy",
        "courierCoy": "Pharmacy",
        "mawb": "PHARMACY",
        "zarValue": "R2530.53",
        "receivingDate": "2019-02-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-02-21 15:16",
        "qaCheck": "2019-02-27 11:34",
        "inventoryAdded ": "2019-02-21 15:16",
        "sss": "2019-02-27 14:42"
    },
    {
        "id": "IN19-03_01",
        "sponsor": "CATALENT",
        "study": "MO39196",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0349 6835",
        "zarValue": "R79232.49",
        "receivingDate": "2019-03-08                           ",
        "email": "2019-03-04 14:49",
        "confirmArrival": "2019-03-13 16:00",
        "qaCheck": "2019-03-14 11:49",
        "inventoryAdded ": "2019-03-14 08:53",
        "sss": "2019-03-14 12:11"
    },
    {
        "id": "IN19-03_02",
        "sponsor": "CATALENT",
        "study": "MO39196",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-0349 6846",
        "zarValue": "R8698278.57",
        "receivingDate": "2019-03-08                           ",
        "email": "2019-03-04 14:49",
        "confirmArrival": "2019-03-13 16:00",
        "qaCheck": "2019-03-14 12:11",
        "inventoryAdded ": "2019-03-14 08:53",
        "sss": "2019-03-14 12:12"
    },
    {
        "id": "IN19-03_09",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2894960",
        "zarValue": "R27154.00",
        "receivingDate": "2019-03-26                           ",
        "email": "2019-03-25 09:49",
        "confirmArrival": "2019-03-27 16:06",
        "qaCheck": "2019-04-01 13:18",
        "inventoryAdded ": "2019-03-28 11:00",
        "sss": "2019-04-01 13:43"
    },
    {
        "id": "IN19-03_13",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2893904",
        "zarValue": "R50756.00",
        "receivingDate": "2019-03-27                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-03-27 16:12",
        "qaCheck": "2019-04-01 12:48",
        "inventoryAdded ": "2019-03-27 16:13",
        "sss": "2019-04-01 13:43"
    },
    {
        "id": "IN19-04_11",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2894769",
        "zarValue": "R80555.00",
        "receivingDate": "2019-04-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-04-16 11:56",
        "qaCheck": "2019-04-17 09:36",
        "inventoryAdded ": "2019-04-16 12:03",
        "sss": "2019-04-17 10:16"
    },
    {
        "id": "IN19-05_07",
        "sponsor": "CATALENT",
        "study": "DAL-301",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "636X10872839",
        "zarValue": "R353188.00",
        "receivingDate": "2019-05-17                           ",
        "email": "2019-05-07 09:33",
        "confirmArrival": "2019-05-13 16:01",
        "qaCheck": "2019-05-13 16:10",
        "inventoryAdded ": "2019-05-14 12:17",
        "sss": "2019-05-14 12:20"
    },
    {
        "id": "IN19-07_07",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "932-56869982",
        "zarValue": "R138843.00",
        "receivingDate": "2019-07-15                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-07-15 16:16",
        "qaCheck": "2019-07-18 16:06",
        "inventoryAdded ": "2019-07-19 14:54",
        "sss": "2019-07-25 09:04"
    },
    {
        "id": "IN19-07_08",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2920987",
        "zarValue": "R83391.70",
        "receivingDate": "2019-07-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-07-16 16:26",
        "qaCheck": "2019-07-23 12:15",
        "inventoryAdded ": "2019-07-24 08:31",
        "sss": "2019-07-24 15:43"
    },
    {
        "id": "IN19-07_12",
        "sponsor": "CATALENT",
        "study": "AUR-VCS-2016-01",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "615x11324527",
        "zarValue": "R21382.70",
        "receivingDate": "2019-07-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-07-29 15:44",
        "qaCheck": "2019-07-29 15:45",
        "inventoryAdded ": "2019-08-02 12:05",
        "sss": "2019-08-02 12:07"
    },
    {
        "id": "IN19-07_13",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0014",
        "clearingAgent": "Seabourne",
        "courierCoy": "World Courier",
        "mawb": "2925556",
        "zarValue": "R86050.90",
        "receivingDate": "2019-08-02                           ",
        "email": "2019-07-29 16:20",
        "confirmArrival": "2019-07-31 16:19",
        "qaCheck": "2019-08-02 13:13",
        "inventoryAdded ": "2019-08-02 13:14",
        "sss": "2019-08-02 13:16"
    },
    {
        "id": "IN19-07_14",
        "sponsor": "ROCHE SA",
        "study": "MV40618",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "ROC1998",
        "zarValue": "R0.00",
        "receivingDate": "2019-07-29                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-07-29 16:33",
        "qaCheck": "2019-08-02 12:19",
        "inventoryAdded ": "2019-08-02 12:20",
        "sss": "2019-08-02 12:48"
    },
    {
        "id": "IN19-08_02",
        "sponsor": "SANOFI",
        "study": "EFC14153",
        "clearingAgent": "DHL Global",
        "courierCoy": "DHL",
        "mawb": "125-6165-5823",
        "zarValue": "R382677.00",
        "receivingDate": "2019-08-07                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2019-08-07 16:03",
        "qaCheck": "2019-08-14 13:13",
        "inventoryAdded ": "2019-08-08 14:35",
        "sss": "2019-08-14 13:14"
    },
    {
        "id": "IN19-08_07",
        "sponsor": "ROCHE SA",
        "study": "AG40661",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-7274 2714",
        "zarValue": "R300685.00",
        "receivingDate": "2019-08-21                           ",
        "email": "2019-08-16 12:19",
        "confirmArrival": "2019-08-20 16:06",
        "qaCheck": "2019-08-22 10:04",
        "inventoryAdded ": "2019-08-22 10:31",
        "sss": "2019-08-22 10:33"
    },
    {
        "id": "IN19-08_13",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "724-0181 5332",
        "zarValue": "R3678838.55",
        "receivingDate": "2019-08-30                           ",
        "email": "2019-08-27 15:57",
        "confirmArrival": "2019-09-02 15:16",
        "qaCheck": "2019-09-03 13:25",
        "inventoryAdded ": "2019-09-03 13:29",
        "sss": "2019-09-03 13:29"
    },
    {
        "id": "IN19-09_01",
        "sponsor": "CATALENT",
        "study": "GA29145",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-72742865",
        "zarValue": "R97680.20",
        "receivingDate": "2019-09-06                           ",
        "email": "2019-09-02 12:56",
        "confirmArrival": "2019-09-10 16:18",
        "qaCheck": "2019-09-11 08:30",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2019-09-11 12:13"
    },
    {
        "id": "IN19-09_03",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17672742935",
        "zarValue": "R11909.20",
        "receivingDate": "2019-09-09                           ",
        "email": "2019-09-03 10:50",
        "confirmArrival": "2019-09-10 16:17",
        "qaCheck": "2019-09-11 10:53",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2019-09-11 12:14"
    },
    {
        "id": "IN19-09_06",
        "sponsor": "CATALENT",
        "study": "GA28951",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17672742994",
        "zarValue": "R135597.00",
        "receivingDate": "2019-09-09                           ",
        "email": "2019-09-05 13:10",
        "confirmArrival": "2019-09-11 12:59",
        "qaCheck": "2019-09-13 14:26",
        "inventoryAdded ": "2019-09-13 14:26",
        "sss": "2019-09-16 08:56"
    },
    {
        "id": "IN19-09_21",
        "sponsor": "CATALENT",
        "study": "BO40336",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-7274 3263",
        "zarValue": "R45279.59",
        "receivingDate": "2019-10-04                           ",
        "email": "2019-09-25 12:31",
        "confirmArrival": "2019-09-30 16:25",
        "qaCheck": "2019-09-30 16:25",
        "inventoryAdded ": "2019-10-01 09:36",
        "sss": "2019-10-01 11:35"
    },
    {
        "id": "IN19-10_02",
        "sponsor": "SANOFI",
        "study": "MET 55",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X11761910",
        "zarValue": "R514326.00",
        "receivingDate": "2019-10-07                           ",
        "email": "2019-10-04 09:16",
        "confirmArrival": "2019-10-08 16:17",
        "qaCheck": "2019-10-14 16:20",
        "inventoryAdded ": "2019-10-10 10:56",
        "sss": "2019-10-14 16:21"
    },
    {
        "id": "IN19-10_08",
        "sponsor": "ROCHE SA",
        "study": "CO41101",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-72743543",
        "zarValue": "R2649188.71",
        "receivingDate": "2019-10-21                           ",
        "email": "2019-10-11 14:37",
        "confirmArrival": "2019-10-17 16:13",
        "qaCheck": "2019-10-24 11:15",
        "inventoryAdded ": "2019-10-21 09:31",
        "sss": "2019-10-24 13:36"
    },
    {
        "id": "IN20-01_01",
        "sponsor": "CATALENT",
        "study": "BO40336",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17610389831",
        "zarValue": "R3007467.44",
        "receivingDate": "2020-01-06                           ",
        "email": "2020-01-03 12:52",
        "confirmArrival": "2020-01-07 14:13",
        "qaCheck": "2020-01-07 15:12",
        "inventoryAdded ": "2020-01-07 14:13",
        "sss": "2020-01-08 11:56"
    },
    {
        "id": "IN20-01_03",
        "sponsor": "MARKEN",
        "study": "EFC14092 ",
        "clearingAgent": "MARKEN",
        "courierCoy": "DHL",
        "mawb": "057-88797516",
        "zarValue": "R268138.00",
        "receivingDate": "2020-01-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-01-16 14:03",
        "qaCheck": "2020-01-20 14:49",
        "inventoryAdded ": "2020-01-20 12:14",
        "sss": "2020-01-27 12:51"
    },
    {
        "id": "IN20-02_02",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2975520",
        "zarValue": "R88870.00",
        "receivingDate": "2020-02-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-19 16:16",
        "qaCheck": "2020-02-21 11:32",
        "inventoryAdded ": "2020-02-20 14:33",
        "sss": "2020-02-21 12:03"
    },
    {
        "id": "IN20-02_03",
        "sponsor": "CATALENT",
        "study": "AKB6548-CI-0015",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "2975526",
        "zarValue": "R30025.00",
        "receivingDate": "2020-02-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-19 16:18",
        "qaCheck": "2020-02-21 10:24",
        "inventoryAdded ": "2020-02-20 14:34",
        "sss": "2020-02-21 11:16"
    },
    {
        "id": "IN20-02_05",
        "sponsor": "ROCHE SA",
        "study": "MO25515",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "601616549005",
        "zarValue": "R7537456.80",
        "receivingDate": "2020-02-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-19 16:21",
        "qaCheck": "2020-02-20 14:42",
        "inventoryAdded ": "2020-02-20 14:43",
        "sss": "2020-02-20 14:48"
    },
    {
        "id": "IN20-02_06",
        "sponsor": "ROCHE SA",
        "study": "ML28711",
        "clearingAgent": "DSV",
        "courierCoy": "DSV PHARMA",
        "mawb": "601616549005",
        "zarValue": "R7537456.80",
        "receivingDate": "2020-02-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-02-19 16:22",
        "qaCheck": "2020-02-20 14:50",
        "inventoryAdded ": "2020-02-20 14:44",
        "sss": "2020-02-20 15:01"
    },
    {
        "id": "IN20-02_09",
        "sponsor": "CATALENT",
        "study": "BH30071",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-10390623",
        "zarValue": "R12505502.27",
        "receivingDate": "2020-03-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-03-03 16:20",
        "qaCheck": "2020-03-04 15:01",
        "inventoryAdded ": "2020-03-03 16:20",
        "sss": "2020-03-05 10:43"
    },
    {
        "id": "IN20-03_02",
        "sponsor": "MARKEN",
        "study": "EFC14828",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "636X12734430",
        "zarValue": "R675767.00",
        "receivingDate": "2020-03-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-03-02 16:05",
        "qaCheck": "2020-03-04 16:21",
        "inventoryAdded ": "1970-01-01 00:00",
        "sss": "2020-03-05 11:33"
    },
    {
        "id": "IN20-03_04",
        "sponsor": "ROCHE SA",
        "study": "MO29746",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17610390774",
        "zarValue": "R1117969.81",
        "receivingDate": "2020-03-10                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-03-16 14:13",
        "qaCheck": "2020-04-01 08:47",
        "inventoryAdded ": "2020-04-01 10:01",
        "sss": "2020-04-01 10:02"
    },
    {
        "id": "IN20-03_10",
        "sponsor": "CATALENT",
        "study": "CO39721",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "17610390892",
        "zarValue": "R4245567.87",
        "receivingDate": "2020-03-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-03-19 16:50",
        "qaCheck": "2020-03-23 15:54",
        "inventoryAdded ": "2020-03-19 16:50",
        "sss": "2020-03-26 14:46"
    },
    {
        "id": "IN20-03_12",
        "sponsor": "MARKEN",
        "study": "EFC14643 ",
        "clearingAgent": "MARKEN",
        "courierCoy": "MARKEN",
        "mawb": "604X12899302",
        "zarValue": "R0.00",
        "receivingDate": "2020-03-25                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-03-25 16:22",
        "qaCheck": "2020-04-09 11:22",
        "inventoryAdded ": "2020-03-25 17:12",
        "sss": "2020-04-09 12:42"
    },
    {
        "id": "IN20-06_07",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "S.BUYS WHOLESALERS",
        "mawb": "44157736313",
        "zarValue": "R186172.35",
        "receivingDate": "2020-06-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-06-22 08:16",
        "qaCheck": "2020-06-22 08:31",
        "inventoryAdded ": "2020-07-02 10:15",
        "sss": "2020-07-02 10:25"
    },
    {
        "id": "IN20-07_05",
        "sponsor": "ROCHE SA",
        "study": "CO41101",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15726749483",
        "zarValue": "R2655152.74",
        "receivingDate": "2020-07-17                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-16 14:30",
        "qaCheck": "2020-07-16 14:57",
        "inventoryAdded ": "2020-07-16 12:44",
        "sss": "2020-07-17 14:06"
    },
    {
        "id": "IN20-07_08",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "DHL",
        "courierCoy": "DHL",
        "mawb": "1471717203",
        "zarValue": "R0.00",
        "receivingDate": "2020-07-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-07-20 15:05",
        "qaCheck": "2020-07-23 10:29",
        "inventoryAdded ": "2020-07-21 14:27",
        "sss": "2020-07-23 12:15"
    },
    {
        "id": "IN20-08_05",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "176-18002036",
        "zarValue": "R12196.50",
        "receivingDate": "2020-08-21                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-25 13:37",
        "qaCheck": "2020-08-28 11:29",
        "inventoryAdded ": "2020-08-28 11:29",
        "sss": "2020-08-28 11:49"
    },
    {
        "id": "IN20-08_06",
        "sponsor": "MARKEN",
        "study": "EFC15805",
        "clearingAgent": "CoolCare",
        "courierCoy": "Coolcare",
        "mawb": "Coolcare",
        "zarValue": "R54682.50",
        "receivingDate": "2020-08-24                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-08-24 13:16",
        "qaCheck": "2020-08-24 13:32",
        "inventoryAdded ": "2020-08-24 13:16",
        "sss": "2020-08-24 13:34"
    },
    {
        "id": "IN20-09_11",
        "sponsor": "Biovian",
        "study": "MVX0005",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "120004386",
        "zarValue": "R788.99",
        "receivingDate": "2020-10-02                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-01 16:00",
        "qaCheck": "2020-10-02 10:46",
        "inventoryAdded ": "2020-10-02 10:44",
        "sss": "2020-10-02 10:47"
    },
    {
        "id": "IN20-10_07",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "3032818",
        "zarValue": "R283985.00",
        "receivingDate": "2020-10-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-10-16 13:45",
        "qaCheck": "2020-10-20 14:18",
        "inventoryAdded ": "2020-10-20 12:42",
        "sss": "2020-10-20 14:18"
    },
    {
        "id": "IN20-11_09",
        "sponsor": "CATALENT",
        "study": "GA29144",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15710188754",
        "zarValue": "R40214.40",
        "receivingDate": "2020-11-30                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2020-11-30 12:16",
        "qaCheck": "2020-11-30 15:10",
        "inventoryAdded ": "2020-11-30 13:53",
        "sss": "2020-11-30 15:11"
    },
    {
        "id": "IN21-01_01",
        "sponsor": "ALMAC",
        "study": "C4591001",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X14883367",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-04 16:04",
        "qaCheck": "2021-01-05 08:53",
        "inventoryAdded ": "2021-01-04 16:04",
        "sss": "2021-01-05 08:59"
    },
    {
        "id": "IN21-01_02",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "N/A",
        "courierCoy": "MARKEN",
        "mawb": "621x14873658",
        "zarValue": "R0.00",
        "receivingDate": "2021-01-04                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-01-04 16:16",
        "qaCheck": "2021-01-06 15:05",
        "inventoryAdded ": "2021-01-04 16:17",
        "sss": "2021-01-06 15:20"
    },
    {
        "id": "IN21-03_07",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "15714890816",
        "zarValue": "R21418.90",
        "receivingDate": "2021-03-18                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-16 14:34",
        "qaCheck": "2021-03-17 10:36",
        "inventoryAdded ": "2021-03-16 14:39",
        "sss": "2021-03-19 09:16"
    },
    {
        "id": "IN21-03_09",
        "sponsor": "Triclinium",
        "study": "SP-PA-COV-202 STUDY",
        "clearingAgent": "SPX",
        "courierCoy": "SPX",
        "mawb": "MED Q",
        "zarValue": "R24380.00",
        "receivingDate": "2021-03-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-03-16 11:39",
        "qaCheck": "2021-03-16 12:07",
        "inventoryAdded ": "2021-03-16 11:42",
        "sss": "2021-03-16 12:34"
    },
    {
        "id": "IN21-04_05",
        "sponsor": "ROCHE SA",
        "study": "AG41991",
        "clearingAgent": "DSV",
        "courierCoy": "SPX",
        "mawb": "176-60123545",
        "zarValue": "R2128434.42",
        "receivingDate": "2021-04-13                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-16 10:57",
        "qaCheck": "2021-04-19 12:52",
        "inventoryAdded ": "2021-04-16 10:58",
        "sss": "2021-04-20 13:56"
    },
    {
        "id": "IN21-04_10",
        "sponsor": "CATALENT",
        "study": "CP40559",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "3058960",
        "zarValue": "R33875.40",
        "receivingDate": "2021-04-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-04-16 10:26",
        "qaCheck": "2021-04-19 14:12",
        "inventoryAdded ": "2021-04-16 10:29",
        "sss": "2021-04-20 13:58"
    },
    {
        "id": "IN21-07_05",
        "sponsor": "ALMAC",
        "study": "C4591031 ",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16624585",
        "zarValue": "R558073.00",
        "receivingDate": "2021-07-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-16 09:55",
        "qaCheck": "2021-07-20 15:12",
        "inventoryAdded ": "2021-07-20 15:32",
        "sss": "2021-07-20 15:34"
    },
    {
        "id": "IN21-07_06",
        "sponsor": "ALMAC",
        "study": "C4591031 ",
        "clearingAgent": "Marken",
        "courierCoy": "Marken",
        "mawb": "636X16624185",
        "zarValue": "R69241.70",
        "receivingDate": "2021-07-19                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-16 08:34",
        "qaCheck": "2021-07-23 08:25",
        "inventoryAdded ": "2021-07-23 08:29",
        "sss": "2021-07-23 08:31"
    },
    {
        "id": "IN21-07_08",
        "sponsor": "RMPRU ",
        "study": "ChAdOx1",
        "clearingAgent": "World Courier",
        "courierCoy": "World Courier",
        "mawb": "711738008",
        "zarValue": "R281118.42",
        "receivingDate": "2021-07-16                           ",
        "email": "1970-01-01 00:00",
        "confirmArrival": "2021-07-16 08:28",
        "qaCheck": "2021-07-23 10:13",
        "inventoryAdded ": "2021-07-16 08:44",
        "sss": "2021-07-23 10:14"
    }
];

export default ia;