import React, { useState, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { getData, getStudyByStudyNumber } from '../../apis/logictrials';
import './ViewSite.css';

export default function NewSite({editSite, setEditSite}) {
    const [groupEmails, setGroupEmails] = useState('');
    const [validEmails, setValidEmails] = useState([]);
    const [province, setProvince] = useState('');
    const [study, setStudy] = useState();
    const [specialDeliveryNeeded, setSpecialDeliveryNeeded] = useState(false);
    const [siteNumber, setSiteNumber] = useState();
    const [siteContact, setSiteContact] = useState();
    const [siteEmail, setSiteEmail] = useState();
    const [siteTel, setSiteTel] = useState();
    const [siteTel2, setSiteTel2] = useState();
    const [mobile, setMobile] = useState();
    const [investigator, setInvestigator] = useState();
    const [buildingBlockName, setBuildingBlockName] = useState();
    const [streetName, setStreetName] = useState();
    const [suburb, setSuburb] = useState();
    const [city, setCity] = useState();
    const [code, setCode] = useState();

    console.log(editSite)

    function addToValidEmails(e) {
        if(e.key === "Enter") {
            setValidEmails([...validEmails, groupEmails]);
            setGroupEmails('');
        }
    }

    useEffect(() => {
        if(editSite) {
            setStudy(editSite.study);
            setSiteNumber(editSite.number);
            setSiteContact(editSite.contact);
            setSiteEmail(editSite.email);
            setSiteTel(editSite.tel);
            setSiteTel2(editSite.tel2 ? editSite.tel2 : '');
            setMobile(editSite.mobile);
            setInvestigator(editSite.investigator);
            setBuildingBlockName(editSite.buildingBlockName);
            setStreetName(editSite.streetName);
            setSuburb(editSite.suburb);
            setCity(editSite.city);
            setCode(editSite.code);
            setSpecialDeliveryNeeded(editSite.specialDelivery);
            setStreetName(editSite.streetName);

            if(editSite && editSite.province) setProvince(editSite.province);
        }

        return function cleanup() {
            setEditSite();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSite]);

    useEffect(() => {
        
        document.removeEventListener('keydown', addToValidEmails);
        document.addEventListener('keydown', addToValidEmails);
        if(groupEmails.includes(',')) {
            setValidEmails([...validEmails, groupEmails.split(',')[0]]);
            setGroupEmails('');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupEmails]);

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol className="font-bold">
                        Study Name
                    </IonCol>
                    <IonCol>
                        {study}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Province
                    </IonCol>
                    <IonCol>
                        {province}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Site Number
                    </IonCol>
                    <IonCol>
                        {siteNumber}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Site Contact
                    </IonCol>
                    <IonCol>
                        {siteContact}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Site Tel
                    </IonCol>
                    <IonCol>
                        {siteTel}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Site Tel 2
                    </IonCol>
                    <IonCol>
                        {siteTel2}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Mobile
                    </IonCol>
                    <IonCol>
                        {mobile}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Site Email
                    </IonCol>
                    <IonCol>
                        {siteEmail}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Investigator
                    </IonCol>
                    <IonCol>
                        {investigator}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Building Block Name
                    </IonCol>
                    <IonCol>
                        {buildingBlockName}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Street Name
                    </IonCol>
                    <IonCol>
                        {streetName}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        City
                    </IonCol>
                    <IonCol>
                        {city}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Postal Code
                    </IonCol>
                    <IonCol>
                        {code}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Suburb
                    </IonCol>
                    <IonCol>
                        {suburb}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Special Delivery
                    </IonCol>
                    <IonCol>
                        {specialDeliveryNeeded ? 'Yes' : 'No'}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
