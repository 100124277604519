import React, { useState, useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonPage, IonProgressBar, IonSplitPane, IonModal, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Redirect } from 'react-router-dom';

import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Login/Login';
import OrdersDispatch from '../pages/OrdersDispatch/OrdersDispatch';
import CollectionsReturns from '../pages/CollectionsReturns/CollectionsReturns';
import InboundShipments from '../pages/InboundShipments/InboundShipments';
import NewShipment from './NewShipment';
import AddNewReturn from '../pages/AddNewReturn/AddNewReturn';
import PrivateRoute from './PrivateRoute';
import ManageSites from '../pages/ManageSites/ManageSites';
import ManageStudies from '../pages/ManageStudies/ManageStudies';
import ManageSponsors from '../pages/ManageSponsors/ManageSponsors';
import ManageUsers from '../pages/ManageUsers/ManageUsers';
import DestructionList from '../pages/DestructionList/DestructionList';
import OrdersProcessing from '../pages/OrdersProcessing/OrdersProcessing';
import ReturnsProcessing from '../pages/ReturnsProcessing/ReturnsProcessing';
import CreateOrder from '../pages/CreateOrder';
import AlertMessage from "./general/AlertMessage";
import SearchSitesStudies from '../pages/SearchSitesStudies/SearchSitesStudies';
import OrderArchive from '../pages/archives/OrderArchive';
import ReturnsArchive from '../pages/archives/ReturnsArchive';
import InboundsArchive from '../pages/archives/InboundsArchive';

import OfflineToast from './OfflineToast';
import AlertToast from '../components/AlertToast';

import isActive from '../utils/isActive';
import decode from 'jwt-decode';

import LogRocket from 'logrocket';

import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Theme variables */
import '../theme/variables.css';

/* Core CSS */
import '../theme/core.css';

window.jwtDecode = decode;

function App() {
  const [changingPage, setChangingPage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [showLoading, setShowLoading] = useState(false);
  const [canViewDashboard, setCanViewDashboard] = useState(false);

  useEffect(() => {
    if(window.location.href.includes('qms')) LogRocket.init('dmvfmy/logictrials');

    if(window.location.href.includes('localhost')) LogRocket.init('sxmqgf/fg');

    if(sessionStorage.getItem('lt-user')) {
      const userParsed = JSON.parse(sessionStorage.getItem('lt-user'));

      if(userParsed && userParsed.id && userParsed.firstname && userParsed.lastname && userParsed.email) {
        LogRocket.identify(String(userParsed.id), {
          name: `${userParsed.firstname} ${userParsed.lastname}`,
          email: userParsed.email
        });
      }
    }

    // @ts-ignore
    window.addEventListener('display-alert', (e) => {
      setAlertTitle(e.detail.alertTitle);
      if(typeof e.detail.alertMessage === "string") {
        setAlertMessage(e.detail.alertMessage);
      } else {
        setAlertMessage(`Something went wrong and we're not sure what to do about it. Please try again and contact us if the error persists.`);
      }

      setShowAlert(true);
      setAlertType(e.detail.alertType);
    });

    window.addEventListener('display-loading', () => {
      setShowLoading(true);
    });

    window.addEventListener('dismiss-loading', () => {
      setShowLoading(false);
    });

    // isActive();

    const token = sessionStorage.getItem('lt-access-token');

    if(token) {
      const decodedToken = decode(token);

      if(decodedToken && decodedToken.perms && decodedToken.perms.includes("dashboard")) setCanViewDashboard(true);
    }
  }, []);

  return (
    <IonApp>
      <IonPage>
        <AlertToast />
        <OfflineToast />
        {changingPage && <IonProgressBar type="indeterminate"></IonProgressBar>}
        <IonReactRouter id="main-content">
          <IonSplitPane contentId="main" when={"(min-width: 600px)"}>
            <IonRouterOutlet id="main">
              <Route exact path="/" render={() => canViewDashboard ? <Redirect to="/dashboard" /> : <Redirect to="/collections-and-returns" />} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <Route exact path="/login" render={() => <Login setChangingPage={setChangingPage} />}/>  
              <PrivateRoute exact path="/orders-and-dispatch" component={OrdersDispatch} />
              <PrivateRoute exact path="/collections-and-returns" component={() => <CollectionsReturns isExternal={!canViewDashboard} />} />
              <PrivateRoute exact path="/inbound-shipments" component={InboundShipments} />
              <PrivateRoute exact path="/new-return" component={AddNewReturn} setChangingPage={setChangingPage}/>
              <PrivateRoute exact path="/new-shipment" component={NewShipment} setChangingPage={setChangingPage}/>
              <PrivateRoute exact path="/create-order" component={CreateOrder} setChangingPage={setChangingPage} />
              <PrivateRoute exact path="/order-processing" component={OrdersProcessing} />
              <PrivateRoute exact path="/return-processing" component={ReturnsProcessing} />
              <PrivateRoute exact path="/destruction-list" component={DestructionList} />
              <PrivateRoute exact path="/manage/studies" component={ManageStudies} />
              <PrivateRoute exact path="/manage/sites" component={ManageSites} />
              <PrivateRoute exact path="/manage/sponsors" component={ManageSponsors} />
              <PrivateRoute exact path="/manage/users" component={ManageUsers} />
              <PrivateRoute exact path="/archives/orders" component={OrderArchive} />
              <PrivateRoute exact path="/archives/returns" component={() => <ReturnsArchive isExternal={!canViewDashboard} />} />
              <PrivateRoute exact path="/archives/inbounds" component={InboundsArchive} />
              <PrivateRoute exact path="/search-sites-studies" component={SearchSitesStudies} />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
        <IonModal cssClass="alert-modal" isOpen={showAlert} onDidDismiss={() => setShowAlert(false)}>
          <AlertMessage type={alertType} alertTitle={alertTitle} alertDescription={alertMessage} />
        </IonModal>
      </IonPage>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Loading...'}
        mode="ios"
      />
    </IonApp>
  );
}

export default App;
