import React, { useState, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';
import { addSponsor, updateItem } from '../apis/logictrials';

export default function NewSponsor({editSponsor}) {
    const [groupEmails, setGroupEmails] = useState('');
    const [validEmails, setValidEmails] = useState([]);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');

    useEffect(() => {
        
        if(editSponsor) {
            setName(editSponsor.name);
            setContactName(editSponsor.contactName ? editSponsor.contactName : '');
            setShortName(editSponsor.shortName ? editSponsor.shortName : '');
            setContactNumber(editSponsor.contactNumber ? editSponsor.contactNumber : '');
            setContactEmail(editSponsor.contactEmail ? editSponsor.contactEmail : '');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSponsor]);

    async function submit() {
        const newSponsor = {
            name,
            shortName,
            contactName,
            contactNumber,
            contactEmail,
        };

        if(!editSponsor) {
            addSponsor(newSponsor).then((res) => window.location.reload());
        } else {
            updateItem('sponsor', ['data'], [newSponsor], { id: editSponsor.id }).then((res) => window.location.reload());
        }

    }

    function addToValidEmails(e) {
        if(e.key === "Enter") {
            
            setValidEmails([...validEmails, groupEmails]);
            setGroupEmails('');
        }
    }

    useEffect(() => {
        document.removeEventListener('keydown', addToValidEmails);
        document.addEventListener('keydown', addToValidEmails);
        if(groupEmails.includes(',')) {
            setValidEmails([...validEmails, groupEmails.split(',')[0]]);
            setGroupEmails('');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupEmails]);

    function removeValidEmail(index) {
        let tempEmails = validEmails;
        if(Array.isArray(tempEmails)) {
            tempEmails = tempEmails.filter((_, i) => i !== index);
        }

        setValidEmails(tempEmails);
    }

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Name" value={name} onIonChange={e => setName(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Short Name" value={shortName} onIonChange={e => setShortName(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact Name" value={contactName} onIonChange={e => setContactName(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact Number" value={contactNumber} onIonChange={e => setContactNumber(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact Email" value={contactEmail} onIonChange={e => setContactEmail(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <div style={{padding: '1em'}}>
                    {validEmails.length > 0 && <div style={{height: 'auto'}}>
                        {
                            validEmails.map((email, i) => (
                                <>
                                    <span style={{display: "inline-block", background:'var(--ion-color-primary)', borderRadius: '20px', color: 'white', padding: '5px 10px 5px 20px', marginRight: '5px', margin: '5px'}}>
                                        {email}
                                        <i class="fas fa-times-circle" onClick={() => removeValidEmail(i)} style={{marginLeft: '5px', cursor: 'pointer'}}></i>
                                    </span>
                                    {((i + 1) % 3 === 0) && <br />}
                                </>
                            ))
                        }
                    </div>}
                    <IonInput style={{marginTop: '2em'}} placeholder="Group Emails (separate emails by commar)" value={groupEmails} onIonChange={e => setGroupEmails(e.detail.value)} />
                </div>
                <IonRow>
                </IonRow>
                <IonRow>
                    <IonCol>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                    <IonCol>
                        <IonButton color="success" expand="full" onClick={submit}>
                            <IonIcon icon={checkmarkOutline} slot="start" />
                            {!editSponsor ? 'Create' : 'Update'}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
