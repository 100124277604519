import { ltApiLogin } from "../apis/logictrials";

// 25 Sep 2024 - 1727241328000 (1 day ago)
// 23 SEP 2024 - 1727068528000 (3 days ago)
// 28 August 2024 - 1724822128000 (29 days ago)
// 27 August 20204 - 1724735728000 (30 days)
// 26 August 20204 - 1724649328000 (31 days)
// 18 July 2024 - 1721279728000 (70 days ago)
// 17 July 2024 - 1721193328000  (71 days ago)
// 28 June 2024 -  1719551728000  (90 days ago)
// 27 June 2024 -  1719465328000  (91 days ago)
// 30 May 2024 - 1717046128000 (119 days ago)
// 29 May 2024 - 1716959728000 (120 days ago)
// 28 May 2024 - 1716873328000 (121 days ago)

const noActionDays = 70;
const randomPasswordSetDay = 90;
const randomPassValidDays = 30;

export function epochExpireCheck(
  passSetEpoch,
  randomPassword,
  randomPassSetDay,
  email,
  currentPassword
) {

  const currentTime = Date.now();
  const timeDiff = Math.floor(
    // !USE ME
    (currentTime - passSetEpoch) / 1000 / 60 / 60 / 24
    // !DELETE ME - TESTING
    // (currentTime - 1716959728000) / 1000 / 60 / 60 / 24
  );

  if (timeDiff < noActionDays) {
    // password does not need reset
    return "valid";
  } else if (timeDiff >= noActionDays && timeDiff < randomPasswordSetDay) {
    // password general reset period
    return "change";
  } else if (timeDiff === randomPasswordSetDay && randomPassSetDay === 0) {
    // reset period for client has expired, now create a random password (first time)
    replacePassword(email, currentPassword, true);
    return "random-set";
  } else {
    // check if refreshed password is still valid
    if (randomPassword !== "none") {
      
      // !TESTING - DELETE ME
      // randomPassSetDay = 1724735728000;

      const daysOver = Math.floor(
        (currentTime - randomPassSetDay) / 1000 / 60 / 60 / 24
      );
      if (daysOver >= randomPassValidDays) {
        if(randomPassword === "exists"){
          // random password needs to be renewed
          replacePassword(email, currentPassword, false);
          return "random-renewed";
        }else{
          replacePassword(email, currentPassword, true);
          return "random-set";
        }
      } else {
        // random password still valid
        return "random-waiting";
      }
    } else {
      // Default -> over reset time and no current random password
      // set a new random password
      replacePassword(email, currentPassword, true);
      return "random-set";
    }
  }
}

// Make BE call to move client password to 'data' field and replace with random password
// and generate random password to replace client password
function replacePassword(email, password, replace) {
  try {
    ltApiLogin
      .post("/replacePassWord", {
        email: email,
        password: password,
        replaceUserPassword: replace,
      })
      .then((res) => {
        return res.status;
      })
      .catch((error) => {
        console.error("ERROR", error);
      });
  } catch (error) {
    console.log("Error replacing user expired password");
  }
}
