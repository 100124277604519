import React, { useState, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';
import { insertStudy, getData, updateItem, getItem, getSponsorByName } from '../apis/logictrials';
import Select from "react-dropdown-select";

export default function NewStudy({editStudy}) {
    const [groupEmails, setGroupEmails] = useState('');
    const [validEmails, setValidEmails] = useState([]);
    const [ studyNumber, setStudyNumber] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [sponsors, setSponsors] = useState([]);
    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const selectSponsor = useRef();

    function addToValidEmails(e) {
        if(e.key === "Enter") {
            setValidEmails([...validEmails, groupEmails]);
            setGroupEmails('');
        }
    }

    useEffect(() => {
        getData('sponsor', 0, 9999).then(res => {
            setSponsors(res);
            if(selectSponsor && selectSponsor.current && !editStudy) selectSponsor.current.clearAll();
        });
        if(editStudy) {
            setValidEmails((editStudy.groupEmails && editStudy.groupEmails.includes(';')) ? editStudy.groupEmails.split(';') : editStudy.groupEmails.split(','));
            setStudyNumber(editStudy.studyNumber ? editStudy.studyNumber : '');
            if(editStudy.sponsorId) {
                getItem('sponsor', editStudy.sponsorId).then(res => setSponsor(JSON.parse(res[0].data)));
            } else if (editStudy.sponsor) {
                getSponsorByName(editStudy.sponsor).then(res => setSponsor(JSON.parse(res[0].data)));
            }
            setContactName(editStudy.contactName ? editStudy.contactName : '');
            setContactNumber(editStudy.contactNumber ? editStudy.contactNumber : '');
            setContactEmail(editStudy.contactEmail ? editStudy.contactEmail : '');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editStudy]);

    useEffect(() => {
        document.removeEventListener('keydown', addToValidEmails);
        document.addEventListener('keydown', addToValidEmails);
        if(groupEmails.includes(',')) {
            setValidEmails([...validEmails, groupEmails.split(',')[0]]);
            setGroupEmails('');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupEmails]);

    function removeValidEmail(index) {
        let tempEmails = validEmails;
        if(Array.isArray(tempEmails)) {
            tempEmails = tempEmails.filter((_, i) => i !== index);
        }

        setValidEmails(tempEmails);
    }

    const submit = async () => {
        const sponsorData = JSON.parse(sponsor.data);
        
        insertStudy({
            name: sponsorData.name + ' - ' + studyNumber,
            sponsor: sponsorData.shortName,
            sponsorId: sponsor.id,
            contactName,
            "groupEmails": Array.isArray(validEmails) && validEmails.length > 0 ? (validEmails.join(', ') + (groupEmails && groupEmails.length > 0 ? ` ${groupEmails}` : '')) : groupEmails,
            studyNumber: studyNumber,
            contactEmail,
            sponsorNumber: contactNumber,
            contactNumber
        }).then(() => {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });
    };

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Study Number" value={studyNumber} onIonChange={e => setStudyNumber(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    <div style={{width: '100%', paddingLeft: 16, paddingRight: 16}}>
                        <Select
                            placeholder="Select Sponsor"
                            labelField="name"
                            searchBy="name"
                            valueField="name"
                            addPlaceholder=""
                            searchable="true"
                            options={sponsors}
                            ref={selectSponsor}
                            values={sponsor ? [sponsor] : []}
                            onChange={(values) => {
                                if(values.length > 0) {
                                    setSponsor(values[0]);
                                }
                            }}
                        />
                    </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact Name" value={contactName} onIonChange={e => setContactName(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact Number" value={contactNumber} onIonChange={e => setContactNumber(e.detail.value)}/>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact Email" value={contactEmail} onIonChange={e => setContactEmail(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <div style={{padding: '1em'}}>
                    {validEmails.length > 0 && <div style={{height: 'auto'}}>
                        {
                            validEmails.map((email, i) => (
                                <>
                                    <span style={{display: "inline-block", background:'var(--ion-color-primary)', borderRadius: '20px', color: 'white', padding: '5px 10px 5px 20px', marginRight: '5px', margin: '5px'}}>
                                        {email}
                                        <i class="fas fa-times-circle" onClick={() => removeValidEmail(i)} style={{marginLeft: '5px', cursor: 'pointer'}}></i>
                                    </span>
                                    {((i + 1) % 3 === 0) && <br />}
                                </>
                            ))
                        }
                    </div>}
                    <IonInput placeholder="Group Emails (separate emails by comma)" value={groupEmails} onIonChange={e => setGroupEmails(e.detail.value)} />
                </div>
                <IonRow>
                </IonRow>
                <IonRow>
                    <IonCol>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                    <IonCol>
                        {!editStudy ? <IonButton color="success" expand="full" onClick={submit}>
                            <IonIcon icon={checkmarkOutline} slot="start" />
                            Create
                        </IonButton> : 
                        <IonButton color="success" expand="full" onClick={() => {
                            const sponsorData = sponsor;
                            updateItem('study', ['data'], [{
                                id: editStudy.id,
                                name: sponsorData.name + ' - ' + studyNumber,
                                sponsor: sponsorData.name,
                                sponsorId: sponsor.id,
                                contactName,
                                "groupEmails": Array.isArray(validEmails) && validEmails.length > 0 ? (validEmails.join(', ') + (groupEmails && groupEmails.length > 0 ? ` ${groupEmails}` : '')) : groupEmails,
                                studyNumber: studyNumber,
                                contactEmail,
                                sponsorNumber: contactNumber,
                                contactNumber
                            }], { id: editStudy.id }).then(res => {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            });
                        }}>
                            <IonIcon icon={checkmarkOutline} slot="start" />
                            {editStudy ? 'Edit' : 'Create'}
                        </IonButton>}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
