import React, { useRef, useState, useEffect } from "react";
import {
  IonCard,
  IonText,
  IonButton,
  IonCardContent,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
} from "@ionic/react";
import jwtDecode from "jwt-decode";

import Modal from "../../components/Modal";
import ForgotPassword from "../../components/ForgotPassword";
import ChangePassword from "../../components/ChangePassword";
import ExpiredPassReset from "../../components/ExpiredPassReset";

import { ltApiLogin } from "../../apis/logictrials";
import { parseQueryString } from "../../utils";
import { epochExpireCheck } from "../../utils/loginChecks";

import "./Login.css";
import { last } from "lodash";

export default function Login({ setChangingPage }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetPasswordId, setResetPasswordId] = useState(null);
  const [passwordExpired, setPassWordExpired] = useState(false);
  const [passwordTimeMessage, setPasswordTimeMessage] = useState("");
  const [passAlertHeader, setPassAlertHeader] = useState("");
  const [editUser, setEditUser] = useState(null);

  let passwordStatus = useRef();

  function alertDismiss() {
    setChangingPage(false);
    setError("");
  }

  useEffect(() => {
    if (!passwordExpired) {
      setError("");
    }
  }, [passwordExpired]);

  useEffect(() => {
    const urlQueries = parseQueryString();

    if (urlQueries && urlQueries.id && urlQueries.token && !showResetPassword) {
      setShowResetPassword(true);
      setResetPasswordId(urlQueries.id);
    }

    window.addEventListener("keydown", (e) => {
      if (e && e.key && e.key === "Enter") {
        handleSubmit();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setChangingPage(false);
  }, [setChangingPage]);

  async function handleSubmit(e) {
    if (e) e.preventDefault();
    setError("");
    try {
      ltApiLogin
        .post("/login", {
          email: emailRef.current.value,
          password: passwordRef.current.value,
        })
        .then((res) => {
          if (res.status === 200) {
            const {
              accessToken,
              refreshToken,
              passReset,
              randomPass,
              randomSet,
            } = res.data;

            // Check for Force password reset needed
            if (passReset && passReset === "none") {
              const decodedToken = jwtDecode(accessToken);
              setEditUser({
                id: decodedToken.id,
                firstname: decodedToken.firstname,
                lastname: decodedToken.lastname,
                email: decodedToken.email,
              });
              setPassWordExpired(true);
              setError(
                `\n\nHi ${decodedToken.firstname},\n\nYour password has expired.\n\n Please set a new password to continue.`
              );
              passwordRef.current.value = "";
            } else {
              // perform epoch calculation
              passwordStatus = epochExpireCheck(
                passReset,
                randomPass,
                randomSet,
                emailRef.current.value,
                passwordRef.current.value
              );

              // Password not fully expired
              if (passwordStatus === "change") {
                setPassAlertHeader("Password Reset Recommendation");
                setPasswordTimeMessage(
                  "It looks like your password is about\nto expire.\nPlease reset your password."
                );
                // First set random password
              } else if (passwordStatus === "random-set") {
                setPassAlertHeader("Password Reset Needed");
                setPasswordTimeMessage(
                  "Your password has expired and is no longer valid.\n A new, random password has been emailed to you.\nIt may take a few minutes to arrive.\nRemember to check your spam/junk folder"
                );
                // Random Password Set - Has not expired
              } else if (passwordStatus === "random-waiting") {
                setPassAlertHeader("Password Reset Needed");
                setPasswordTimeMessage(
                  "You are still using a random password. \n Please reset your password."
                );
                // Random password set and has expired
              } else if (passwordStatus === "random-renewed") {
                setPassAlertHeader("Password Reset Needed");
                setPasswordTimeMessage(
                  "Your random password has been reset and a\nnew one has been emailed to you.\nIt may take a few minutes to arrive."
                );
              }

              // new random password just set, do not allow login
              if (
                passwordStatus !== "random-set" &&
                passwordStatus !== "random-renewed"
              ) {
                // allow login info to save
                sessionStorage.setItem("lt-access-token", accessToken);
                sessionStorage.setItem("lt-refresh-token", refreshToken);

                const decoded = jwtDecode(accessToken);

                sessionStorage.setItem("lt-user", JSON.stringify(decoded));
                // Session["lt-access-token"] = accessToken;
                passwordRef.current.value = "";
                sessionStorage.setItem(
                  "demo-current-user",
                  emailRef.current.value
                );
              }

              if (passwordStatus === "valid") {
                window.location.href = "/";
                return;
              }
            }
          } else {
            setError("failed to sign in");
          }
        })
        .catch((error) => {
          setError("failed to sign in");
        });
    } catch {
      setError("failed to sign in");
    }
    setLoading(false);
  }

  return (
    <div className="login">
      {!showResetPassword && (
        <IonGrid>
          <IonRow style={{ boxShadow: "none" }}>
            <IonCol offset-md="3" size-md="6">
              <IonCard className="shadow-xl">
                <IonCardContent>
                  <div style={{ textAlign: "center", marginBottom: "1.5em" }}>
                    <img
                      src="/assets/logic_logo.svg"
                      alt="logic logo"
                      style={{ width: "60%", margin: "1em auto" }}
                    />
                  </div>
                  <form onSubmit={handleSubmit} style={{ marginTop: "2em" }}>
                    <IonItem lines="none">
                      <IonInput
                        type="text"
                        required
                        ref={emailRef}
                        placeholder="email"
                      ></IonInput>
                    </IonItem>
                    <IonItem lines="none" style={{ marginTop: "1em" }}>
                      <IonInput
                        type="password"
                        required
                        ref={passwordRef}
                        placeholder="password"
                      ></IonInput>
                    </IonItem>
                    <IonButton
                      onClick={() => setChangingPage(true)}
                      type="submit"
                      expand="full"
                      style={{ marginTop: "3em" }}
                      disabled={loading}
                    >
                      Login
                    </IonButton>
                  </form>
                  <div className="forgot-password-div">
                    <IonText>
                      <p
                        style={{ color: "var(--ion-color-primary)", cursor: "pointer" }}
                        onClick={() => setShowForgotPassword(true)}
                      >
                        Forgot password
                      </p>
                    </IonText>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {showResetPassword && <ChangePassword id={resetPasswordId} />}
      <IonAlert
        isOpen={error !== ""}
        onDidDismiss={() => alertDismiss()}
        header={passwordExpired ? "" : "Login Error"}
        message={error.replace(/\n/g, "<br />")}
        buttons={["OK"]}
        cssClass={passwordExpired && "custom-alert"}
      />
      <IonAlert
        isOpen={passwordTimeMessage !== ""}
        onDidDismiss={() => alertDismiss()}
        header={passAlertHeader}
        message={passwordTimeMessage.replace(/\n/g, "<br />")}
        buttons={[
          {
            text: "OK",
            handler: () => {
              if (
                passwordStatus !== "random-set" ||
                passwordStatus !== "random-renewed"
              ) {
                window.location.href = "/";
                return;
              }
            },
          },
        ]}
        cssClass={"custom-alert"}
      />

      <Modal showModal={passwordExpired} setShowModal={setPassWordExpired}>
        <ExpiredPassReset
          editUser={editUser}
          setShowModal={setPassWordExpired}
        />
      </Modal>
      <Modal
        showModal={showForgotPassword}
        setShowModal={setShowForgotPassword}
      >
        <ForgotPassword />
      </Modal>
    </div>
  );
}
