import React from 'react';
import { IonSpinner } from '@ionic/react';

export default function Loading({loading}) {
    return (
    <div style={{width: '100%', textAlign: 'center', marginTop: '3em'}}>
        <div style={{margin: 'auto'}}>
            {loading && <IonSpinner />}
        </div>
    </div>
    )
}
