import React, { useState } from 'react';
import  { IonGrid, IonRow, IonCol, IonButton, IonInput } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';

export default function GnNumber({returnObject, setShowModal}) {
    const [gnNumber, setGnNumber] = useState('');

    async function submit() {
        let tempReturn = {...returnObject, gnNo: gnNumber};

        if(tempReturn && tempReturn.parcels && typeof tempReturn.parcels === 'string') {
            tempReturn.parcels = JSON.parse(tempReturn.parcels);
        }

        const { id } = returnObject;
        await updateItem('returns', ['data'], [tempReturn], { id });
        setShowModal(false);
        window.location.reload();
    }

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <IonGrid>
                <IonRow style={{marginTop: '2em'}}>
                    <IonCol>
                        <IonRow>
                            <IonInput value={gnNumber} onIonChange={e => setGnNumber(e.detail.value)} placeholder="GN number" />
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow style={{marginTop: '2em'}}>
                    <IonCol size="9">
                        
                    </IonCol>
                    <IonCol size="3" style={{textAlign: 'right'}}>
                        <IonButton color="warning" onClick={submit}>
                            Update
                        </IonButton>    
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
