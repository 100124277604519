import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonButton,
  IonAlert,
} from "@ionic/react";
import { forgotPassword } from "../apis/logictrials";

export default function RestePassword() {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");

  async function handleSubmit() {
    const response = await submit();

    if (response.statusCode === "Email sent") {
      setMessage(
        "An email with the password reset\nlink has been sent to you."
      );
      setShowAlert(true);
    } else {
      setShowAlert(true);
      setMessage("There was an error sending the email.");
    }
  }

  async function submit() {
    if (email) {
      try {
        const res = await forgotPassword(email);
        console.log(res);
        return res;
      } catch (error) {
        console.error("Error submitting forgot password request:", error);
        return "error";
      }
    } else {
      console.error("Email is required");
      return "error";
    }
  }

  return (
    <div>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h2>Forgot Password</h2>
          </IonCol>
          <IonCol size="12">
            <IonItem lines="none">
              <IonInput
                type="text"
                placeholder="Your email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value)}
              />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonItem lines="none">
              <p>Get a link to reset your password sent to you.</p>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonButton color="success" expand="full" onClick={handleSubmit}>
              {/* <IonIcon icon={refreshOutline} slot="start" /> */}
              Submit
            </IonButton>
          </IonCol>
        </IonRow>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          message={message.replace(/\n/g, "<br />")}
          buttons={[
            {
              text: "Close",
              role: "cancel",
              handler: () => {
                setShowAlert(false);
              },
            },
          ]}
        />
      </IonGrid>
    </div>
  );
}
