import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonModal, IonRow } from '@ionic/react';
import { format } from 'date-fns';
import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import { retryAramexOrders, retryAramexReturns } from '../../apis/logictrials';
import { displayAlert, isValidDate } from '../../utils';

import publicHolidays from '../../utils/publicHolidays';





export default function RetryAramex({ orderNumber, tableName, fetchData, setShowModal, needsAramexAccount = false }) {
    const [pickUpDate, setPickUpDate] = useState('');
    const [aramexAccount, setAramexAccount] = useState('');
    const [showPickUpDate, setShowPickUpDate] = useState({ showPopover: false, event: undefined });
    const [showAramexAccountModal, setShowAramexAccountModal] = useState(false);
    const [pickUpPickUpTime, setPickUpPickUpTime] = useState('');
    const [error, setError] = useState('');
    const nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    React.useEffect(() => {
        if (needsAramexAccount && aramexAccount === '') {
            setShowAramexAccountModal(true)
        }
    }, [showAramexAccountModal]);

    function submit() {

        const pickUpDateTime = resolvePickUpTime();



        if (pickUpDateTime) {
            if (tableName === 'returns') {
                retryAramexReturns(aramexAccount, orderNumber, pickUpDateTime).then(res => {
                    fetchData();
                    if (res && typeof res === "string") {

                        if (res === "successful") {
                            displayAlert('success', 'Retry Successful', 'Or system is generating the new waybill. It will be available in the next 30 seconds.');
                        } else {
                            displayAlert('warning', res, 'Please try again later.');
                        }
                    } else {
                        displayAlert('warning', 'An error occurred', 'Please try again later.');
                    }

                    setPickUpDate('');
                    setShowPickUpDate({ showPopover: false, event: undefined });
                    setPickUpPickUpTime('');
                    setError('');

                    setShowModal(false);
                    setTimeout(() => {
                        fetchData();
                    }, 10000);
                });
            }

            if (tableName === 'orders') {
                retryAramexOrders(orderNumber, pickUpDateTime).then(res => {
                    fetchData();
                    displayAlert('success', 'Retry Successfull', 'Or system is generating the new waybill. It will be available in the next 30 seconds.');

                    setPickUpDate('');
                    setShowPickUpDate({ showPopover: false, event: undefined });
                    setPickUpPickUpTime('');
                    setError('');

                    setShowModal(false);
                    setTimeout(() => {
                        fetchData();
                    }, 10000);
                });
            }
        }
    }

    function resolvePickUpTime() {
        if (!pickUpDate || (pickUpDate && !isValidDate(new Date(pickUpDate)))) {
            setError('Pick up date invalid. Please select pick up date');
            // setHasSubmitted(false);

            return false;
        }

        if (!pickUpPickUpTime) {
            setError('Pick up time invalid. Please enter a pick up time between 08:00 and 17:00');
            // setHasSubmitted(false);

            return false;
        }

        let arrivalDateWithTime;
        if ((pickUpDate && isValidDate(new Date(pickUpDate))) && pickUpPickUpTime) {
            const hoursMins = pickUpPickUpTime.split(':');

            let tempDate = new Date(pickUpDate);
            arrivalDateWithTime = tempDate.setHours(hoursMins[0]);
            arrivalDateWithTime = tempDate.setMinutes(hoursMins[1]);

            arrivalDateWithTime = new Date(arrivalDateWithTime).toISOString();
        }

        if (arrivalDateWithTime && isValidDate(new Date(arrivalDateWithTime)) && (new Date(arrivalDateWithTime).getHours() < 8 || new Date(arrivalDateWithTime).getHours() > 17)) {
            setError('Pick up time invalid. Pick up time must be between 08:00 and 17:00');

            return false;
        }

        if (arrivalDateWithTime) return arrivalDateWithTime;

        return false;
    }

    return (
        <div className="w-full p-5">
            {tableName === "returns" && showAramexAccountModal && <>



                <div style={{ padding: "10px", marginBottom: "15px" }}>
                    This return does not have Aramex as the selected Courier. Do you want to change the courier to Aramex?
                </div>

                <IonButton style={{ width: "90%" }} onClick={() => {

                    setAramexAccount("clinical");
                    setShowAramexAccountModal(false)
                }}>Yes - Use Clinical Account</IonButton>
                <IonButton style={{ width: "90%" }} onClick={() => {
                    setAramexAccount("ecommerce");
                    setShowAramexAccountModal(false)
                }}>Yes - Use Ecommerce Account</IonButton>
                <IonButton style={{ width: "90%" }} color="danger" onClick={() => {
                    setShowAramexAccountModal(false)
                    setShowModal(false)
                }}>No</IonButton>
            </>}
            {!showAramexAccountModal && (<>            <IonGrid>
                <IonRow style={{ marginTop: '3em' }}>
                    <>
                        <IonCol size="12">
                            <h2 className="bold">Aramex Retry</h2>
                        </IonCol>
                        <IonCol size="12">
                            <div className="rounded-xl bg-gray-200 p-5 flex items-center">
                                <p>The pick up for this return is in the past. Select a new pick up date, to continue.</p>
                            </div>
                        </IonCol>
                    </>
                </IonRow>
                <IonRow style={{ marginTop: '1.75em' }}>
                    <>
                        <IonCol size-md="6">
                            <IonItem lines="none">
                                <IonInput onClick={(e) => { setShowPickUpDate({ showPopover: true, event: e }); setError('') }} placeholder="Pickup Date" value={pickUpDate ? format(new Date(pickUpDate), 'MM d y') : ''} />
                            </IonItem>
                        </IonCol>
                        <IonCol size="6">
                            <IonItem lines="none">
                                Pickup Time: <span style={{ width: 20 }} /> <IonInput min="08:00" max="17:00" placeholder="Pickup Time" type="time" value={pickUpPickUpTime} onIonChange={e => { setError(''); setPickUpPickUpTime(e.detail.value); }} />
                            </IonItem>
                        </IonCol>
                    </>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            {error && <p className="text-red-500">{error}</p>}
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{ textAlign: 'right', marginTop: '3em' }} size-md="12">
                        <IonButton expand="full" color="warning" onClick={submit}>
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
                <IonModal
                    cssClass="day-modal-picker"
                    isOpen={showPickUpDate.showPopover}
                    event={showPickUpDate.event}
                    onDidDismiss={() => setShowPickUpDate({ showPopover: false, event: undefined })}
                >
                    <DayPicker
                        canChangeMonth={true}
                        onDayClick={(day) => {
                            setPickUpDate(day.toString());
                            setShowPickUpDate({ showPopover: false, event: undefined });
                        }}
                        disabledDays={[{ daysOfWeek: [0, 6] }, { after: nextWeek, before: new Date() }, ...publicHolidays]}
                    />
                    <IonButton onClick={() => setShowPickUpDate({ showPopover: false, event: undefined })}>Close</IonButton>
                </IonModal></>)}

        </div>
    )
}
