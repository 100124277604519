import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonLabel,
  IonInput,
  IonItem,
  IonButton,
  IonTextarea,
  IonModal,
  IonPopover,
} from "@ionic/react";
import DayPicker from "react-day-picker";
import { format } from "date-fns";
import { updateItem } from "../../apis/logictrials";

import { isValidJson, displayAlert } from "../../utils";
import { set } from "lodash";

export default function ConfirmArrival({ data, setShowModal, orderObject, arrivalDateUpdated, setArrivalDateUpdated }) {
  const [selectedDate, setSelectedDate] = useState();
  const [noParcel, setNoParcel] = useState();
  const [isDamaged, setIsDamaged] = useState();
  const [comment, setComment] = useState();
 
  const [excursion, setExcursion] = useState(
    orderObject.tt4Excursion === "yes" ? true : false
  );

//update fields with existing values if an update is being performed
useEffect(() => {
  if(orderObject.arrivalDate.updated && orderObject.arrivalDate.updated === "Yes") {
    setSelectedDate(format(new Date(orderObject.arrivalDate.tooltip), "MM d y"))
    setNoParcel(orderObject.arrivalDate.noParcel)
    setIsDamaged(orderObject.arrivalDate.isDamaged)
    setComment(orderObject.arrivalDate.comment)
  }
}, [])


  const [showSelectedDate, setShowSelectedDate] = useState({
    showPopover: false,
    event: undefined,
  });

  const submit = () => {
    if (!selectedDate) {
      displayAlert(
        "warning",
        "Pickup Date has not been supplied",
        "Please select a pickup date"
      );
      return;
    }
    let tempOrder = orderObject;

    tempOrder.boxTypes =
      typeof tempOrder.boxTypes === "string" && isValidJson(tempOrder.boxTypes)
        ? JSON.parse(tempOrder.boxTypes)
        : tempOrder.boxTypes;
    tempOrder.tt4Excursion = excursion ? "yes" : "N/A";
    tempOrder.arrivalDate = {
      tooltip: format(new Date(selectedDate), 'yyyy-MM-dd'),
      label: "Arrived",
      comment,
      isDamaged,
      noParcel,
      ...(orderObject.arrivalDate.updated === undefined && { btnColor: "warning" }),
      ...(orderObject.arrivalDate.updated === undefined && { btnIcon: "calendarOutline" }),
      updated: orderObject.arrivalDate.updated === undefined  ? "Yes" : "Closed" 
    };

    if(orderObject.arrivalDate.updated === undefined ){
      setArrivalDateUpdated("Yes")
    }else if(orderObject.arrivalDate.updated === "Yes"){
      setArrivalDateUpdated("Closed") 
    }

  

    updateItem("orders", ["data"], [tempOrder], { id: orderObject.id }).then(
      (res) => setShowModal(false)
    );
  };

  return (
    <div>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem lines="none">
              <IonInput
                onClick={(e) =>
                  setShowSelectedDate({ showPopover:  orderObject.arrivalDate.updated === "Yes"? false : true, event: e })
                }
                placeholder="Pickup Date"
                value={
                  selectedDate ? format(new Date(selectedDate), "MM d y") : ""
                }
                readonly={orderObject.arrivalDate.updated === "Yes"} 
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>No Parcel</IonLabel>
              <IonCheckbox
                checked={noParcel}
                onIonChange={(e) => setNoParcel(e.detail.checked)}
                disabled={orderObject.arrivalDate.updated === "Yes"}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
          <IonItem>
              <IonLabel color="danger">Damaged:</IonLabel>
              <IonCheckbox
                checked={isDamaged}
                onIonChange={(e) => setIsDamaged(e.detail.checked)}
                disabled={orderObject.arrivalDate.updated === "Yes"}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonTextarea
            placeholder="Comments"
            value={comment}
            onIonChange={(e) => setComment(e.detail.value)}
            readonly={orderObject.arrivalDate.updated === "Yes"}
          />
        </IonRow>
        <IonRow>
          <IonCol style={{ textAlign: "right", marginTop: "10px" }}>
            Serial:
          </IonCol>
          <IonCol>
            <IonInput readonly={orderObject.arrivalDate.updated === "Yes"} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-md="12">
            <IonItem
              lines="none"
              style={{
                color: "var(--ion-color-danger)",
                "--color-hover": "var(--ion-color-danger)",
              }}
            >
              <IonLabel color="danger">Excursion:</IonLabel>
              <IonCheckbox
                color="danger"
                checked={excursion}
                value={excursion}
                onIonChange={(e) => setExcursion(e.detail.checked)}
                slot="end"
              />
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Remind site send docs</IonLabel>
              <IonCheckbox disabled={orderObject.arrivalDate.updated === "Yes"}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol></IonCol>
          <IonCol></IonCol>
          <IonCol style={{ textAlign: "right" }}>
            <IonButton color="warning" onClick={submit}>
              Update
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonModal
        cssClass="day-modal-picker"
        isOpen={showSelectedDate.showPopover}
        event={showSelectedDate.event}
        onDidDismiss={() =>
          setShowSelectedDate({ showPopover: false, event: undefined })
        }
      >
        <DayPicker
          canChangeMonth={true}
          onDayClick={(day) => {
            setSelectedDate(day.toString());
            setShowSelectedDate({ showPopover: false, event: undefined });
          }}
        />
        <IonButton
          onClick={() =>
            setShowSelectedDate({ showPopover: false, event: undefined })
          }
        >
          Close
        </IonButton>
      </IonModal>
    </div>
  );
}
