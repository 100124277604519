const rsaHolidays = [
    {
      name: "New Year's Day",
      date: '2022-01-01',
      observed: '2022-01-01',
      public: true,
      country: 'ZA',
      uuid: 'e34baf46-2118-4d7e-802b-90ba0a00821d',
    },
    {
      name: 'Human Rights Day',
      date: '2022-03-21',
      observed: '2022-03-22',
      public: true,
      country: 'ZA',
      uuid: '2552acb3-cacc-434d-9fcc-8e5b28c4d2f1',
    },
    {
      name: 'Good Friday',
      date: '2022-04-15',
      observed: '2022-04-15',
      public: true,
      country: 'ZA',
      uuid: '43ee4c57-0905-44b7-9b90-e3b0a4b2d298',
    },
    {
      name: 'Family Day',
      date: '2022-04-18',
      observed: '2022-04-18',
      public: true,
      country: 'ZA',
      uuid: 'd2f6363e-8509-4ab4-8029-adeb73e8d1b2',
    },
    {
      name: 'Freedom Day',
      date: '2022-04-27',
      observed: '2022-04-27',
      public: true,
      country: 'ZA',
      uuid: '73ba1697-9a3d-45cb-9544-6bf4bfe74da4',
    },
    {
      name: "Workers' Day",
      date: '2022-05-02',
      observed: '2022-05-02',
      public: true,
      country: 'ZA',
      uuid: '83204b79-4fa4-4f07-ba73-c099bb8a2c8d',
    },
    {
      name: 'Youth Day',
      date: '2022-06-16',
      observed: '2022-06-16',
      public: true,
      country: 'ZA',
      uuid: 'adfca46c-2ffb-4c83-9b3b-730b1ed761cd',
    },
    {
      name: "National Women's Day",
      date: '2022-08-09',
      observed: '2022-08-09',
      public: true,
      country: 'ZA',
      uuid: '79a445b0-2160-4ddd-bb78-a97cf8b0a269',
    },
    {
      name: 'Heritage Day',
      date: '2022-09-24',
      observed: '2022-09-24',
      public: true,
      country: 'ZA',
      uuid: 'bc39d2e9-4308-4e08-980c-ff4c332cd135',
    },
    // {
    //   name: 'Election Day',
    //   date: '2022-11-01',
    //   observed: '2022-11-01',
    //   public: true,
    //   country: 'ZA',
    //   uuid: '948678ca-f161-4496-bb1c-914d0322fb3e',
    // },
    {
      name: 'Day of Reconciliation',
      date: '2022-12-16',
      observed: '2022-12-16',
      public: true,
      country: 'ZA',
      uuid: 'fe1fba8f-5a8b-432c-a1c2-493b7a83f897',
    },
    {
      name: 'Christmas Day',
      date: '2022-12-25',
      observed: '2022-12-25',
      public: true,
      country: 'ZA',
      uuid: '11725b66-441c-41ac-b6a5-85161a1072cd',
    },
    {
      name: 'Day of Goodwill',
      date: '2022-12-26',
      observed: '2022-12-27',
      public: true,
      country: 'ZA',
      uuid: 'b38ff9eb-657a-4be2-acb1-6dc4924c727a',
    }
];

export default rsaHolidays;