import React, { useEffect, useState } from 'react';
import { IonFab, IonFabButton, IonIcon, IonModal, IonButton } from '@ionic/react';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument, rgb, grayscale } from 'pdf-lib';

import { arrowBackOutline } from 'ionicons/icons';

const PDFwarehouse = ({ inbound, setDisplayPdf }) => {
    const [showModal, setShowModal] = useState(false);
    const [sigCanvas, setSigCanvas] = useState();
    const [pdfBytes, setPdfBytes] = useState();

    useEffect(() => {
        createPdf();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    async function hideExistingText(page) {
        let offset = 18.75;
        const indexes = ['id', 'sponsor', 'study', 'invoiceNumber', 'mawb', 'clearingAgent', 'clearingAgent', 'zarValue', 'receivingDate', 'courierCoy'];

        for(let i = 0; i < 14; i++) {

            page.drawRectangle({
                x: 168,
                y: page.getHeight() - 97 - (i *offset),
                width: 100,
                height: 15,
                color: grayscale(1),
                opacity: 1,
            });
            function getText(i) {
                if(i < indexes.length) {
                    const ret = typeof inbound[indexes[i]] === 'string' ? inbound[indexes[i]] : (inbound[indexes[i]] && inbound[indexes[i]].label) ? inbound[indexes[i]].label : '';

                    if(typeof ret === 'string') {
                        return ret;
                    } else {
                        return '';
                    }
                }
            }

            const val = getText(i);

            page.drawText(val ? val : '', {
                x: 170,
                y: page.getHeight() - 93 - (i *offset),
                size: 10,
                color: rgb(0, 0, 0),
            })

        }

        page.drawRectangle({
            x: 482,
            y: page.getHeight() - 145,
            width: 70,
            height: 15,
            color: grayscale(1),
            opacity: 1,
        });

        page.drawRectangle({
            x: 350,
            y: page.getHeight() - 175,
            width: 100,
            height: 80,
            color: grayscale(1),
            opacity: 1,
        });
        page.drawText(inbound['qaCheck'] && typeof inbound['qaCheck'] === 'string' ? inbound['qaCheck'] : '', {
            x: 350,
            y: page.getHeight() - 135,
            size: 10,
            color: rgb(0, 0, 0),
        })

        page.drawRectangle({
            x: 350,
            y: page.getHeight() - 235,
            width: 100,
            height: 50,
            color: grayscale(1),
            opacity: 1,
        });

        page.drawRectangle({
            x: 482,
            y: page.getHeight() - 178.5,
            width: 70,
            height: 15,
            color: grayscale(1),
            opacity: 1,
        });

        page.drawRectangle({
            x: 482,
            y: page.getHeight() - 233.5,
            width: 70,
            height: 15,
            color: grayscale(1),
            opacity: 1,
        });

        page.drawRectangle({
            x: 482,
            y: page.getHeight() - 200,
            width: 70,
            height: 15,
            color: grayscale(1),
            opacity: 1,
        });

        page.drawRectangle({
            x: 472,
            y: page.getHeight() - 240,
            width: 100,
            height: 150,
            color: grayscale(1),
            opacity: 1,
        });

    }

    function onDoneClick() {
        setShowModal(false);
        const sigImageBase64 = sigCanvas.toDataURL('image/png', {});

        embedImage(sigImageBase64);
    }

    async function embedImage(url, x, y, scale) {
        const imageBytes = await fetch(url).then((res) => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(pdfBytes)

        const pngImage = await pdfDoc.embedPng(imageBytes)

        const pngDims = pngImage.scale(scale ? scale : 0.5)

        const page = pdfDoc.getPages()[0]

        page.drawImage(pngImage, {
            x: x ? x : page.getWidth() / 2,
            y: y ? y : 10,
            width: pngDims.width,
            height: pngDims.height,
        })

        const tempPdfBytes = await pdfDoc.save()
        setPdfBytes(tempPdfBytes);
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        document.getElementById('pdf').src = pdfDataUri;
    }

    async function createPdf() {
        const url = "/pdf-templates/warehouse-receiving-register.pdf"
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        hideExistingText(pdfDoc.getPage(0));
    
        // pdfDoc.

        const tempPdfBytes = await pdfDoc.save()
        setPdfBytes(tempPdfBytes);
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        document.getElementById('pdf').src = pdfDataUri;
    }

    return (
        <>
            <iframe title="PDF viewer" id="pdf" style={{ width: '100%', height: '96vh' }}></iframe>
            <IonFab vertical="bottom" horizontal="start" slot="fixed" >
                <IonFabButton onClick={() => setDisplayPdf(null)} style={{ marginLeft: '2em', marginBottom: '4em' }}>
                    <IonIcon icon={arrowBackOutline}></IonIcon>
                </IonFabButton>
            </IonFab>
            <IonModal isOpen={showModal} cssClass='my-custom-class'>
                <SignatureCanvas ref={(ref) => { setSigCanvas(ref) }} penColor='black'
                    canvasProps={{ width: 500, height: 500, className: 'sigCanvas', backgroundColor: 'white' }} />
                <IonButton onClick={() => onDoneClick()}>Done</IonButton>
            </IonModal>
        </>

    );
};

export default PDFwarehouse;
