import React, { useState, useRef, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonModal,
  IonToast
} from "@ionic/react";
import Modal from "./Modal";
import BaseTemplate from "./base/BaseTemplate";
import {
  cashOutline,
  checkmarkCircle,
} from "ionicons/icons";
import CurrencyConverter from "./actionButtonForms/CurrencyConvertor";
import Select from "react-dropdown-select";
import { generateOrderNumber, addShipment, getData, updateItem, getSponsorById, getStudyById, getSponsorByName, getStudyByStudyNumber } from '../apis/logictrials';
import useAsyncEffect from "use-async-effect";
import { useHistory } from 'react-router-dom';
import { displayAlert } from '../utils';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';

const BOX_TEMPERATURES = [
  '-70',
  '-25',
  '-15 to -25',
  "2 to 8",
  '15 to 25',
  'Uncontrolled'
];

export default function NewShipment({ editShipment, setEditShipment }) {
  const [sponsor, setSponsor] = useState();
  const [study, setStudy] = useState();
  const [receivingDate, setReceivingDate] = useState();
  const [courierCoy, setCourierCoy] = useState();
  const [clearingAgent, setClearingAgent] = useState();
  const [mawb, setMawb] = useState();
  const [zarValue, setZarValue] = useState();
  const [showCurrencyConvertor, setShowCurrencyConvertor] = useState(false);
  const [sponsorId, setSponsorId] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [studies, setStudies] = useState([]);
  const [studiesOriginal, setStudiesOriginal] = useState([]);
  const [packingListNumber, setPackingListNumber] = useState('');
  const [boxTemperature, setBoxTemperature] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [boxes, setBoxes] = useState('');
  const selectStudy = useRef();
  const selectSponsor = useRef();
  const history = useHistory();
  const lsAgents = sessionStorage.getItem('lt-clearing-agents');
  const CLEARING_AGENTS = lsAgents ? JSON.parse(lsAgents) : [{ name: "DSV" }, { name: "World Courier" }];
  const [showReceivingDate, setShowReceivingDate] = useState({ showPopover: false, event: undefined });

  useAsyncEffect(async () => {
    if (!lsAgents) sessionStorage.setItem('lt-clearing-agents', JSON.stringify(CLEARING_AGENTS));

    getData('sponsor', 0, 999).then(res => {

      let tempSponsors = res.map(({ _, id, data }) => {
        const parsed = JSON.parse(data);

        parsed.id = id;

        return parsed;
      });

      setSponsors(tempSponsors);
      if (selectSponsor.current && !editShipment) selectSponsor.current.clearAll();
    });

    if (studies.length === 0) {
      getData('study', 0, 9999).then(res => {
        const data = res.map(({ data, id }) => {
          data = JSON.parse(data);
          data.id = id;
          data.labelField = `${data.sponsor} - ${data.studyNumber}`
          return data;
        }).filter(opt => {
          if (typeof opt !== "object") return false;
          if (typeof opt.studyNumber === "number") opt.studyNumber = opt.studyNumber.toString();
          if ((typeof opt.studyNumber !== "string") || (opt.studyNumber === "")) return false;

          return true;
        }).sort(function (a, b) {

          return a.labelField.localeCompare(b.labelField);
        }).filter(function (item, pos, arr) {
          return !pos || item.labelField !== arr[pos - 1].labelField;
        });
        const dataRet = [];
        data.forEach(opt => {
          if (!dataRet.includes(opt)) dataRet.push(opt);
        });

        setStudies(dataRet);
        setStudiesOriginal(dataRet);
        if (selectStudy.current && !editShipment) selectStudy.current.clearAll();
      });
    }

    if (editShipment) {
      if (editShipment.studyId) {
        getStudyById(editShipment.studyId).then(res => {
          if (res && res[0] && res[0].data) {
            const tempStudy = JSON.parse(res[0].data);
            tempStudy.labelField = `${tempStudy.sponsor} - ${tempStudy.studyNumber}`;
            setStudy(tempStudy);
          }
        })
      } else if (editShipment.study) {
        getStudyByStudyNumber(editShipment.study).then(res => {
          if (res && res[0] && res[0].data) {
            const tempStudy = JSON.parse(res[0].data);
            tempStudy.labelField = `${tempStudy.sponsor} - ${tempStudy.studyNumber}`;
            setStudy(tempStudy);
          }
        });
      }

      if (editShipment.sponsorId) {
        getSponsorById(editShipment.sponsorId).then(res => {
          if (res && res[0] && res[0].data) {
            setSponsor(JSON.parse(res[0].data));
          }
        })
      } else if (editShipment.sponsor) {
        getSponsorByName(editShipment.sponsor).then(res => {
          if (res && res[0] && res[0].data) {
            setSponsor(JSON.parse(res[0].data));
          }
        })
      }

      if (editShipment.boxTemperature) {
        setBoxTemperature(editShipment.boxTemperature);
      }

      if (editShipment.packingListNumber) {
        setPackingListNumber(editShipment.packingListNumber);
      }

      if (editShipment.invoiceNumber) {
        setInvoiceNumber(editShipment.invoiceNumber);
      }

      if (typeof editShipment.receivingDate === 'string') {

        const rd = new Date(editShipment.receivingDate);
        rd.setFullYear(2021);
        setReceivingDate(rd.toISOString());
      }

      if (editShipment.receivingDate && editShipment.receivingDate.label && typeof editShipment.receivingDate.label === 'string') {
        setReceivingDate(new Date(editShipment.receivingDate.label).toISOString());
      }

      if (editShipment.boxes) setBoxes(editShipment.boxes);

      setCourierCoy(editShipment.courierCoy);
      setClearingAgent(editShipment.clearingAgent);
      setMawb(editShipment.mawb);

      if (typeof editShipment.zarValue === 'string') {
        setZarValue(editShipment.zarValue);
      }

      if (editShipment.zarValue && editShipment.zarValue.label && typeof editShipment.zarValue.label === 'string') {
        setZarValue(editShipment.zarValue.label);
      }
      setPackingListNumber(editShipment.packingListNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit() {
    if (!sponsor) {
      displayAlert('warning', 'Error', 'Please select a sponsor');
      return;
    }
    if (!study) {
      displayAlert('warning', 'Error', 'Please select a study');
      return;
    }
    if (!receivingDate) {
      displayAlert('warning', 'Error', 'Please enter a receiving date');
      return;
    }
    if (boxes && !isNaN(Number(boxes)) && Number(boxes) < 0) {
      displayAlert('warning', 'Error', "Number of boxes can't be a negative number");
      return;
    }

    if (boxes && isNaN(Number(boxes))) {
      displayAlert('warning', 'Error', "Number of boxes must be a numberical value");
      return;
    }

    setHasSubmitted(true);
    const id = editShipment ? editShipment.id : await generateOrderNumber('inbound');
    const sId = editShipment && !sponsorId ? editShipment.sponsorId : sponsorId;
    const newShipment = {
      id,
      sponsor: !editShipment ? sponsor.name : editShipment.sponsor,
      sponsorId: !editShipment ? sponsor.id : editShipment.sponsorId,
      studyId: !editShipment ? study.id : editShipment.studyId,
      study: !editShipment ? study.studyNumber : editShipment.study,
      clearingAgent,
      courierCoy,
      mawb,
      invoiceNumber,
      zarValue: zarValue ? zarValue : {
        tooltip: "Confirm ZAR Cost",
        label: "",
        btnColor: "success",
        btnIcon: "cashOutline",
      },
      receivingDate: {
        "label": String(format(new Date(receivingDate), 'yyyy-MM-dd')),
        // "label": String(receivingDate).slice(0, 15),
        "btnIcon": "documentOutline",
        "btnColor": "success",
        "tooltip": "Warehouse Receiving Register"
      },

      email: {
        tooltip: "Generate another email",
        label: "",
        btnColor: "warning",
        btnLabel: "email",
        btnIcon: "mailOutline",
      },
      confirmArrival: !editShipment ? {
        tooltip: "Confirm arrival",
        label: "",
        btnColor: "warning",
        btnIcon: "calendarOutline",
      } : editShipment.confirmArrival,
      qaCheck: !editShipment ? {
        tooltip: "Confirm QA check",
        label: "",
        btnColor: "primary",
        btnLabel: "check",
        btnIcon: "searchOutline",
      } : editShipment.qaCheck,
      inventoryAdded: !editShipment ? "false" : editShipment.inventoryAdded,
      sss: !editShipment ? {
        tooltip: "Waiting for SSS",
        label: "",
        btnLabel: "SSS",
        btnColor: "warning",
        btnIcon: "alertOutline",
      } : editShipment.sss,
      boxTemperature,
      // boxType,
      packingListNumber,
      boxes
    };

    if (editShipment) {
      await updateItem('inbound', ["sponsor_id", "inbound_id", "order_number", "data"], [
        sId,
        id,
        id,
        newShipment
      ], { inbound_id: id }).then(() => window.location.href = "/inbound-shipments");
      setEditShipment();
      history.push('/inbound-shipments');
      return;
    }

    addShipment(newShipment).then(() => {
      setTimeout(() => {
        window.location.href = '/inbound-shipments';
      }, 1000);
    });
  }

  useEffect(() => {
    if (sponsor && !editShipment) {
      const data = studiesOriginal.filter((opt) => (opt.sponsor && (opt.sponsor.trim().toUpperCase() === sponsor.name.trim().toUpperCase() || (opt.sponsor.trim().toUpperCase() === sponsor.shortName.trim().toUpperCase()))));

      selectStudy.current.clearAll();
      setStudies(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsor])

  return (
    <BaseTemplate customBack={setEditShipment ? () => setEditShipment(null) : null}>
      <IonGrid className="orders-processing form">
        <IonRow style={{ boxShadow: "none" }}>
          <IonCol>
            <div
              className="shadow-xl py-4 px-10"
              style={{ backgroundColor: "white" }}
            >
              <h2 className="form-heading" style={{ marginLeft: "25px", fontWeight: "bold" }}>
                {editShipment ? "Edit" : "New"} Inbound Shipment{" "}
              </h2>
              <IonGrid style={{ marginTop: "2em" }}>
                <IonRow>
                  <IonCol>
                    <div style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
                      <Select ref={selectSponsor}
                        placeholder={sponsors.length === 0 ? "Loading..." : `Search Sponsor (${sponsors.length})`}
                        labelField="name"
                        searchBy="name"
                        valueField="name"
                        loading={sponsors.length === 0}
                        dropdownHandle={true}
                        searchable={true}
                        options={sponsors}
                        values={sponsor ? [sponsor] : []}
                        onChange={(values) => {
                          if (values.length > 0) {
                            setSponsor(values[0]);
                            setSponsorId(values[0].id);
                          }
                        }}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
                      <Select ref={selectStudy}
                        placeholder={studies.length === 0 ? "Loading..." : `Search Study (${studies.length})`}
                        labelField="labelField"
                        searchBy="labelField"
                        valueField="studyNumber"
                        multi={false}
                        loading={studies.length === 0}
                        dropdownHandle={true}
                        searchable={true}
                        values={study ? [study] : []}
                        options={studies}
                        onChange={(values) => {

                          if (values.length > 0) {
                            setStudy(values[0]);
                          }
                        }}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput onClick={(e) => setShowReceivingDate({ showPopover: true, event: e })} placeholder="Receiving Date" value={receivingDate ? format(new Date(receivingDate), 'MM d y') : ''} />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput
                        value={clearingAgent}
                        onIonChange={(e) => setClearingAgent(e.detail.value)}
                        placeholder={"Clearing Agent"}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput
                        placeholder={"Courier COY"}
                        value={courierCoy}
                        onIonChange={(e) => setCourierCoy(e.detail.value)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput
                        placeholder={"Invoice Number"}
                        value={invoiceNumber}
                        onIonChange={(e) => setInvoiceNumber(e.detail.value)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <div style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
                      <IonSelect
                        className="dropdown-popup border"
                        interface="popover"
                        value={boxTemperature}
                        placeholder="Box Temperature"
                        okText="Okay"
                        cancelText="Dismiss"
                        interfaceOptions={{
                          header: 'Select Box Temperature', // Header text
                        }}
                        onIonChange={e => setBoxTemperature(e.detail.value)}
                      >
                        {BOX_TEMPERATURES.map((temp, i) => (
                          <IonSelectOption key={i} value={temp}>
                            {temp}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput
                        placeholder={"Number of boxes"}
                        type="text"
                        value={boxes}
                        onIonChange={(e) => setBoxes(e.detail.value)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput
                        placeholder={"Packing List Number"}
                        value={packingListNumber}
                        onIonChange={(e) => setPackingListNumber(e.detail.value)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none">
                      <IonInput
                        value={mawb}
                        onIonChange={(e) => setMawb(e.detail.value)}
                        placeholder={"MAWB"}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow></IonRow>
                <IonRow>
                  <IonCol>
                    {!zarValue && (
                      <IonItem lines="none">
                        <IonButton
                          expand="block"
                          color="success"
                          onClick={() => setShowCurrencyConvertor(true)}
                        >
                          <IonIcon icon={cashOutline} slot="start" />
                          ZAR VALUE
                        </IonButton>
                      </IonItem>
                    )}

                    {zarValue && (
                      <IonItem lines="none">
                        {zarValue}
                        <IonButton
                          slot="end"
                          onClick={() => setShowCurrencyConvertor(true)}
                        >
                          edit
                        </IonButton>
                      </IonItem>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow style={{ paddingTop: "3em" }}>
                  <IonCol>
                    <IonButton
                      color="success"
                      expand="full"
                      onClick={handleSubmit}
                      disabled={hasSubmitted}
                    >
                      {editShipment ? "Update" : "Create"} Shipment
                      <IonIcon icon={checkmarkCircle} slot="start" />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Modal
        showModal={showCurrencyConvertor}
        setShowModal={setShowCurrencyConvertor}
      >
        <CurrencyConverter
          setZarValue={setZarValue}
          zarValueIndex={editShipment ? editShipment.index : 0}
          setShowModal={setShowCurrencyConvertor}
          defaultValue={editShipment && editShipment.zarValue && typeof editShipment.zarValue === 'string' ? editShipment.zarValue : editShipment && editShipment.zarValue && typeof editShipment.zarValue === 'object' ? editShipment.zarValue.label : 0}
        />
      </Modal>
      {/* Making a modal that will display the date picker on , It should be response and fit in the middle of the screen it shouldnt take up the whole screen and should be about 30% width*/}
      <IonModal
        cssClass="day-modal-picker"
        isOpen={showReceivingDate.showPopover}
        event={showReceivingDate.event}
        onDidDismiss={() => setShowReceivingDate({ showPopover: false, event: undefined })}
        style={{ width: '10px !important', height: '10px' }}
      >
        <DayPicker canChangeMonth={true} onDayClick={(day) => { setReceivingDate(day.toString()); setShowReceivingDate({ showPopover: false, event: undefined }); }} />
        <IonButton onClick={() => setShowReceivingDate({ showPopover: false, event: undefined })}>Close</IonButton>
      </IonModal>
    </BaseTemplate>
  );
}
