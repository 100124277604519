import { useRef } from 'react';

const DocumentUpload = ({children, setFile}) => {
    const filesRef = useRef();

    function handleFilesClick() {
        if(filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function handleFilesUpload(e) {
        e.persist();
        if(e && e.target.files && e.target.files.length > 0) {
            [].forEach.call(e.target.files, (file) => {
                const reader = new FileReader();
                reader.onload = function() {
                    if(typeof reader.result === 'string') {
                        const tempFile = {
                            // @ts-ignore
                            file_name: file.name,
                            file_data: reader.result,
                        };

                        setFile({...tempFile});
                        // uploadImage(tempFile);
                    }
                };
                reader.onerror = function() {};

                reader.readAsDataURL(file);
            })
        }
    }

    return (
        <>
            <input style={{visibility: 'hidden', height: 0, margin: 0, padding: 0}} type="file" ref={filesRef} onChange={e => handleFilesUpload(e)} accept=".pdf" />
            <div onClick={handleFilesClick} style={{margin: 0, padding: 0}}>
                {children}
            </div>
        </>
    );
};

export default DocumentUpload;