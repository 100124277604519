import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonModal, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { format } from 'date-fns';
import { add, checkmarkCircle } from 'ionicons/icons';
import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import useAsyncEffect from 'use-async-effect';
import { addReturn, generateOrderNumber, getSite, getSiteById, getSponsorById, getSponsorByName, getStudyById, getStudyByStudyNumber, updateItem } from '../../apis/logictrials';
import AddNewBoxForm from '../../components/AddNewBoxForm';
import BaseTemplate from '../../components/base/BaseTemplate';
import Modal from '../../components/Modal';
import { displayAlert, isValidDate, sopsToArr } from '../../utils';

import publicHolidays from '../../utils/publicHolidays';

import 'react-day-picker/lib/style.css';

const ACTIONS = [
    "Destruction",
    "Over Supply",
    "Recall",
    "Quarantine",
    "Reject",
    "Collection",
    "TT4 Return",
    "Quarantined due to damaged box"
];

export default function ReturnsProcessing({ returnObject, setDisplayReturn }) {
    const [showAddBoxModal, setShowBoxModal] = useState(false);
    const [returnItem, setReturn] = useState();
    const [study, setStudy] = useState();
    const [sponsor, setSponsor] = useState();
    const [site, setSite] = useState();
    const [returnAction, setReturnAction] = useState('');
    const [referenceNumber, setReferenceNumber] = useState();
    const [boxes, setBoxes] = useState();
    const [boxTypes, setBoxTypes] = useState([]);
    const [arrivalDate, setArrivalDate] = useState();
    const [aramexRef, setAramexRef] = useState('');
    const [aramexAccount, setAramexAccount] = useState(null)
    const [gnNumber, setGnNumber] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showArrivalDate, setShowArrivalDate] = useState({ showPopover: false, event: undefined });
    // <----CM_Changes made for waybills for the return process---->
    const [aramexNeeded, setAramexNeeded] = useState(false);
    const [logicDriverNeeded, setLogicDriverNeeded] = useState(false);
    const [otherDriverNeeded, setOtherDriverNeeded] = useState(false);
    const [manualWaybill, setManualWaybill] = useState('');
    const [logicTrialsWaybill, setLogicTrialsWaybill] = useState();
    const [serviceType, setServiceType] = useState('PEC');
    const [noKits, setNoKits] = useState('');
    const [editBox, setEditBox] = useState(null);
    const nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    const [pickUpTime, setPickUpTime] = useState('');

    useAsyncEffect(async () => {

        if (returnObject) {
            setReturn(returnObject)

            setAramexNeeded(returnObject.aramexNeeded ? true : false);

            setLogicDriverNeeded(returnObject.logicDriverNeeded ? true : false);

            setOtherDriverNeeded(returnObject.otherDriverNeeded ? true : false);


            //Old way of the label setAramexRef(returnObject.aramexRef.label);
            if (returnObject.aramexNeeded === true) {
                setManualWaybill(returnObject.waybill.label);
                setAramexRef(returnObject.aramexRef.label);
            }

            if (returnObject.logicDriverNeeded === true) {
                setManualWaybill(returnObject.waybill.label);
                setAramexRef("Logic Driver");
            }
            if (returnObject.otherDriverNeeded === true) {
                setManualWaybill(returnObject.waybill.label);
                setAramexRef("Other Driver");
            }
            if (returnObject.boxTypes) { setBoxTypes(returnObject.boxTypes) };

            if (returnObject.referenceNo && returnObject.referenceNo.label) {
                setReferenceNumber(returnObject.referenceNo.label);
            }

            if (returnObject && returnObject.serviceType) {
                setServiceType(returnObject.serviceType);
            }

            if (returnObject.StudyID) {
                getStudyById(returnObject.StudyID).then(res => {
                    if (res && res[0] && res[0].data) {
                        const tempStudy = JSON.parse(res[0].data);

                        if (res[0].id) tempStudy.id = res[0].id;

                        tempStudy.labelField = `${tempStudy.sponsor} - ${tempStudy.studyNumber}`;

                        setStudy(tempStudy);
                    }
                });
            } else if (returnObject.Study) {
                getStudyByStudyNumber(returnObject.Study).then(res => {
                    if (res && res[0] && res[0].data) {
                        const tempStudy = JSON.parse(res[0].data);

                        if (res[0].id) tempStudy.id = res[0].id;

                        tempStudy.labelField = `${tempStudy.sponsor} - ${tempStudy.studyNumber}`;

                        setStudy(tempStudy);
                    }
                });
            }

            if (returnObject.SponsorID) {
                getSponsorById(returnObject.SponsorID).then(res => {
                    if (res && res[0] && res[0].data) {
                        const parsed = JSON.parse(res[0].data);

                        if (res[0].id) parsed.id = res[0].id;

                        setSponsor(parsed);
                    }
                });
            } else if (returnObject.sponsor) {
                getSponsorByName(returnObject.sponsor).then(res => {
                    if (res && res[0] && res[0].data) {
                        const parsed = JSON.parse(res[0].data);

                        if (res[0].id) parsed.id = res[0].id;

                        setSponsor(parsed);
                    }
                });
            }
            sopsToArr(returnObject.sops).then(sops => {
                const siteNum = sops[sops.length - 1];

                if (returnObject.siteID) {
                    getSiteById(returnObject.siteID).then(res => {
                        if (res && res[0] && res[0].data) {
                            const parsed = JSON.parse(res[0].data);

                            if (res[0].id) parsed.id = res[0].id;

                            setSite(parsed);
                        }
                    });
                } else {
                    getSite(siteNum).then(res => {
                        if (res && res[0] && res[0].data) {
                            const parsed = JSON.parse(res[0].data);

                            if (res[0].id) parsed.id = res[0].id;

                            setSite(parsed);
                        }
                    });
                }
                setReturnAction(returnObject.action);
                setBoxes(returnObject.boxes.label.slice(0, 2));
                setGnNumber(returnObject.gnNo);
                setAramexRef(returnObject.aramexRef.label);
                if (returnObject && returnObject.receivedDate && returnObject.receivedDate.label) {
                    setArrivalDate(new Date(returnObject.receivedDate.label).toISOString());
                }
                if (returnObject && returnObject.receivedDate && isValidDate(returnObject.receivedDate)) {
                    setArrivalDate(new Date(returnObject.receivedDate).toISOString());
                }

                if (returnObject && returnObject.numberOfKits) setNoKits(returnObject.numberOfKits);
            });

        } else {
            const study = JSON.parse(sessionStorage.getItem('lt-temp-add-return-study'));
            setStudy(study);
            const site = JSON.parse(sessionStorage.getItem('lt-temp-add-return-site'));
            setSite(site);
            const tempReturn = {
                "sss": "",
                "dspl": "",
                "edit": {
                    "btnColor": "primary",
                    "btnLabel": "Edit"
                },
                "gnNo": {
                    "btnIcon": "pricetagOutline",
                    "tooltip": "Add GN number",
                    "btnColor": "warning"
                },
                "list": "",
                "sops": "",
                "boxes": {
                    "label": "",
                    "btnIcon": "qrCodeOutline",
                    "tooltip": "Check Label(s)",
                    "btnColor": "warning"
                },
                boxTypes: boxTypes,
                "action": "Quarantine",
                "weight": {
                    "btnIcon": "addOutline",
                    "tooltip": "Add weight",
                    "btnColor": "warning"
                },
                "waybill": {
                    "label": "waybill",
                    "button1": {
                        "btnIcon": "addOutline",
                        "tooltip": "Create manual waybill",
                        "btnColor": "danger"
                    },
                    "button2": {
                        "btnIcon": "downloadOutline",
                        "tooltip": "Download waybill",
                        "btnColor": "success"
                    }
                },
                "aramexRef": {
                    "label": "",
                    "button1": {
                        "btnIcon": "sendOutline",
                        "tooltip": "Retry Aramex API",
                        "btnColor": "success"
                    },
                    "button2": {
                        "btnIcon": "addOutline",
                        "tooltip": "Add manual waybill",
                        "btnColor": "danger"
                    }
                },
                "referenceNo": {
                    "label": "",
                    "btnLabel": "update"
                },
                "requestDate": {
                    "label": "",
                    "btnIcon": "megaphoneOutline",
                    "btnColor": "danger"
                },
                "receivedDate": {
                    "btnIcon": "calendarOutline",
                    "tooltip": "Confirm arrival date",
                    "brnColor": "warning"
                },
                "collectionDate": "",
                "confirmReceipt": {
                    "btnIcon": "receiptOutline",
                    "tooltip": "Confirm receipt",
                    "brnColor": "warning"
                }
            }
            setReturn(tempReturn);
            if (study && study.sponsorId) {
                getSponsorById(study.sponsorId).then(res => {

                    if (res && res[0] && res[0].data) {
                        setSponsor(JSON.parse(res[0].data));
                    }
                });
            } else if (study && study.sponsor) {
                getSponsorByName(study.sponsor).then(res => {

                    if (res && res[0] && res[0].data) {
                        setSponsor(JSON.parse(res[0].data));
                    }
                });
            }
        }
    }, []);

    async function handleSubmit() {
        if (boxTypes.length === 0) {
            displayAlert('warning', 'No box added', 'Please add atleast one box');
            return;
        }
        if ((manualWaybill.length === 0) && !aramexNeeded) {
            displayAlert('warning', 'Waybill entered', 'Please enter a waybill');
            return;
        }

        if (!returnObject && aramexNeeded && (aramexAccount === null || (aramexAccount !== "clinical" && aramexAccount !== "ecommerce"))) {
            displayAlert('warning', 'Aramex account not selected', 'Please select an Aramex account');
            return;
        }

        setHasSubmitted(true);

        let waybillLabel = returnObject ? returnObject.waybill.label : aramexNeeded ? "" : manualWaybill;

        if (returnObject && manualWaybill && manualWaybill !== returnObject.waybill.label) waybillLabel = manualWaybill;

        if (returnObject) {
            let totWeight = 0;
            boxTypes.map(bt => totWeight += (bt.number * Number(bt.weight.replace('kg', ''))));

            const editReturn = {
                "sss": returnObject.sss,
                "dspl": returnObject.dspl,
                "gnNo": gnNumber ? gnNumber : {
                    "btnIcon": "pricetagOutline",
                    "tooltip": "Add GN number",
                    "btnColor": "warning"
                },
                SponsorID: study.sponsorId,
                StudyID: study.id,
                Study: study.studyNumber,
                "list": returnObject ? returnObject.list : "",
                "sops": returnObject.sops,
                "boxes": {
                    "label": `${boxTypes.length} (${boxTypes.length})`,
                    "btnIcon": "qrCodeOutline",
                    "tooltip": "Check Label(s)",
                    "btnColor": "warning"
                },
                boxTypes: boxTypes ? boxTypes : returnObject.boxTypes,
                action: returnAction,
                "weight": totWeight ? `${totWeight}kg` : {
                    "btnIcon": "addOutline",
                    "tooltip": "Add weight",
                    "btnColor": "warning"
                },
                "waybill": {
                    "label": logicTrialsWaybill ? logicTrialsWaybill : returnObject ? returnObject.waybill.label : aramexNeeded ? "" : manualWaybill,// returnObject ? returnObject.waybill.label : "",
                    "button1": {
                        "btnIcon": "addOutline",
                        "tooltip": "Create manual waybill",
                        "btnColor": "danger"
                    },
                    "button2": {
                        "btnIcon": "downloadOutline",
                        "tooltip": "Download waybill",
                        "btnColor": "success"
                    }
                },
                "aramexRef": {
                    "label": aramexRef,
                    "button1": {
                        "btnIcon": "sendOutline",
                        "tooltip": "Retry Aramex API",
                        "btnColor": "success"
                    },
                    "button2": {
                        "btnIcon": "addOutline",
                        "tooltip": "Add manual waybill",
                        "btnColor": "danger"
                    }
                },
                "referenceNo": {
                    "label": referenceNumber,
                    "btnLabel": "update"
                },
                "requestDate": {
                    "label": returnObject.requestDate.label,
                    "btnIcon": "megaphoneOutline",
                    "btnColor": "danger"
                },
                "receivedDate": returnObject.receivedDate ? returnObject.receivedDate : {
                    "btnIcon": "calendarOutline",
                    "tooltip": "Confirm arrival date",
                    "brnColor": "warning"
                },
                "collectionDate": arrivalDate ? arrivalDate.slice(0, 16) : '',
                "confirmReceipt": returnObject ? returnObject.confirmReceipt : {
                    "btnIcon": "receiptOutline",
                    "tooltip": "Confirm receipt",
                    "brnColor": "warning"
                },
                sponsorName: returnObject.sponsorName ? returnObject.sponsorName : sponsor && sponsor.name ? sponsor.name : '',
                sponsorID: returnObject.sponsorID ? returnObject.sponsorID : sponsor && sponsor.id ? sponsor.id : '',
                SponsorID: returnObject.sponsorID ? returnObject.sponsorID : sponsor && sponsor.id ? sponsor.id : '',
                sponsorShortName: returnObject.sponsorShortName ? returnObject.sponsorShortName : sponsor && sponsor.shortName ? sponsor.shortName : '',
                siteNumber: returnObject.siteNumber ? returnObject.siteNumber : site && site.number,
                siteID: returnObject.siteID ? returnObject.siteID : site && site.id ? site.id : '',
                studyNumber: returnObject.studyNumber ? returnObject.studyNumber : study && study.studyNumber ? study.studyNumber : '',
                studyID: returnObject.studyID ? returnObject.studyID : study && study.id ? study.id : '',
                numberOfKits: noKits, aramexNeeded, logicDriverNeeded, otherDriverNeeded,
            }
            boxTypes.map(bt => totWeight += (bt.number * Number(bt.weight.replace('kg', ''))));
            const where = { id: returnObject.id };

            if (editReturn && editReturn.parcels && typeof editReturn.parcels === 'string') {
                editReturn.parcels = JSON.parse(editReturn.parcels);
            }

            updateItem('returns', ['data'], [editReturn], where).then(res => {
                setDisplayReturn(false);
                window.location.reload();
            });
            return;
        }

        if (!returnObject) {
            const orderNumber = await generateOrderNumber('returns');
            let totWeight = 0;
            boxTypes.map(bt => totWeight += (((bt && bt.number) ? bt.number : 0) * ((bt && bt.weight && typeof bt.weight === 'string') ? Number(bt.weight.replace('kg', '')) : 0)));

            if (!arrivalDate || (arrivalDate && !isValidDate(new Date(arrivalDate)))) {
                displayAlert('warning', 'Pick up date invalid', 'Please select a pick up date');
                setHasSubmitted(false);

                return;
            }

            if (!pickUpTime) {
                displayAlert('warning', 'Pick up time invalid', 'Please enter a pick up time between 08:00 and 17:00');
                setHasSubmitted(false);

                return;
            }

            let arrivalDateWithTime;
            if ((arrivalDate && isValidDate(new Date(arrivalDate))) && pickUpTime) {
                const hoursMins = pickUpTime.split(':');

                let tempDate = new Date(arrivalDate);
                arrivalDateWithTime = tempDate.setHours(hoursMins[0]);
                arrivalDateWithTime = tempDate.setMinutes(hoursMins[1]);

                arrivalDateWithTime = new Date(arrivalDateWithTime).toISOString();

                let date = new Date(arrivalDateWithTime.replace(/"/g, ''));
                date.setHours(date.getHours() + 2);
            }

            if (arrivalDateWithTime && isValidDate(new Date(arrivalDateWithTime)) && (new Date(arrivalDateWithTime).getHours() < 8 || new Date(arrivalDateWithTime).getHours() > 17)) {
                displayAlert('warning', 'Pick up time invalid', 'Pick up time must be between 08:00 and 17:00');
                setHasSubmitted(false);

                return;
            }
            //This is to remove the studyName from the site number  
            const convertNumber = (num, studyName) => {
                if (num && studyName && num.includes(studyName)) {
                    return num.replace(studyName, '');
                } else {
                    return num;
                }
            }

            //  ? format(new Date(arrivalDate), 'yyyy-MM-d hh:mm')
            const newReturn = {
                "sss": returnObject ? returnObject.sss : "",
                "dspl": returnObject ? returnObject.dspl : "",
                "gnNo": gnNumber ? gnNumber : {
                    "btnIcon": "pricetagOutline",
                    "tooltip": "Add GN number",
                    "btnColor": "warning"
                },
                SponsorID: returnObject ? returnObject.SponsorID : study.sponsorId,
                StudyID: returnObject ? returnObject.StudyID : study.id,
                Study: returnObject ? returnObject.Study : study.studyNumber,
                "list": returnObject ? returnObject.list : "",
                "sops": !returnObject ? `${study.studyNumber} ${orderNumber} ${convertNumber(site.number, study?.studyNumber)}` : returnObject.sops,
                "boxes": {
                    "label": returnObject ? returnObject.boxes.label : `${boxTypes.length} (${boxTypes.length})`,
                    "btnIcon": "qrCodeOutline",
                    "tooltip": "Check Label(s)",
                    "btnColor": "warning"
                },
                boxTypes,
                action: returnAction,
                "weight": totWeight ? `${totWeight}kg` : {
                    "btnIcon": "addOutline",
                    "tooltip": "Add weight",
                    "btnColor": "warning"
                },
                "waybill": {
                    "label": !manualWaybill ? returnObject ? returnObject.waybill.label : "No waybill" : manualWaybill,
                    "button1": {
                        "btnIcon": "addOutline",
                        "tooltip": "Create manual waybill",
                        "btnColor": "danger"
                    },
                    "button2": {
                        "btnIcon": "downloadOutline",
                        "tooltip": "Download waybill",
                        "btnColor": "success"
                    }
                },
                "aramexRef": {
                    "label": aramexRef ? aramexRef : "N/A",
                    "button1": {
                        "btnIcon": "sendOutline",
                        "tooltip": "Retry Aramex API",
                        "btnColor": "success"
                    },
                    "button2": {
                        "btnIcon": "addOutline",
                        "tooltip": "Add manual waybill",
                        "btnColor": "danger"
                    }
                },
                "referenceNo": {
                    "label": referenceNumber,
                    "btnLabel": "update"
                },
                "requestDate": {
                    "label": returnObject ? returnObject.requestDate.label : new Date().toISOString().slice(0, 10),
                    "btnIcon": "megaphoneOutline",
                    "btnColor": "danger"
                },
                "receivedDate": {
                    "btnIcon": "calendarOutline",
                    "tooltip": "Confirm date received",
                    "brnColor": "warning"
                },
                "collectionDate": arrivalDateWithTime ? arrivalDateWithTime : (arrivalDate && isValidDate(new Date(arrivalDate))) ? format(new Date(arrivalDate), 'yyyy-MM-d hh:mm') : {
                    "btnIcon": "calendarOutline",
                    "tooltip": "Confirm collection date",
                    "brnColor": "warning"
                },
                "confirmReceipt": returnObject ? returnObject.confirmReceipt : {
                    "btnIcon": "receiptOutline",
                    "tooltip": "Confirm receipt",
                    "brnColor": "warning"
                },
                serviceType,
                sponsorName: sponsor && sponsor.name ? sponsor.name : '',
                sponsorID: sponsor && sponsor.id ? sponsor.id : '',
                sponsorShortName: sponsor && sponsor.shortName ? sponsor.shortName : '',
                siteNumber: site && site.number,
                siteID: site && site.id ? site.id : '',
                studyNumber: study && study.studyNumber ? study.studyNumber : '',
                studyID: study && study.id ? study.id : '',
                numberOfKits: noKits,
            }
            boxTypes.forEach(bt => {
                if (bt && bt.weight && (typeof bt.weight !== 'undefined')) totWeight += (bt.number * Number(bt.weight.replace('kg', '')));
            });

            const parcels = boxTypes.map((bt, i) => {
                return {
                    "parcel_number": `${i + 1}`, //?
                    "quantity": Number(bt.number), //number of boxes
                    "length": Number(bt.length), //length of box ??
                    "width": Number(bt.width), //width of box ??
                    "height": Number(bt.height), //height of selected box ??
                    "weight": Number(bt.weight)
                }
            });
            // Add Aramex account if needed
            // This must be done separately as BE cannot have an ref to an account number if aramex is not needed - will cause issues
            if (aramexNeeded) {
                newReturn.aramexAccount = aramexAccount;
            }

            addReturn({ ...newReturn, parcels, aramexNeeded, siteId: site.id }).then(res => {
                setTimeout(() => {
                    window.location.href = "/collections-and-returns";
                }, 2000);
            });
            return;
        }
    }
    function handleDeliverySelect(selectedDeliveryMethod) {
        if (selectedDeliveryMethod === "None") {
            setAramexNeeded(false);
            setLogicDriverNeeded(false);
            setOtherDriverNeeded(false);
        }
        if (selectedDeliveryMethod === "Aramex") {
            setAramexNeeded(true);
            setLogicDriverNeeded(false);
            setOtherDriverNeeded(false);
        }

        if (selectedDeliveryMethod === "LogicTrials") {
            setLogicDriverNeeded(true);
            setAramexNeeded(false);
            setOtherDriverNeeded(false);
            //Automatic waybill
            //let ltWaybill = `LTD${String(new Date().getFullYear()).replace('20', '')}${new Date().getMonth() + 1}${Math.floor(Math.random() * 1000)}${new Date().getSeconds()}`
            //setLogicTrialsWaybill(ltWaybill);
        }
        if (selectedDeliveryMethod === "Other") {
            setAramexNeeded(false);
            setLogicDriverNeeded(false);
            setOtherDriverNeeded(true);
        }

    }
    function removeBox(index) {
        const filteredBoxes = boxTypes.filter((b, i) => index !== i);

        setBoxTypes(filteredBoxes);
    }

    return (
        <BaseTemplate customBack={returnObject ? () => setDisplayReturn(null) : undefined}>
            <IonGrid>
                <IonRow style={{ boxShadow: "none" }}>
                    <IonCol>
                        <div
                            className="shadow-xl py-4 px-10"
                            style={{ backgroundColor: "white" }}
                        >
                            {returnItem && <h2 className="form-heading">Return Processing</h2>}
                            {returnItem && <IonGrid style={{ marginTop: '2em' }}>
                                <IonRow>
                                    <IonCol>
                                    </IonCol>
                                    <IonCol>
                                        Sponsor: {sponsor && sponsor.name} <br />
                                        Study: {study && study.studyNumber} <br />
                                        Investigator: {site && site.investigator} <br />
                                    </IonCol>
                                    <IonCol>
                                        Site: {site && site.number} <br />
                                        Site Contact: {site && site.contact} <br />
                                        Tel: {site && site.tel} <br />
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ marginTop: '2em' }}>
                                    {boxTypes.length > 0 && boxTypes.map((box, i) => (
                                        <IonCol style={{ textAlign: 'left' }}>
                                            <IonCard onClick={_ => { setShowBoxModal(true); setEditBox({ ...box, index: i }) }}>
                                                <IonCardContent>
                                                    <div key={box.serialNumber} style={{ textAlign: 'left', position: "relative" }} >
                                                        <i class="fas fa-times-circle" onClick={(e) => { e.stopPropagation(); removeBox(i); }} style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}></i>
                                                        <p><strong>Box {i + 1}</strong></p>
                                                        Type: {box.boxType}<br />
                                                        Temperature: {box.boxTemperature}<br />
                                                        Serial Number: {box.tt4Serial}<br />
                                                        Total needed: {box.number}
                                                    </div>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    ))}
                                    <IonCol>

                                    </IonCol>
                                    <IonCol>
                                        <IonButton color="primary" expand="full" onClick={() => setShowBoxModal(true)}>
                                            <IonIcon icon={add} slot="start" />
                                            Box
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ marginTop: '2em' }}>
                                    <IonCol size="3">
                                        <IonItem lines="none">
                                            <IonInput value={referenceNumber} onIonChange={e => setReferenceNumber(e.detail.value)} placeholder={"Reference Number"} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonItem lines="none">
                                            <IonInput value={gnNumber} onIonChange={e => setGnNumber(e.detail.value)} placeholder={"GN Number"} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonItem lines="none">
                                            <IonInput onClick={(e) => setShowArrivalDate({ showPopover: true, event: e })} placeholder="Pick Up Date" value={arrivalDate ? format(new Date(arrivalDate), 'MM d y') : ''} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonItem lines="none">
                                            Pickup Time: <span style={{ width: 20 }} /> <IonInput min="08:00" max="17:00" placeholder="Pick Up Time" type="time" value={pickUpTime} onIonChange={e => setPickUpTime(e.detail.value)} />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-md="3">
                                        <div style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
                                            <IonSelect className="border" interface="popover" interfaceOptions={{ header: "Select an Action" }} value={returnAction} okText="Okay" placeholder="Return Action" cancelText="Dismiss" onIonChange={e => setReturnAction(e.detail.value)}>
                                                {ACTIONS.map((a, i) => (
                                                    <IonSelectOption key={i} value={a}>{a}</IonSelectOption>
                                                ))}
                                            </IonSelect>
                                        </div>
                                    </IonCol>
                                    {!returnObject && <IonCol size-md="3">
                                        <IonItem lines="none">
                                            <IonSelect interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} onIonChange={(e) => handleDeliverySelect(e.detail.value)} placeholder="Select Delivery Method">
                                                <IonSelectOption value="None">No Courier Option Required</IonSelectOption>
                                                <IonSelectOption value="Aramex">Aramex</IonSelectOption>
                                                <IonSelectOption value="LogicTrials">LogicTrials Driver</IonSelectOption>
                                                <IonSelectOption value="Other">Other</IonSelectOption>
                                            </IonSelect>
                                            {/* <IonCheckbox isChecked={aramexNeeded} onIonChange={e => setAramexNeeded(e.detail.checked)} slot="end" /> */}
                                        </IonItem>
                                    </IonCol>}
                                    {!aramexNeeded && (!returnObject || returnObject.aramexNeeded === false) && <IonCol size="3">
                                        <IonItem lines="none">
                                            <IonInput value={manualWaybill} onIonChange={e => setManualWaybill(e.detail.value)} placeholder="Manual waybill" />
                                        </IonItem>
                                    </IonCol>}
                                    {(aramexNeeded && !returnObject) && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select an Aramex Account" }} value={aramexAccount} placeholder="Aramex Account Selection" onIonChange={e => setAramexAccount(e.detail.value)}>
                                            <IonSelectOption value="clinical">Clinical trial</IonSelectOption>
                                            <IonSelectOption value="ecommerce">Ecommerce</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                    {(aramexNeeded && !returnObject) && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} value={serviceType} placeholder="Aramex service type" onIonChange={e => setServiceType(e.detail.value)}>
                                            <IonSelectOption value="SMP">Same day shipment (SMP)</IonSelectOption>
                                            <IonSelectOption value="EMD">Early morning delivery (EMD)</IonSelectOption>
                                            <IonSelectOption value="ONP">Overnight delivery (ONP)</IonSelectOption>
                                            <IonSelectOption value="PEC">Economy (PEC)</IonSelectOption>
                                            <IonSelectOption value="PEX">Road Express (PEX)</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                    {logicDriverNeeded && !returnObject && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} value={serviceType} placeholder="LogicTrials service type" onIonChange={e => setServiceType(e.detail.value)}>
                                            <IonSelectOption value="SMP">Same day shipment (SMP)</IonSelectOption>
                                            <IonSelectOption value="ONP">Overnight delivery (ONP)</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                    {otherDriverNeeded && !returnObject && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} value={serviceType} placeholder="Other courier service type" onIonChange={e => setServiceType(e.detail.value)}>
                                            <IonSelectOption value="SMP">Same day shipment (SMP)</IonSelectOption>
                                            <IonSelectOption value="ONP">Overnight delivery (ONP)</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                    <IonCol size="3">
                                        <IonItem lines="none">
                                            <IonInput value={noKits} onIonChange={e => setNoKits(e.detail.value)} placeholder={"Number of kits"} />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ paddingTop: '3em' }}>
                                    <IonCol size-md="4">
                                    </IonCol>
                                    <IonCol size-md="4">
                                    </IonCol>
                                    <IonCol size-md="4">
                                        <IonButton disabled={hasSubmitted} onClick={handleSubmit} color="success" expand="full">
                                            Process
                                            <IonIcon icon={checkmarkCircle} slot="start" />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            {showAddBoxModal && <Modal className="add-new-box-modal" showModal={showAddBoxModal} setShowModal={setShowBoxModal} onDidDismiss={() => { setShowBoxModal(false); setEditBox(null); }}>
                <AddNewBoxForm editBox={editBox} setBoxTypes={setBoxTypes} boxTypes={boxTypes} setShowModal={setShowBoxModal} boxes={boxes} setBoxes={setBoxes} />
            </Modal>}
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showArrivalDate.showPopover}
                event={showArrivalDate.event}
                onDidDismiss={() => setShowArrivalDate({ showPopover: false, event: undefined })}
            >
                <DayPicker
                    canChangeMonth={true}
                    onDayClick={(day) => {
                        setArrivalDate(day.toString());
                        setShowArrivalDate({ showPopover: false, event: undefined });
                    }}
                    disabledDays={[{ daysOfWeek: [0, 6] }, { after: nextWeek, before: new Date() }, ...publicHolidays]}
                />
                <IonButton onClick={() => setShowArrivalDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
        </BaseTemplate>
    )
}
