import React, { useState } from 'react';
import  { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonItem, IonButton, IonTextarea } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';

export default function AorTelTimeEmailFollowup({updateReturn, setShowModal}) {
    const [noDocsReceived, setNoDocsReceived] = useState(false);
    const [comments, setComments] = useState('');

    const submit = () => {
        let updated = updateReturn;

        updated.aorTelTime = 'Done';
        updated.aorTelTimeComments = comments;
        updated.aorTelTimeNoDocsReceived = noDocsReceived;
        updateItem('orders', ['data'], [updated], {id: updateReturn.id}).then(res => {
        window.location.href = '/orders-and-dispatch';
        setShowModal(false);
    })};

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonTextarea value={comments} onIonChange={e => setComments(e.detail.value)} placeholder="Comments" />
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>
                                No docs received: 
                            </IonLabel>
                            <IonCheckbox value={noDocsReceived} onIonChange={e => setNoDocsReceived(!noDocsReceived)} />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="9">
                        
                    </IonCol>
                    <IonCol size="3" style={{textAlign: 'right'}}>
                        <IonButton color="warning" onClick={submit}>
                            Update
                        </IonButton>    
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
