import XLSX from "xlsx";
import { getSite, getAccessToken } from "../apis/logictrials";
import decode from "jwt-decode";

export function exportArrayToExcel(array, name) {
  let arrayWS = XLSX.utils.json_to_sheet(array);

  // Create a new Workbook
  var wb = XLSX.utils.book_new();

  if (name && name.length > 31) {
    name = name.slice(0, 30);
  }

  // Name your sheet
  XLSX.utils.book_append_sheet(wb, arrayWS, `${name}`);

  // export your excel
  XLSX.writeFile(wb, `${name}.xlsx`);
}

export function browserType() {
  window.navigator.sayswho = (function () {
    var ua = window.navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem !== null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2]
      ? [M[1], M[2]]
      : [window.navigator.appName, window.navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  })();

  return window.navigator.sayswho;
}

export function parseQueryString() {
  if (window && window.location && window.location.search) {
    let str = window.location.search.replaceAll("%20", " ");
    let objURL = {};

    str.replace(
      new RegExp("([^?=&]+)(=([^&]*))?", "g"),
      function ($0, $1, $2, $3) {
        objURL[$1] = $3;
      }
    );
    return objURL;
  }

  return {};
}

export function displayAlert(type, title, message) {
  const event = new CustomEvent("display-alert", {
    detail: {
      alertTitle: title,
      alertMessage: message,
      alertType: type,
    },
  });

  window.dispatchEvent(event);
}

export function isValidDate(d) {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  } else {
    // not a date
    return false;
  }
}

export function isValidYear(d) {
  if (isValidDate(new Date(d))) {
    const year = new Date(d).getFullYear().toString();

    if (year && year.length !== 4) return false;

    return true;
  }

  return false;
}

export async function sopsToArr(sopsString) {
  if (typeof sopsString !== "string") return sopsString;
  let sopsArr = sopsString.split(" ");
  let lenA = sopsArr.length;
  if (lenA <= 4) return sopsArr;

  let sites = [];
  let siteNumber = "";
  let siteNumberLen = 0;
  for (let c = lenA - 3; c > 0; c--) {
    siteNumber = "";
    for (let d = c; d > 0; d--) {
      siteNumber += sopsArr[lenA - d];
      if (d !== 1) siteNumber += " ";
    }
    sites = await getSite(siteNumber);

    if (sites.length > 0) {
      siteNumberLen = c;
      break;
    }
  }

  if (siteNumberLen > 0) {
    sopsArr.length = lenA - siteNumberLen;
    if (sopsArr.length > 3) {
      let study = "";
      for (let e = 0; e < sopsArr.length - 2; e++) {
        study += sopsArr[e];
        if (e < sopsArr.length - 3) study += " ";
      }
      sopsArr = [
        study,
        sopsArr[sopsArr.length - 2],
        sopsArr[sopsArr.length - 1],
      ];
    }
    sopsArr.push(siteNumber);
  }

  return sopsArr;
}

export function share(aramexRef) {
  if (navigator.share) {
    navigator
      .share({
        text: `Aramex reference number: ${aramexRef}`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
}

export function downloadFile(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
}

export function checkJwt(token) {
  if (!token) token = sessionStorage.getItem("lt-access-token");

  if (token) {
    const decoded = decode(token);

    if (new Date(decoded.exp * 1000) < new Date()) {
      return true;
    }

    return false;
  }
}

export function logout() {
  sessionStorage.clear();
  window.location.href = "/login";
}

export async function checkTokens() {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const refreshToken = sessionStorage.getItem("lt-refresh-token");

  if (!accessToken && !refreshToken) {
    logout();

    return Promise.resolve(true);
  }

  const accessExpired = checkJwt(accessToken);
  const refreshExpired = checkJwt(refreshToken);

  if (accessExpired && !refreshExpired && accessToken && refreshToken) {
    const tokens = await getAccessToken(refreshToken);

    return Promise.resolve(tokens);
  }

  if (accessExpired && refreshExpired && accessToken && refreshToken) {
    logout();

    return Promise.resolve(true);
  }

  if (
    (accessExpired || !accessToken) &&
    window.location.pathname !== "/login"
  ) {
    logout();

    return Promise.resolve(true);
  }
}

export function isValidJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isUpperCase(str) {
  return /^[^a-z]*$/.test(str);
}
