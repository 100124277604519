import React, { useState, useEffect } from 'react';
import { IonItem, IonInput, IonButton } from '@ionic/react';
import { updateItem } from '../apis/logictrials';

export default function UpdateReference({ obj, originalNumber, setShowModal }) {
    const [ updatedReference, setReferenceNumber ] = useState(originalNumber);

    useEffect(() => {
        setReferenceNumber(originalNumber);
    }, [originalNumber]);

    async function submitReference() {
        let tempObj = obj;
        tempObj.referenceNo = {
            label: updatedReference,
            btnLabel: "update",
        }; 

        await updateItem('returns', ['data'], [tempObj], { id: obj.id });
        
        setShowModal(false);
    }
    
    return (
        <div>
            <IonItem>
                <IonInput value={updatedReference} onIonChange={e => setReferenceNumber(e.detail.value)} />
                <IonButton onClick={() => submitReference()}>Update</IonButton>
            </IonItem>
        </div>
    )
}
