import React from 'react';
import { useState, useEffect } from 'react';
import { IonSelect, IonSelectOption, IonList, IonItem } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';
import { format } from 'date-fns';

export default function QaCheck({ qaRef, value, setValue, inboundObject, setShowModal }) {
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        return function cleanup() {
            setIsActive(false);
        }
    }, [isActive]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%', flexDirection: 'column', marginTop: '3em' }}>
            <div style={{ width: '100%' }}>
                <IonSelect className="border" interface="popover" value={inboundObject.qaCheck} placeholder="Check" onIonChange={(e) => {
                    if (isActive) {
                        let tempInbound = inboundObject;
                        if (e && e.detail && e.detail.value && typeof e.detail.value === 'string') {
                            tempInbound.qaCheck = e.detail.value;
                            const user = JSON.parse(sessionStorage.getItem('lt-user'));
                            const userName = user.firstname;
                            tempInbound[e.detail.value.replaceAll(' ', '').toLowerCase()] = `${e.detail.value} ${new Date().toISOString().slice(0, 10)} ${userName}`;

                            const statusObject = {
                                user: user,
                                dateCreated: new Date(),
                                status: e.detail.value
                            };

                            tempInbound.qaSteps = tempInbound.qaSteps ? [...tempInbound.qaSteps, statusObject] : [statusObject];

                            updateItem('inbound', ['data'], [tempInbound], { id: tempInbound.db_id }).then(res => window.location.href = "/inbound-shipments");
                        }
                    }
                }}>
                    <IonSelectOption value="Quarantine">Quarantine</IonSelectOption>
                    <IonSelectOption value="Awaiting Status">Awaiting Status</IonSelectOption>
                    <IonSelectOption value="Awaiting QA Release">Awaiting QA Release</IonSelectOption>
                    <IonSelectOption value="QA Release">QA Release</IonSelectOption>
                </IonSelect>
                <IonList>
                    {inboundObject && inboundObject.qaSteps && inboundObject.qaSteps.length > 0 ? inboundObject.qaSteps.map(q => (
                        <IonItem>{q.status} by {q.user.firstname} {q.user.lastname} {format(new Date(q.dateCreated), 'yyyy-MM-dd h:mm')}</IonItem>
                    )) : <h1 style={{ marginTop: '2em' }}>No QA checks have been recorded yet</h1>}
                </IonList>
            </div>
        </div>
    )
}
