import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonCheckbox, IonButton, IonIcon, IonCard, IonCardContent, IonModal, IonSelect, IonSelectOption } from '@ionic/react';
import { add, checkmarkCircle } from "ionicons/icons";
import Modal from '../../components/Modal';
import AddNewBoxForm from '../../components/AddNewBoxForm';
import BaseTemplate from '../../components/base/BaseTemplate';
import { getItem, getAnything, getSite, updateItem, addOrder, getSponsorByName, getSiteById, getSponsorById, logIt } from '../../apis/logictrials';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';
import { displayAlert, isValidDate, sopsToArr } from '../../utils';

import publicHolidays from '../../utils/publicHolidays';

import 'react-day-picker/lib/style.css';
import './OrdersProcessing.css';

export default function OrdersProcessing({ orderObject, setDisplayOrder }) {
    const [showAddBoxModal, setShowBoxModal] = useState(false);
    const [order, setOrder] = useState();
    const [externalOrderNumber, setExternalOrderNumber] = useState();
    const [cspl, setCspl] = useState();
    const [dispatchDate, setDispatchDate] = useState();
    const [requestDate, setRequestDate] = useState();
    const [tt4Date, setTt4Date] = useState();
    const [stems, setStems] = useState(false);
    const [tag, setTag] = useState(false);
    const [aramexNeeded, setAramexNeeded] = useState(false);
    //For the Drivers
    const [logicDriverNeeded, setLogicDriverNeeded] = useState(false);
    const [markenDriverNeeded, setMarkenDriverNeeded] = useState(false);
    const [logicTrialsWaybill, setLogicTrialsWaybill] = useState();
    const [iwrsNeeded, setIwrsNeeded] = useState();
    const [study, setStudy] = useState();
    const [sponsor, setSponsor] = useState();
    const [site, setSite] = useState();
    const [isNew, setIsNew] = useState(false);
    const [boxes, setBoxes] = useState('0');
    const [boxTypes, setBoxTypes] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const history = useHistory();
    const [showDispatchDate, setShowDispatchDate] = useState({ showPopover: false, event: undefined });
    const [showRequestDate, setShowRequestDate] = useState({ showPopover: false, event: undefined });
    const [showTt4Date, setShowTt4Date] = useState({ showPopover: false, event: undefined });
    const [manualWaybill, setManualWaybill] = useState('');
    const [serviceType, setServiceType] = useState('ONP');
    const [editBox, setEditBox] = useState(null);
    const [tt4Required, setTt4Required] = useState(false);
    const [tt4ReturnRequired, setTt4ReturnRequired] = useState(false);
    const [pickUpTime, setPickUpTime] = useState('');

    const [boxReturnRequired, setBoxReturnRequired] = useState(false);

    const [comments, setComments] = useState('');

    const [boxDate, setBoxDate] = useState('');
    const [showBoxDate, setShowBoxDate] = useState({ showPopover: false, event: undefined });
    const [boxPickUpTime, setBoxPickUpTime] = useState('');

    const [isBoxReturn, setIsBoxReturn] = useState(false);

    const nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    useAsyncEffect(async () => {
        if (orderObject) {

            setOrder(orderObject);
            setAramexNeeded(orderObject.aramexNeeded ? true : false);
            setLogicDriverNeeded(orderObject.logicDriverNeeded ? true : false);
            setMarkenDriverNeeded(orderObject.markenDriverNeeded ? true : false);

            if (orderObject.aramexNeeded !== undefined && orderObject.aramexNeeded === false && orderObject.waybill && orderObject.waybill.label) {
                setManualWaybill(orderObject.waybill.label);
            }

            if (orderObject.logicDriverNeeded !== undefined && orderObject.logicDriverNeeded === false && orderObject.waybill && orderObject.waybill.label) {
                setManualWaybill(orderObject.waybill.label);
            }
            if (orderObject.markenDriverNeeded !== undefined && orderObject.markenDriverNeeded === false && orderObject.waybill && orderObject.waybill.label) {
                setManualWaybill(orderObject.waybill.label);
            }

            if (orderObject && orderObject.serviceType) {
                setServiceType(orderObject.serviceType);
            }

            if (orderObject.StudyID) {
                getItem('study', Number(orderObject.StudyID)).then(res => {
                    if (res && res[0] && res[0].data) {
                        setStudy(JSON.parse(res[0].data));
                        return;
                    }
                    logIt('Failed to fetch related study for existing order. ORDER:', { orderObject, apiResponse: res });
                });
            }

            if (orderObject.SponsorID) {
                getItem('sponsor', Number(orderObject.SponsorID)).then(res => {
                    if (res[0]) {
                        const parsed = JSON.parse(res[0].data);
                        if (res[0].id) {
                            parsed.id = res[0].id;
                        }
                        setSponsor(parsed);
                    } else {
                        logIt('Failed to fetch related sponsor by ID for existing order. ORDER:', { orderObject, apiResponse: res });
                    }
                });
            } else if (orderObject.Sponsor) {
                getSponsorByName(orderObject.Sponsor).then(res => {
                    if (res[0]) {
                        const parsed = JSON.parse(res[0].data);
                        if (res[0].id) {
                            parsed.id = res[0].id;
                        }
                        setSponsor(parsed);
                    } else {
                        logIt('Failed to fetch related sponsor by name for existing order. ORDER:', { orderObject, apiResponse: res });
                    }
                });
            }

            if (orderObject && orderObject.waybill)

                sopsToArr(orderObject.SOPS).then(sops => {
                    const siteNum = sops[sops.length - 1];
                    if (orderObject.boxTypes) { setBoxTypes(orderObject.boxTypes) };
                    if (orderObject.boxes.label) { setBoxes(orderObject.boxes.label) };
                    if (orderObject.siteID) {
                        getSiteById(orderObject.siteID).then(res => {
                            if (res && res[0] && res[0].data && res[0].id) {
                                const parsed = JSON.parse(res[0].data);

                                parsed.id = res[0].id;
                                setSite(parsed);
                            } else {
                                logIt('Failed to fetch related site by ID for existing order. ORDER:', { orderObject, apiResponse: res });
                            }
                        });
                    } else {
                        getSite(siteNum).then(res => {
                            if (res && res[0] && res[0].data && res[0].id) {
                                const parsed = JSON.parse(res[0].data);

                                parsed.id = res[0].id;
                                setSite(parsed);
                            } else {
                                logIt('Failed to fetch related site by siteNumber for existing order. ORDER:', { orderObject, apiResponse: res });
                            }
                        });
                    }
                    setExternalOrderNumber(sops[1]);
                    setCspl(sops[2]);
                    if (orderObject.dispatchDate && isValidDate(new Date(orderObject.dispatchDate))) {
                        setDispatchDate(new Date(orderObject.dispatchDate).toISOString());
                    }
                    if (orderObject.requestDate && isValidDate(new Date(orderObject.requestDate))) {
                        setRequestDate(new Date(orderObject.requestDate).toISOString());
                    }
                    if (orderObject) {
                        getAnything('orders', 'comments', 'order_number', orderObject?.orderNumber).then(res => {
                            setComments(res[0].comments)
                        });
                    }
                    setStems(orderObject.STEM === "0" ? false : true);
                    setTag(orderObject.TAG === "0" ? false : true);
                    if (orderObject.IWRS && orderObject.IWRS.value) {
                        setIwrsNeeded(orderObject.IWRS.value);
                    }

                    if (orderObject.TT4 && orderObject.TT4.label && orderObject.TT4.label === 'not required') {
                        setTt4Required(false);
                    } else if (orderObject.TT4 && orderObject.TT4.label && orderObject.TT4.label === 'no return') {
                        setTt4Required(true);
                        setTt4ReturnRequired(false);
                    } else if (orderObject.TT4 && orderObject.TT4.label && isValidDate(new Date(orderObject.TT4.label))) {
                        setTt4Required(true);
                        setTt4ReturnRequired(true);
                        setTt4Date(new Date(orderObject.TT4.label));
                    }
                });
        } else {
            setIsNew(true);
            const study = JSON.parse(sessionStorage.getItem('lt-temp-add-order-study'));
            setStudy(study);
            const site = JSON.parse(sessionStorage.getItem('lt-temp-add-order-site'));
            setSite(site);

            const tempOrder = {
                "TAG": "0",
                "TT4": {
                    "btnIcon": "barChartOutline",
                    "tooltip": "Confirm TT4",
                    "btnColor": "warning"
                },
                "sss": {
                    "icon": "documentOutline",
                    "label": "",
                    "tooltip": "Reprint line clearance"
                },
                "IWRS": {
                    "label": "",
                    "btnIcon": "flagOutline",
                    "tooltip": "Waiting for IWRS Followup",
                    "btnColor": "warning"
                },
                "SOPS": "",
                "STEM": "",
                "boxes": {
                    "label": "1",
                    "btnIcon": "qrCodeOutline",
                    "tooltip": "Check Label",
                    "btnColor": "warning"
                },
                "Sponsor": "",
                "StudyID": "",
                "aorDocs": {
                    "icon": "documentOutline",
                    "label": "",
                    "tooltip": "Confirm AOR docs"
                },
                "waybill": {
                    "label": "",
                    "btnIcon": "documentOutline",
                    "tooltip": "view shipping label PDF"
                },
                "SponsorID": study.sponsorId,
                "aorTelTime": {
                    "label": "",
                    "btnIcon": "timeOutline",
                    "tooltip": "Follow up call",
                    "btnColor": "warning"
                },
                "arrivalDate": {
                    "label": "",
                    "btnIcon": "calendarOutline",
                    "tooltip": "Confirm arrival",
                    "btnColor": "warning"
                },
                "orderNumber": "",
                "requestDate": "",
                "dispatchDate": ""
            }
            setOrder(tempOrder);
            if (study && study.sponsorId) {
                getSponsorById(study.sponsorId).then(res => {
                    if (res && res[0] && res[0].data) {
                        setSponsor(JSON.parse(res[0].data));
                    } else {
                        logIt('Failed to fetch sponsor by ID for new order.', { study, apiResponse: res });
                    }
                });

            } else if (study && study.sponsor) {
                getSponsorByName(study.sponsor).then(res => {
                    if (res && res[0] && res[0].data) {
                        setSponsor(JSON.parse(res[0].data));
                    } else {
                        logIt('Failed to fetch sponsor by name for new order.', { study, apiResponse: res });
                    }
                });
            } else {
                logIt('Study undefined or does not have sponsorId or sponsor fields. STUDY:', study);
            }

            setCspl(tempOrder.orderNumber);
            setDispatchDate(tempOrder.dispatchDate);
            setRequestDate(tempOrder.requestDate);
            setStems(false);
            setTag(false);
        }

    }, []);

    function resolveTt4() {
        if (!tt4Required) return 'not required';

        const collectionDate = tt4ReturnRequired ? resolveTt4DateTime() : '';

        // if(!collectionDate) return;

        if (tt4Required && tt4Date) return {
            "collectionDate": collectionDate,
            "label": !tt4ReturnRequired ? 'no return' : format(new Date(tt4Date), 'yyyy-MM-dd') + '(return)',
            "btnIcon": "barChartOutline",
            "tooltip": "Confirm TT4",
            "btnColor": "warning"
        };

        if (tt4Required && !tt4Date) return {
            "label": !tt4ReturnRequired ? 'no return' : format(new Date(tt4Date), 'yyyy-MM-dd') + '(return)',
            "btnIcon": "barChartOutline",
            "tooltip": "Confirm TT4",
            "btnColor": "warning"
        };

    }

    function resolveTt4DateTime() {
        if (!tt4Date || (tt4Date && !isValidDate(new Date(tt4Date)))) {
            displayAlert('warning', 'Pick up date invalid', 'Please select a TT4 pick up date');
            setHasSubmitted(false);

            return false;
        }

        if (!pickUpTime) {
            displayAlert('warning', 'TT4 pick up time invalid', 'Please enter a pick up time between 08:00 and 17:00');
            setHasSubmitted(false);

            return false;
        }

        let arrivalDateWithTime;
        if ((tt4Date && isValidDate(new Date(tt4Date))) && pickUpTime) {
            const hoursMins = pickUpTime.split(':');

            let tempDate = new Date(tt4Date);
            arrivalDateWithTime = tempDate.setHours(hoursMins[0]);
            arrivalDateWithTime = tempDate.setMinutes(hoursMins[1]);

            arrivalDateWithTime = new Date(arrivalDateWithTime).toISOString();
        }

        if (arrivalDateWithTime && isValidDate(new Date(arrivalDateWithTime)) && (new Date(arrivalDateWithTime).getHours() < 8 || new Date(arrivalDateWithTime).getHours() > 17)) {
            displayAlert('warning', 'Pick up time invalid', 'Pick up time must be between 08:00 and 17:00');

            return false;
        }

        if (arrivalDateWithTime) return arrivalDateWithTime;

        return false;
    }

    function resolveBoxDateTime() {
        if (boxReturnRequired && (!boxDate || (boxDate && !isValidDate(new Date(boxDate))))) {
            displayAlert('warning', 'Pick up date invalid', 'Please select a Box pick up date');
            setHasSubmitted(false);

            return false;
        }

        if (boxReturnRequired && !boxPickUpTime) {
            displayAlert('warning', 'Box pick up time invalid', 'Please enter a pick up time between 08:00 and 17:00');
            setHasSubmitted(false);

            return false;
        }

        let arrivalDateWithTime;
        if ((boxDate && isValidDate(new Date(boxDate))) && boxPickUpTime) {
            const hoursMins = boxPickUpTime.split(':');

            let tempDate = new Date(boxDate);
            arrivalDateWithTime = tempDate.setHours(hoursMins[0]);
            arrivalDateWithTime = tempDate.setMinutes(hoursMins[1]);

            arrivalDateWithTime = new Date(arrivalDateWithTime).toISOString();
        }

        if (boxReturnRequired && arrivalDateWithTime && isValidDate(new Date(arrivalDateWithTime)) && (new Date(arrivalDateWithTime).getHours() < 8 || new Date(arrivalDateWithTime).getHours() > 17)) {
            displayAlert('warning', 'Pick up time invalid', 'Pick up time must be between 08:00 and 17:00');

            return false;
        }

        if (boxReturnRequired && arrivalDateWithTime) return arrivalDateWithTime;

        return false;
    }

    function handleDeliverySelect(selectedDeliveryMethod) {
        if (selectedDeliveryMethod === "None") {
            setAramexNeeded(false);
            setLogicDriverNeeded(false);
            setMarkenDriverNeeded(false);
        }
        if (selectedDeliveryMethod === "Aramex") {
            setAramexNeeded(true);
            setLogicDriverNeeded(false);
            setMarkenDriverNeeded(false);
        }

        if (selectedDeliveryMethod === "LogicTrials") {
            setLogicDriverNeeded(true);
            setAramexNeeded(false);
            setMarkenDriverNeeded(false);
            //Automatic waybill
            //let ltWaybill = `LTD${String(new Date().getFullYear()).replace('20', '')}${new Date().getMonth() + 1}${Math.floor(Math.random() * 1000)}${new Date().getSeconds()}`
            //setLogicTrialsWaybill(ltWaybill);
        }
        if (selectedDeliveryMethod === "MarkenCourier") {
            setAramexNeeded(false);
            setLogicDriverNeeded(false);
            setMarkenDriverNeeded(true);
        }

    }

    function handleSubmit() {
        if ((boxTypes.length === 0) && !orderObject) {
            displayAlert('warning', 'No box added', 'Please add at least one box');
            return;
        }

        if (!dispatchDate) {
            displayAlert('warning', 'No dispatch date', 'Please select a dispatch date');
            return;
        }
        if (!requestDate) {
            displayAlert('warning', 'No requested date', 'Please select a requested date');
            return;
        }
        if ((manualWaybill.length === 0) && !aramexNeeded) {
            displayAlert('warning', 'Waybill entered', 'Please enter a waybill');
            return;
        }

        setHasSubmitted(true);

        const SOPS = study.studyNumber + ' ' + externalOrderNumber + ' ' + cspl + ' ' + site.number;


        let waybillLabel = orderObject ? orderObject.waybill.label : aramexNeeded ? "" : manualWaybill;

        if (orderObject && manualWaybill && manualWaybill !== orderObject.waybill.label) waybillLabel = manualWaybill;

        const tt4Info = resolveTt4();

        if (!tt4Info) {
            setHasSubmitted(false);

            return;
        }

        console.log(dispatchDate)

        const newOrder = {
            SOPS: SOPS,
            // orderNumber: orderNum, 
            requestDate: format(new Date(requestDate), 'yyyy-MM-d'),
            TT4: tt4Info,
            STEM: stems ? "1" : "0",
            TAG: tag ? "1" : "0",
            dispatchDate: format(new Date(dispatchDate), 'yyyy-MM-d'),
            boxes: {
                "label": boxTypes.length,
                "btnIcon": "qrCodeOutline",
                "tooltip": "Check Label",
                "btnColor": "warning"
            },
            boxTypes: boxTypes,
            waybill: {
                "label": waybillLabel,
                "btnIcon": "documentOutline",
                "tooltip": "view shipping label PDF"
            },
            arrivalDate: orderObject ? orderObject.arrivalDate : {
                "label": "",
                "btnIcon": "calendarOutline",
                "tooltip": "Confirm arrival",
                "btnColor": "warning"
            },
            IWRS: orderObject ? orderObject.IWRS : {
                "label": "",
                "btnIcon": "flagOutline",
                "tooltip": "Waiting for IWRS Followup",
                "btnColor": "warning",
                value: iwrsNeeded
            },
            aorTelTime: orderObject ? orderObject.aorTelTime : {
                "label": "",
                "btnIcon": "timeOutline",
                "tooltip": "Follow up call",
                "btnColor": "warning"
            },
            aorDocs: orderObject ? orderObject.aorDocs : {
                "icon": "documentOutline",
                "label": "",
                "tooltip": "Confirm AOR docs"
            },
            sss: orderObject ? orderObject.sss : {
                "icon": "documentOutline",
                "label": "",
                "tooltip": "Reprint line clearance"
            },
            "Sponsor": orderObject ? orderObject.Sponsor : sponsor.name,
            "StudyID": orderObject ? orderObject.StudyID : study.id,
            "SponsorID": orderObject ? orderObject.SponsorID : sponsor.id,
            aramexNeeded,
            logicDriverNeeded,
            markenDriverNeeded,
            sponsorName: orderObject ? orderObject.Sponsor : sponsor.name,
            sponsorID: orderObject ? orderObject.SponsorID : sponsor.id,
            sponsorShortName: orderObject && orderObject.sponsorShortName ? orderObject.sponsorShortName : sponsor && sponsor.shortName ? sponsor.shortName : '',
            siteNumber: site.number,
            siteID: site.id, //double check id is part of object
            studyNumber: study.studyNumber,
            studyID: orderObject ? orderObject.studyID : study.id,
            externalOrderNumber: externalOrderNumber,
            cspl: cspl,
            packingListNumber: cspl,
            //Comments being added in orders in the json
            //comments: comments,
            dispatchData: format(new Date(dispatchDate), 'yyyy-MM-d'),
            wayBill: {
                "label": logicTrialsWaybill ? logicTrialsWaybill : orderObject ? orderObject.waybill.label : aramexNeeded ? "" : manualWaybill,
                "btnIcon": "documentOutline",
                "tooltip": "view shipping label PDF"
            },
            serviceType,
            "boxReturns": boxReturnRequired,
            "boxPickUp": resolveBoxDateTime()
        }

        if (isNew) {
            const parcels = boxTypes.map((bt, i) => {
                return {
                    "parcel_number": `${i + 1}`, //?
                    "quantity": Number(bt.number), //number of boxes
                    "length": Number(bt.length), //length of box ??
                    "width": Number(bt.width), //width of box ??
                    "height": Number(bt.height), //height of selected box ??
                    "weight": Number(bt.weight)
                }
            });

            addOrder({ ...newOrder, parcels, aramexNeeded, siteId: site.id }, comments ? comments : " ").then(res => {
                if (res) {
                    setTimeout(() => {
                        history.push("/orders-and-dispatch");
                    }, 3000);
                }

                if (!res) {
                    logIt('Failed to add order.', { ...newOrder, parcels, aramexNeeded, siteId: site.id });
                }

            });
        } else {
            const where = { id: orderObject.id };

            updateItem('orders', ['data', 'comments'], [newOrder, comments], where).then(res => {
                if (res) {
                    setTimeout(() => {
                        window.location.href = "/orders-and-dispatch";
                    }, 3000);
                }
                if (!res) {
                    logIt('Failed to update order.', newOrder);
                }
            });
        }
    }

    function removeBox(index) {
        const filteredBoxes = boxTypes.filter((b, i) => index !== i);

        setBoxTypes(filteredBoxes);
    }

    return (
        <BaseTemplate customBack={orderObject ? () => setDisplayOrder(null) : undefined}>
            <IonGrid className="orders-processing">
                <IonRow style={{ boxShadow: "none" }}>
                    <IonCol>
                        <div
                            className="shadow-xl py-4 px-10"
                            style={{ backgroundColor: "white" }}
                        >
                            {order && <h2 className="form-heading">Order Processing</h2>}
                            {order && <IonGrid style={{ marginTop: '2em' }}>
                                <IonRow>
                                    <IonCol>
                                        {order && order.orderNumber && `KPI Order/No. ${order.orderNumber}`}
                                    </IonCol>
                                    <IonCol>
                                        Sponsor: {sponsor && sponsor.name} <br />
                                        Study: {study && study.studyNumber} <br />
                                        Investigator: {site && site.investigator} <br />
                                    </IonCol>
                                    <IonCol>
                                        Site: {site && site.number} <br />
                                        Site Contact: {site && site.contact} <br />
                                        Tel: {site && site.tel} <br />
                                    </IonCol>
                                </IonRow>

                                <IonRow style={{ marginTop: '2em' }}>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonInput value={externalOrderNumber} onIonChange={e => setExternalOrderNumber(e.detail.value)} placeholder={"External Order Number"} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonInput value={cspl} onIonChange={e => setCspl(e.detail.value)} placeholder={"CSPL"} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonInput onClick={(e) => setShowDispatchDate({ showPopover: true, event: e })} placeholder="Dispatch Date" value={dispatchDate ? format(new Date(dispatchDate), 'MM d y') : ''} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonInput onClick={(e) => setShowRequestDate({ showPopover: true, event: e })} placeholder="Requested Date" value={requestDate ? format(new Date(requestDate), 'MM d y') : ''} />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ marginTop: '1em' }}>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonInput value={comments} onIonChange={e => setComments(e.detail.value)} placeholder={"Comments"} />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ marginTop: '3em' }}>
                                    <IonCol size-md="3">
                                        <IonItem lines="none">
                                            Box return required:
                                            <IonCheckbox checked={boxReturnRequired} value={boxReturnRequired} onIonChange={e => setBoxReturnRequired(e.detail.checked)} slot="end" />
                                        </IonItem>
                                    </IonCol>
                                    {boxReturnRequired &&
                                        <>
                                            <IonCol size-md="3">
                                                <IonItem lines="none">
                                                    <IonInput onClick={(e) => setShowBoxDate({ showPopover: true, event: e })} placeholder="Box Pickup Date" value={boxDate ? format(new Date(boxDate), 'MM d y') : ''} />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="3">
                                                <IonItem lines="none">
                                                    Pickup Time: <span style={{ width: 20 }} /> <IonInput min="08:00" max="17:00" placeholder="Pickup Time" type="time" value={boxPickUpTime} onIonChange={e => setBoxPickUpTime(e.detail.value)} />
                                                </IonItem>
                                            </IonCol>
                                        </>
                                    }
                                </IonRow>
                                <IonRow style={{ marginTop: '2em' }}>
                                    {boxTypes.length > 0 && boxTypes.map((box, i) => (
                                        <IonCol style={{ textAlign: 'left' }}>
                                            <IonCard onClick={_ => { setEditBox({ ...box, index: i }); setShowBoxModal(true); }}>
                                                <IonCardContent>
                                                    <div key={box.serialNumber} style={{ textAlign: 'left', position: "relative" }}>
                                                        <i class="fas fa-times-circle" onClick={(e) => { e.stopPropagation(); removeBox(i); }} style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}></i>
                                                        <p><strong>Box {i + 1}</strong></p>
                                                        Type: {box.boxType}<br />
                                                        Temperature: {box.boxTemperature}<br />
                                                        Serial Number: {box.tt4Serial}<br />
                                                        Total needed: {box.number}
                                                    </div>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    ))}
                                    <IonCol>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton color="primary" expand="full" onClick={() => setShowBoxModal(true)}>
                                            <IonIcon icon={add} slot="start" />
                                            Box
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ marginTop: '2em' }}>
                                    <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select an Option" }} value={iwrsNeeded} placeholder="IWRS Needed" onIonChange={e => setIwrsNeeded(e.detail.value)}>
                                            <IonSelectOption value="1">1</IonSelectOption>
                                            <IonSelectOption value="2">2</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                    {!orderObject &&
                                        <IonCol size-md="3">
                                            <IonItem lines="none">
                                                {/* Delivery Method: */}
                                                <IonSelect interface="popover" interfaceOptions={{ header: "Select a Delivery Method" }} onIonChange={(e) => handleDeliverySelect(e.detail.value)} placeholder="Select Delivery Method">
                                                    <IonSelectOption value="None">No Courier Option Required</IonSelectOption>
                                                    <IonSelectOption value="Aramex">Aramex</IonSelectOption>
                                                    <IonSelectOption value="LogicTrials">LogicTrials Driver</IonSelectOption>
                                                    <IonSelectOption value="MarkenCourier">Marken Courier</IonSelectOption>
                                                </IonSelect>
                                                {/* <IonCheckbox isChecked={aramexNeeded} onIonChange={e => setAramexNeeded(e.detail.checked)} slot="end"/> */}
                                            </IonItem>
                                        </IonCol>}
                                    {!aramexNeeded && (!orderObject || orderObject.aramexNeeded === false) && <IonCol size="3">
                                        <IonItem lines="none">
                                            <IonInput value={manualWaybill} onIonChange={e => setManualWaybill(e.detail.value)} placeholder="Manual waybill" />
                                        </IonItem>
                                    </IonCol>}
                                    {aramexNeeded && !orderObject && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} value={serviceType} placeholder="Aramex service type" onIonChange={e => setServiceType(e.detail.value)}>
                                            <IonSelectOption value="SMP">Same day shipment (SMP)</IonSelectOption>
                                            <IonSelectOption value="EMD">Early morning delivery (EMD)</IonSelectOption>
                                            <IonSelectOption value="ONP">Overnight delivery (ONP)</IonSelectOption>
                                            <IonSelectOption value="PEC">Economy (PEC)</IonSelectOption>
                                            <IonSelectOption value="PEX">Road Express (PEX)</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                    {logicDriverNeeded && !orderObject && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} value={serviceType} placeholder="LogicTrials service type" onIonChange={e => setServiceType(e.detail.value)}>
                                            <IonSelectOption value="SMP">Same day shipment (SMP)</IonSelectOption>
                                            <IonSelectOption value="ONP">Overnight delivery (ONP)</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                    {markenDriverNeeded && !orderObject && <IonCol size="3">
                                        <IonSelect className="border" style={{ marginTop: 4 }} interface="popover" interfaceOptions={{ header: "Select a Shipment Option" }} value={serviceType} placeholder="Marken courier service type" onIonChange={e => setServiceType(e.detail.value)}>
                                            <IonSelectOption value="SMP">Same day shipment (SMP)</IonSelectOption>
                                            <IonSelectOption value="ONP">Overnight delivery (ONP)</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>}
                                </IonRow>
                                <IonRow style={{ marginTop: '3em' }}>
                                    <IonCol size-md="3">
                                        <IonItem lines="none">
                                            Temptale required:
                                            <IonCheckbox checked={tt4Required} value={tt4Required} onIonChange={e => setTt4Required(e.detail.checked)} slot="end" />
                                        </IonItem>
                                    </IonCol>
                                    {tt4Required && <IonCol size-md="3">
                                        <IonItem lines="none">
                                            Temptale return required:
                                            <IonCheckbox checked={tt4ReturnRequired} value={tt4ReturnRequired} onIonChange={e => setTt4ReturnRequired(e.detail.checked)} slot="end" />
                                        </IonItem>
                                    </IonCol>}
                                    {tt4ReturnRequired &&
                                        <>
                                            <IonCol size-md="3">
                                                <IonItem lines="none">
                                                    <IonInput onClick={(e) => setShowTt4Date({ showPopover: true, event: e })} placeholder="Temptale Pickup Date" value={tt4Date ? format(new Date(tt4Date), 'MM d y') : ''} />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="3">
                                                <IonItem lines="none">
                                                    Pickup Time: <span style={{ width: 20 }} /> <IonInput min="08:00" max="17:00" placeholder="Pickup Time" type="time" value={pickUpTime} onIonChange={e => setPickUpTime(e.detail.value)} />
                                                </IonItem>
                                            </IonCol>
                                        </>
                                    }
                                </IonRow>
                                <IonRow style={{ paddingTop: '3em' }}>
                                    <IonCol size-md="4">
                                    </IonCol>
                                    <IonCol size-md="4">
                                    </IonCol>
                                    <IonCol size-md="4">
                                        <IonButton disabled={hasSubmitted} color="success" expand="full" onClick={handleSubmit}>
                                            Save
                                            <IonIcon icon={checkmarkCircle} slot="start" />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            {showAddBoxModal && <Modal className="add-new-box-modal" showModal={showAddBoxModal} setShowModal={setShowBoxModal} onDidDismiss={() => { setEditBox(null); setShowBoxModal(false); }}>
                <AddNewBoxForm editBox={editBox} setBoxTypes={setBoxTypes} boxTypes={boxTypes} setShowModal={setShowBoxModal} boxes={boxes} setBoxes={setBoxes} />
            </Modal>}
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showDispatchDate.showPopover}
                event={showDispatchDate.event}
                onDidDismiss={() => setShowDispatchDate({ showPopover: false, event: undefined })}
            >
                <DayPicker
                    canChangeMonth={true}
                    onDayClick={(day) => {
                        setDispatchDate(day.toString());
                        setShowDispatchDate({ showPopover: false, event: undefined });
                    }}
                    disabledDays={[{ daysOfWeek: [0, 6] }, { before: new Date() }, ...publicHolidays]}
                />
                <IonButton onClick={() => setShowDispatchDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showRequestDate.showPopover}
                event={showRequestDate.event}
                onDidDismiss={() => setShowRequestDate({ showPopover: false, event: undefined })}
            >
                <DayPicker
                    canChangeMonth={true}
                    onDayClick={(day) => {
                        setRequestDate(day.toString());
                        setShowRequestDate({ showPopover: false, event: undefined });
                    }}
                //They can pick from any day , no days are disabled 
                //disabledDays={[{ daysOfWeek: [0, 6] }, { before: new Date() }, ...publicHolidays]}
                />
                <IonButton onClick={() => setShowRequestDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showTt4Date.showPopover}
                event={showTt4Date.event}
                onDidDismiss={() => setShowTt4Date({ showPopover: false, event: undefined })}
            >
                <DayPicker
                    canChangeMonth={true}
                    onDayClick={(day) => {
                        setTt4Date(day.toString());
                        setShowTt4Date({ showPopover: false, event: undefined });
                    }}
                    disabledDays={[{ daysOfWeek: [0, 6] }, { after: nextWeek, before: new Date() }, ...publicHolidays]}
                />
                <IonButton onClick={() => setShowTt4Date({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>

            <IonModal
                cssClass="day-modal-picker"
                isOpen={showBoxDate.showPopover}
                event={showBoxDate.event}
                onDidDismiss={() => setShowBoxDate({ showPopover: false, event: undefined })}
            >
                <DayPicker
                    canChangeMonth={true}
                    onDayClick={(day) => {
                        setBoxDate(day.toString());
                        setShowBoxDate({ showPopover: false, event: undefined });
                    }}
                    disabledDays={[{ daysOfWeek: [0, 6] }, { after: nextWeek, before: new Date() }, ...publicHolidays]}
                />
                <IonButton onClick={() => setShowBoxDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
        </BaseTemplate>
    )
}
