import './AlertMessage.css';

import { IonGrid, IonRow, IonCol } from '@ionic/react';

const AlertMessage = ({ type, alertTitle, alertDescription }) => {

  return (
    <div className="alert-message">
        <IonGrid>
            <IonRow>
                <IonCol offset-xs="0.2" size-xs="2" offset-md="0.7" size-md="2.5" className="flex-align-center">
                    <div className="alert-icon" style={{backgroundColor: `rgba(var(--ion-color-${type}-rgb), 0.2)`}}><img alt="alert_icon" src={`/assets/icons/${type}-icon.svg`} /></div>
                </IonCol>
                <IonCol>
                    <p className="alert-title">{alertTitle}</p>
                    {alertDescription && <p className="alert-body">{alertDescription}</p>}
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>
  );
};

export default AlertMessage;


