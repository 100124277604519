import {
    IonAlert,
    IonButton,
    IonCol,
    IonContent,
    IonDatetime,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToast
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";

import { format } from 'date-fns';
import { add } from "ionicons/icons";
import DayPicker from 'react-day-picker';
import ReactPaginate from "react-paginate";
import useAsyncEffect from 'use-async-effect';

import { addDestruction, confirmReturn, deleteItem, generatePdf, getData, getDataPerms, getReceiptPdf, getReturnWaybill, retryAramexReturns, updateDestruction, updateItem } from '../../apis/logictrials';

import AddManualAramexWaybill from "../../components/actionButtonForms/AddManualAramexWaybill";
import AddManualWaybill from "../../components/actionButtonForms/AddManualWaybill";
import AddWeight from '../../components/actionButtonForms/AddWeight';
import ConfirmCollectionDate from '../../components/actionButtonForms/ConfirmCollectionDate';
import ConfirmReceipt from '../../components/actionButtonForms/ConfirmReceipt';
import ConfirmReceivedDate from '../../components/actionButtonForms/ConfirmReceivedDate';
import GnNumber from "../../components/actionButtonForms/GnNumber";
import RequestDate from '../../components/actionButtonForms/RequestDate';
import RetryAramex from '../../components/actionButtonForms/RetryAramex';
import BaseTemplate from "../../components/base/BaseTemplate";
import Loading from "../../components/Loading";
import Modal from '../../components/Modal';
import Search from "../../components/Search/Search";
import TableList from "../../components/TableList/TableList";
import UpdateReference from '../../components/UpdateReference';
import ReturnsProcessing from '../../pages/ReturnsProcessing/ReturnsProcessing';
import BoxLabel from '../ReturnsBoxLabel';

import decode from 'jwt-decode';

import {
    exportArrayToExcel,
    isValidDate,
    parseQueryString
} from "../../utils";
import addToDestruction from '../../utils/formulateDestructionListData';

import AlertMessage from "../../components/general/AlertMessage";

const PER_PAGE = 15;

const actions = [
    "Destruction",
    "Over Supply",
    "Recall",
    "Quarantine",
    "Reject",
    "Collection",
    "TT4 Return",
    "Quarantined due to damaged box",
    "Awaiting Destruction",
    "Destroyed"
];

function CollectionsReturns({ isExternal }) {
    const [error, setError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [collectionsReturns, setCollectionsReturns] = useState([]);
    const [unchangedCollectionsReturns, setUnchangedCollectionsReturns] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showUpdateReferenceModal, setShowUpdateReferenceModal] = useState(false);
    const [updateReference, setUpdateReference] = useState('');
    const [filterType, setFilterType] = useState("none");
    const [alertMessage, setAlertMessage] = useState('Error');
    const [showAlert, setShowAlert] = useState(false);
    const [boxLabelSops, setBoxLabelSops] = useState();
    const [showBoxLabelModal, setShowBoxLabelModal] = useState();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showGnNumber, setShowGnNumber] = useState(false);
    const [showConfirmReceived, setShowConfirmReceived] = useState(false);
    const [showConfirmCollection, setShowConfirmCollection] = useState(false);

    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setDate(today.getDate() - 30);

    const [selectedStartDate, setSelectedStartDate] = useState(lastMonth.toISOString());
    const [prevSelectedStartDate] = useState(lastMonth.toISOString());
    const [showStartDate, setShowStartDate] = useState({ showPopover: false, event: undefined });
    const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
    const [showEndDate, setShowEndDate] = useState({ showPopover: false, event: undefined });

    const dateTimeRef = useRef();
    const [showUpdateWaybillModal, setShowUpdateWaybillModal] = useState(false);
    const [showUpdateAramexWaybillModal, setShowUpdateAramexWaybillModal] = useState(false);
    const [showAramaxAccountPopup, setShowAramexAccountPopup] = useState(false);
    const [showAramexAccountRetryModal, setShowAramexAccountRetryModal] = useState(false);
    const [showRequestDate, setShowRequestDate] = useState(false);
    const [showConfirmReceipt, setShowConfirmReceipt] = useState(false);
    const [addWaybillData, setAddWaybillData] = useState({});

    const [displayOrder, setDisplayOrder] = useState(false);

    const [updateObject, setUpdateObject] = useState({});

    const [boxLabelLink, setBoxLabelLink] = useState('');
    const [showAddWeight, setShowAddWeight] = useState(false);
    const [filterByAction, setFilterByAction] = useState('');
    const [showSend, setShowSend] = useState(false);

    // delete
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteOrderNumber, setDeleteOrderNumber] = useState('');

    // confirm
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmId, setConfirmId] = useState(null);

    const [showAltAlert, setShowAltAlert] = useState(false);
    const [alertAltMessage, setAlertAltMessage] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertType, setAlertType] = useState('success');

    const [showAramexRetryModal, setShowAramexRetryModal] = useState(false);
    const [retryOrderNumber, setRetryOrderNumber] = useState('');

    let labels = !isExternal ? [
        "SOPS",
        "Reference No.",
        "Request Date",
        "Action",
        "Boxes",
        "Weight",
        "Aramex Ref",
        "Waybill",
        "Retry Aramex",
        "Collection Date",
        "Confirm Receipt",
        "Received Date",
        "GN No.",
        "",
        "",
        "",
        ""
    ] : [
        "SOPS",
        "Reference No.",
        "Request Date",
        "Action",
        "Boxes",
        "Weight",
        "Aramex Ref",
        "Waybill",
        "Collection Date",
        "Confirm Receipt",
        "Received Date",
        "GN No.",
        "",
    ];

    let headings = !isExternal ? [
        "sops",
        "referenceNo",
        "requestDate",
        "action",
        "boxes",
        "weight",
        "aramexRef",
        "waybill",
        "retryAramex",
        "collectionDate",
        "confirmReceipt",
        "receivedDate",
        "gnNo",
        "confirm",
        "sendToDestruction",
        "edit",
        "delete"
    ] : [
        "sops",
        "referenceNo",
        "requestDate",
        "action",
        "boxes",
        "weight",
        "aramexRef",
        "waybill",
        "collectionDate",
        "confirmReceipt",
        "receivedDate",
        "gnNo",
        "confirm",
        "edit"
    ];

    function displayAlert(type, title, message) {
        setAlertTitle(title);
        setAlertAltMessage(message);
        setAlertType(type);
        setShowAltAlert(true);
    }

    const accessToken = sessionStorage.getItem('lt-access-token');

    const decoded = accessToken ? decode(accessToken) : false;

    let formattedPermissions = [];

    if (decoded && decoded.perms) {
        const permissions = decoded.perms;

        formattedPermissions = permissions.map(p => {
            const toIndex = p.lastIndexOf('_') + 1;
            return p.slice(0, toIndex);
        });
    }

    if (!formattedPermissions.includes('returns_d_')) headings = headings.filter(h => h !== "delete");

    if (!formattedPermissions.includes('returns_d_')) labels.pop();

    if (!formattedPermissions.includes('returns_u_')) headings = headings.filter(h => h !== "edit");

    if (!formattedPermissions.includes('returns_u_')) labels.pop();

    if (!formattedPermissions.includes('returns_u_')) headings = headings.filter(h => h !== "confirm");

    if (!formattedPermissions.includes('returns_u_')) labels.pop();

    if (!formattedPermissions.includes('returns_u_')) headings = headings.filter(h => h !== "sendToDestruction");

    if (!formattedPermissions.includes('returns_u_')) labels.pop();

    const fetchData = async () => {
        const { roleid } = JSON.parse(sessionStorage.getItem('lt-user'));
        const res = await getDataPerms('returns', roleid);
        const data = res.map(({ data, id, order_number }) => {
            let tempData = JSON.parse(data);
            tempData.id = id;
            tempData.orderNumber = order_number;
            if (tempData && tempData.receivedDate && typeof tempData.receivedDate === 'string') {
                tempData.receivedDate = {
                    "label": tempData.receivedDate.includes('Z') ? format(new Date(tempData.receivedDate), 'yyyy-MM-d HH:mm') : tempData.receivedDate,
                    "btnIcon": "documentOutline",
                    "tooltip": "Reprint return doc",
                    "btnColor": "danger"
                };
            }

            if (tempData && tempData.collectionDate && typeof tempData.collectionDate === 'string') {
                tempData.collectionDate = tempData.collectionDate.includes('Z') ? format(new Date(tempData.collectionDate), 'yyyy-MM-d HH:mm') : tempData.collectionDate;
            }

            if (tempData.aramex && tempData.aramex.received && tempData.aramex.request && tempData.waybill) {
                tempData.waybill.label = tempData.aramex.request;
                tempData.waybill.link = tempData.aramex.received;
            }

            tempData.retryAramex = {
                // "label": "Retry Aramex",
                "btnIcon": "refreshOutline",
                "tooltip": "Use this button to re-send Aramex request. ",
                "btnColor": "danger"
            };
            //Editing data for boxes so it only displays the number of boxes and not the brackets in the object 
            if (tempData && tempData.boxes && tempData.boxes.label) {
                tempData.boxes.label = tempData.boxes.label.split('(');
                if (tempData.boxes.label.length > 0) {
                    tempData.boxes.label = tempData.boxes.label[0].trim();
                }
            }
            //Optimized code for the above
            // if (tempData && tempData.boxes && tempData.boxes.label) {
            //     const label = tempData.boxes.label.replace(/\s*\([^)]*\)/, '').trim();
            //     tempData.boxes.label = label;
            // }

            return tempData;
        });

        const filteredData = data.filter(d => !d.isArchived);

        setLoading(false);
        setCollectionsReturns(filteredData);
        setUnchangedCollectionsReturns(filteredData);
    }

    useEffect(() => {
        if (filterType === "None") {
            setCollectionsReturns(unchangedCollectionsReturns);
        }
        if (selectedStartDate && selectedEndDate && ((filterType === 'Arrival Date') || (filterType === 'Request Date'))) {

            if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
                setSelectedStartDate(prevSelectedStartDate);
                setAlertMessage("start date must be before end date");
                setShowAlert(true);
            }
            if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
                let ed = new Date(selectedEndDate).getTime();
                let sd = new Date(selectedStartDate).getTime();
                let key = '';
                switch (filterType) {
                    case "Request Date": {
                        key = 'requestDate';
                        break;
                    }
                    case "Arrival Date": {
                        key = 'receivedDate';
                        break;
                    }
                    default: return false;
                }
                const result = unchangedCollectionsReturns.filter(d => {
                    if (!d[key]) {
                        return false;
                    }
                    var time = new Date(d[key].label ? d[key].label : d[key]).getTime();
                    if (time) {
                        return ((sd <= time) && (time <= ed));
                    } else {
                        return false;
                    }
                });
                setLoading(false);
                setCollectionsReturns(result);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStartDate, selectedEndDate, filterType]);

    function handleUpdateReference(value) {
        let temp = collectionsReturns.map(c => {
            if (updateReference === c.referenceNo.label) {
                c.referenceNo.label = value;

            }
            return c;
        });

        setCollectionsReturns(temp);
    }

    async function handleUpdateAction(value, _, obj) {

        let tempObj = obj;
        tempObj.action = value;

        await updateItem('returns', ['data'], [tempObj], { id: obj.id });

        setToastMessage('Returns updated');
        setShowToast(true);
    }

    async function addToDestructionList(tempObj) {
        //remove from returns

        const destructions = await getData('destruction', 0, 9999);
        const parsedDestructions = destructions.map(d => {
            let parsed = JSON.parse(d.data);

            parsed.id = d.id;

            return parsed;
        });

        //checking to see if destruction list already contains the same order
        const orderExists = parsedDestructions[0].sss.some(orderStatus => {
            return orderStatus.split(":")[0] === tempObj.orderNumber;
        });

        if (orderExists) {
            setToastMessage(`Order ${tempObj.orderNumber} is already in the destruction list`);
            setShowToast(true);
            return;  // return early, don't add to the list again
        }

        let mostRecentDestruction;

        // most recent destruction as skelleton data to add to or false if a new one has to be added
        let desSkel = false;
        const refArr = tempObj.sops.split(' ');

        let ref = refArr[refArr.length - 2];
        ref = ref.slice(1, ref.length);

        // tests if most recent has been sent, set to false if doesnt exist or has not been sent
        let isEqual = false;

        if (parsedDestructions.length !== 0) {
            mostRecentDestruction = parsedDestructions[0];

            const testObj = {
                label: "",
                btnColor: 'warning',
                btnLabel: 'Send for destruction',
                tooltip: ''
            };

            isEqual = mostRecentDestruction.sent.btnColor === testObj.btnColor ? true : false;

            desSkel = isEqual ? mostRecentDestruction : false;
        }
        // TODO
        const { obj, update } = addToDestruction(tempObj.studyNumber, tempObj, tempObj.orderNumber, desSkel);

        //TODO
        if (update) {
            updateDestruction(obj, { id: mostRecentDestruction.id }).then(_ => {
                setToastMessage(`order ${ref} successfully added to destruction list`);
                setShowToast(true);
            });
        }
        if (!update) {
            addDestruction(obj).then(_ => {
                setToastMessage(`order ${ref} successfully added to destruction list`);
                setShowToast(true);
            });
        }
    }

    useAsyncEffect(async () => {
        const urlQueries = parseQueryString();

        if (urlQueries.id) {
            const res = await getData('returns', 0, 9999);
            const data = res.map(({ data, id }) => {
                let tempData = JSON.parse(data);
                tempData.id = id;
                return tempData;
            });

            const display = data.filter(d => {
                if (d.id === urlQueries.id) {
                    return d;
                }

                return false;
            });

            setCollectionsReturns(display);
            setLoading(false);
        } else {
            if (collectionsReturns.length === 0) {
                fetchData();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (collectionsReturns) {
            let tempReturns = collectionsReturns.filter(cr => {

                if (cr && cr.referenceNo && cr.referenceNo.label && typeof cr.referenceNo.label === 'string' && cr.referenceNo.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.sops && cr.sops.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.action && cr.action.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.aramexRef && cr.aramexRef.label && cr.aramexRef.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.waybill && cr.waybill.label && cr.waybill.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.aramexReturn && cr.aramexReturn.request && typeof cr.aramexReturn.request === 'string' && cr.aramexReturn.request.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.aramexReturn && cr.aramexReturn.request && cr.waybill.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;

                return false;
            });

            setCurrentPage(0);

            setSearchResults(tempReturns);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, collectionsReturns]);

    const revisedCollectionsReturns = searchResults;

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    const offset = currentPage * PER_PAGE;

    const currentPageData = revisedCollectionsReturns ? revisedCollectionsReturns.slice(offset, offset + PER_PAGE) : [];

    const pageCount = revisedCollectionsReturns ? Math.ceil(revisedCollectionsReturns.length / PER_PAGE) : 0;

    function excelArray() {
        const temp = [...revisedCollectionsReturns];
        const result = temp.map(r => {
            let keys = Object.keys(r);
            r = { ...r }
            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            if (r['boxTypes'] && Array.isArray(r['boxTypes']) && r['boxTypes'].length > 0) {
                for (let i = 0; i < r['boxTypes'].length; i++) {
                    if (r['Temp Range']) r['Temp Range'] += ', ' + r['boxTypes'][i].boxTemperature;
                    if (!r['Temp Range']) r['Temp Range'] = r['boxTypes'][i].boxTemperature;

                    if (r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                    };
                    if (!r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] = r['boxTypes'][i].tempMonitorType;
                    };

                    if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                }

                r['Number of Temp Monitors'] = r['boxTypes'].length;
            }

            if (r['boxes']) r['Number of Boxes'] = r['boxes'];
            if (r["Sponsor"]) {
                r['sponsorName'] = r['Sponsor'];

                delete r['Sponsor'];
            };

            if (!r['serviceType']) r['serviceType'] = "N/A";
            if (!r['numberOfKits']) r['numberOfKits'] = "N/A";

            delete r['siteId'];
            delete r['siteID'];
            delete r['StudyID'];
            delete r['studyID'];
            delete r['edit'];
            delete r['id'];
            delete r['parcels'];
            delete r['sponsorID'];
            delete r['SponsorID'];
            delete r['view'];
            delete r['sponsorShortName'];

            delete r['confirm'];
            delete r['aramex'];
            delete r['aramexNeeded'];

            delete r['sponsor'];
            delete r['edit'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['list'];
            delete r['dspl'];
            delete r['sss'];
            delete r['boxTypes'];
            delete r['studyNumber'];

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1");
                const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });

            return { " Study": r[" Study"], "Sponsor Name": r["Sponsor Name"], ...r };
        });
        return result;
    }


    async function excelArrayWithArchives() {
        const temp = [...revisedCollectionsReturns];

        const res = await getData('archive_returns', 0, 9999);

        const archives = res.map(({ data }) => {
            let tempData = JSON.parse(data);
            return tempData;
        });

        let searchFiltersArchives;
        if (searchQuery) {
            searchFiltersArchives = archives.filter(cr => {
                if (cr && cr.sops && cr.sops.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.action && cr.action.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.aramexRef && cr.aramexRef.label && cr.aramexRef.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.waybill && cr.waybill.label && cr.waybill.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;

                return false;
            });

            return false;
        } else {
            searchFiltersArchives = archives;
        }

        const dateFiltersArchives = filterByDates(searchFiltersArchives);

        let archiveFinal = dateFiltersArchives ? dateFiltersArchives : archives;

        const result = temp.map(r => {
            let keys = Object.keys(r);
            r = { ...r }
            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            if (r['boxTypes'] && Array.isArray(r['boxTypes']) && r['boxTypes'].length > 0) {
                for (let i = 0; i < r['boxTypes'].length; i++) {
                    if (r['Temp Range']) r['Temp Range'] += ', ' + r['boxTypes'][i].boxTemperature;
                    if (!r['Temp Range']) r['Temp Range'] = r['boxTypes'][i].boxTemperature;

                    if (r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                    };
                    if (!r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] = r['boxTypes'][i].tempMonitorType;
                    };

                    if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                }

                r['Number of Temp Monitors'] = r['boxTypes'].length;
            }

            if (r['boxes']) r['Number of Boxes'] = r['boxes'];
            if (r["Sponsor"]) {
                r['sponsorName'] = r['Sponsor'];

                delete r['Sponsor'];
            };

            if (!r['serviceType']) r['serviceType'] = "N/A";
            if (!r['numberOfKits']) r['numberOfKits'] = "N/A";

            delete r['siteId'];
            delete r['siteID'];
            delete r['StudyID'];
            delete r['studyID'];
            delete r['edit'];
            delete r['id'];
            delete r['parcels'];
            delete r['sponsorID'];
            delete r['SponsorID'];
            delete r['view'];
            delete r['sponsorShortName'];

            delete r['confirm'];
            delete r['aramex'];
            delete r['aramexNeeded'];

            delete r['sponsor'];
            delete r['edit'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['list'];
            delete r['dspl'];
            delete r['sss'];
            delete r['boxTypes'];
            delete r['studyNumber'];

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1");
                const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });

            return { " Study": r[" Study"], "Sponsor Name": r["Sponsor Name"], ...r };
        });

        const archiveResult = archiveFinal.map(r => {
            let keys = Object.keys(r);
            r = { ...r }
            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            if (r['boxTypes'] && Array.isArray(r['boxTypes']) && r['boxTypes'].length > 0) {
                for (let i = 0; i < r['boxTypes'].length; i++) {
                    if (r['Temp Range']) r['Temp Range'] += ', ' + r['boxTypes'][i].boxTemperature;
                    if (!r['Temp Range']) r['Temp Range'] = r['boxTypes'][i].boxTemperature;

                    if (r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                    };
                    if (!r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] = r['boxTypes'][i].tempMonitorType;
                    };

                    if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                }

                r['Number of Temp Monitors'] = r['boxTypes'].length;
            }

            if (r['boxes']) r['Number of Boxes'] = r['boxes'];
            if (r["Sponsor"]) {
                r['sponsorName'] = r['Sponsor'];

                delete r['Sponsor'];
            };

            if (!r['serviceType']) r['serviceType'] = "N/A";
            if (!r['numberOfKits']) r['numberOfKits'] = "N/A";

            delete r['siteId'];
            delete r['siteID'];
            delete r['StudyID'];
            delete r['studyID'];
            delete r['edit'];
            delete r['id'];
            delete r['parcels'];
            delete r['sponsorID'];
            delete r['SponsorID'];
            delete r['view'];
            delete r['sponsorShortName'];

            delete r['confirm'];
            delete r['aramex'];
            delete r['aramexNeeded'];

            delete r['sponsor'];
            delete r['edit'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['list'];
            delete r['dspl'];
            delete r['sss'];
            delete r['boxTypes'];
            delete r['studyNumber'];

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1");
                const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });

            return { " Study": r[" Study"], "Sponsor Name": r["Sponsor Name"], ...r };
        });


        return [...result, ...archiveResult];
    }

    function filterByDates(arr) {
        if (selectedStartDate && selectedEndDate && ((filterType === 'Arrival Date') || (filterType === 'Request Date'))) {
            if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
                setSelectedStartDate(prevSelectedStartDate);
                setAlertMessage("start date must be before end date");
                setShowAlert(true);
            }
            if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
                let ed = new Date(selectedEndDate).getTime();
                let sd = new Date(selectedStartDate).getTime();
                let key = '';
                switch (filterType) {
                    case "Request Date": {
                        key = 'requestDate';
                        break;
                    }
                    case "Arrival Date": {
                        key = 'receivedDate';
                        break;
                    }
                    default: return false;
                }
                if (arr && arr.length > 0) {
                    const result = arr.filter(d => {
                        if (!d[key]) {
                            return false;
                        }
                        var time = new Date(d[key].label ? d[key].label : d[key]).getTime();
                        if (time) {
                            return ((sd <= time) && (time <= ed));
                        } else {
                            return false;
                        }

                    });
                    return result;
                }
            }
        } else {
            return false;
        }
    }

    function clearFilters() {
        setFilterType('none');
    }

    const functions = {
        sops: () => { },
        referenceNo: (refNo, b, obj) => {

            setShowUpdateReferenceModal(true);
            setUpdateReference(refNo);
            setUpdateObject(obj);
        },
        requestDate: (_, __, obj) => {
            setUpdateObject(obj);
            setShowRequestDate(true);
        },
        action: (value, i, obj) => {

            handleUpdateAction(value, i, obj);
        },
        boxes: async (_, index, obj) => {

            const SOPS = obj.sops.split(' ');
            const { referenceNo, orderNumber, action, waybill, weight } = obj;

            const link = await getReceiptPdf(obj.orderNumber);

            setBoxLabelLink(link);
            setBoxLabelSops({
                study: SOPS[0],
                order: SOPS[1],
                cspl: SOPS[2],
                site: SOPS[3],
                ref: referenceNo && referenceNo.label ? referenceNo.label : referenceNo,
                id: orderNumber,
                action,
                weight: weight && weight.label ? weight.label : weight,
                waybill: waybill && waybill.label ? waybill.label : waybill,
                boxes: obj.boxes.label.split('(')[0].trim(),
                boxIndex: 1
            });
            setShowBoxLabelModal(true);
        },
        aramexRef: {
            button1: () => {
                setShowUpdateWaybillModal(true);

            },
            button2: (obj) => {

                setShowUpdateAramexWaybillModal(true);
                setAddWaybillData(obj);
            },
        },
        waybill: {
            button1: (obj) => {

                setShowUpdateWaybillModal(true);
                setAddWaybillData(obj);
            },
            button2: async (obj) => {


                if (obj && obj.aramexNeeded === false) {
                    const showLoading = new Event("display-loading");
                    window.dispatchEvent(showLoading);
                    const dismissLoading = new Event("dismiss-loading");
                    generatePdf("logictrialscollectionpdf", obj.orderNumber, obj.sops).then(res => {
                        setTimeout(() => {
                            window.dispatchEvent(dismissLoading);
                            window.open(res);
                        }, 2000);
                    })
                    return;
                } else {
                    if (obj && obj.aramex && obj.aramex.received && obj.aramex.received !== 'null') {
                        window.open(obj.aramex.received);
                        return;
                    }

                    if (obj && obj.orderNumber && (typeof obj.orderNumber === 'string')) {
                        if (obj.waybill && typeof obj.waybill && obj.waybill.label && typeof obj.waybill.label === 'string' && obj.waybill.label.includes(' ')) {
                            const waybills = obj.waybill.label.split(' ');

                            if (waybills && Array.isArray(waybills) && waybills.length > 0) {
                                let notFound = '';

                                const requests = waybills.map(async (w, i) => {

                                    if (!w) return;

                                    const res = await getReturnWaybill(obj.orderNumber);

                                    if (res && typeof res === 'string' && res !== "Order number doesn't exist" && res !== "Waybill not found" && res !== 'invaild order number') {
                                        window.open(res);

                                    }

                                    if (res && typeof res === 'object' && res.data !== false && res.data !== "Order number doesn't exist" && res.data !== "Waybill not found" && res.data !== 'invaild order number') {

                                        window.open(res.data);
                                    } else {

                                        notFound += `${w + ((i !== waybills.length) ? ', ' : '')}`;

                                        console.log(notFound);
                                    };
                                });

                                const resolved = await Promise.all(requests);

                                console.log(notFound);

                                displayAlert('warning', 'No waybill found', `No waybill found for the following waybills: ${notFound}`);
                            }

                            return;
                        } else {
                            const res = await getReturnWaybill(obj.orderNumber);

                            if (res && typeof res === 'string' && res !== "Order number doesn't exist" && res !== "Waybill not found") {
                                window.open(res);

                                return;
                            };

                            if (res && typeof res === 'object' && res.data !== false && res !== "Order number doesn't exist") {
                                window.open(res.data);
                            } else {
                                displayAlert('warning', 'No waybill found', 'No waybill found for this return');
                            };
                            return;
                        }
                    }
                }
            }
        },
        retryAramex: (_, __, item) => {
            // console.log('ITEM', item);

            // return;

            const collectionDate = item && item.collectionDate && isValidDate(new Date(item.collectionDate)) && new Date(item.collectionDate);

            // if (item && item.orderNumber) {
            //     if (collectionDate > new Date()) {

            //         // const comparablePickUpTime = item && item.
            //         retryAramexReturns(item.orderNumber).then(res => {
            //             fetchData();

            //             displayAlert('success', 'Retry Successfull', 'Or system is generating the new waybill. It will be available in the next 30 seconds.');

            //             setTimeout(() => {
            //                 fetchData();
            //             }, 10000);
            //         });
            //     } else {
            //         setShowAramexRetryModal(true);

            //         setRetryOrderNumber(item.orderNumber);

            //     }
            // }

            if (item && item.orderNumber) {

                const collectionDate = item && item.collectionDate && isValidDate(new Date(item.collectionDate)) && new Date(item.collectionDate);
                if (collectionDate > new Date()) {
                    // item date is in the future

                    // 1) Check if the item was originally aramax
                    if (item.aramexNeeded) {
                        // items with no aramexAccount are old - can use the standard clinical account
                        retryAramexReturns(item.aramexAccount ? item.aramexAccount : "clinical", item.orderNumber).then(res => {
                            fetchData();

                            if (res && typeof res === "string") {
                                if (res === "successful") {
                                    displayAlert('success', 'Retry Successful', 'Or system is generating the new waybill. It will be available in the next 30 seconds.');
                                } else {
                                    displayAlert('warning', res, 'Please try again later.');
                                }
                            } else {
                                displayAlert('warning', 'An error occurred', 'Please try again later.');
                            }


                            setTimeout(() => {
                                fetchData();
                            }, 10000);
                        });


                    } else {
                        // item was never Aramex
                        setRetryOrderNumber(item.orderNumber);
                        setShowAramexAccountPopup(true);
                    }

                } else {
                    // item has a past date
                    // check if the item was originally aramex
                    if (item.aramexNeeded === false) {
                        // passed to the Aramex Retry
                        setShowAramexAccountRetryModal(true);

                    } else {
                        console.log("Aramex account not needed")
                    }
                    setRetryOrderNumber(item.orderNumber);
                    setShowAramexRetryModal(true);

                }

            }

        },
        receivedDate: (test, __, obj) => {

            if (!test) {
                setUpdateObject(obj);
                setShowConfirmReceived(true);
            }

            if (test) {
                const showLoading = new Event("display-loading");
                window.dispatchEvent(showLoading);
                const dismissLoading = new Event("dismiss-loading");
                setUpdateObject(obj);
                getReceiptPdf(obj.orderNumber).then(res => {
                    window.dispatchEvent(dismissLoading);
                    window.open(res);
                });
            }

        },
        confirmReceipt: (_, __, obj) => {
            setUpdateObject(obj);
            setShowConfirmReceipt(true);
        },
        delete: (_, __, obj) => {

            setShowConfirmDelete(true);
            const { id, orderNumber } = obj;

            setDeleteOrderNumber(orderNumber);
            setDeleteId(id);
        },
        edit: (_, __, obj) => {

            setDisplayOrder(true);
            setUpdateObject(obj);
        },
        gnNo: (_, __, obj) => {
            setShowGnNumber(true);
            setUpdateObject(obj);
        },
        confirm: (_, __, obj) => {

            if (obj && obj.orderNumber) {
                setShowConfirm(true);
                const { orderNumber } = obj;

                setConfirmId(orderNumber);
            }
        },
        weight: (_, __, obj) => {
            setShowAddWeight(true);
            setUpdateObject(obj);
        },
        collectionDate: (_, __, object) => {
            if (object) {
                setUpdateObject(object);
                setShowConfirmCollection(true);
            }
        },
        sendToDestruction: (_, __, obj) => {
            setUpdateObject(obj);
            setShowSend(true);
        }
    };

    useEffect(() => {
        if (filterByAction && filterType === 'Action') {
            const filtered = unchangedCollectionsReturns.filter(r => r.action && r.action === filterByAction);

            setCollectionsReturns(filtered);
        }

        if (filterByAction && filterType !== 'Action') {
            setFilterByAction('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByAction, filterType]);

    return (
        <>{!displayOrder && <BaseTemplate title="Collections & Returns">
            <div>
                <Search
                    setSearchQuery={setSearchQuery}
                    placeholder="Search"
                />
                <IonGrid>
                    <IonRow>
                        <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"} offset-md="1">
                            <IonItem className="shadow-xl">
                                <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                    Filter Type
                                </IonLabel>
                                <IonSelect interface="popover" value={filterType} onIonChange={e => setFilterType(e.detail.value)}>
                                    <IonSelectOption value={"none"}>
                                        None
                                    </IonSelectOption>
                                    <IonSelectOption value="Request Date">
                                        Request Date
                                    </IonSelectOption>
                                    <IonSelectOption value="Arrival Date">
                                        Arrival Date
                                    </IonSelectOption>
                                    <IonSelectOption value="Action">
                                        Action
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        {filterType !== 'none' && filterType !== "Action" && <>
                            <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                <IonItem
                                    className="shadow-xl"
                                    onClick={(e) => setShowStartDate({ showPopover: true, event: e })}
                                >
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        Start Date
                                    </IonLabel>
                                    <IonDatetime
                                        displayFormat="D MMM YYYY H:mm"
                                        min="1997"
                                        max="2021"
                                        value={selectedStartDate}
                                        readonly={true}
                                    ></IonDatetime>
                                </IonItem>
                            </IonCol>
                            <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                <IonItem
                                    className="shadow-xl"
                                    onClick={(e) => setShowEndDate({ showPopover: true, event: e })}
                                >
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        End Date
                                    </IonLabel>
                                    <IonDatetime
                                        displayFormat="D MMM YYYY H:mm"
                                        min="1997"
                                        max="2021"
                                        value={selectedEndDate}
                                        readonly={true}
                                    ></IonDatetime>
                                </IonItem>
                            </IonCol></>}
                        {filterType === "Action" &&
                            <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                <IonItem
                                    className="shadow-xl"
                                >
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        Action
                                    </IonLabel>
                                    <IonSelect placeholder="Select action" interface="popover" interfaceOptions={{ header: "Select an Action" }} value={filterByAction} okText="Okay" cancelText="Dismiss" onIonChange={e => setFilterByAction(e.target.value)}>
                                        {actions.map((a, i) => (
                                            <IonSelectOption key={i} value={a}>{a}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>}
                        <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"}>
                            <IonButton expand="full" style={{ height: '50px', marginTop: 0 }} onClick={() => exportArrayToExcel(excelArray(), `collections-returns-${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`)}>
                                export to excel
                            </IonButton>
                        </IonCol>
                        {!isExternal && <IonCol size-xs="12" size-sm="12" offset={filterType !== "none" ? "1" : "0"} size-md={filterType !== 'none' ? "2.5" : "2.5"}>
                            <IonButton expand="full" style={{ height: '50px', marginTop: 0 }} onClick={async () => {
                                const exportArray = await excelArrayWithArchives();
                                exportArrayToExcel(exportArray, `collections-returns-with-archives-${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`);
                            }}>
                                export with archives
                            </IonButton>
                        </IonCol>}
                    </IonRow>
                    {filterType !== 'none' && <IonRow>
                        <IonCol offset-md="1.05">
                            <span style={{ cursor: 'pointer', color: 'var(--ion-color-primary)' }} onClick={() => clearFilters()}>clear filters</span>
                        </IonCol>
                    </IonRow>}
                </IonGrid>
            </div>
            <div className="users" style={{ paddingBottom: "5em" }}>
                <h2 className="results-display">{revisedCollectionsReturns.length} Result(s)</h2>
                <IonGrid>
                    <IonRow style={{ boxShadow: "none" }}>
                        <IonCol offset="0" size="12">
                            {loading && <Loading loading={loading} />}
                            {!loading && (
                                currentPageData && currentPageData.length > 0 ? <TableList
                                    headings={headings}
                                    items={currentPageData}
                                    labels={labels}
                                    functions={functions}
                                /> : <h1>No search results</h1>
                            )}
                            <div style={{ margin: "1em 1em" }}>
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonAlert
                    isOpen={error !== ""}
                    onDidDismiss={() => setError("")}
                    header={"Error"}
                    message={error}
                    buttons={["OK"]}
                />
            </div>
            <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{ visibility: 'hidden' }}></IonDatetime>
            {formattedPermissions && formattedPermissions.includes('returns_c_') && <IonFab
                vertical="bottom"
                horizontal="start"
                slot="fixed"
                style={{
                    marginBottom: window.innerWidth < 900 ? "3em" : "5em",
                    position: "fixed",
                    marginRight: "2em",
                }}
            >
                <IonFabButton routerLink="/new-return">
                    <IonIcon icon={add}></IonIcon>
                </IonFabButton>
            </IonFab>}
            <Modal showModal={showUpdateReferenceModal} setShowModal={setShowUpdateReferenceModal}>
                {showUpdateReferenceModal && <UpdateReference obj={updateObject} originalNumber={updateReference} setShowModal={setShowUpdateReferenceModal} handleUpdateReference={handleUpdateReference} fetchData={fetchData} />}
            </Modal>
            {showBoxLabelModal && <Modal className="box-label-modal" showModal={showBoxLabelModal} setShowModal={setShowBoxLabelModal}>
                {showBoxLabelModal && <BoxLabel setShowModal={setShowBoxLabelModal} sops={boxLabelSops} link={boxLabelLink} updateObject={updateObject} />}
            </Modal>}
            <Modal showModal={showUpdateAramexWaybillModal} setShowModal={setShowUpdateAramexWaybillModal}>
                {showUpdateAramexWaybillModal && <AddManualAramexWaybill obj={addWaybillData} handleUpdateWaybill={() => { }} setShowModal={setShowUpdateAramexWaybillModal} fetchData={fetchData} />}
            </Modal>
            <Modal showModal={showUpdateWaybillModal} setShowModal={setShowUpdateWaybillModal}>
                {showUpdateWaybillModal && <AddManualWaybill obj={addWaybillData} handleUpdateWaybill={() => { }} setShowModal={setShowUpdateWaybillModal} fetchData={fetchData} />}
            </Modal>
            <Modal showModal={showRequestDate} setShowModal={setShowRequestDate}>
                {showRequestDate && <RequestDate obj={updateObject} setShowModal={setShowRequestDate} fetchData={fetchData} />}
            </Modal>
            <Modal showModal={showConfirmReceipt} setShowModal={setShowConfirmReceipt}>
                {showConfirmReceipt && <ConfirmReceipt returnObject={updateObject} setShowModal={setShowConfirmReceipt} fetchData={fetchData} />}
            </Modal>
            <Modal showModal={showGnNumber} setShowModal={setShowGnNumber}>
                {showGnNumber && <GnNumber returnObject={updateObject} setShowModal={setShowConfirmReceipt} fetchData={fetchData} />}
            </Modal>
            <Modal showModal={showConfirmReceived} setShowModal={setShowConfirmReceived}>
                {showConfirmReceived && <ConfirmReceivedDate returnObject={updateObject} setShowModal={setShowConfirmReceived} fetchData={fetchData} />}
            </Modal>
            <Modal showModal={showConfirmCollection} setShowModal={setShowConfirmCollection}>
                {showConfirmCollection && <ConfirmCollectionDate returnObject={updateObject} setShowModal={setShowConfirmCollection} fetchData={fetchData} />}
            </Modal>
            {showAddWeight && <Modal showModal={showAddWeight} setShowModal={setShowAddWeight}>
                <AddWeight returnObject={updateObject} setShowModal={setShowAddWeight} fetchData={fetchData} />
            </Modal>}
            <IonModal cssClass="alert-modal" isOpen={showAltAlert} onDidDismiss={() => setShowAlert(false)}>
                <AlertMessage type={alertType} alertTitle={alertTitle} alertDescription={alertAltMessage} />
            </IonModal>

            <IonModal isOpen={showAramexRetryModal} onDidDismiss={() => setShowAramexRetryModal(false)}>
                <RetryAramex setShowModal={setShowAramexRetryModal} orderNumber={retryOrderNumber} tableName={'returns'} fetchData={fetchData} needsAramexAccount={showAramexAccountRetryModal} />
            </IonModal>
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showAramaxAccountPopup}
                event={showStartDate.event}
                onDidDismiss={() => setShowAramexAccountPopup(false)}
                style={{ padding: "1em" }} // Padding on popover
            >
                <IonContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "1em",
                    }}
                >
                    <div style={{ padding: "10px", marginBottom: "15px" }}>
                        This return does not have Aramex as the selected Courier. Do you want to change the courier to Aramex?
                    </div>

                    <IonButton style={{ width: "90%" }} onClick={() => {
                        retryAramexReturns("clinical", retryOrderNumber).then(res => {
                            fetchData();

                            if (res && typeof res === "string") {
                                console.log(res);
                                if (res === "successful") {
                                    displayAlert('success', 'Retry Successful', 'Or system is generating the new waybill. It will be available in the next 30 seconds.');
                                } else {
                                    displayAlert('warning', res, 'Please try again later.');
                                }
                            } else {
                                displayAlert('warning', 'An error occurred', 'Please try again later.');
                            }

                            setTimeout(() => {
                                fetchData();
                            }, 10000);
                        });
                        setShowAramexAccountPopup(false);
                    }}>Yes - Use Clinical Account</IonButton>
                    <IonButton style={{ width: "90%" }} onClick={() => {
                        retryAramexReturns("ecommerce", retryOrderNumber).then(res => {
                            fetchData();

                            if (res && typeof res === "string") {
                                console.log(res);
                                if (res === "successful") {
                                    displayAlert('success', 'Retry Successful', 'Or system is generating the new waybill. It will be available in the next 30 seconds.');
                                } else {
                                    displayAlert('warning', res, 'Please try again later.');
                                }
                            } else {
                                displayAlert('warning', 'An error occurred', 'Please try again later.');
                            }

                            setTimeout(() => {
                                fetchData();
                            }, 10000);
                        });
                        setShowAramexAccountPopup(false)
                    }}>Yes - Use Ecommerce Account</IonButton>
                    <IonButton style={{ width: "90%" }} color="danger" onClick={() => {
                        setShowAramexAccountPopup(false);
                    }}>No</IonButton>

                </IonContent>
            </IonPopover>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={"Alert"}
                message={alertMessage}
                buttons={["OK"]}
            />
        </BaseTemplate>}
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                position="bottom"
                duration={2000}
                color="success"
            />
            <IonAlert
                isOpen={showConfirmDelete}
                onDidDismiss={() => setShowConfirmDelete(false)}
                message={`Are you sure you want to delete<br /> order ${deleteOrderNumber}?`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowConfirmDelete(false)
                    },
                    {
                        text: 'Delete',
                        cssClass: 'danger',
                        handler: () => {
                            if (deleteId) deleteItem('returns', { id: deleteId }).then(res => fetchData());
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showConfirm}
                onDidDismiss={() => setShowConfirm(false)}
                message={`Are you sure you want to complete<br /> order ${confirmId}?`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowConfirm(false)
                    },
                    {
                        text: 'Confirm',
                        cssClass: '',
                        handler: () => {
                            if (confirmId) confirmReturn(confirmId).then(() => fetchData());
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showSend}
                onDidDismiss={() => setShowSend(false)}
                message={`Are you sure you want to send<br /> order ${updateObject && updateObject.orderNumber} to destructions?`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowSend(false)
                    },
                    {
                        text: 'Send',
                        cssClass: '',
                        handler: () => {
                            if (updateObject && updateObject.orderNumber) {
                                addToDestructionList(updateObject);
                            }
                        }
                    }
                ]}
            />
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showStartDate.showPopover}
                event={showStartDate.event}
                onDidDismiss={() => setShowStartDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedStartDate(day.toString()); setShowStartDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowStartDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonPopover>
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showEndDate.showPopover}
                event={showEndDate.event}
                onDidDismiss={() => setShowEndDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedEndDate(day.toString()); setShowEndDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowEndDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonPopover>
            {displayOrder && <ReturnsProcessing returnObject={updateObject} setDisplayReturn={setDisplayOrder} fetchData={fetchData} />}</>
    );
}

export default CollectionsReturns;
