import React, { useState, useEffect } from 'react';
import { IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonItem, IonButton, IonLabel, IonInput, IonSegment, IonSegmentButton } from '@ionic/react';
import { getBoxTypes } from '../apis/logictrials';
import staticBoxes from '../data/box-types';

const BOX_TEMPERATURES = [
    '-70',
    '-25',
    "2-8",
    '15-25',
    'Uncontrolled'
];

const TEMP_TYPES = [
    'TT4',
    'Libero',
    'ITAG',
    'Weblogger',
    'None'
];

export default function AddNewBoxForm({ setBoxTypes, boxTypes, setShowModal, boxes, setBoxes, editBox }) {
    const [boxType, setBoxType] = useState('');
    const [boxTypesArr, setBoxTypesArr] = useState([]);
    const [boxTemperature, setBoxTemperature] = useState(null);
    const [tt4Serial, setTt4Serial] = useState('');
    const [numberOfBoxes, setNumberOfBoxes] = useState(null);
    const [tempMonitorType, setTempMonitorType] = useState(null);
    const [weight, setWeight] = useState(null);
    const [height, setHeight] = useState(null);
    const [length, setLength] = useState(null);
    const [width, setWidth] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState('predefined');

    useEffect(() => {
        getBoxTypes()
            .then(types => {
                const parsedBoxes = types.map(t => { return { ...JSON.parse(t.data), name: t.name } });
                if (editBox) {
                    if (editBox.boxTemperature) setBoxTemperature(editBox.boxTemperature);
                    if (editBox.tempMonitorType) setTempMonitorType(editBox.tempMonitorType);
                    if (editBox.tt4Serial) setTt4Serial(editBox.tt4Serial);

                    if (editBox.weight) setWeight(editBox.weight);
                    if (editBox.height) setHeight(editBox.height);
                    if (editBox.length) setLength(editBox.length);
                    if (editBox.width) setWidth(editBox.width);

                    setNumberOfBoxes(1);

                    let isPredefinedType = false;
                    parsedBoxes.forEach(t => {
                        if (editBox.boxType && t.name === editBox.boxType) {
                            setBoxType(t);
                            isPredefinedType = true;
                        }
                    });

                    if (!isPredefinedType) setBoxType(editBox.boxType);
                }
                setBoxTypesArr(parsedBoxes);
            })
            .catch(_ => {
                const parsedBoxes = staticBoxes.map(t => { return { ...JSON.parse(t.data), name: t.name } });
                setBoxTypesArr(parsedBoxes);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!boxTypesArr || boxTypesArr.length === 0) {
            const parsedBoxes = staticBoxes.map(t => { return { ...JSON.parse(t.data), name: t.name } });
            setBoxTypesArr(parsedBoxes);
        }
    });

    function addBox() {
        if (selectedSegment === "predefined") {
            const addArr = [...Array(Number(numberOfBoxes))].map(_ => {
                return {
                    number: 1,
                    boxType: boxType.name,
                    boxTemperature,
                    tempMonitorType,
                    tt4Serial,
                    ...boxType
                }
            });
            setBoxTypes([...boxTypes, ...addArr]);
        }

        if (selectedSegment === "custom") {
            const addArr = [...Array(Number(numberOfBoxes))].map(_ => {
                return {
                    number: 1,
                    weight,
                    boxType,
                    boxTemperature,
                    tt4Serial,
                    tempMonitorType,
                    height,
                    length,
                    width
                }
            });
            setBoxTypes([...boxTypes, ...addArr]);
        }
        setBoxes(Number(boxes) + 1);
        setShowModal(false);
    }

    function updateBox() {
        if (selectedSegment === "predefined") {
            const updated = {
                number: 1,
                boxType: boxType.name,
                boxTemperature,
                tempMonitorType,
                tt4Serial,
                ...boxType
            };

            let tempBoxTypes = boxTypes;

            tempBoxTypes[editBox.index] = updated;
            setBoxTypes([...tempBoxTypes]);
        }

        if (selectedSegment === "custom") {
            const updated = {
                number: 1,
                weight,
                boxType,
                boxTemperature,
                tt4Serial,
                tempMonitorType,
                height,
                length,
                width
            };

            let tempBoxTypes = boxTypes;

            tempBoxTypes[editBox.index] = updated;
            setBoxTypes([...tempBoxTypes]);
        }
        setBoxes(Number(boxes) + 1);
        setShowModal(false);
    }

    useEffect(() => {
        if (!editBox) {
            setBoxType('');
            setBoxTemperature('');
            setTt4Serial('');
            setNumberOfBoxes('');
            setTempMonitorType('');
            setWeight('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSegment]);

    return (
        <div style={{ marginTop: '1em' }}>
            <IonSegment value={selectedSegment} onIonChange={e => setSelectedSegment(e.detail.value)}>
                <IonSegmentButton value="predefined">
                    <IonLabel>Predefined</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="custom">
                    <IonLabel>Custom</IonLabel>
                </IonSegmentButton>
            </IonSegment>

            <IonGrid>
                <IonRow>
                    {selectedSegment === "predefined" &&
                        <>
                            <IonCol size="12">
                                <IonItem>
                                    <IonLabel>Box Type</IonLabel>
                                    <IonSelect interface="popover" value={boxType} placeholder="Box Type" okText="Okay" cancelText="Dismiss" onIonChange={e => setBoxType(e.detail.value)}>
                                        {boxTypesArr.map((type, i) => (
                                            <IonSelectOption key={i} value={type}>{type.name}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem>
                                    <IonLabel>Box Temperature</IonLabel>
                                    <IonSelect interface="popover" value={boxTemperature} placeholder="Box Temperature" okText="Okay" cancelText="Dismiss" onIonChange={e => setBoxTemperature(e.detail.value)}>
                                        {BOX_TEMPERATURES.map((temp, i) => (
                                            <IonSelectOption key={i} value={temp}>{temp}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem>
                                    <IonLabel>Temperature Monitor Type</IonLabel>
                                    <IonSelect interface="popover" value={tempMonitorType} placeholder="Box Temperature" okText="Okay" cancelText="Dismiss" onIonChange={e => setTempMonitorType(e.detail.value)}>
                                        {TEMP_TYPES.map((temp, i) => (
                                            <IonSelectOption key={i} value={temp}>{temp}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </>}
                    {selectedSegment === "custom" && <>
                        <IonCol size="12">
                            <IonInput placeholder="Box Type" value={(boxType && (typeof boxType === 'object') && boxType.name) ? boxType.name : boxType} onIonChange={e => setBoxType(e.detail.value)} />
                        </IonCol>
                        <IonCol size="12">
                            <IonInput placeholder="Box Temperature" value={boxTemperature} onIonChange={e => setBoxTemperature(e.detail.value)} />
                        </IonCol>
                        <IonCol size="12">
                            <IonInput placeholder="Temperature Monitor Type" value={tempMonitorType} onIonChange={e => setTempMonitorType(e.detail.value)} />
                        </IonCol>
                        <IonCol size="12">
                            <IonInput placeholder="Weight (kg)" value={weight} onIonChange={e => setWeight(e.detail.value)} type="number" />
                        </IonCol>
                        <IonCol size="12">
                            <IonInput placeholder="Height (cm)" value={height} onIonChange={e => setHeight(e.detail.value)} type="number" />
                        </IonCol>
                        <IonCol size="12">
                            <IonInput placeholder="Length (cm)" value={length} onIonChange={e => setLength(e.detail.value)} type="number" />
                        </IonCol>
                        <IonCol size="12">
                            <IonInput placeholder="Width (cm)" value={width} onIonChange={e => setWidth(e.detail.value)} type="number" />
                        </IonCol>
                    </>}
                    <IonCol size="12">
                        <IonInput placeholder="Serial of TT4" value={tt4Serial} onIonChange={e => setTt4Serial(e.detail.value)}></IonInput>
                    </IonCol>
                    {!editBox && <IonCol size="12">
                        <IonInput type="number" placeholder="How many of this type of box for this order?" value={numberOfBoxes} onIonChange={e => setNumberOfBoxes(e.detail.value)}></IonInput>
                    </IonCol>}
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton color="success" expand="full" style={{ marginTop: '3em' }} onclick={editBox ? updateBox : addBox}>
                            {editBox ? 'update box' : 'Create Box'}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
