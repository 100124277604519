import React, { useState } from 'react';

import { IonGrid, IonCol, IonRow, IonPopover, IonModal, IonButton, IonInput, IonItem } from '@ionic/react';
import { isValidDate } from '../../utils';
import { updateItem } from '../../apis/logictrials';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';

export default function ConfirmCollectionDate({ returnObject, setShowModal, fetchData }) {
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [showSelectDate, setShowSelectDate] = useState({ showPopover: false, event: undefined });

    async function handleSubmit() {

        let dateWithTime;
        if ((date && isValidDate(new Date(date))) && time) {
            const hoursMins = time.split(':');

            let tempDate = new Date(date);
            dateWithTime = tempDate.setHours(hoursMins[0]);
            dateWithTime = tempDate.setMinutes(hoursMins[1]);

            dateWithTime = new Date(dateWithTime).toISOString();
        }

        if ((dateWithTime && isValidDate(new Date(dateWithTime))) && returnObject) {
            let tempObj = returnObject;

            tempObj.collectionDate = format(new Date(dateWithTime), 'yyyy-MM-dd HH:mm');

            await updateItem('returns', ['data'], [tempObj], { id: returnObject.id });

            fetchData();
            setShowModal(false);
        }
    }

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <h3 className="text-xl">Confirm Collection Date</h3>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <IonItem lines="none">
                            <IonInput onClick={(e) => setShowSelectDate({ showPopover: true, event: e })} placeholder="Collection Date" value={date ? format(new Date(date), 'MM d y') : ''} />
                        </IonItem>
                    </IonCol>
                    <IonCol size="6">
                        <IonItem lines="none">
                            Collection time: <span style={{ width: 20 }} /> <IonInput min="08:00" max="17:00" placeholder="Collection Time" type="time" value={time} onIonChange={e => setTime(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton onClick={handleSubmit} color="success" expand="full">
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showSelectDate.showPopover}
                event={showSelectDate.event}
                onDidDismiss={() => setShowSelectDate({ showPopover: false, event: undefined })}
            >
                <DayPicker
                    canChangeMonth={true}
                    onDayClick={(day) => {
                        setDate(day.toString());
                        setShowSelectDate({ showPopover: false, event: undefined });
                    }}
                // disabledDays={[{ daysOfWeek: [0, 6] }, {after: nextWeek, before: new Date()}]} 
                />
                <IonButton onClick={() => setShowSelectDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
        </>
    )
}
