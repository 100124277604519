import "./PrimaryButton.css";

import { IonButton, IonIcon } from "@ionic/react";

const PrimaryButton = ({
    text,
    action,
    width,
    link,
    className,
    color,
    icon,
    type,
    disabled
}) => {
    return (
        <IonButton
            type={type}
            onClick={action}
            className={`btn-primary ${className}`}
            style={
                width
                    ? { width: width, backgroundColor: color, }
                    : { backgroundColor: color }
            }
            routerLink={link}
            color={color}
            disabled={disabled !== undefined ? disabled : false}
        >
            {icon && <IonIcon icon={icon.svg} slot={icon.slot} />}

            {text}
        </IonButton>
    );
};

export default PrimaryButton;
