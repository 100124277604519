import React, { useState, useEffect, useRef } from "react";
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from "@ionic/react";
import BaseTemplate from "../components/base/BaseTemplate";
import { checkmarkOutline } from "ionicons/icons";
import { getData } from "../apis/logictrials";
import Select from "react-dropdown-select";

export default function () {
  const [studies, setStudies] = useState([]);
  const [sites, setSites] = useState([]);
  const [sitesOrig, setSitesOrig] = useState([]);
  const [study, setStudy] = useState();
  const [studyPrev, setStudyPrev] = useState();
  const [site, setSite] = useState();
  const selectStudy = useRef();
  const selectSite = useRef();

  function generateOrderNumber() {
    return `${String(new Date().getFullYear()).replace("20", "")}-${
      new Date().getMonth() + 1
    }`;
  }

  useEffect(() => {
    if (studies.length === 0) {
      getData("study", 0, 9999).then((res) => {
        const data = res
          .map(({ data, id }) => {
            data = JSON.parse(data);
            data.id = id;
            data.labelField = `${data.sponsor} - ${data.studyNumber}`;
            return data;
          })
          .filter((opt) => {
            if (typeof opt !== "object") return false;
            if (typeof opt.studyNumber === "number")
              opt.studyNumber = opt.studyNumber.toString();
            if (typeof opt.studyNumber !== "string" || opt.studyNumber === "")
              return false;

            return true;
          })
          .sort(function (a, b) {
            return a.labelField.localeCompare(b.labelField);
          })
          .filter(function (item, pos, ary) {
            return !pos || item.labelField !== ary[pos - 1].labelField;
          });
        const dataRet = [];
        data.forEach((opt) => {
          if (!dataRet.includes(opt)) dataRet.push(opt);
        });
        setStudies(dataRet);
        if (selectStudy.current) selectStudy.current.clearAll();
      });
    }

    if (sites.length === 0) {
      getData("sites", 0, 9999).then((res) => {
        const data = res.map(({ data, id }) => {
          let parsed = JSON.parse(data);
          parsed.id = id;
          parsed.displayNumber = `${parsed.number} ${parsed.study}`;

          return parsed;
        });
        setSites(data);
        setSitesOrig(data);
        if (selectSite.current) selectSite.current.clearAll();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site)
      sessionStorage.setItem("lt-temp-add-order-site", JSON.stringify(site));
    if (study) {
      sessionStorage.setItem("lt-temp-add-order-study", JSON.stringify(study));
      const data = sitesOrig.filter((opt) => opt.study === study.studyNumber);

      if (study !== studyPrev) selectSite.current.clearAll();
      setStudyPrev(study);
      setSites(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, study]);

  return (
    <BaseTemplate>
      <IonGrid>
        <IonRow style={{ boxShadow: "none" }}>
          <IonCol>
            <div
              className="shadow-xl py-4 px-10"
              style={{ backgroundColor: "white" }}
            >
              <h2 className="form-heading">
                Create Order <span style={{ color: "grey" }}>|</span>{" "}
                <span className="order-number-processing">
                  {generateOrderNumber()}
                </span>
              </h2>
              <IonGrid style={{ marginTop: "2em" }}>
                <IonRow>
                  <IonCol size="12">
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <Select
                        ref={selectStudy}
                        placeholder={
                          studies === []
                            ? "Loading..."
                            : `Search Study (${studies.length})`
                        }
                        labelField="labelField"
                        searchBy="labelField"
                        valueField="labelField"
                        multi={false}
                        loading={studies === []}
                        dropdownHandle={true}
                        searchable={true}
                        values={study ? [study] : []}
                        options={studies}
                        onChange={(values) => {
                          if (values.length > 0) {
                            setStudy(values[0]);
                          }
                        }}
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <Select
                        ref={selectSite}
                        placeholder={
                          sites === []
                            ? "Loading..."
                            : `Search Site (${sites.length})`
                        }
                        labelField="displayNumber"
                        searchBy="displayNumber"
                        valueField="displayNumber"
                        multi={false}
                        loading={sites === []}
                        dropdownHandle={true}
                        searchable={true}
                        options={sites}
                        onChange={(values) => {
                          if (values.length > 0) {
                            setSite(values[0]);
                          }
                        }}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="4"></IonCol>
                  <IonCol size="4"></IonCol>
                  <IonCol size="4">
                    <IonButton
                      disabled={!site}
                      color="success"
                      expand="full"
                      routerLink="/order-processing"
                    >
                      <IonIcon icon={checkmarkOutline} slot="start" />
                      Create
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </BaseTemplate>
  );
}
