import React, { useState, useEffect } from 'react';
import { IonToast } from '@ionic/react';

const AlertToast = (show, set) => {
    const [showToast, setShowToast] = useState({ isOpen: false });

    useEffect(() => {
        window.addEventListener('network_fail', e => {
            setShowToast({
                isOpen: true,
                message: e.msg,
                action: () => { }
            });
        }, false);
    }, []);

    return (
        <>
            <IonToast
                isOpen={showToast.isOpen}
                onDidDismiss={() => setShowToast({ isOpen: false })}
                message={showToast.message}
                position="bottom"
                color="danger"
                buttons={[
                    {
                        text: 'retry',
                        handler: () => showToast.action()
                    },
                    {
                        text: 'dismiss',
                        handler: () => setShowToast({isOpen: false})
                    }
                ]}
            />
        </>
    );
};

export default AlertToast;