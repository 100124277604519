import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {

    return (
        <Route
            {...rest}
            render={props => {
                return sessionStorage.getItem('demo-current-user') ? <Component {...props} /> : <Redirect to="/login" />
            }}
        />
    )
}
