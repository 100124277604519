import React from 'react';
import  { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';

export default function ViewBoxWaybills({waybills, setShowModal}) {

            // IsActive: "0"
            // IsReturnable: "0"
            // ReportName: "STK5"
            // VolWeight: "5.779"
            // aramexReturn:
            //     received: "https://nservice.aramex.co.za/Home/GeneratePDFTemplateOne/?waybill_number=TST97558037"
            //     request: "TST97558037"
            //     status: "TRANSACTION SUCCESSFUL"
            //     statusCode: 0
            // [[Prototype]]: Object
            // boxTemperature: "-25"
            // boxType: "STD CARBORD STK 4 (4KG)"
            // height: "30"
            // length: "30"
            // name: "STD CARBORD STK 4 (4KG)"
            // number: 1
            // quantity: 0
            // tempMonitorType: "Libero"
            // tt4Serial: "1234"
            // weight: "8"
            // width: "23"
    return (
        <div>
            <IonGrid>
                <IonRow style={{marginTop: '3em'}}>
                    <IonCol size="12">
                        {waybills && Array.isArray(waybills) && (waybills.length > 0) ? waybills.map((b, i) => (
                            <div key={i} className="rounded-xl bg-gray-200 p-5">
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>TT4 Serial:</span>{b && b.tt4Serial}</p>
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Weight:</span>{b && b.weight}</p>
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Height:</span>{b && b.height}</p>
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Width:</span>{b && b.width}</p>
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Length:</span>{b && b.length}</p>
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Box Type:</span>{b && b.boxType}</p>
                                {b && b.aramexReturn && b.aramexReturn.request && <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Aramex Request Reference:</span>{b && b.aramexReturn && b.aramexReturn.request}</p>}
                                <p className="text-xs"><span style={{fontWeight: 'bold', marginRight: '1em'}}>Aramex Reference:</span>{b && b.collectionReturn && b.collectionReturn.collection_reference}</p>
                                <p className="text-xs mb-2" style={{color: b && b.aramexReturn && b.aramexReturn.status ? 'var(--ion-color-success)' : 'var(--ion-color-danger)'}}><span style={{fontWeight: 'bold', marginRight: '1em', color: 'black'}}>Aramex Request Status:</span>{b && b.aramexReturn && b.aramexReturn.status}</p>

                                <a href={b && b.aramexReturn && b.aramexReturn.received} target="_blank" rel="noopener noreferrer">
                                    <IonButton expand="full" color="success">
                                        View Waybill
                                    </IonButton>
                                </a>
                            </div>
                        )) : (
                            <h3>No waybill data</h3>
                        )}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{textAlign: 'right', marginTop: '3em'}} size-md="12">
                        <IonButton expand="full" color="warning" onClick={() => setShowModal(false)}>
                            Done
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
