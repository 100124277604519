import React, { useState, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { getItem, getSponsorByName } from '../../apis/logictrials';

export default function NewStudy({editStudy}) {
    const [groupEmails, setGroupEmails] = useState('');
    const [validEmails, setValidEmails] = useState([]);
    const [ studyNumber, setStudyNumber] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');

    console.log(editStudy)

    useEffect(() => {
        if(editStudy) {
            setValidEmails((editStudy.groupEmails && editStudy.groupEmails.includes(';')) ? editStudy.groupEmails.split(';') : editStudy.groupEmails.split(','));
            setStudyNumber(editStudy.studyNumber ? editStudy.studyNumber : '');
            if(editStudy.sponsor) {
                setSponsor(editStudy.sponsor);
            } else if(editStudy.sponsorId) {
                getItem('sponsor', editStudy.sponsorId).then(res => setSponsor(JSON.parse(res[0].data)));
            }

            setContactName(editStudy.contactName ? editStudy.contactName : '');
            setContactNumber(editStudy.contactNumber ? editStudy.contactNumber : '');
            setContactEmail(editStudy.contactEmail ? editStudy.contactEmail : '');

            setGroupEmails(editStudy.groupEmails);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editStudy]);

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol className="font-bold">
                        Study Number
                    </IonCol>
                    <IonCol>
                        {studyNumber}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Sponsor
                    </IonCol>
                    <IonCol>
                        {sponsor}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Contact Name
                    </IonCol>
                    <IonCol>
                        {contactName}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Contact Number
                    </IonCol>
                    <IonCol>
                        {contactNumber}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Contact Email
                    </IonCol>
                    <IonCol>
                        {contactEmail}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="font-bold">
                        Group Emails
                    </IonCol>
                    <IonCol>
                        {groupEmails}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
