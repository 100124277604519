const bt = [
  {
    id: 1,
    name: 'CONTROLLED TEMPERATURE (15KG)',
    data:
      '{"width": "35", "height": "29", "length": "55", "weight": "15", "IsActive": 0, "quantity": 0, "VolWeight": "11.165", "ReportName": "CIP", "IsReturnable": 0}',
  },
  {
    id: 2,
    name: 'STD CARBORD BOX STK3 (3KG)',
    data:
      '{"width": "15", "height": "25", "length": "25", "weight": "4", "IsActive": 0, "quantity": 0, "VolWeight": "3.96", "ReportName": "STK4", "IsReturnable": 0}',
  },
  {
    id: 3,
    name: 'STD CARBORD STK4 (4KG)',
    data:
      '{"width": "23", "height": "30", "length": "30", "weight": "8", "IsActive": "0", "quantity": 0, "VolWeight": "5.779", "ReportName": "STK4", "IsReturnable": "0"}',
  },
  {
    id: 4,
    name: 'DOCUMENTS',
    data:
      '{"width": "20", "height": "1", "length": "20", "weight": "1", "IsActive": 0, "quantity": 0, "VolWeight": "0.08", "ReportName": "DOCS", "IsReturnable": 0}',
  },
  {
    id: 5,
    name: 'QB55 SHIPPER',
    data:
      '{"width": "45", "height": "48", "length": "47", "weight": "20", "IsActive": "0", "quantity": 0, "VolWeight": "20.304", "ReportName": "QB55", "IsReturnable": "0"}',
  },
  {
    id: 6,
    name: 'QB60 PREMIUM',
    data:
      '{"width": "64.8", "height": "52.3", "length": "74.8", "weight": "50", "IsActive": "0", "quantity": 0, "VolWeight": "50.70", "ReportName": "QB60", "IsReturnable": "0"}',
  },
  {
    id: 7,
    name: 'STD CARBORD BOX STK1 (1KG)',
    data:
      '{"width": "10", "height": "10", "length": "15", "weight": "1", "IsActive": 0, "quantity": 0, "VolWeight": "1.248", "ReportName": "STK1", "IsReturnable": 0}',
  },
  {
    id: 8,
    name: 'STD CARBORD BOX STK2 (2KG)',
    data:
      '{"width": "15", "height": "15", "length": "23", "weight": "3", "IsActive": 0, "quantity": 0, "VolWeight": "1.875", "ReportName": "STK2", "IsReturnable": 0}',
  },
  {
    id: 9,
    name: 'MacCool101C',
    data:
      '{"width": "38", "height": "39", "length": "37", "weight": "8", "IsActive": 0, "quantity": 0, "VolWeight": "10.967", "ReportName": "MacCool101C", "IsReturnable": 1}',
  },
  {
    id: 10,
    name: 'MacCool101A',
    data:
      '{"width": "38", "height": "39", "length": "37", "weight": "8", "IsActive": 0, "quantity": 0, "VolWeight": "10.967", "ReportName": "MacCool101A", "IsReturnable": 1}',
  },
  {
    id: 11,
    name: 'Controlled Pallet',
    data:
      '{"width": "100", "height": "120", "length": "100", "weight": "60", "IsActive": "0", "quantity": 0, "VolWeight": "240", "ReportName": "Controlled Pallet", "IsReturnable": "0"}',
  },
  {
    id: 12,
    name: 'STD CARBORD STK5 (5KG)',
    data:
      '{"width": "30", "height": "30", "length": "45", "weight": "8", "IsActive": "0", "quantity": 0, "VolWeight": "5.779", "ReportName": "STK5", "IsReturnable": "0"}',
  },
  {
    id: 13,
    name: 'Dry Ice Shipper',
    data:
      '{"width": "38", "height": "39", "length": "37", "weight": "8", "IsActive": 0, "quantity": 0, "VolWeight": "10.967", "ReportName": "MacCool101A", "IsReturnable": 1}',
  },
]


export default bt;