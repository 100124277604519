import React, { useState, useEffect } from "react";
import {
  IonMenu,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonButton
} from "@ionic/react";
import InstallButton from "../InstallButton/InstallButton";
import decode from 'jwt-decode';
import "./MobileMenu.css";
import { close } from "ionicons/icons";
import { logout } from "../../utils";
import ResetPassword from '../ResetPassword';
import Modal from '../Modal';

function MobileMenu() {
  // const [operationsExpanded, setOperationsExpanded] = useState(false);
  const [manageExpanded, setManageExpanded] = useState(false);
  const [archivedExpanded, setArchivedExpanded] = useState(false);

  // permissions
  // const [canViewDashboard, setCanViewDashboard] = useState(false);
  // const [canViewInbounds, setCanViewInbounds] = useState(false);
  // const [canViewReturns, setCanViewReturns] = useState(false);
  // const [canViewOrders, setCanViewOrders] = useState(false);
  // const [canViewDestructions, setCanViewDestructions] = useState(false);

  // const [canViewSponsors, setCanViewSponsors] = useState(false);
  // const [canViewSites, setCanViewSites] = useState(false);
  // const [canViewStudies, setCanViewStudies] = useState(false);
  // const [canViewUsers, setCanViewUsers] = useState(false);

  // const [canViewReturnsArchive, setCanViewReturnsArchive] = useState(false);
  // const [canViewInboundArchive, setCanViewInboundArchive] = useState(false);
  // const [canViewOrdersArchive, setCanViewOrdersArchive] = useState(false);

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [isExternal, setIsExternal] = useState(true);

  const user = JSON.parse(sessionStorage.getItem('lt-user'));

  useEffect(() => {
    const accessToken = sessionStorage.getItem('lt-access-token');

    const decoded = accessToken ? decode(accessToken) : false;

    if (decoded && decoded.perms) {
      const permissions = decoded.perms;

      // const formattedPermissions = permissions.map(p => {
      //   const toIndex = p.lastIndexOf('_') + 1;
      //   return p.slice(0, toIndex);
      // });

      if (permissions.includes('dashboard')) {
        // setCanViewDashboard(true);
        setIsExternal(false);
      }
      // if (formattedPermissions.includes('inbound_r_')) setCanViewInbounds(true);
      // if (formattedPermissions.includes('orders_r_')) setCanViewOrders(true);
      // if (formattedPermissions.includes('returns_r_')) setCanViewReturns(true);
      // if (formattedPermissions.includes('destruction_r_')) setCanViewDestructions(true);

      // if (formattedPermissions.includes('sponsor_r_')) setCanViewSponsors(true);
      // if (formattedPermissions.includes('sites_r_')) setCanViewSites(true);
      // if (formattedPermissions.includes('study_r_')) setCanViewStudies(true);
      // if (formattedPermissions.includes('users_r_')) setCanViewUsers(true);

      // if (formattedPermissions.includes('archive-inbound_r_')) setCanViewInboundArchive(true);
      // if (formattedPermissions.includes('archive-orders_r_')) setCanViewOrdersArchive(true);
      // if (formattedPermissions.includes('archive-returns_r_')) setCanViewReturnsArchive(true);
    } else {
      logout();
    }

  }, []);

  function closeMenu() {
    const menu = document.getElementById("mobile-menu");
    //this.menuInnerEl is undefined 
    // if (menu && menu.innerHTML) menu.close();
    if (menu && menu.innerHTML) menu.style.display = 'none';
  }

  return (
    <IonMenu
      side="start"
      type="overlay"
      contentId="main-content"
      id="mobile-menu"
    >
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "20px",
          paddingBottom: "2em",
          paddingTop: "1em",
        }}
      >
        <IonIcon
          onClick={() => closeMenu()}
          size="large"
          style={{ position: "absolute", right: "0.5em", color: 'white' }}
          icon={close}
        ></IonIcon>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          src="/assets/logic_logo.svg"
          alt="logic logo"
          style={{ width: "60%", margin: "2em auto" }}
        />
      </div>
      <IonContent
        style={{
          position: "relative",
          "--background": "var(--ion-primary-color)",
        }}
      >
        <p className="text-white text-center text-gray-300">{sessionStorage.getItem('demo-current-user')}</p>
        <IonButton
          onClick={() => setShowResetPassword(true)}
          fill="clear"
          style={{
            cursor: "pointer",
            marginTop: window.innerWidth > 600 ? "1em" : "initial",
            width: '90%',
            marginLeft: '5%',
            textTransform: 'lowercase',
            fontWeight: 'bold'
          }}
        >
          reset password
        </IonButton>
        <IonList style={{ width: "100%" }}>
          {!isExternal && <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            routerLink="/dashboard"
            onClick={() => closeMenu()}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-tachometer-alt"></i>
            </div>
            Dashboard
          </IonItem>}
          {/* {!isExternal && <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            routerLink="/search-sites-studies"
            onClick={() => closeMenu()}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-search"></i>
            </div>
                Search Sites & Studies
              </IonItem>} */}
          {!isExternal && <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            routerLink="/inbound-shipments"
            onClick={() => closeMenu()}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-ship"></i>
            </div>
            Inbound Shipments
          </IonItem>}
          {!isExternal && <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            routerLink="/orders-and-dispatch"
            onClick={() => closeMenu()}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-truck"></i>
            </div>
            Orders & Dispatch
          </IonItem>}
          <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            routerLink="/collections-and-returns"
            onClick={() => closeMenu()}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-clock"></i>
            </div>
            Collections & Returns
          </IonItem>
          {!isExternal && <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            routerLink="/destruction-list"
            onClick={() => closeMenu()}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-trash"></i>
            </div>
            Destruction Lists
          </IonItem>}
          {!isExternal && <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            onClick={() => setManageExpanded(!manageExpanded)}
            style={{ cursor: 'pointer' }}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-tasks"></i>
            </div>
            Manage
            <div slot="end" className="icon-container">
              <i class={`fas fa-chevron-left expand-operations ${manageExpanded && 'rotated'}`}></i>
            </div>
          </IonItem>}
          {manageExpanded && <div style={{ marginLeft: '1em' }}>
            {!isExternal && <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/manage/studies"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-vial"></i>
              </div>
              Studies
            </IonItem>}
            {!isExternal && <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/manage/sponsors"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-certificate"></i>
              </div>
              Sponsors
            </IonItem>}
            {!isExternal && <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/manage/sites"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-clinic-medical"></i>
              </div>
              Sites
            </IonItem>}
            {!isExternal && <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/manage/users"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-user-friends"></i>
              </div>
              Users
            </IonItem>}
          </div>}
          <IonItem
            className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
            onClick={() => setArchivedExpanded(!archivedExpanded)}
            style={{ cursor: 'pointer' }}
          >
            <div slot="start" className="icon-container">
              <i class="fas fa-archive"></i>
            </div>
            Archives
            <div slot="end" className="icon-container">
              <i class={`fas fa-chevron-left expand-operations ${archivedExpanded && 'rotated'}`}></i>
            </div>
          </IonItem>
          {archivedExpanded && <div style={{ marginLeft: '1em' }}>
            {!isExternal && <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/archives/orders"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-truck"></i>
              </div>
              Orders
            </IonItem>}
            <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/archives/returns"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-clock"></i>
              </div>
              Returns
            </IonItem>
            {!isExternal && <IonItem
              className="bg-gray-100 text-white align-middle text-xs font-semibold text-left"
              routerLink="/archives/inbounds"
              onClick={() => closeMenu()}
            >
              <div slot="start" className="icon-container">
                <i class="fas fa-ship"></i>
              </div>
              Inbounds
            </IonItem>}
          </div>}
        </IonList>
        <InstallButton />
        <Modal
          showModal={showResetPassword}
          setShowModal={setShowResetPassword}
        >
          <ResetPassword editUser={user} setShowModal={setShowResetPassword} />
        </Modal>
      </IonContent>
    </IonMenu>
  );
}

export default MobileMenu;
