import React, { useEffect, useState } from 'react';
import QRgenerator from '../components/qr/QRgenerator';
import { parseQueryString } from '../utils';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';

import './BoxLabel.css';

export default function BoxLabel({ sops, link }) {
    const [data, setData] = useState();
    const [index, setIndex] = useState(sops && sops['boxIndex'] ? sops['boxIndex'] : 1);

    useEffect(() => {
        if (sops) {
            setData(sops);
        } else {
            const urlParams = parseQueryString();
            const { cspl, order, site, study } = urlParams;
            setData({ cspl, order, site, study });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderField(key) {
        if (key && data[key] && typeof data[key] === 'string') {
            return data[key];
        } else if (key && data[key] && typeof data[key] === 'string' && data[key].label) {
            return data[key].label;
        } else if (key && data[key] && typeof data[key] === 'number') {
            return data[key].toString();
        } else {
            return '';
        }
    }

    return (
        <div className="box-label">
            <IonGrid>
                <IonRow>
                    <IonCol offset="1" size="11" style={{ textAlign: 'left' }}>
                        <h1 style={{ textAlign: "left", fontWeight: 'bold' }}>{'Logic Trials (PTY) Ltd'}</h1>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol offset="1" size="5">
                        <QRgenerator value="test" link={link} />
                    </IonCol>
                    <IonCol size="4">
                        {data && <>
                            <p>Order: {renderField('order')}</p>
                            <p>Study: {renderField('study')}</p>
                            <p>Site: {renderField('site')}</p>
                            <p>Box: {index} of {renderField('boxes')}</p>
                            <p>CSPL: {renderField('cspl')}</p>
                        </>
                        }
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol offset="1">
                        {data && <p>{renderField('study')}-{renderField('order')}-{renderField('cspl')}-{renderField('site')}</p>}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton expand="full" onClick={() => window.print()} id="print-button">
                            Print
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {[...Array(Number(sops['boxes']))].map((_, i) => (
                            <IonButton fill={((i + 1) === index) ? "solid" : "outline"} onClick={() => setIndex(i + 1)}>{i + 1}</IonButton>
                        ))}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
