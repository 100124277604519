// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./background-cube.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ion-header {\r\n    border-radius: 50% !important;\r\n}\r\n\r\n.header-md::after {\r\n    display: none;\r\n}\r\n\r\n.page-content {\r\n    /* border-radius: 40px;  */\r\n    z-index: 9999;\r\n    background: var(--ion-color-primary-contrast);\r\n    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */\r\n    margin: auto;\r\n    text-align: left;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-repeat: no-repeat;\r\n    background-position: right bottom;\r\n    min-height: 100vh;\r\n    background-color: #e9e9e9;\r\n    margin-top: -33px;\r\n    padding-top: 33px;\r\n    padding-bottom: 3em;\r\n\r\n}", "",{"version":3,"sources":["webpack://src/components/base/BaseTemplate.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,aAAa;IACb,6CAA6C;IAC7C,kDAAkD;IAClD,YAAY;IACZ,gBAAgB;IAChB,yDAA4C;IAC5C,4BAA4B;IAC5B,iCAAiC;IACjC,iBAAiB;IACjB,yBAAyB;IACzB,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;;AAEvB","sourcesContent":["ion-header {\r\n    border-radius: 50% !important;\r\n}\r\n\r\n.header-md::after {\r\n    display: none;\r\n}\r\n\r\n.page-content {\r\n    /* border-radius: 40px;  */\r\n    z-index: 9999;\r\n    background: var(--ion-color-primary-contrast);\r\n    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */\r\n    margin: auto;\r\n    text-align: left;\r\n    background-image: url(./background-cube.png);\r\n    background-repeat: no-repeat;\r\n    background-position: right bottom;\r\n    min-height: 100vh;\r\n    background-color: #e9e9e9;\r\n    margin-top: -33px;\r\n    padding-top: 33px;\r\n    padding-bottom: 3em;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
