const c = [
    
    { name: "Australia Dollar", abbrev: "AUD" },
    
    { name: "Great Britain Pound", abbrev: "GBP" },
    
    { name: "Euro", abbrev: "EUR" },
    
    { name: "Japan Yen", abbrev: "JPY" },
    
    { name: "Switzerland Franc", abbrev: "CHF" },
    
    { name: "USA Dollar", abbrev: "USD" },
    
    { name: "Afghanistan Afghani", abbrev: "AFN" },
    
    { name: "Albania Lek", abbrev: "ALL" },
    
    { name: "Algeria Dinar", abbrev: "DZD" },
    
    { name: "Angola Kwanza", abbrev: "AOA" },
    
    { name: "Argentina Peso", abbrev: "ARS" },
    
    { name: "Armenia Dram", abbrev: "AMD" },
    
    { name: "Aruba Florin", abbrev: "AWG" },
    
    { name: "Australia Dollar", abbrev: "AUD" },
    
    { name: "Austria Schilling", abbrev: "ATS" },
    
    { name: "Belgium Franc", abbrev: "BEF" },
    
    { name: "Azerbaijan New Manat", abbrev: "AZN" },
    
    { name: "Bahamas Dollar", abbrev: "BSD" },
    
    { name: "Bahrain Dinar", abbrev: "BHD" },
    
    { name: "Bangladesh Taka", abbrev: "BDT" },
    
    { name: "Barbados Dollar", abbrev: "BBD" },
    
    { name: "Belarus Ruble", abbrev: "BYR" },
    
    { name: "Belize Dollar", abbrev: "BZD" },
    
    { name: "Bermuda Dollar", abbrev: "BMD" },
    
    { name: "Bhutan Ngultrum", abbrev: "BTN" },
    
    { name: "Bolivia Boliviano", abbrev: "BOB" },
    
    { name: "Bosnia Mark", abbrev: "BAM" },
    
    { name: "Botswana Pula", abbrev: "BWP" },
    
    { name: "Brazil Real", abbrev: "BRL" },
    
    { name: "Great Britain Pound", abbrev: "GBP" },
    
    { name: "Brunei Dollar", abbrev: "BND" },
    
    { name: "Bulgaria Lev", abbrev: "BGN" },
    
    { name: "Burundi Franc", abbrev: "BIF" },
    
    { name: "CFA Franc BCEAO", abbrev: "XOF" },
    
    { name: "CFA Franc BEAC", abbrev: "XAF" },
    
    { name: "CFP Franc", abbrev: "XPF" },
    
    { name: "Cambodia Riel", abbrev: "KHR" },
    
    { name: "Canada Dollar", abbrev: "CAD" },
    
    { name: "Cape Verde Escudo", abbrev: "CVE" },
    
    { name: "Cayman Islands Dollar", abbrev: "KYD" },
    
    { name: "Chili Peso", abbrev: "CLP" },
    
    { name: "China Yuan/Renminbi", abbrev: "CNY" },
    
    { name: "Colombia Peso", abbrev: "COP" },
    
    { name: "Comoros Franc", abbrev: "KMF" },
    
    { name: "Congo Franc", abbrev: "CDF" },
    
    { name: "Costa Rica Colon", abbrev: "CRC" },
    
    { name: "Croatia Kuna", abbrev: "HRK" },
    
    { name: "Cuba Convertible Peso", abbrev: "CUC" },
    
    { name: "Cuba Peso", abbrev: "CUP" },
    
    { name: "Cyprus Pound", abbrev: "CYP" },
    
    { name: "Czech Koruna", abbrev: "CZK" },
    
    { name: "Denmark Krone", abbrev: "DKK" },
    
    { name: "Djibouti Franc", abbrev: "DJF" },
    
    { name: "Dominican Republich Peso", abbrev: "DOP" },
    
    { name: "East Caribbean Dollar", abbrev: "XCD" },
    
    { name: "Egypt Pound", abbrev: "EGP" },
    
    { name: "El Salvador Colon", abbrev: "SVC" },
    
    { name: "Estonia Kroon", abbrev: "EEK" },
    
    { name: "Ethiopia Birr", abbrev: "ETB" },
    
    { name: "Euro", abbrev: "EUR" },
    
    { name: "Falkland Islands Pound", abbrev: "FKP" },
    
    { name: "Finland Markka", abbrev: "FIM" },
    
    { name: "Fiji Dollar", abbrev: "FJD" },
    
    { name: "Gambia Dalasi", abbrev: "GMD" },
    
    { name: "Georgia Lari", abbrev: "GEL" },
    
    { name: "Germany Mark", abbrev: "DMK" },
    
    { name: "Ghana New Cedi", abbrev: "GHS" },
    
    { name: "Gibraltar Pound", abbrev: "GIP" },
    
    { name: "Greece Drachma", abbrev: "GRD" },
    
    { name: "Guatemala Quetzal", abbrev: "GTQ" },
    
    { name: "Guinea Franc", abbrev: "GNF" },
    
    { name: "Guyana Dollar", abbrev: "GYD" },
    
    { name: "Haiti Gourde", abbrev: "HTG" },
    
    { name: "Honduras Lempira", abbrev: "HNL" },
    
    { name: "Hong Kong Dollar", abbrev: "HKD" },
    
    { name: "Hungary Forint", abbrev: "HUF" },
    
    { name: "Iceland Krona", abbrev: "ISK" },
    
    { name: "India Rupee", abbrev: "INR" },
    
    { name: "Indonesia Rupiah", abbrev: "IDR" },
    
    { name: "Iran Rial", abbrev: "IRR" },
    
    { name: "Iraq Dinar", abbrev: "IQD" },
    
    { name: "Ireland Pound", abbrev: "IED" },
    
    { name: "Israel New Shekel", abbrev: "ILS" },
    
    { name: "Italy L", abbrev: "ira" },
    
    { name: "Jamaica Dollar", abbrev: "JMD" },
    
    { name: "Japan Yen", abbrev: "JPY" },
    
    { name: "Jordan Dinar", abbrev: "JOD" },
    
    { name: "Kazakhstan Tenge", abbrev: "KZT" },
    
    { name: "Kenya Shilling", abbrev: "KES" },
    
    { name: "Kuwait Dinar", abbrev: "KWD" },
    
    { name: "Kyrgyzstan Som", abbrev: "KGS" },
    
    { name: "Laos Kip", abbrev: "LAK" },
    
    { name: "Latvia Lats", abbrev: "LVL" },
    
    { name: "Lebanon Pound", abbrev: "LBP" },
    
    { name: "Lesotho Loti", abbrev: "LSL" },
    
    { name: "Liberia Dollar", abbrev: "LRD" },
    
    { name: "Libya Dinar", abbrev: "LYD" },
    
    { name: "Lithuania Litas", abbrev: "LTL" },
    
    { name: "Luxembourg Franc", abbrev: "LUF" },
    
    { name: "Macau Pataca", abbrev: "MOP" },
    
    { name: "Macedonia Denar", abbrev: "MKD" },
    
    { name: "Malagasy Ariary", abbrev: "MGA" },
    
    { name: "Malawi Kwacha", abbrev: "MWK" },
    
    { name: "Malaysia Ringgit", abbrev: "MYR" },
    
    { name: "Maldives Rufiyaa", abbrev: "MVR" },
    
    { name: "Malta Lira", abbrev: "MTL" },
    
    { name: "Mauritania Ouguiya", abbrev: "MRO" },
    
    { name: "Mauritius Rupee", abbrev: "MUR" },
    
    { name: "Mexico Peso", abbrev: "MXN" },
    
    { name: "Moldova Leu", abbrev: "MDL" },
    
    { name: "Mongolia Tugrik", abbrev: "MNT" },
    
    { name: "Morocco Dirham", abbrev: "MAD" },
    
    { name: "Mozambique New Metical", abbrev: "MZN" },
    
    { name: "Myanmar Kyat", abbrev: "MMK" },
    
    { name: "NL Antilles Guilder", abbrev: "ANG" },
    
    { name: "Namibia Dollar", abbrev: "NAD" },
    
    { name: "Nepal Rupee", abbrev: "NPR" },
    
    { name: "Netherlands Guilder", abbrev: "NLG" },
    
    { name: "New Zealand Dollar", abbrev: "NZD" },
    
    { name: "Nicaragua Cordoba Oro", abbrev: "NIO" },
    
    { name: "Nigeria Naira", abbrev: "NGN" },
    
    { name: "North Korea Won", abbrev: "KPW" },
    
    { name: "Norway Kroner", abbrev: "NOK" },
    
    { name: "Oman Rial", abbrev: "OMR" },
    
    { name: "Pakistan Rupee", abbrev: "PKR" },
    
    { name: "Panama Balboa", abbrev: "PAB" },
    
    { name: "Papua New Guinea Kina", abbrev: "PGK" },
    
    { name: "Paraguay Guarani", abbrev: "PYG" },
    
    { name: "Peru Nuevo Sol", abbrev: "PEN" },
    
    { name: "Philippines Peso", abbrev: "PHP" },
    
    { name: "Poland Zloty", abbrev: "PLN" },
    
    { name: "Portugal Escudo", abbrev: "PTE" },
    
    { name: "Qatar Rial", abbrev: "QAR" },
    
    { name: "Romania New Lei", abbrev: "RON" },
    
    { name: "Russia Rouble", abbrev: "RUB" },
    
    { name: "Rwanda Franc", abbrev: "RWF" },
    
    { name: "Samoa Tala", abbrev: "WST" },
    
    { name: "Sao Tome/Principe Dobra", abbrev: "STD" },
    
    { name: "Saudi Arabia Riyal", abbrev: "SAR" },
    
    { name: "Serbia Dinar", abbrev: "RSD" },
    
    { name: "Seychelles Rupee", abbrev: "SCR" },
    
    { name: "Sierra Leone Leone", abbrev: "SLL" },
    
    { name: "Singapore Dollar", abbrev: "SGD" },
    
    { name: "Slovakia Koruna", abbrev: "SKK" },
    
    { name: "Slovenia Tolar", abbrev: "SIT" },
    
    { name: "Solomon Islands Dollar", abbrev: "SBD" },
    
    { name: "Somali Shilling", abbrev: "SOS" },
    
    { name: "South Africa Rand", abbrev: "ZAR" },
    
    { name: "South Korea Won", abbrev: "KRW" },
    
    { name: "Spain Peseta", abbrev: "ESP" },
    
    { name: "Sri Lanka Rupee", abbrev: "LKR" },
    
    { name: "St Helena Pound", abbrev: "SHP" },
    
    { name: "Sudan Pound", abbrev: "SDG" },
    
    { name: "Suriname Dollar", abbrev: "SRD" },
    
    { name: "Swaziland Lilangeni", abbrev: "SZL" },
    
    { name: "Sweden Krona", abbrev: "SEK" },
    
    { name: "Switzerland Franc", abbrev: "CHF" },
    
    { name: "Syria Pound", abbrev: "SYP" },
    
    { name: "Taiwan Dollar", abbrev: "TWD" },
    
    { name: "Tanzania Shilling", abbrev: "TZS" },
    
    { name: "Thailand Baht", abbrev: "THB" },
    
    { name: "Tonga Pa'anga", abbrev: "TOP" },
    
    { name: "Trinidad/Tobago Dollar", abbrev: "TTD" },
    
    { name: "Tunisia Dinar", abbrev: "TND" },
    
    { name: "Turkish New Lira", abbrev: "TRY" },
    
    { name: "Turkmenistan Manat", abbrev: "TMM" },
    
    { name: "USA Dollar", abbrev: "USD" },
    
    { name: "Uganda Shilling", abbrev: "UGX" },
    
    { name: "Ukraine Hryvnia", abbrev: "UAH" },
    
    { name: "Uruguay Peso", abbrev: "UYU" },
    
    { name: "United Arab Emirates Dirham", abbrev: "AED" },
    
    { name: "Vanuatu Vatu", abbrev: "VUV" },
    
    { name: "Venezuela Bolivar", abbrev: "VEB" },
    
    { name: "Vietnam Dong", abbrev: "VND" },
    
    { name: "Yemen Rial", abbrev: "YER" },
    
    { name: "Zambia Kwacha", abbrev: "ZMK" },
    
    { name: "Zimbabwe Dollar", abbrev: "ZWD" },
];

export default c;