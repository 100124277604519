import React, { useState, useEffect } from 'react'
import { IonAlert, IonGrid, IonCol, IonRow, IonToast } from '@ionic/react'
import Fuse from 'fuse.js'
import ReactPaginate from 'react-paginate'
import BaseTemplate from '../../components/base/BaseTemplate'
import Search from '../../components/Search/Search'
import Loading from '../../components/Loading'
import TableList from '../../components/TableList/TableList'
import Modal from '../../components/Modal'
import AddDestructionCertificateNumber from '../../components/actionButtonForms/AddDestructionCertificateNumber'
import ConfirmDestructionDetails from '../../components/actionButtonForms/ConfirmDestructionDetails'
import ViewCertificates from '../../components/actionButtonForms/ViewCertificates'
import decode from 'jwt-decode'

import {updateDestruction} from '../../apis/logictrials';

import {
    generatePdf,
    getDataPerms,
} from '../../apis/logictrials'

const PER_PAGE = 15

function DestructionList() {
    const [error, setError] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [destructionList, setDestructionList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [showToast, setShowToast] = useState(false)
    const [showAddCertificateNumber, setShowAddCertificateNumber] = useState(
        false,
    )
    const [certificateNumber, setCertificateNumber] = useState('')
    const [updateObject, setUpdateObject] = useState(null)
    const [showConfirmDestruction, setShowConfirmDestruction] = useState(false)
    const [showViewCertificatesModal, setShowViewCertificatesModal] = useState(
        false,
    )

    
let headings = [
    'orderNumber',
    'orders',
    'boxes',
    'totalWeight',
    'created',
    'sent',
    'sss',
    'confirmDestruction',
]

let labels = [
    'List Number',
    'Order (s)',
    'Boxes',
    'Total Weight',
    'Created',
    'Sent',
    'SSS',
    '',
]

const accessToken = sessionStorage.getItem('lt-access-token');

const decoded = accessToken ? decode(accessToken) : false;

let formattedPermissions = [];

if (decoded && decoded.perms) {
    const permissions = decoded.perms;

    formattedPermissions = permissions.map(p => {
        const toIndex = p.lastIndexOf('_') + 1;
        return p.slice(0, toIndex);
    });
}

if(!formattedPermissions.includes('destruction_u_')) headings = headings.filter(h => h !== "confirmDestruction");

if(!formattedPermissions.includes('destruction_u_')) labels.pop();

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () =>
        getDataPerms('destruction', 0, 9999).then((destructions) => {
            const parsedDestructions = destructions.map((d) => {
                const parsed = JSON.parse(d.data)

                parsed.orderNumber = d.order_number
                parsed.id = d.id

                return parsed
            })

            setDestructionList([...parsedDestructions])
            setLoading(false)
        })

    useEffect(() => {
        const destructionListFuse = new Fuse(destructionList, {
            keys: ['listNumber'],
        })

        setSearchResults(destructionListFuse.search(searchQuery))
    }, [searchQuery, destructionList])

    const revisedDestructionList =
        searchResults.length > 0
            ? searchResults.map((item) => item.item)
            : destructionList

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    const offset = currentPage * PER_PAGE

    const currentPageData = revisedDestructionList
        ? revisedDestructionList.slice(offset, offset + PER_PAGE)
        : []

    const pageCount = revisedDestructionList
        ? Math.ceil(revisedDestructionList.length / PER_PAGE)
        : []

    return (
        <BaseTemplate title="Destruction List">
            <div>
                <Search setSearchQuery={setSearchQuery} placeholder="Search" />
            </div>
            <div style={{ paddingBottom: '5em' }}>
                <IonGrid>
                    <IonRow style={{ boxShadow: 'none' }}>
                        <IonCol offset-xl="1" size-xl="10">
                            {loading && <Loading loading={loading} />}
                            {!loading && (
                                <TableList
                                    headings={headings}
                                    items={currentPageData}
                                    labels={labels}
                                    className="destruction-list"
                                    functions={{
                                        orderNumber: () => { },
                                        sent: async (_, index, obj) => {
                                            if (obj.sent.btnColor === 'warning') {
                                                setUpdateObject(obj)
                                                setShowAddCertificateNumber(true)
                                            } else {
                                                const showLoading = new Event('display-loading')
                                                window.dispatchEvent(showLoading)
                                                const dismissLoading = new Event('dismiss-loading')
                                                generatePdf(
                                                    'destructionwaybillpdf',
                                                    obj.orderNumber,
                                                ).then((res) => {
                                                    setTimeout(() => {
                                                        window.dispatchEvent(dismissLoading)
                                                        window.open(res)
                                                    }, 2000)
                                                })
                                            }
                                        },
                                        confirmDestruction: (_, i, obj) => {
                                            obj.confirmDestruction = 'confirmed';

                                            updateDestruction(obj, {
                                                id: obj.id
                                            })
                                            .then(() => window.location.reload());
                                            // setUpdateObject(obj)
                                            // setShowConfirmDestruction(true)
                                        },
                                        retryConfirmation: (_, __, obj) => {
                                            // obj.confirmDestruction = 'confirmed';

                                            // updateDestruction(obj, {
                                            //     id: obj.id
                                            // })
                                            // .then(() => window.location.reload());

                                            setUpdateObject(obj)
                                            setShowConfirmDestruction(true)
                                        },
                                        viewDocuments: (_, __, obj) => {
                                            setUpdateObject(obj)
                                            setShowViewCertificatesModal(true)
                                        },
                                        sss: (_, obj, __) => {
                                            console.log('1', obj, '2', _, '3', __);
                                            setUpdateObject(obj)
                                            setShowConfirmDestruction(true)
                                        }
                                    }}
                                />
                            )}
                            <div style={{ margin: '1em 1em' }}>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    previousLinkClassName={'pagination__link'}
                                    nextLinkClassName={'pagination__link'}
                                    disabledClassName={'pagination__link--disabled'}
                                    activeClassName={'pagination__link--active'}
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonAlert
                    isOpen={error !== ''}
                    onDidDismiss={() => setError('')}
                    header={'Error'}
                    message={error}
                    buttons={['OK']}
                />
            </div>
            <IonToast
                message="Destruction confirmed, all related parties have been notified"
                color="success"
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                buttons={[
                    {
                        handler: () => setShowToast(false),
                        text: 'dismiss',
                    },
                ]}
            />
            <Modal
                showModal={showAddCertificateNumber}
                setShowModal={setShowAddCertificateNumber}
            >
                <AddDestructionCertificateNumber
                    fetchData={fetchData}
                    obj={updateObject}
                    certificateNumber={certificateNumber}
                    setCertificateNumber={setCertificateNumber}
                    setShowModal={setShowAddCertificateNumber}
                />
            </Modal>
            <Modal
                showModal={showConfirmDestruction}
                setShowModal={setShowConfirmDestruction}
            >
                {showConfirmDestruction && (
                    <ConfirmDestructionDetails
                        obj={updateObject}
                        setShowModal={setShowConfirmDestruction}
                        fetchData={fetchData}
                    />
                )}
            </Modal>
            <Modal
                showModal={showViewCertificatesModal}
                setShowModal={setShowViewCertificatesModal}
            >
                {showViewCertificatesModal && (
                    <ViewCertificates
                        obj={updateObject}
                        setShowModal={setShowViewCertificatesModal}
                        fetchData={fetchData}
                    />
                )}
            </Modal>
        </BaseTemplate>
    )
}

export default DestructionList
