import React, { useState, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon, IonCheckbox } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';
import { getData, updateItem, insertSite, getStudyByStudyNumber } from '../apis/logictrials';
import Select from "react-dropdown-select";
import './NewSite.css';
const provinces = [{ name: "Western Cape" }, { name: "Eastern Cape"}, { name: "Northern Cape"}, { name: "North West"}, { name: "Free State"}, { name: "Kwazulu Natal"}, { name: "Gauteng"}, { name: "Limpopo"}, { name: "Mpumalanga"}];

export default function NewSite({editSite, setShowModal, fetchData, setEditSite}) {
    const [groupEmails, setGroupEmails] = useState('');
    const [validEmails, setValidEmails] = useState([]);
    const [province, setProvince] = useState('');
    const [study, setStudy] = useState();
    const [studies, setStudies] = useState([]);
    const [specialDeliveryNeeded, setSpecialDeliveryNeeded] = useState(false);
    const [siteNumber, setSiteNumber] = useState();
    const [siteContact, setSiteContact] = useState();
    const [siteEmail, setSiteEmail] = useState();
    const [siteTel, setSiteTel] = useState();
    const [siteTel2, setSiteTel2] = useState();
    const [mobile, setMobile] = useState();
    const [investigator, setInvestigator] = useState();
    const [buildingBlockName, setBuildingBlockName] = useState();
    const [streetName, setStreetName] = useState();
    const [suburb, setSuburb] = useState();
    const [city, setCity] = useState();
    const [code, setCode] = useState();
    const selectStudy  = useRef();
    const selectProvince = useRef();

    function addToValidEmails(e) {
        if(e.key === "Enter") {
            setValidEmails([...validEmails, groupEmails]);
            setGroupEmails('');
        }
    }

    useEffect(() => {
        if(editSite) {
            getStudyByStudyNumber(editSite.study).then(res => {
                if(res && Array.isArray(res) && res[0]) {
                    if(res[0].data && typeof res[0].data === 'string') {
                        const parsed = JSON.parse(res[0].data);
                        parsed.name = parsed.studyNumber;
                        setStudy(parsed);
                    };
                };
            });
            setSiteNumber(editSite.number);
            setSiteContact(editSite.contact);
            setSiteEmail(editSite.email);
            setSiteTel(editSite.tel);
            setSiteTel2(editSite.tel2 ? editSite.tel2 : '');
            setMobile(editSite.mobile);
            setInvestigator(editSite.investigator);
            setBuildingBlockName(editSite.buildingBlockName);
            setStreetName(editSite.streetName);
            setSuburb(editSite.suburb);
            setCity(editSite.city);
            setCode(editSite.code);
            setSpecialDeliveryNeeded(editSite.specialDelivery);
            setStreetName(editSite.streetName);

            if(editSite && editSite.province) setProvince(editSite.province);
        }
        getData('study', 0, 99999).then(res => {
            setStudies(res);
            if (selectStudy.current && !editSite) selectStudy.current.clearAll();

            if (selectProvince.current && !editSite) selectProvince.current.clearAll();
        });

        return function cleanup() {
            setEditSite();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSite]);

    useEffect(() => {
        
        document.removeEventListener('keydown', addToValidEmails);
        document.addEventListener('keydown', addToValidEmails);
        if(groupEmails.includes(',')) {
            setValidEmails([...validEmails, groupEmails.split(',')[0]]);
            setGroupEmails('');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupEmails]);

    async function submit() {

        const studySubmit = study && study.data ? JSON.parse(study.data) : study;

        const data = {
            name: `${studySubmit.studyNumber}-${siteNumber}`,
            sponsor: study.data ? studySubmit.sponsor : editSite.sponsor,
            study: studySubmit.studyNumber,
            number: siteNumber,
            contact: siteContact,
            email: siteEmail,
            tel: siteTel,
            tel2: siteTel2 ? siteTel2 : '',
            mobile,
            investigator,
            buildingBlockName,
            streetName,
            suburb,
            city,
            code,
            province,
            specialDelivery: specialDeliveryNeeded
        };

        if(!editSite) {
            const res = await insertSite(data);
            console.log(res);

            setShowModal(false);
            fetchData();
            return;
        } 
        updateItem('sites', ['data'], [data], { id: editSite.id }).then(res => { 
            fetchData();
            setShowModal(false);
        });
    }

    return (
        <div>
            <IonGrid>
            <IonRow>
                    <IonCol>
                        <div style={{width: '100%', paddingLeft: 16, paddingRight: 16}}>
                            <Select
                                placeholder="Select Study"
                                labelField="name"
                                searchBy="name"
                                valueField="name"
                                addPlaceholder=""
                                searchable="true"
                                options={studies}
                                values={study ? [study] : []}
                                ref={selectStudy}
                                onChange={(values) => {
                                    if(values.length > 0) {
                                        setStudy(values[0]);
                                    }
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div style={{width: '100%', paddingLeft: 16, paddingRight: 16}}>
                            <Select
                                placeholder="Select Province"
                                labelField="name"
                                searchBy="name"
                                valueField="name"
                                addPlaceholder=""
                                searchable="true"
                                options={provinces}
                                ref={selectProvince}
                                values={province ? [{ name: province }] : []}
                                onChange={(values) => {
                                    if(values.length > 0) {
                                        setProvince(values[0].name);
                                    }
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Site Number" value={siteNumber} onIonChange={e => setSiteNumber(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Contact" value={siteContact} onIonChange={e => setSiteContact(e.detail.value)}/>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Site Tel" value={siteTel} onIonChange={e => setSiteTel(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Site Tel 2" value={siteTel2} onIonChange={e => setSiteTel2(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput placeholder="Mobile" value={mobile} onIonChange={e => setMobile(e.detail.value)} />
                    </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Site Email" value={siteEmail} onIonChange={e => setSiteEmail(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Investigator" value={investigator} onIonChange={e => setInvestigator(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Building/Block Name" value={buildingBlockName} onIonChange={e => setBuildingBlockName(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Street Name" value={streetName} onIonChange={e => setStreetName(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="City" value={city} onIonChange={e => setCity(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Postal Code" value={code} onIonChange={e => setCode(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput placeholder="Suburb" value={suburb} onIonChange={e => setSuburb(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            Special Delivery Needed:
                            <IonCheckbox isChecked={specialDeliveryNeeded} onIonChange={e => setSpecialDeliveryNeeded(e.detail.checked ? "yes" : "")} slot="end"/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                    <IonCol>
                        <IonButton color="success" expand="full" onClick={submit} style={{marginTop: '50px'}}>
                            <IonIcon icon={checkmarkOutline} slot="start" />
                            {editSite ? 'Edit' : 'Create'}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
