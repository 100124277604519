import React, { useState, useEffect, useRef } from "react";
import {
    IonAlert,
    IonFab,
    IonFabButton,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonDatetime,
} from "@ionic/react";
import Fuse from "fuse.js";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import Search from "../../components/Search/Search";
import { add } from "ionicons/icons";
import Loading from "../../components/Loading";
import TableList from "../../components/TableList/TableList";
import Modal from '../../components/Modal';
import NewUser from '../../components/NewUser';
import { 
    getData, 
    deleteItem
} from '../../apis/logictrials';

import decode from 'jwt-decode';

const PER_PAGE = 15;

function ManageUsers() {
const [error, setError] = useState("");
const [searchQuery, setSearchQuery] = useState("");
const [searchResults, setSearchResults] = useState([]);
const [users, setUsers] = useState([]);
const [currentPage, setCurrentPage] = useState(0);
const [loading, setLoading] = useState(true);
const [showModal, setShowModal] = useState(false);
const [editUser, setEditUser] = useState();
const dateTimeRef = useRef();

// delete
const [showConfirmDelete, setShowConfirmDelete] = useState(false);
const [deleteId, setDeleteId] = useState(null);
const [confirmDeleteName, setConfirmDeleteName] = useState("");


const accessToken = sessionStorage.getItem('lt-access-token');

const decoded = accessToken ? decode(accessToken) : false;

let formattedPermissions = [];

let headings = [
    "email",
    "firstname",
    "lastname",
    "phone",
    "edit",
    "delete"
];

let labels = [
    "email",
    "first name",
    "last name",
    "phone",
    "",
    ""
];

if (decoded && decoded.perms) {
    const permissions = decoded.perms;

    formattedPermissions = permissions.map(p => {
        const toIndex = p.lastIndexOf('_') + 1;
        return p.slice(0, toIndex);
    });
}

if(!formattedPermissions.includes('users_u_')) headings = headings.filter(h => h !== "edit");

if(!formattedPermissions.includes('users_u_')) labels.pop();

if(!formattedPermissions.includes('users_d_')) headings = headings.filter(h => h !== "delete");

if(!formattedPermissions.includes('users_d_')) labels.pop();

useEffect(() => {
    if (users.length === 0) {
        fetchData();
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

function fetchData() {
    getData('users', 0, 9999).then(res => {
        const data = res.map((user) => {
            let td = {...JSON.parse(user.data), ...user};

            td.id = user.id;

            return td;
        });
        setUsers(data);
        setLoading(false);
    });
}

useEffect(() => {
    const usersFuse = new Fuse(users, {
        keys: [
            "email",
            "firstname",
            "lastname",
            "phone"
        ],
    });
    setSearchResults(usersFuse.search(searchQuery));

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchQuery, users]);

const usersFuse =
    searchResults.length > 0 ? searchResults.map((item) => item.item) : users;

function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
}

const offset = currentPage * PER_PAGE;

const currentPageData = usersFuse.slice(offset, offset + PER_PAGE);

const pageCount = Math.ceil(usersFuse.length / PER_PAGE);

return (
    <BaseTemplate title="Manage Users">
        <div>
            <Search
                setSearchQuery={setSearchQuery}
                placeholder="Search"
            />
        </div>
        <div className="users" style={{ paddingBottom: "5em" }}>
            <IonGrid>
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol offset="1" size="10">
                {loading && <Loading loading={loading} />}
                {!loading && (
                    <TableList
                        headings={headings}
                        items={currentPageData}
                        labels={labels}
                        functions={{
                            email: () => {},
                            edit: (_, __, obj) => {
                                setEditUser(obj);
                                setShowModal(true);
                            },
                            delete: (_, __, obj) => {
                                setShowConfirmDelete(true);
                                const { id, firstname, lastname } = obj;
                                
                                setConfirmDeleteName(`${firstname} ${lastname}`);
                                setDeleteId(id);
                            }
                        }}
                    />
                )}
                <div style={{ margin: "1em 1em" }}>
                    <ReactPaginate
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
                </IonCol>
            </IonRow>
            </IonGrid>
            <IonAlert
            isOpen={error !== ""}
            onDidDismiss={() => setError("")}
            header={"Error"}
            message={error}
            buttons={["OK"]}
            />
        </div>
        {formattedPermissions && formattedPermissions.includes('users_c_') && <IonFab
            vertical="bottom"
            horizontal="start"
            slot="fixed"
            style={{
                marginBottom: window.innerWidth < 900 ? "3em" : "5em",
                position: "fixed",
                marginRight: "2em",
            }}
        >
            <IonFabButton onClick={() => setShowModal(true)}>
                <IonIcon icon={add}></IonIcon>
            </IonFabButton>
        </IonFab>}
    {showModal && (
        <Modal showModal={showModal} setShowModal={setShowModal}>
            <NewUser editUser={editUser} fetchData={fetchData} closeModal={() => setShowModal(false)}/>
        </Modal>
    )}
    <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{visibility: 'hidden'}}></IonDatetime>
    <IonAlert
        isOpen={showConfirmDelete}
        onDidDismiss={() => setShowConfirmDelete(false)}
        message={`Are you sure you want to delete <br /> ${confirmDeleteName}?`}
        buttons={[
            {
                text: 'Cancel',
                role: 'cancel',
                handler: () => setShowConfirmDelete(false)
            },
            {
                text: 'Delete',
                cssClass: 'danger',
                handler: () => {
                    if(deleteId) deleteItem('users', { id: deleteId }).then(res => fetchData());
                }
            }
        ]}
    />
    </BaseTemplate>
);
}

export default ManageUsers;
