import React from 'react';
import { IonInput, IonButton } from '@ionic/react';
import { updateDestruction } from '../../apis/logictrials';
import { format } from 'date-fns';

export default function AddDestructionCertificateNumber({
    obj,
    setShowModal,
    fetchData,
    setCertificateNumber,
    certificateNumber,
}) {

    async function submitReference() {
        if(obj && obj.sss && Array.isArray(obj.sss)) {
            obj.sss = obj.sss.map(s => {
                if(s.includes('pending')) {
                    return s.replace('pending', format(new Date(), 'y-M-d H:m')) + ' (' + certificateNumber + ')';
                }

                return undefined;
            });
        }
    
        obj.sent = {
            "label": `${String(new Date().getFullYear())}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().toLocaleTimeString('en-US', { hour12: false })}`,
            "btnColor": "primary",
            "btnIcon": "documentOutline",
            "tooltip": "Print destruction waybill"
        }

        updateDestruction(obj, {
            id: obj.id
        })
        .then(() => fetchData());

        setShowModal(false);
    }

    return (
        <div style={{ marginTop: '2em' }}>
                <IonInput placeholder="Destruction certificate number" value={certificateNumber} onIonChange={e => setCertificateNumber(e.detail.value)} />
                <IonButton style={{marginTop: '2em' }} onClick={submitReference}>Submit</IonButton>
        </div>
    )
}
