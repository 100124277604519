import React, {useState} from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonIcon,
  IonButtons,
  IonButton,
  IonBackButton,
} from "@ionic/react";
import { logInOutline, menuOutline } from "ionicons/icons";
import MobileMenu from "../MobileMenu/MobileMenu";
import Modal from "../Modal";
import SitesStudiesModal from '../../pages/SearchSitesStudies/SearchSitesStudiesModal';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '../../utils';

import "./BaseTemplate.css";

export default function BaseTemplate({
  children,
  title,
  defaultHref,
  customBack
}) {
  const [showSitesStudies, setShowSitesStudies] = useState(false);
  const history = useHistory();
  const location = useLocation();

  async function handleMenuToggle() {
    const menu = document.getElementById("mobile-menu");
    if(menu) {
      const isOpen = await menu.isOpen();

      if (!isOpen) {
        menu.open();
      } else {
        menu.close();
      }
    }
  }

  return (
    <>
      <MobileMenu />
      <IonHeader style={{ textAlign: "center" }}>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleMenuToggle}>
              <IonIcon style={{color: 'white'}} size="large" icon={menuOutline}></IonIcon>
            </IonButton>
            <IonBackButton defaultHref={defaultHref ? defaultHref : "/"} onClick={customBack ? customBack : () => history.goBack()} />
          </IonButtons>
          {window.innerWidth > 600 && <IonTitle>{title}</IonTitle>}
          <IonButtons slot="end">
            <IonButton
              style={{height: '40px'}}
              onClick={logout}
            >
                logout <IonIcon size="large" slot="end" icon={logInOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="main-content">
          <div className="page-content">
            {(!location.pathname.includes('new') && !location.pathname.includes('edit') && !location.pathname.includes('create')) && !location.pathname.includes('processing') &&  <div className="w-full">
              <IonButton fill="outline" size="small" color="danger" className="mt-5 mb-4 mr-20 float-right" onClick={() => setShowSitesStudies(true)}>
                Search Sites & Studies
              </IonButton>
            </div>}
            {children}
          </div>
          <Modal
              showModal={showSitesStudies}
              setShowModal={setShowSitesStudies}
          >
            <SitesStudiesModal />
          </Modal>
      </IonContent>
    </>
  );
}
