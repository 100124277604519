import React, {useState} from 'react';

import {IonGrid, IonCol, IonRow, IonButton} from '@ionic/react';
import { viewDestructionPdf } from '../../apis/logictrials';

import {format} from 'date-fns';

import {isValidDate} from '../../utils';

export default function ViewCertificates({obj,  setShowModal}) {
    console.log(obj);
    const [viewReturnConfirmationDetails, setViewReturnConfirmationDetails] = useState(false);

    const returnItems = obj && obj.sss && Array.isArray(obj.sss) && obj.sss;
    const currentUploads = (obj && obj.uploadedDocs && Array.isArray(obj.uploadedDocs)) ? obj.uploadedDocs : [];

    function handleViewCertificateClick(r) {
        const returnId = getReturnId(r);

        viewDestructionPdf(returnId).then(res => {
            console.log(res);

            if(res && res[0] && res[0].fileBuffer) {
                let certificate = res[0].fileBuffer;

                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>${res[0].filename}</title>
                    </head>
                    <body style="height:100vh;width:100vw;">
                        <iframe width='100%' height='100%' src='${certificate}'></iframe>
                    </body>
                    </html>`
                );
                window.open(certificate);
            }
        });
    }

    function getReturnId(r) {
        if(r && (typeof r === 'string')) {
            if(r.includes(':')) return r.split(':')[0];

            if(!r.includes(':')) return r;

        }

        return '';
    }

    function onViewDataClick(r) {
        const rId = getReturnId(r);

        if(rId) {
            const details = obj && obj.completions && obj.completions.find(({returnId}) => returnId === rId);

            console.log(details, obj.completions);

            if(details && details.certificateNumber && details.returnId && details.dateDestroyed) {
                setViewReturnConfirmationDetails(details);

                return;
            }

            return;
        }

        return;
    }

    return (
        <>
            <h2 className="font-bold">View Confirmed Items</h2>
            <IonGrid className="mt-10">
                {returnItems && !viewReturnConfirmationDetails ? returnItems.map(r => 
                    (
                        <IonRow >
                            <IonCol size="6">
                                {currentUploads.includes(getReturnId(r)) ? 
                                    <p onClick={() => onViewDataClick(r)} style={{color: 'var(--ion-color-primary)'}} className="underline cursor-pointer">{getReturnId(r)}</p> :
                                    <p className="text-red-500">{getReturnId(r)}</p>
                                }
                            </IonCol>
                            <IonCol size="6">
                                {currentUploads.includes(getReturnId(r)) && <IonButton size="small" className="m-0 p-0 float-right" color="success" onClick={() => handleViewCertificateClick(r)}>
                                    View Certificate
                                </IonButton>}
                            </IonCol>
                        </IonRow>
                    )
                ) : (
                    <div className="w-full">
                        <div className="w-full grid grid-cols-2 justify-items-stretch">
                            {viewReturnConfirmationDetails && viewReturnConfirmationDetails.returnId && <>
                                <h4 className="font-bold">Certificate Number</h4>
                                <h4 className="font-light">{viewReturnConfirmationDetails.certificateNumber}</h4>
                                <h4 className="font-bold">Date Destroyed</h4>
                                <h4 className="font-light">{isValidDate(new Date(viewReturnConfirmationDetails.dateDestroyed)) && format(new Date(viewReturnConfirmationDetails.dateDestroyed), 'dd-MM-yyyy hh:MM')}</h4>
                            </>}
                        </div>
                        <div className="h-10" />
                        <IonButton expand="full" color="success" onClick={() => setViewReturnConfirmationDetails(false)}>
                            Back
                        </IonButton>
                    </div>
                )}
            </IonGrid>
        </>
    )
}
