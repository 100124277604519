import React, { useState, useEffect, useRef } from "react";
import {
    IonAlert,
    IonFab,
    IonFabButton,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonDatetime,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonToast,
    IonModal,
    IonPopover,
} from "@ionic/react";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import Search from "../../components/Search/Search";
import { add } from "ionicons/icons";
import CurrencyConverter from '../../components/actionButtonForms/CurrencyConvertor';
import Loading from "../../components/Loading";
import TableList from "../../components/TableList/TableList";
import Modal from '../../components/Modal';
import NewShipment from '../../components/NewShipment';
import { exportArrayToExcel, isValidDate, isUpperCase } from '../../utils';
import { deleteItem, getDataPerms, updateItem, confirmInbound, getData } from '../../apis/logictrials';
import QaCheck from '../../components/actionButtonForms/QaCheck';
import PDFwarehouse from '../../components/PDFwarehouse';
import ConfirmArrival from '../../components/actionButtonForms/ConfirmArrivalInbound';
import CreateEmail from '../../components/actionButtonForms/CreateEmail';
import OrderExtraInfo from '../../components/actionButtonForms/OrderExtraInfo';
import SssComplete from '../../components/actionButtonForms/SssComplete';
import DayPicker from 'react-day-picker';
import decode from 'jwt-decode';
import { format } from "date-fns/esm";

const PER_PAGE = 7;

function Users() {
    const [error, setError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [inboundShipments, setInboundShipments] = useState([]);
    const [unchangedInboundShipments, setUnchangedInboundShipments] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [displayShipment, setDisplayShipment] = useState(null);
    const [showCurrencyConvertor, setShowCurrencyConvertor] = useState(false);
    const [selectedValueIndex, setSelectedValueIndex] = useState(null);
    const [dateValue] = useState(null);
    const [filterType, setFilterType] = useState('none');
    const [dateField] = useState(null);
    const [displayPdf, setDisplayPdf] = useState(false);
    const [showConfirmArrivalModal, setShowConfirmArrivalModal] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailInboundData, setEmailInboundData] = useState();
    const [updateObject, setUpdateObject] = useState({});
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setDate(today.getDate() - 30);
    const [prevSelectedStartDate] = useState(lastMonth);

    const [selectedStartDate, setSelectedStartDate] = useState(lastMonth.toISOString());
    const [showStartDate, setShowStartDate] = useState({ showPopover: false, event: undefined });

    const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
    const [showEndDate, setShowEndDate] = useState({ showPopover: false, event: undefined });

    const dateTimeRef = useRef();
    const [alertMessage, setAlertMessage] = useState('Error');
    const [showAlert, setShowAlert] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage] = useState('');
    const [initialFetch] = useState(false);
    const [showExtraInfoModal, setShowExtraInfoModal] = useState(false);
    const [showQaModal, setShowQaModal] = useState(false);

    // delete
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteOrderNumber, setDeleteOrderNumber] = useState('');

    // confirm
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmId, setConfirmId] = useState(null);

    const [showArchives] = useState(false);
    const [archivesIncluded, setArchivesIncluded] = useState([]);

    const accessToken = sessionStorage.getItem('lt-access-token');

    const decoded = accessToken ? decode(accessToken) : false;

    let headings = [
        "id",
        "sponsor",
        "study",
        "clearingAgent",
        "courierCoy",
        "mawb",
        "zarValue",
        "receivingDate",
        "email",
        "confirmArrival",
        "qaCheck",
        "inventoryAdded",
        "sss",
        "confirm",
        "edit",
        "delete",
    ];

    let labels = [
        "ID",
        "Sponsor",
        "Study",
        "Clearing Agent",
        "Courier COY",
        "MAWB",
        "ZAR Value",
        "Receiving Date",
        "Email",
        "Confirm Arrival",
        "QA Check",
        "Inventory Added",
        "SSS",
        "",
        "",
        ""
    ];

    let formattedPermissions = [];

    if (decoded && decoded.perms) {
        const permissions = decoded.perms;

        formattedPermissions = permissions.map(p => {
            const toIndex = p.lastIndexOf('_') + 1;
            return p.slice(0, toIndex);
        });
    }

    if (!formattedPermissions.includes('inbound_d_')) headings = headings.filter(h => h !== "delete");

    if (!formattedPermissions.includes('inbound_d_')) labels.pop();

    if (!formattedPermissions.includes('inbound_u_')) headings = headings.filter(h => h !== "edit");

    if (!formattedPermissions.includes('inbound_u_')) labels.pop();

    if (!formattedPermissions.includes('inbound_u_')) headings = headings.filter(h => h !== "confirm");

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = () => {

        const { roleid } = JSON.parse(sessionStorage.getItem('lt-user'));

        getDataPerms('inbound', roleid).then(res => {

            const data = res.map(({ data, id, order_number }) => {
                let tempData = JSON.parse(data);
                tempData.db_id = id;
                tempData.id = order_number;

                return tempData;
            });
            const filteredData = data.filter(d => d && !d.isArchived);
            setArchivesIncluded([...data]);


            setInboundShipments([...filteredData]);
            setUnchangedInboundShipments([...filteredData]);
            setLoading(false);
        });
    }

    useEffect(() => {

        if (filterType === 'none') {
            setInboundShipments(unchangedInboundShipments);
        }
        if (selectedStartDate && selectedEndDate && (filterType === 'Arrival Date' || filterType === "Receiving Date" || filterType === "Inventory Date")) {
            if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
                setSelectedStartDate(prevSelectedStartDate);
                setAlertMessage("start date must be before end date");
                setShowAlert(true);
            }

            if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
                let ed = new Date(selectedEndDate).getTime();
                let sd = new Date(selectedStartDate).getTime();
                let key = '';
                switch (filterType) {
                    case "Receiving Date": {
                        key = 'receivingDate';
                        break;
                    }
                    case "Arrival Date": {
                        key = 'confirmArrival';
                        break;
                    }
                    case "Inventory Date": {
                        key = 'inventoryAdded';
                        break;
                    }
                    default: return false;
                }
                if (unchangedInboundShipments && unchangedInboundShipments.length > 0) {
                    const result = unchangedInboundShipments
                        .filter(d => {
                            if (typeof d[key] === "string") {
                                let time = new Date(d[key]).getTime();

                                if (key === 'confirmArrival') {
                                    console.log('confirmArrival', d[key])
                                    time = new Date('20' + d[key]).getTime();
                                }

                                return ((sd <= time) && (time <= ed));
                            }

                            if (d[key] && typeof d[key] === "object" && d[key].label) {
                                const currentYear = new Date().getFullYear();
                                if (key === 'receivingDate') {
                                    let time = new Date(d[key].label).setFullYear(currentYear);

                                    return ((sd <= time) && (time <= ed));
                                } else {
                                    let time = new Date(d[key].label).getTime();

                                    return ((sd <= time) && (time <= ed));
                                }
                            }

                            return false;
                        });

                    setInboundShipments(result);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStartDate, selectedEndDate, filterType]);

    function setSssFileName(value) {
        let tempShipments = inboundShipments;
        tempShipments = tempShipments.map(t => {
            if (t.db_id === updateObject.db_id) {
                t.sss = {
                    label: "",
                    link: value,
                    tooltip: 'print confirmation doc',
                    icon: 'documentOutline'
                };
                return t;
            } else {
                return t;
            }
        });
        setSelectedValueIndex(null);
        setInboundShipments(tempShipments);
    }

    function handleDateChange(value, field, index) {
        if (value) {
            let tempShipments = inboundShipments;
            tempShipments[index - 1][field] = value.slice(0, 10);
            setSelectedValueIndex(null);
            setInboundShipments(tempShipments);
        }
    }

    function handleDateChange2(value, type) {
        if (value === null) return;

        if (type === "start") {
            setSelectedStartDate(value);
        } else if (type === "end") {
            setSelectedEndDate(value);
        }
    }

    function addZarValue(inboundObject, value) {

        inboundObject.zarValue = value;

        updateItem('inbound', ['data'], [inboundObject], { id: inboundObject.db_id }).then(res => window.location.href = "/inbound-shipments");
    }

    useEffect(() => {
        if (inboundShipments && Array.isArray(inboundShipments)) {
            let tempInbounds = inboundShipments.filter(ib => {
                if (ib && ib.id && ib.id.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.sponsor && ib.sponsor.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.study && ib.study.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.clearingAgent && ib.clearingAgent.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.courierCoy && ib.courierCoy.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.mawb && ib.mawb.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.zarValue && ib.zarValue.label && ib.zarValue.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.receivingDate && ib.receivingDate.label && ib.receivingDate.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.email && ib.email.label && ib.email.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.confirmArrival && ib.confirmArrival.label && ib.confirmArrival.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.qaCheck && ib.qaCheck.label && ib.qaCheck.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.inventoryAdded && ib.inventoryAdded.label && ib.inventoryAdded.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;

                return false;
            });

            setCurrentPage(0);

            setSearchResults(tempInbounds);
        }

    }, [searchQuery, inboundShipments]);

    const revisedInboundShipments = searchResults;

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    const offset = currentPage * PER_PAGE;

    const currentPageData = revisedInboundShipments ? revisedInboundShipments.slice(offset, offset + PER_PAGE) : [];

    const pageCount = revisedInboundShipments ? Math.ceil(revisedInboundShipments.length / PER_PAGE) : 0;

    const functions = {
        id: () => { },
        zarValue: (_, index, c) => {
            setUpdateObject(c);
            setSelectedValueIndex(index);
            setShowCurrencyConvertor(true);
        },
        email: (_, __, obj) => {
            setEmailInboundData({
                sponsor: '',
                study: '',
                site: '',
                investigator: '',
                contact: '',
                devices: ['']
            });

            setUpdateObject(obj);
            setShowEmailModal(true);
        },
        confirmArrival: (a, b, c) => {
            setShowConfirmArrivalModal(true);
            setUpdateObject(c);
        },
        qaCheck: (obj) => {
            setUpdateObject(obj);
            setShowQaModal(true);
        },
        inventoryAdded: (obj, value) => {
            if (confirmId !== obj.id) {
                let tempInbound = obj;

                tempInbound.inventoryAdded = value ? "true" : "false";
                updateItem('inbound', ["data"], [tempInbound], { id: obj.db_id }).then(res => {
                    console.log('IA', res);

                    fetchData();
                });
            };
        },
        sss: (obj) => {
            if (obj) setUpdateObject(obj);

            setShowFileUploadModal(true);
        },
        receivingDate: (a, b, c) => {
            setUpdateObject(c);
            setDisplayPdf(true);
        },
        delete: (_, __, obj) => {

            setShowConfirmDelete(true);
            const { db_id, id } = obj;

            setDeleteOrderNumber(id);
            setDeleteId(db_id);
        },
        edit: (_, __, obj) => {
            setDisplayShipment(obj);
        },
        confirm: (_, __, obj) => {

            if (obj && obj.id) {
                setShowConfirm(true);
                const { id } = obj;

                setConfirmId(id);
            }
        }
    };

    useEffect(() => {
        if (showArchives) {
            setInboundShipments(archivesIncluded);
        } else if (inboundShipments) {
            setInboundShipments(unchangedInboundShipments);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showArchives]);

    function excelArray() {
        const temp = [...revisedInboundShipments];

        const result = temp.map(r => {
            let keys = Object.keys(r);
            r = { ...r };

            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            delete r['edit'];
            delete r['email'];
            delete r['confirm'];
            delete r['view'];
            delete r['devices'];
            delete r['awaitingstatus'];
            delete r['awaitingqarelease'];

            if ((typeof r['confirmArrival'] !== "string") || r['confirmArrival'] === '') r['confirmArrival'] = "Unconfirmed";
            if (!r['zarValue']) r['zarValue'] = "N/A";
            if (!r['packingListNumber']) r['packingListNumber'] = "N/A";
            if (!r['mawb']) r['mawb'] = "N/A";
            if (!r['quarantine']) r['quarantine'] = "N/A";
            if (r['inventoryAdded']) {
                r['inventoryAdded'] = "Yes";
            } else {
                r['inventoryAdded'] = "No";
            };

            if (!r['boxType']) r['boxType'] = "N/A";

            if (!r['boxTemperature']) r['boxTemperature'] = "N/A";

            if (r['qaCheck'] === '') r['qaCheck'] = "Unconfirmed";

            delete r['inventoryAdded'];
            delete r['sss'];
            delete r['index'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['db_id'];
            delete r['studyId'];
            delete r['sponsorId'];
            delete r['qaSteps'];
            delete r['certAnalysis'];

            keys = Object.keys(r);

            let reOrderedKeys = keys;

            reOrderedKeys[1] =

                keys.forEach(k => {
                    const result = k.replace(/([A-Z])/g, " $1");
                    let excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                    if (isUpperCase(excelKey)) excelKey = excelKey.replace(/ /g, "");

                    if (excelKey.toLowerCase() === 'id') excelKey = 'ID';
                    if (excelKey.toLowerCase() === 'mawb') excelKey = 'MAWB';
                    if (excelKey.toLowerCase() === 'qa check') excelKey = 'QA Check';
                    if (excelKey.toLowerCase() === 'qarelease') excelKey = 'QA Release';

                    if (excelKey.toLowerCase() === 'receiving date') {
                        const setDate = new Date(r[k]);
                        const today = new Date();

                        const year = typeof r['ID'] === 'string' ? '20' + r['ID'].slice(2, 4) : today.getFullYear();

                        setDate.setFullYear(year);

                        r[k] = isValidDate(setDate) ? format(setDate, 'yyyy-MM-dd') : r[k];
                    }

                    if (excelKey.toLowerCase() === 'receiving date') console.log(isValidDate(new Date(r[k])), r[k], new Date(r[k]))
                    if (!r[k]) r[k] = 'N/A';

                    r[excelKey] = r[k];

                    console.log(excelKey)

                    delete r[k];
                });

            return r;
        });


        return result;
    }

    async function excelArrayWithArchives() {
        const temp = [...revisedInboundShipments];

        const res = await getData('archive_inbound', 0, 9999);
        const archives = res.map(({ data, id }) => {
            let tempData = JSON.parse(data);
            tempData.db_id = id;
            return tempData;
        });

        let searchFiltersArchives;
        if (searchQuery) {
            searchFiltersArchives = archives.filter(ib => {
                if (ib && ib.id && ib.id.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.sponsor && ib.sponsor.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.study && ib.study.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.clearingAgent && ib.clearingAgent.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.courierCoy && ib.courierCoy.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.mawb && ib.mawb.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.zarValue && ib.zarValue.label && ib.zarValue.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.receivingDate && ib.receivingDate.label && ib.receivingDate.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.email && ib.email.label && ib.email.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.confirmArrival && ib.confirmArrival.label && ib.confirmArrival.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.qaCheck && ib.qaCheck.label && ib.qaCheck.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (ib && ib.inventoryAdded && ib.inventoryAdded.label && ib.inventoryAdded.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;

                return false;
            });
        } else {
            searchFiltersArchives = archives;
        }

        const dateFiltersArchives = filterByDates(searchFiltersArchives);

        let archiveFinal = dateFiltersArchives ? dateFiltersArchives : archives;

        const result = temp.map(r => {
            let keys = Object.keys(r);
            r = { ...r };

            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            delete r['edit'];
            delete r['email'];
            delete r['confirm'];
            delete r['view'];
            delete r['devices'];
            delete r['awaitingstatus'];
            delete r['awaitingqarelease'];

            if ((typeof r['confirmArrival'] !== "string") || r['confirmArrival'] === '') r['confirmArrival'] = "Unconfirmed";
            if (!r['zarValue']) r['zarValue'] = "N/A";
            if (!r['packingListNumber']) r['packingListNumber'] = "N/A";
            if (!r['mawb']) r['mawb'] = "N/A";
            if (!r['quarantine']) r['quarantine'] = "N/A";
            if (r['inventoryAdded']) {
                r['inventoryAdded'] = "Yes";
            } else {
                r['inventoryAdded'] = "No";
            };

            if (!r['boxType']) r['boxType'] = "N/A";

            if (!r['boxTemperature']) r['boxTemperature'] = "N/A";

            if (r['qaCheck'] === '') r['qaCheck'] = "Unconfirmed";

            // delete r['inventoryAdded'];
            delete r['sss'];
            delete r['index'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['db_id'];
            delete r['studyId'];
            delete r['sponsorId'];
            delete r['qaSteps'];
            delete r['certAnalysis'];

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1");
                let excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (isUpperCase(excelKey)) excelKey = excelKey.replace(/ /g, "");
                // if(isUpperCase(excelKey)) excelKey = excelKey.replace(/ /g, "");

                if (excelKey.toLowerCase() === 'id') excelKey = 'ID';
                if (excelKey.toLowerCase() === 'mawb') excelKey = 'MAWB';
                if (excelKey.toLowerCase() === 'qa check') excelKey = 'QA Check';
                if (excelKey.toLowerCase() === 'qarelease') excelKey = 'QA Release';

                if (excelKey.toLowerCase() === 'receiving date') {
                    const setDate = new Date(r[k]);
                    const today = new Date();

                    const year = typeof r['ID'] === 'string' ? '20' + r['ID'].slice(2, 4) : today.getFullYear();

                    setDate.setFullYear(year);

                    r[k] = isValidDate(setDate) ? format(setDate, 'yyyy-MM-dd') : r[k];
                }

                if (excelKey.toLowerCase() === 'receiving date') console.log(isValidDate(new Date(r[k])), r[k], new Date(r[k]))
                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });
            return r;
        });

        const finalArchive = archiveFinal.map(r => {
            let keys = Object.keys(r);

            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            delete r['edit'];
            delete r['email'];
            delete r['confirm'];
            delete r['view'];
            delete r['devices'];
            delete r['awaitingstatus'];
            delete r['awaitingqarelease'];

            if ((typeof r['confirmArrival'] !== "string") || r['confirmArrival'] === '') r['confirmArrival'] = "Unconfirmed";
            if (!r['zarValue']) r['zarValue'] = "N/A";
            if (!r['packingListNumber']) r['packingListNumber'] = "N/A";
            if (!r['mawb']) r['mawb'] = "N/A";
            if (!r['quarantine']) r['quarantine'] = "N/A";
            if (r['inventoryAdded']) {
                r['inventoryAdded'] = "Yes";
            } else {
                r['inventoryAdded'] = "No";
            };

            if (!r['boxType']) r['boxType'] = "N/A";

            if (!r['boxTemperature']) r['boxTemperature'] = "N/A";

            if (r['qaCheck'] === '') r['qaCheck'] = "Unconfirmed";

            // delete r['inventoryAdded'];
            delete r['sss'];
            delete r['index'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['db_id'];
            delete r['studyId'];
            delete r['sponsorId'];
            delete r['qaSteps'];
            delete r['certAnalysis'];

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1");
                let excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (isUpperCase(excelKey)) excelKey = excelKey.replace(/ /g, "");
                // if(isUpperCase(excelKey)) excelKey = excelKey.replace(/ /g, "");

                if (excelKey.toLowerCase() === 'id') excelKey = 'ID';
                if (excelKey.toLowerCase() === 'mawb')
                    excelKey = 'MAWB';
                if (excelKey.toLowerCase() === 'qa check') excelKey = 'QA Check';
                if (excelKey.toLowerCase() === 'qarelease') excelKey = 'QA Release';

                if (excelKey.toLowerCase() === 'receiving date') {
                    const setDate = new Date(r[k]);
                    const today = new Date();

                    const year = typeof r['ID'] === 'string' ? '20' + r['ID'].slice(2, 4) : today.getFullYear();

                    setDate.setFullYear(year);

                    r[k] = isValidDate(setDate) ? format(setDate, 'yyyy-MM-dd') : r[k];
                }

                if (excelKey.toLowerCase() === 'receiving date') console.log(isValidDate(new Date(r[k])), r[k], new Date(r[k]))
                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });
            return r;
        });

        const final = [...result, ...finalArchive];
        return final;
    }

    function filterByDates(arr) {
        if (selectedStartDate && selectedEndDate && (filterType === 'Arrival Date' || filterType === "Receiving Date" || filterType === "Inventory Date")) {
            if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
                let ed = new Date(selectedEndDate).getTime();
                let sd = new Date(selectedStartDate).getTime();
                let key = '';
                switch (filterType) {
                    case "Receiving Date": {
                        key = 'receivingDate';
                        break;
                    }
                    case "Arrival Date": {
                        key = 'confirmArrival';
                        break;
                    }
                    case "Inventory Date": {
                        key = 'inventoryAdded';
                        break;
                    }
                    default: return false;
                }
                if (arr && arr.length > 0) {
                    const result = arr
                        .filter(d => {
                            if (typeof d[key] === "string") {
                                let time = new Date(d[key]).getTime();

                                if (key === 'confirmArrival') {
                                    console.log('confirmArrival', d[key])
                                    time = new Date('20' + d[key]).getTime();
                                }

                                return ((sd <= time) && (time <= ed));
                            }

                            if (d[key] && typeof d[key] === "object" && d[key].label) {
                                let time = new Date(d[key].label).getTime();

                                return ((sd <= time) && (time <= ed));
                            }

                            return false;
                        });

                    return result;
                }
            }
        } else {
            return false;
        }
    }

    function clearFilters() {
        setFilterType('none');
    }

    return (
        <>
            {!displayShipment && <BaseTemplate title="Inbound Shipments" customBack={() => setDisplayPdf(null)}>
                {!displayPdf && (
                    <>
                        <div>
                            <Search
                                setSearchQuery={setSearchQuery}
                                placeholder="Search"
                            />
                            <IonGrid>
                                <IonRow>
                                    <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"} offset-md="1">
                                        <IonItem className="shadow-xl">
                                            <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                                Filter Type
                                            </IonLabel>
                                            <IonSelect interface="popover" value={filterType} onIonChange={e => setFilterType(e.detail.value)}>
                                                <IonSelectOption value="none">
                                                    None
                                                </IonSelectOption>
                                                <IonSelectOption value="Receiving Date">
                                                    Receiving Date
                                                </IonSelectOption>
                                                <IonSelectOption value="Arrival Date">
                                                    Confirm Arrival Data
                                                </IonSelectOption>
                                                <IonSelectOption value="Inventory Date">
                                                    Inventory Added Date
                                                </IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                    {filterType !== 'none' && <>
                                        <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                            <IonItem
                                                className="shadow-xl"
                                                onClick={(e) => setShowStartDate({ showPopover: true, event: e })}
                                            >
                                                <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                                    Start Date
                                                </IonLabel>
                                                <IonDatetime
                                                    displayFormat="D MMM YYYY H:mm"
                                                    min="1997"
                                                    max="2021"
                                                    value={selectedStartDate}
                                                    onIonChange={(e) => handleDateChange2(e.detail.value, "start")}
                                                    readonly={true}
                                                ></IonDatetime>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                            <IonItem
                                                className="shadow-xl"
                                                onClick={(e) => setShowEndDate({ showPopover: true, event: e })}
                                            >
                                                <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                                    End Date
                                                </IonLabel>
                                                <IonDatetime
                                                    displayFormat="D MMM YYYY H:mm"
                                                    min="1997"
                                                    max="2021"
                                                    value={selectedEndDate}
                                                    onIonChange={(e) => handleDateChange2(e.detail.value, "end")}
                                                    readonly={true}
                                                ></IonDatetime>
                                            </IonItem>
                                        </IonCol></>}
                                    <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"}>
                                        <IonButton expand="full" style={{ height: '50px', marginTop: 0 }} onClick={() => exportArrayToExcel(excelArray(), `inbound-shipments-${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`)}>
                                            export to excel
                                        </IonButton>
                                    </IonCol>
                                    <IonCol size-xs="12" size-sm="12" offset={filterType !== "none" ? "1" : "0"} size-md={filterType !== 'none' ? "2.5" : "2.5"}>
                                        <IonButton expand="full" style={{ height: '50px', marginTop: 0 }} onClick={async () => {
                                            const exportArray = await excelArrayWithArchives();
                                            exportArrayToExcel(exportArray, `inbound-shipments-with-archives-${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`);
                                        }}>
                                            export with archives
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                {filterType !== 'none' && <IonRow>
                                    <IonCol offset-md="1.05">
                                        <span style={{ cursor: 'pointer', color: 'var(--ion-color-primary)' }} onClick={() => clearFilters()}>clear filters</span>
                                    </IonCol>
                                </IonRow>}
                            </IonGrid>
                        </div>

                        <div className="users" style={{ paddingBottom: "5em" }}>
                            <h2 className="results-display">{revisedInboundShipments ? revisedInboundShipments.length : 0} Result(s)</h2>
                            <IonGrid>
                                <IonRow style={{ boxShadow: "none" }}>
                                    <IonCol offset-xl="0.5" size-xl="11">
                                        {loading && <Loading loading={loading} />}
                                        {!loading && (
                                            <>
                                                {currentPageData && currentPageData.length > 0 ? <TableList
                                                    headings={headings}
                                                    items={currentPageData}
                                                    labels={labels}
                                                    functions={functions}
                                                /> : <h1>No search results</h1>}
                                            </>
                                        )}
                                        <div style={{ margin: "1em 1em" }}>
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                            />
                                        </div>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            <IonAlert
                                isOpen={error !== ""}
                                onDidDismiss={() => setError("")}
                                header={"Error"}
                                message={error}
                                buttons={["OK"]}
                            />
                            <IonAlert
                                isOpen={showAlert}
                                onDidDismiss={() => setShowAlert(false)}
                                cssClass="my-custom-class"
                                header={"Alert"}
                                message={alertMessage}
                                buttons={["OK"]}
                            />
                        </div>
                        {formattedPermissions && formattedPermissions.includes('inbound_c_') && <IonFab
                            vertical="bottom"
                            horizontal="start"
                            slot="fixed"
                            style={{
                                marginBottom: window.innerWidth < 900 ? "3em" : "5em",
                                position: "fixed",
                                marginRight: "2em",
                                marginLeft: "2em"
                            }}
                        >
                            <IonFabButton routerLink="/new-shipment">
                                <IonIcon icon={add}></IonIcon>
                            </IonFabButton>
                        </IonFab>}
                    </>
                )}
                {displayPdf && <PDFwarehouse inbound={updateObject} setDisplayPdf={setDisplayPdf} />}
                <Modal showModal={showModal} setShowModal={setShowModal}>
                </Modal>
                <Modal showModal={showCurrencyConvertor} setShowModal={setShowCurrencyConvertor}>
                    <CurrencyConverter addZarValue={addZarValue} zarValueIndex={selectedValueIndex} setShowModal={setShowCurrencyConvertor} updateObject={updateObject} />
                </Modal>
                {updateObject && <Modal showModal={showFileUploadModal} setShowModal={setShowFileUploadModal}>
                    <SssComplete fetchData={fetchData} tableType={"inbound"} setShowModal={setShowFileUploadModal} protocol="inbound-sss" setFileName={setSssFileName} selectedIndex={selectedValueIndex} object={updateObject} orderNumber={updateObject && updateObject.id} />
                </Modal>}
                <Modal showModal={showConfirmArrivalModal} setShowModal={setShowConfirmArrivalModal} className="confirm-inbound-arrival-modal">
                    <ConfirmArrival fetchData={fetchData} setShowModal={setShowConfirmArrivalModal} setShowConfirmArrivalModal={setShowConfirmArrivalModal} inboundObject={updateObject} setShowExtraInfoModal={setShowExtraInfoModal} />
                </Modal>
                {updateObject && <Modal showModal={showEmailModal} setShowModal={setShowEmailModal}>
                    <CreateEmail setShowModal={setShowEmailModal} data={emailInboundData} orderNumber={updateObject && updateObject.id} updatedInbound={updateObject && updateObject} />
                </Modal>}
                <Modal showModal={showExtraInfoModal} setShowModal={setShowExtraInfoModal}>
                    <OrderExtraInfo />
                </Modal>
                <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{ visibility: 'hidden' }} value={dateValue} onIonChange={e => handleDateChange(e.detail.value, dateField, selectedValueIndex)}></IonDatetime>
                <Modal showModal={showQaModal} setShowModal={setShowQaModal}>
                    {showQaModal && <QaCheck inboundObject={updateObject} />}
                </Modal>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMessage}
                    position="bottom"
                    duration={2000}
                    color="success"
                />
                <IonAlert
                    isOpen={showConfirmDelete}
                    onDidDismiss={() => setShowConfirmDelete(false)}
                    message={`Are you sure you want to delete<br /> shipment ${deleteOrderNumber}?`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => setShowConfirmDelete(false)
                        },
                        {
                            text: 'Delete',
                            cssClass: 'danger',
                            handler: () => {
                                if (deleteId) deleteItem('inbound', { id: deleteId }).then(res => fetchData());
                            }
                        }
                    ]}
                />
                <IonAlert
                    isOpen={showConfirm}
                    onDidDismiss={() => setShowConfirm(false)}
                    message={`Are you sure you want to complete<br /> shipment ${confirmId}?`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => setShowConfirm(false)
                        },
                        {
                            text: 'Confirm',
                            cssClass: '',
                            handler: () => {
                                if (confirmId) confirmInbound(confirmId).then(() => {
                                    fetchData();
                                });
                            }
                        }
                    ]}
                />
            </BaseTemplate>}
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showStartDate.showPopover}
                event={showStartDate.event}
                onDidDismiss={() => setShowStartDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedStartDate(day.toString()); setShowStartDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowStartDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonPopover>
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showEndDate.showPopover}
                event={showEndDate.event}
                onDidDismiss={() => setShowEndDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedEndDate(day.toString()); setShowEndDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowEndDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonPopover>
            {displayShipment && <NewShipment editShipment={displayShipment} length={inboundShipments ? inboundShipments.length : 0} setEditShipment={setDisplayShipment} />}
        </>
    );
}

export default Users;
