import React, { useState, useEffect, useRef } from "react";
import {
    IonAlert,
    IonGrid,
    IonCol,
    IonRow,
    IonDatetime,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonToast,
    IonModal,
    IonPopover,
} from "@ionic/react";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import Search from "../../components/Search/Search";
import {
    exportArrayToExcel,
} from "../../utils";
import Loading from "../../components/Loading";
import TableList from "../../components/TableList/TableList";
import Modal from '../../components/Modal';
import UpdateReference from '../../components/UpdateReference';
import BoxLabel from '../BoxLabel';
import { getDataPerms, deleteItem } from '../../apis/logictrials';
import AddManualWaybill from "../../components/actionButtonForms/AddManualWaybill";
import AddManualAramexWaybill from "../../components/actionButtonForms/AddManualAramexWaybill";
import ConfirmReceipt from '../../components/actionButtonForms/ConfirmReceipt';
import RequestDate from '../../components/actionButtonForms/RequestDate';
import { updateItem } from '../../apis/logictrials';
import ReturnsProcessing from '../../pages/ReturnsProcessing/ReturnsProcessing';
import { getReturnWaybill, confirmReturn, getReceiptPdf, getData, addDestruction, updateDestruction } from '../../apis/logictrials';
import addToDestruction from '../../utils/formulateDestructionListData';
import GnNumber from "../../components/actionButtonForms/GnNumber";
import DayPicker from 'react-day-picker';

const PER_PAGE = 15;

const actions = [
    "Destruction",
    "Over Supply",
    "Recall",
    "Quarantine",
    "Reject",
    "Collection",
    "TT4 Return",
    "Quarantined due to damaged box",
    "Awaiting Destruction",
    "Destroyed"
];

function ReturnsArchive({ isExternal }) {
    const [error, setError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [collectionsReturns, setCollectionsReturns] = useState([]);
    const [unchangedCollectionsReturns, setUnchangedCollectionsReturns] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showUpdateReferenceModal, setShowUpdateReferenceModal] = useState(false);
    const [updateReference, setUpdateReference] = useState('');
    const [filterType, setFilterType] = useState("none");
    const [alertMessage, setAlertMessage] = useState('Error');
    const [showAlert, setShowAlert] = useState(false);
    const [boxLabelSops, setBoxLabelSops] = useState();
    const [showBoxLabelModal, setShowBoxLabelModal] = useState();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setDate(today.getDate() - 30);

    const [selectedStartDate, setSelectedStartDate] = useState(lastMonth.toISOString());
    const [prevSelectedStartDate] = useState(lastMonth.toISOString());
    const [showStartDate, setShowStartDate] = useState({ showPopover: false, event: undefined });

    const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
    const [showEndDate, setShowEndDate] = useState({ showPopover: false, event: undefined });

    const dateTimeRef = useRef();
    const [showUpdateWaybillModal, setShowUpdateWaybillModal] = useState(false);
    const [showUpdateAramexWaybillModal, setShowUpdateAramexWaybillModal] = useState(false);
    const [showRequestDate, setShowRequestDate] = useState(false);
    const [showConfirmReceipt, setShowConfirmReceipt] = useState(false);
    const [addWaybillData, setAddWaybillData] = useState({});

    const [displayOrder, setDisplayOrder] = useState(false);

    const [updateObject, setUpdateObject] = useState({});

    const [boxLabelLink, setBoxLabelLink] = useState('');
    const [showGnNumber, setShowGnNumber] = useState(false);
    const [filterByAction, setFilterByAction] = useState('');
    const [showSend, setShowSend] = useState(false);

    let labels = !isExternal ? [
        "SOPS",
        "Reference No.",
        "Request Date",
        "Action ",
        "Boxes",
        "Waybill",
        "Collection Date",
        "Confirm Receipt",
        "Received Date",
        "GN No.",
        ""
    ] : [
        "SOPS",
        "Reference No.",
        "Request Date",
        "Action ",
        "Boxes",
        "Waybill",
        "Collection Date",
        "Confirm Receipt",
        "Received Date",
        "GN No.",
    ];

    let headings = !isExternal ? [
        "sops",
        "referenceNo",
        "requestDate",
        "action",
        "boxes",
        "waybill",
        "collectionDate",
        "confirmReceipt",
        "receivedDate",
        "gnNo",
        "sendToDestruction"
    ] : [
        "sops",
        "referenceNo",
        "requestDate",
        "action",
        "boxes",
        "waybill",
        "collectionDate",
        "confirmReceipt",
        "receivedDate",
        "gnNo",
    ];

    useEffect(() => {
        fetchData();
    }, [displayOrder]);

    const fetchData = async () => {
        const res = await getDataPerms('archive_returns', 0, 9999);
        const data = res.map(({ data, id, order_number }) => {
            let tempData = JSON.parse(data);
            tempData.id = id;
            tempData.orderNumber = order_number;
            if (tempData && tempData.receivedDate && typeof tempData.receivedDate === 'string') {
                tempData.receivedDate = {
                    "label": tempData.receivedDate,
                    "btnIcon": "documentOutline",
                    "tooltip": "Reprint return doc",
                    "btnColor": "danger"
                };
            }
            if (tempData["action "]) tempData.action = tempData["action "];

            if (tempData.aramex && tempData.aramex.received && tempData.aramex.request && tempData.waybill) {
                tempData.waybill.label = tempData.aramex.request;
                tempData.waybill.link = tempData.aramex.received;
            }
            // if (tempData && tempData.boxes && tempData.boxes.label) {
            //     tempData.boxes.label = tempData.boxes.label.split('(');
            //     if (tempData.boxes.label.length > 0) {
            //         tempData.boxes.label = tempData.boxes.label[0].trim();
            //     }
            // } else if (tempData && tempData.boxes && tempData.boxes) {
            //     tempData.boxes = tempData.boxes.split('/');
            //     if (tempData.boxes.length > 0) {
            //         tempData.boxes = tempData.boxes[0].trim();
            //     }
            // }
            if (tempData && tempData.boxes) {
                if (tempData.boxes.label) {
                    tempData.boxes.label = tempData.boxes.label.split('(')[0].trim();
                } else if (typeof tempData.boxes === 'string') {
                    [tempData.boxes] = tempData.boxes.split('/');
                    tempData.boxes = tempData.boxes.trim();
                }
            }
            return tempData;
        });

        setLoading(false);
        setCollectionsReturns(data);
        setUnchangedCollectionsReturns(data);
    }

    useEffect(() => {
        if (filterType === "None") {
            setCollectionsReturns(unchangedCollectionsReturns);
        }
        if (selectedStartDate && selectedEndDate && ((filterType === 'Arrival Date') || (filterType === 'Request Date'))) {

            if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
                setSelectedStartDate(prevSelectedStartDate);
                setAlertMessage("start date must be before end date");
                setShowAlert(true);
            }
            if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
                let ed = new Date(selectedEndDate).getTime();
                let sd = new Date(selectedStartDate).getTime();
                let key = '';
                switch (filterType) {
                    case "Request Date": {
                        key = 'requestDate';
                        break;
                    }
                    case "Arrival Date": {
                        key = 'receivedDate';
                        break;
                    }
                    default: return false;
                }
                const result = unchangedCollectionsReturns.filter(d => {
                    if (!d[key]) {
                        return false;
                    }
                    var time = new Date(d[key].label ? d[key].label : d[key]).getTime();
                    if (time) {
                        return ((sd <= time) && (time <= ed));
                    } else {
                        return false;
                    }

                });
                setLoading(false);
                setCollectionsReturns(result);
            }
        }
    }, [selectedStartDate, selectedEndDate, prevSelectedStartDate, unchangedCollectionsReturns, filterType]);

    function handleUpdateReference(value) {
        let temp = collectionsReturns.map(c => {
            if (updateReference === c.referenceNo.label) {
                c.referenceNo.label = value;

            }
            return c;
        });

        setCollectionsReturns(temp);
    }

    async function handleUpdateAction(value, _, obj) {

        let tempObj = obj;
        tempObj.action = value;

        await updateItem('archive_returns', ['data'], [tempObj], { id: obj.id });

        setToastMessage('Returns updated');
        setShowToast(true);
    }

    async function addToDestructionList(tempObj) {
        const destructions = await getData('destruction', 0, 9999);
        const parsedDestructions = destructions.map(d => {
            let parsed = JSON.parse(d.data);

            parsed.id = d.id;

            return parsed;
        });

        let mostRecentDestruction;

        // most recent destruction as skelleton data to add to or false if a new one has to be added
        let desSkel = false;
        const refArr = tempObj.sops.split(' ');

        let ref = refArr[refArr.length - 2];
        ref = ref.slice(1, ref.length);

        // tests if most recent has been sent, set to false if doesnt exist or has not been sent
        let isEqual = false;

        if (parsedDestructions.length !== 0) {
            mostRecentDestruction = parsedDestructions[0];

            const testObj = {
                label: "",
                btnColor: 'warning',
                btnLabel: 'Send for destruction',
                tooltip: ''
            };

            isEqual = mostRecentDestruction.sent.btnColor === testObj.btnColor ? true : false;

            desSkel = isEqual ? mostRecentDestruction : false;
        }

        const { obj, update } = addToDestruction(tempObj.studyNumber, tempObj, tempObj.orderNumber, desSkel);

        if (update) {
            updateDestruction(obj, { id: mostRecentDestruction.id }).then(res => {
                addToArchived(tempObj);
                setToastMessage(`order ${ref} successfully added to destruction list`);
                setShowToast(true);
            });
        }

        if (!update) {
            addDestruction(obj).then(res => {
                addToArchived(tempObj);
                setToastMessage(`order ${ref} successfully added to destruction list`);
                setShowToast(true);
            });
        }
    }

    function addToArchived(tempObj) {
        let ltReturns = sessionStorage.getItem('lt-a-r');
        ltReturns = JSON.parse(ltReturns);
        ltReturns.unshift(tempObj);

        sessionStorage.setItem('lt-a-r', JSON.stringify(ltReturns));
        setTimeout(() => {
            fetchData();
        }, 1500);
    }

    useEffect(() => {
        if (collectionsReturns) {
            let tempReturns = collectionsReturns.filter((cr, i) => {
                if (cr && cr.referenceNo && cr.referenceNo.label && typeof cr.referenceNo.label === 'string' && cr.referenceNo.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.sops && cr.sops.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.action && cr.action.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.aramexRef && cr.aramexRef.label && cr.aramexRef.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.waybill && cr.waybill.label && cr.waybill.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                if (cr && cr.aramexReturn && cr.aramexReturn.request && typeof cr.aramexReturn.request === 'string' && cr.aramexReturn.request.toLowerCase().includes(searchQuery.toLowerCase())) return true;
                return false;
            });

            setCurrentPage(0);

            setSearchResults(tempReturns);
        }

    }, [searchQuery, collectionsReturns, setSearchResults]);

    const revisedCollectionsReturns = searchResults;

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    const offset = currentPage * PER_PAGE;

    const currentPageData = revisedCollectionsReturns ? revisedCollectionsReturns.slice(offset, offset + PER_PAGE) : [];

    const pageCount = revisedCollectionsReturns ? Math.ceil(revisedCollectionsReturns.length / PER_PAGE) : 0;

    function clearFilters() {
        setFilterType('none');
    }

    function excelArray() {
        const temp = [...revisedCollectionsReturns];
        const result = temp.map(r => {
            let keys = Object.keys(r);
            r = { ...r };
            keys.forEach(k => {
                if (typeof r[k] === 'object') {
                    r[k] = (r[k] && r[k].label) ? r[k].label : 'N/A';
                }
            });

            if (r['boxTypes'] && Array.isArray(r['boxTypes']) && r['boxTypes'].length > 0) {
                for (let i = 0; i < r['boxTypes'].length; i++) {
                    if (r['Temp Range']) r['Temp Range'] += ', ' + r['boxTypes'][i].boxTemperature;
                    if (!r['Temp Range']) r['Temp Range'] = r['boxTypes'][i].boxTemperature;

                    if (r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                    };
                    if (!r['Type of Temp Monitor']) {
                        if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] = r['boxTypes'][i].tempMonitorType;
                    };

                    if (r['boxTypes'][i].tempMonitorType) r['Type of Temp Monitor'] += ', ' + r['boxTypes'][i].tempMonitorType;
                }

                r['Number of Temp Monitors'] = r['boxTypes'].length;
            }

            if (r['boxes']) r['Number of Boxes'] = r['boxes'];
            if (r["Sponsor"]) {
                r['sponsorName'] = r['Sponsor'];

                delete r['Sponsor'];
            };

            if (!r['serviceType']) r['serviceType'] = "N/A";
            if (!r['numberOfKits']) r['numberOfKits'] = "N/A";

            delete r['siteId'];
            delete r['siteID'];
            delete r['StudyID'];
            delete r['studyID'];
            delete r['edit'];
            delete r['id'];
            delete r['parcels'];
            delete r['sponsorID'];
            delete r['SponsorID'];
            delete r['view'];
            delete r['sponsorShortName'];

            delete r['confirm'];
            delete r['aramex'];
            delete r['aramexNeeded'];

            delete r['sponsor'];
            delete r['edit'];
            delete r['delete'];
            delete r['email'];
            delete r['confirmDestruction'];
            delete r['list'];
            delete r['dspl'];
            delete r['sss'];
            delete r['boxTypes'];
            delete r['studyNumber'];

            keys = Object.keys(r);

            keys.forEach(k => {
                const result = k.replace(/([A-Z])/g, " $1");
                const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

                if (!r[k]) r[k] = 'N/A';

                r[excelKey] = r[k];

                delete r[k];
            });

            return { " Study": r[" Study"], "Sponsor Name": r["Sponsor Name"], ...r };
        });
        return result;
    }

    useEffect(() => {
        if (filterByAction && filterType === 'Action') {
            const filtered = unchangedCollectionsReturns.filter(r => r.action && r.action === filterByAction);

            setCollectionsReturns(filtered);
        }

        if (filterByAction && filterType !== 'Action') {
            setFilterByAction('');
        }
    }, [filterByAction, filterType, unchangedCollectionsReturns]);

    const functions = {
        sops: (obj) => {

            setDisplayOrder(true);
            setUpdateObject(obj);
        },
        referenceNo: (refNo, b, obj) => {

            setShowUpdateReferenceModal(true);
            setUpdateReference(refNo);
            setUpdateObject(obj);
        },
        requestDate: (_, __, obj) => {
            setUpdateObject(obj);
            setShowRequestDate(true);
        },
        action: (value, i, obj) => {

            handleUpdateAction(value, i, obj);
        },
        boxes: (_, index, obj) => {

            const SOPS = collectionsReturns[index].sops.split(' ');
            const { id } = obj;

            const link = `https://lt-xi.vercel.app/collections-and-returns?id=${id}`;

            setBoxLabelLink(link);
            setBoxLabelSops({
                study: SOPS[0],
                order: SOPS[1],
                cspl: SOPS[2],
                site: SOPS[3],
                boxes: collectionsReturns[index].boxes.label.slice(0, 1),
                boxIndex: 1
            });
            setShowBoxLabelModal(true);
        },
        aramexRef: {
            button1: () => {
                setShowUpdateWaybillModal(true);

            },
            button2: (obj) => {

                setShowUpdateAramexWaybillModal(true);
                setAddWaybillData(obj);
            },
        },
        waybill: {
            button1: (obj) => {

                setShowUpdateWaybillModal(true);
                setAddWaybillData(obj);
            },
            button2: async (obj, item, key) => {

                if (obj && obj.aramex && obj.aramex.received) {
                    window.open(obj.aramex.received);
                    return;
                }

                let orderNumber = '';
                if (obj && obj.sops) {
                    orderNumber = obj.sops.split(' ')[obj.sops.split(' ').length - 2].replace('R', '');
                }

                let waybill = '';

                if (obj && obj.waybill && obj.waybill.label) {
                    waybill = obj.waybill.label;
                }

                if (orderNumber) {
                    const res = await getReturnWaybill(orderNumber);

                    if (res && typeof res === 'string' && res !== "Order number doesn't exist" && res !== "Waybill not found") window.open(res);

                    if (res && typeof res === 'object' && res.data !== false && res !== "Order number doesn't exist") {
                        window.open(res.data);
                    } else {
                        window.open(`https://nservice.aramex.co.za/Home/GeneratePDFTemplateOne/?waybill_number=${waybill}`);
                    };
                    return;
                }

            }
        },
        receivedDate: (_, __, obj) => {

            setUpdateObject(obj);
            getReceiptPdf(obj.orderNumber).then(res => window.open(res));
        },
        confirmReceipt: (_, __, obj) => {
            setUpdateObject(obj);
            setShowConfirmReceipt(true);
        },
        delete: (_, __, obj) => {
            const { id } = obj;
            deleteItem('returns', { id }).then(res => fetchData());
        },
        edit: (_, __, obj) => {

            setDisplayOrder(true);
            setUpdateObject(obj);
        },
        gnNo: (_, __, obj) => {
            setShowGnNumber(true);
            setUpdateObject(obj);
        },
        confirm: (_, __, obj) => {

            if (obj && obj.orderNumber) {
                confirmReturn(obj.orderNumber).then(() => window.location.reload());;
            }
        },
        sendToDestruction: (_, __, obj) => {
            setUpdateObject(obj);
            setShowSend(true);
        }
    };

    return (
        <>{!displayOrder && <BaseTemplate title="Returns Archive">
            <div>
                <Search
                    setSearchQuery={setSearchQuery}
                    placeholder="Search"
                />
                <IonGrid>
                    <IonRow>
                        <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"} offset-md="1">
                            <IonItem className="shadow-xl">
                                <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                    Filter Type
                                </IonLabel>
                                <IonSelect interface="popover" value={filterType} onIonChange={e => setFilterType(e.detail.value)}>
                                    <IonSelectOption value={"none"}>
                                        None
                                    </IonSelectOption>
                                    <IonSelectOption value="Request Date">
                                        Request Date
                                    </IonSelectOption>
                                    <IonSelectOption value="Arrival Date">
                                        Arrival Date
                                    </IonSelectOption>
                                    <IonSelectOption value="Action">
                                        Action
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        {filterType !== 'none' && filterType !== "Action" && <>
                            <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                <IonItem
                                    className="shadow-xl"
                                    onClick={(e) => setShowStartDate({ showPopover: true, event: e })}
                                >
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        Start Date
                                    </IonLabel>
                                    <IonDatetime
                                        displayFormat="D MMM YYYY H:mm"
                                        min="1997"
                                        max="2021"
                                        value={selectedStartDate}
                                        readonly={true}
                                    ></IonDatetime>
                                </IonItem>
                            </IonCol>
                            <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                <IonItem
                                    className="shadow-xl"
                                    onClick={(e) => setShowEndDate({ showPopover: true, event: e })}
                                >
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        End Date
                                    </IonLabel>
                                    <IonDatetime
                                        displayFormat="D MMM YYYY H:mm"
                                        min="1997"
                                        max="2021"
                                        value={selectedEndDate}
                                        readonly={true}
                                    ></IonDatetime>
                                </IonItem>
                            </IonCol>
                        </>}
                        {filterType === "Action" &&
                            <IonCol size-xs="12" size-sm="12" size-md="2.5">
                                <IonItem
                                    className="shadow-xl"
                                >
                                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                                        Action
                                    </IonLabel>
                                    <IonSelect placeholder="Select action" interface="popover" value={filterByAction} okText="Okay" cancelText="Dismiss" onIonChange={e => setFilterByAction(e.target.value)}>
                                        {actions.map((a, i) => (
                                            <IonSelectOption key={i} value={a}>{a}</IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        }

                        <IonCol size-xs="12" size-sm="12" size-md={filterType !== 'none' ? "2.5" : "2.5"}>
                            <IonButton expand="full" style={{ height: '50px', marginTop: 0 }} onClick={() => exportArrayToExcel(excelArray(), `returns-archive-${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`)}>
                                export to excel
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {filterType !== 'none' && <IonRow>
                        <IonCol offset-md="1.05">
                            <span style={{ cursor: 'pointer', color: 'var(--ion-color-primary)' }} onClick={() => clearFilters()}>clear filters</span>
                        </IonCol>
                    </IonRow>}
                </IonGrid>
            </div>
            <div className="users" style={{ paddingBottom: "5em" }}>
                <IonGrid>
                    <IonRow style={{ boxShadow: "none" }}>
                        <IonCol offset="0" size="12">
                            {loading && <Loading loading={loading} />}
                            {!loading && (
                                <>
                                    {currentPageData && currentPageData.length > 0 ? <TableList
                                        headings={headings}
                                        items={currentPageData}
                                        labels={labels}
                                        functions={functions}
                                    /> : <h1>No search results</h1>}
                                </>
                            )}
                            <div style={{ margin: "1em 1em" }}>
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonAlert
                    isOpen={error !== ""}
                    onDidDismiss={() => setError("")}
                    header={"Error"}
                    message={error}
                    buttons={["OK"]}
                />
            </div>
            <IonDatetime ref={dateTimeRef} displayFormat="MM DD YYYY" placeholder="Select Date" style={{ visibility: 'hidden' }}></IonDatetime>
            <Modal showModal={showUpdateReferenceModal} setShowModal={setShowUpdateReferenceModal}>
                <UpdateReference obj={updateObject} originalNumber={updateReference} setShowModal={setShowUpdateReferenceModal} handleUpdateReference={handleUpdateReference} fetchData={fetchData} />
            </Modal>
            <Modal showModal={showBoxLabelModal} setShowModal={setShowBoxLabelModal}>
                <BoxLabel setShowModal={setShowBoxLabelModal} sops={boxLabelSops} link={boxLabelLink} />
            </Modal>
            <Modal showModal={showUpdateAramexWaybillModal} setShowModal={setShowUpdateAramexWaybillModal}>
                <AddManualAramexWaybill obj={addWaybillData} handleUpdateWaybill={() => { }} setShowModal={setShowUpdateAramexWaybillModal} fetchData={fetchData} />
            </Modal>
            <Modal showModal={showUpdateWaybillModal} setShowModal={setShowUpdateWaybillModal}>
                <AddManualWaybill obj={addWaybillData} handleUpdateWaybill={() => { }} setShowModal={setShowUpdateWaybillModal} fetchData={fetchData} />
            </Modal>
            <Modal showModal={showRequestDate} setShowModal={setShowRequestDate}>
                <RequestDate obj={updateObject} setShowModal={setShowRequestDate} fetchData={fetchData} />
            </Modal>
            <Modal showModal={showConfirmReceipt} setShowModal={setShowConfirmReceipt}>
                <ConfirmReceipt returnObject={updateObject} setShowModal={setShowConfirmReceipt} fetchData={fetchData} />
            </Modal>
            <Modal showModal={showGnNumber} setShowModal={setShowGnNumber}>
                <GnNumber returnObject={updateObject} setShowModal={setShowConfirmReceipt} fetchData={fetchData} />
            </Modal>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={"Alert"}
                message={alertMessage}
                buttons={["OK"]}
            />
        </BaseTemplate>}
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                position="bottom"
                duration={2000}
                color="success"
            />
            <IonAlert
                isOpen={showSend}
                onDidDismiss={() => setShowSend(false)}
                message={`Are you sure you want to send<br /> order ${updateObject && updateObject.orderNumber} to destructions? <br /> This will also confirm the return <br /> and move it to archives`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => setShowSend(false)
                    },
                    {
                        text: 'Send',
                        cssClass: '',
                        handler: () => {
                            if (updateObject && updateObject.orderNumber) {
                                addToDestructionList(updateObject);
                                // confirmReturn(updateObject.orderNumber, 'destruction');
                            }
                        }
                    }
                ]}
            />
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showStartDate.showPopover}
                event={showStartDate.event}
                onDidDismiss={() => setShowStartDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedStartDate(day.toString()); setShowStartDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowStartDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonPopover>
            <IonPopover
                cssClass="day-modal-picker"
                isOpen={showEndDate.showPopover}
                event={showEndDate.event}
                onDidDismiss={() => setShowEndDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedEndDate(day.toString()); setShowEndDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowEndDate({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonPopover>
            {displayOrder && <ReturnsProcessing returnObject={updateObject} setDisplayReturn={setDisplayOrder} fetchData={fetchData} />}</>
    );
}

export default ReturnsArchive;
