export default function isActive() {
    var time;
    
    // events
    window.onload = resetTime;
    window.onclick = resetTime;
    window.onkeypress = resetTime;
    window.ontouchstart = resetTime;
    window.onmousemove = resetTime;
    window.onmousedown = resetTime;
    window.addEventListener('scroll', () => {
        resetTime();
    }, true);

    function logUserOut() {
        window.sessionStorage.clear();
    }

    function resetTime() {
        clearTimeout(time);
        time = setTimeout(logUserOut, ((1000 * 60) * 60) * 2); // 10 seconds
    }

};