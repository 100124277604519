
const formulate = (refNo, returnObj, uniqueNo, desSkel) => {
    let update = desSkel ? true : false;
    let num = '01';

    desSkel =  desSkel ? desSkel : {
        listNumber: `D${String(new Date().getFullYear()).replace("20","")}-0${new Date().getMonth() + 1}_${num}`,
        orders: [
            
        ],
        boxes: '0',
        totalWeight: "",
        created: `${String(new Date().getFullYear())}-${new Date().getMonth()}-${new Date().getDate()} ${new Date().toLocaleTimeString('en-US', { hour12: false })}`,
        sent: {
            tooltip: 'Send for destruction',
            btnColor: 'warning',
            btnIcon: 'sendOutline',
        },
        sss: [

        ]
    };
    let index = 1;
    let hasAdded = false;
    desSkel.orders.forEach(o => {
        if(o.includes(refNo)) {
            index = Number(o.split(':')[1].trim()) + 1;
        }

        desSkel.orders = desSkel.orders.map(oo => {
            if(oo.includes(refNo)) { 
                hasAdded = true;
                return `${refNo} : ${index}`;
            } else { return oo; };
        }
        );
    });
    desSkel.sss.push(`${uniqueNo}: pending`);

    if(!hasAdded) {
        desSkel.orders.push(`${refNo} : ${index}`);
    }

    const returnObjWeight = 
    (returnObj.weight && typeof returnObj.weight === 'string') ? returnObj.weight : 
    (returnObj.weight.label && typeof returnObj.weight.label === 'string') ? returnObj.weight.label : '0kg';

    desSkel.totalWeight = (Number(desSkel.totalWeight.replace('kg', '')) + Number(returnObjWeight.replace('kg', ''))).toFixed() + 'kg';
    desSkel.boxes = (Number(desSkel.boxes) + Number(returnObj.boxes.label.split('(')[0])).toString();

    return { obj: desSkel, update };
}

//pass through returns reference number then add total of orders to index then add sss to arr
//update dl item

export default formulate;