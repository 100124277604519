import './FileUploadApi.css';

import { useState, useRef } from 'react';
import _ from 'lodash';
import SecondaryButton from '../inputs/SecondaryButton';
import PrimaryButton from '../inputs/PrimaryButton';
import { cloudUpload } from 'ionicons/icons';
import { displayAlert } from '../../utils';
import { IonGrid, IonCol, IonRow } from '@ionic/react';
import { uploadDocument, updateItem } from '../../apis/logictrials';

const FileUploadApi = ({ protocol, object, setEmailFiles, setShowModal, orderNumber, tableType }) => {
    const filesRef = useRef(null);
    const [files, setFiles] = useState([]);

    function handleFilesClick() {
        if (filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function handleFilesUpload(e) {
        if (e && e.target.files && e.target.files.length > 0) {
            [].forEach.call(e.target.files, (file) => {
                const reader = new FileReader();
                reader.onload = function () {
                    const tempFiles = files;

                    if (typeof reader.result === 'string') {
                        tempFiles.push({
                            fullName: file.name,
                            size_bytes: file.size,
                            isLoading: false,
                            content: reader.result
                        });

                        setFiles(tempFiles);
                        if (setEmailFiles) {
                            setEmailFiles(tempFiles);
                        }
                        displayAlert('success', 'Alert', 'File added')
                    }
                };
                reader.onerror = function () {
                };

                reader.readAsDataURL(file);
            })
        }
    }

    function removeFile(fileName) {
        let tempFiles = files.filter((f) => f.fullName !== fileName);

        setFiles(tempFiles);
    }

    function upload() {
        if (protocol === "inbound-sss" && files && files.length > 0) {
            files.forEach(async f => await uploadDocument(f.content.slice(28, f.content.length), orderNumber + '_' + f.fullName));
            let tempInbound = object;

            if (files.length === 1 && !object.sss.link) {
                tempInbound.sss = {
                    tooltip: "view",
                    label: "",
                    icon: "documentOutline",
                    link: `${process.env.REACT_APP_API_URL}/genpdf/${orderNumber + '_' + files[0].fullName}`
                };
            }

            if (files.length > 1 || (files.length === 1 && object.sss.link)) {
                let linksArr = [];
                files.forEach((f) => linksArr.push({ link: `${process.env.REACT_APP_API_URL}/genpdf/${orderNumber + '_' + f.fullName}`, fileName: orderNumber + '_' + f.fullName }));

                if (object.sss.link && Array.isArray(object.sss.link)) linksArr = [...object.sss.link, ...linksArr];

                if (object.sss.link && typeof object.sss.link === 'string') {
                    const nameArr = object.sss.link.split('/');

                    const fileName = nameArr[4];

                    linksArr = [{ link: object.sss.link, fileName }, ...linksArr];
                };

                const noDuplicates = _.uniqBy(linksArr, 'link');

                tempInbound.sss = {
                    tooltip: "view",
                    label: "",
                    icon: "documentOutline",
                    link: noDuplicates
                };
            }

            updateItem(tableType, ["data"], [tempInbound], { id: object.db_id }).then(() => {
                displayAlert('success', 'Success', 'Shipment has been updated successfully');
                setShowModal(false);
            });
        }

        if (protocol === "orders-sss" && files && files.length > 0) {
            files.forEach(async f => await uploadDocument(f.content.slice(28, f.content.length), orderNumber + '_' + f.fullName));
            let tempOrder = object;

            if (files.length === 1) {
                tempOrder.sss = {
                    tooltip: "view",
                    label: "",
                    icon: "documentOutline",
                    link: `${process.env.REACT_APP_API_URL}/genpdf/${orderNumber + '_' + files[0].fullName}`
                };
            }

            if (files.length > 1) {
                let linksArr = [];
                files.forEach((f) => linksArr.push({ link: `${process.env.REACT_APP_API_URL}/genpdf/${orderNumber + '_' + f.fullName}`, fileName: orderNumber + '_' + f.fullName }));

                tempOrder.sss = {
                    tooltip: "view",
                    label: "",
                    icon: "documentOutline",
                    link: linksArr
                };
            }

            updateItem(tableType, ["data"], [tempOrder], { id: object.db_id }).then(res => {
                displayAlert('success', 'Success', 'Order has been updated successfully');
                setShowModal(false);
            });
        }

        if (protocol === 'inbound-email') {
            console.log(files)
            setEmailFiles(files);
        }
    }

    return (
        <div className="documents-form" style={{ marginTop: '2em' }}>
            <div className="documents-form-container">
                <input style={{ visibility: 'hidden' }} multiple type="file" ref={filesRef} onChange={handleFilesUpload} accept=".xls,.xlsx,.doc,.docx,.txt,.pdf,.ppt,.pptx" />
                <SecondaryButton icon={{ svg: cloudUpload, slot: "start" }} text={protocol === "inbound-email" ? "Select files to send" : "Select files to upload"} width={"100%"} action={handleFilesClick} />
                {files && files.length > 0 ? <IonGrid className="upload-documents">
                    {files.map((f, i) => (
                        <IonRow key={i} className={"document-upload-item"}>
                            <IonCol size-md="1" size-xs="1" className="flex-align-center">
                                <img alt="doc_icon_small" className="doc-icon" src="/assets/icons/doc-icon-sml.svg" />
                            </IonCol>
                            <IonCol className="flex-align-center">
                                <p className="bold margin-none">{f.fullName}</p>
                            </IonCol>
                            <IonCol size-md="0.5" size-xs="1" className="flex-align-center cursor-pointer">
                                <img alt="close_icon" onClick={() => removeFile(f.fullName)} className="icon-16" src="/assets/icons/close.png" />
                            </IonCol>
                        </IonRow>
                    ))}
                </IonGrid> :
                    <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {protocol === "inbound-email" ? <h1>First click 'select files to send' and choose files from your computer that you would like to email to the contacts related to shipment </h1> : <h1>First click 'select files to upload' and select the related 'SSS' document to upload</h1>}
                    </div>}
                {files && files.length > 0 && <div style={{ height: 50 }} />}
                {protocol === "inbound-sss" && <PrimaryButton disabled={!files || files.length === 0} text="Upload files" action={upload} width={"100%"} />}
                {protocol === "orders-sss" && <PrimaryButton disabled={!files || files.length === 0} text="Upload files" action={upload} width={"100%"} />}
            </div>
        </div>
    );
};

export default FileUploadApi;