import React, { useState, useEffect } from 'react';
import  { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonItem, IonButton, IonTextarea } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';

export default function IWRSFollowup({updateReturn, setShowModal, showModal}) {
    const [confirm, setConfirm] = useState(false);
    const [comments, setComments] = useState('');

    useEffect(() => {
        console.log(showModal)
        if(showModal === false) {
            setConfirm(false);
            setComments('');
        }
    }, [showModal]);

    return (
        <div style={{marginTop: '5em'}}>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonTextarea value={comments} onIonChange={e => setComments(e.detail.value)} placeholder="Comments" />
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>
                                Confirm IWRS 
                            </IonLabel>
                            <IonCheckbox checked={confirm} onIonChange={e => setConfirm(e.detail.checked)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>

                    </IonCol>
                    <IonCol>
                        
                    </IonCol>
                    <IonCol style={{textAlign: 'right'}}>
                        <IonButton color="warning" onClick={() => {
                            let updated = updateReturn;
                            updated.IWRS = {
                                label: 
                                    updated.IWRS.label ? 
                                    `${updated.IWRS.label} Done ${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()+1}-${new Date().getDate()}` 
                                    : `Done ${String(new Date().getFullYear()).replace('20', '')}-${new Date().getMonth()}-${new Date().getDate()}`,
                                btnColor: "warning",
                                btnIcon: 'addOutline'
                            }
                            updated.iwrsConfirm = confirm;
                            updated.iwrsComments = comments;

                            if(updated && updated.parcels && typeof updated.parcels === 'string') {
                                updated.parcels = JSON.parse(updated.parcels);
                            }

                            updateItem('orders', ['data'], [updated], {id: updateReturn.id}).then(() => {
                            
                            setShowModal(false);
                        })}}>
                            Save
                        </IonButton>    
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
