import React, { useEffect, useState } from "react";

import Fuse from "fuse.js";
import ReactPaginate from "react-paginate";
import Search from "../../components/Search/Search";
import { IonGrid, IonCol, IonRow } from '@ionic/react';
import BaseTemplate from "../../components/base/BaseTemplate.js";
import TableList from '../../components/TableList/TableList';

import { getData } from '../../apis/logictrials';

import './SearchSitesStudies.css';
import Loading from "../../components/Loading.js";

import Modal from '../../components/Modal';
import NewSite from '../../components/NewSite';
import NewStudy from '../../components/NewStudy';

const PER_PAGE = 4;

// site

const headingsSite = [
  "sponsor", 
  "study",
  "number",
  "contact",
  "email",
  "tel",
  "investigator",
  "buildingBlockName",
  "streetName",
  "suburb",
  "city",
  "code",
  "specialDelivery",
  "view"
];

const labelsSite = [
  "Sponsor", 
  "Study",
  "Number",
  "Contact",
  "Email",
  "Tel",
  "Investigator",
  "Building/Block Name",
  "Street Name",
  "Suburb",
  "City",
  "Code",
  "Special Delivery",
  ""
];

// study

const headingsStudy = [
  "studyNumber",
  "sponsor",
  "contactName",
  "sponsorNumber",
  "contactEmail",
  "groupEmails",
  "view"
];

const labelsStudy = [
  "Study Number",
  "Sponsor",
  "Contact Name",
  "Sponsor Number",
  "Contact Email",
  "Group Emails",
  ""
];

export default function Dashboard() {
  // site vars
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResultsSite, setSearchResultsSite] = useState([]);
  const [sites, setSites] = useState([]);
  const [currentPageSite, setCurrentPageSite] = useState(0);
  const [loading, setLoading] = useState(true);
  const [editSite, setEditSite] = useState(null);
  const [showModalSite, setShowModalSite] = useState(false);

  // study vars
  const [searchResultsStudy, setSearchResultsStudy] = useState([]);
  const [studies, setStudies] = useState([]);
  const [currentPageStudy, setCurrentPageStudy] = useState(0);
  const [showModalStudy, setShowModalStudy] = useState(false);
  const [editStudy, setEditStudy] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  // fetch site & study data
  const fetchData = () => {
    getData('sites', 0, 9999).then(res => {
        const data = res.map(({ data, id }) => {
            let tempData = JSON.parse(data);
            tempData.id = id;
            return tempData;
        });
        setLoading(false);
        setSites(data);
        sessionStorage.setItem('lt-sites', JSON.stringify(data));
    });

    getData('study', 0, 9999).then(res => {
      const data = res.map(({ data, id }) => {
          let td = JSON.parse(data);

          td.id = id;

          return td;
      });
      setStudies(data);
      setLoading(false);
    });
  }
  
  // site search
  useEffect(() => {
    if(sites && sites.length > 0) {
        const sitesFuse = new Fuse(sites, {
            keys: [
                "sponsor",
                "study",
                "number",
                "contact",
                "email",
                "tel",
                "investigator",
                "buildingBlockName",
                "streetName",
                "suburb",
                "city",
                "code",
            ],
        });
        setSearchResultsSite(sitesFuse.search(searchQuery));
    }
  }, [searchQuery, sites]);

  const revisedSites =
  searchResultsSite.length > 0 ? searchResultsSite.map((item) => item.item) : sites;

  function handlePageClickSite({ selected: selectedPage }) {
      setCurrentPageSite(selectedPage);
  }

  const offsetSite = currentPageSite * PER_PAGE;

  const currentPageDataSite = revisedSites ? revisedSites.slice(offsetSite, offsetSite + PER_PAGE) : [];

  const pageCountSite = revisedSites ?  Math.ceil(revisedSites.length / PER_PAGE) : 1;

  // study search 

  useEffect(() => {
    const studiesFuse = new Fuse(studies, {
        keys: [
            "studyNumber",
            "sponsor",
            "contactName",
            "sponsorNumber",
            "contactEmail",
            "groupEmails",
        ],
    });
    setSearchResultsStudy(studiesFuse.search(searchQuery));
  }, [searchQuery, studies]);

  const studiesFuse =
    searchResultsStudy.length > 0 ? searchResultsStudy.map((item) => item.item) : studies;

  function handlePageClickStudy({ selected: selectedPage }) {
      setCurrentPageStudy(selectedPage);
  }

  const offsetStudy = currentPageStudy * PER_PAGE;

  const currentPageDataStudy = studiesFuse.slice(offsetStudy, offsetStudy + PER_PAGE);

  const pageCountStudy = Math.ceil(studiesFuse.length / PER_PAGE);

  return (
    <BaseTemplate title="Search Sites & Studies">
      <div>
        <Search
          setSearchQuery={setSearchQuery}
          placeholder="Search sites & studies"
        />
        {searchQuery && <IonGrid>
          <IonRow>
            <IonCol  offset="0.25" size="11.5">
              <h1 style={{fontSize: '1.5em', marginTop: '0.5em', marginBottom: '1em'}}>Site results</h1>
            </IonCol>
          </IonRow>
          <IonRow style={{ boxShadow: "none" }}>
              <IonCol offset="0.25" size="11.5">
              {loading && <Loading loading={loading} />}
              {!loading && (
                  <TableList
                  className="sites"
                      headings={headingsSite}
                      items={currentPageDataSite}
                      labels={labelsSite}
                      functions={{
                        sponsor: (obj) => {
                          
                          setEditSite(obj);
                          setShowModalSite(true);
                        },
                        view: (_, __, obj) => {
                          
                          setEditSite(obj);
                          setShowModalSite(true);
                        }
                      }}
                  />
              )}
              <div style={{ margin: "1em 1em" }}>
                  <ReactPaginate
                      previousLabel={"←"}
                      nextLabel={"→"}
                      pageCount={pageCountSite}
                      onPageChange={handlePageClickSite}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                  />
              </div>
              </IonCol>
          </IonRow>
        </IonGrid>}
        {searchQuery && <IonGrid>
          <IonRow>
            <IonCol offset="0.25" size="11.5">
              <h1 style={{fontSize: '1.5em', marginTop: '0.5em', marginBottom: '1em'}}>Study results</h1>
            </IonCol>
          </IonRow>
            <IonRow style={{ boxShadow: "none" }}>
                <IonCol offset="0.25" size="11.5">
                {loading && <Loading loading={loading} />}
                {!loading && (
                    <TableList
                        headings={headingsStudy}
                        items={currentPageDataStudy}
                        labels={labelsStudy}
                        functions={{
                          studyNumber: (obj) => {
                            setEditStudy(obj);
                            setShowModalStudy(true);
                          },
                          view: (_, __, obj) => {
                            setEditStudy(obj);
                            setShowModalStudy(true);
                          }
                        }}
                    />
                )}
                <div style={{ margin: "1em 1em" }}>
                    <ReactPaginate
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pageCountStudy}
                        onPageChange={handlePageClickStudy}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
                </IonCol>
            </IonRow>
          </IonGrid>}
      </div>

    {showModalSite && <Modal showModal={showModalSite} setShowModal={setShowModalSite} className="sites-modal">
        <NewSite editSite={editSite} setShowModal={setShowModalSite} fetchData={fetchData} setEditSite={setEditSite} />
    </Modal>}
    {showModalStudy && <Modal showModal={showModalStudy} setShowModal={setShowModalStudy} setEditStudy={setEditStudy} >
        <NewStudy editStudy={editStudy} setEditStudy={setEditStudy} />
    </Modal>}
    </BaseTemplate>
  );
}
