import axios from "axios";
import { format } from "date-fns";
// import logicTrialsInfo from '../data/logic-trials-info';
import { displayAlert, logout, checkTokens } from "../utils";

export const ltApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/dynamiccrud`,
});

export const ltApiLogin = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/auth`,
});

export const ltApiGen = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
});

export const ltApiAramex = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/aramex`,
});

ltApi.interceptors.request.use(
  async function (config) {
    const tokens = await checkTokens();

    if (tokens && tokens.accessToken) {
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ltApiGen.interceptors.request.use(
  async function (config) {
    await checkTokens();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getData = async (table, offset, limit) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table,
    limit: [offset, limit],
  };
  const res = await ltApi.post("/selectAllColumns", body, { headers });

  if (res.status === 200) {
    return res.data.reverse();
  }
};

export const getDataPerms = async (table, roleId) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const user = sessionStorage.getItem("lt-user");
  roleId = user ? JSON.parse(user).roleid : 0;

  const body = {
    role_id: roleId,
    column: table,
  };
  const res = await ltApi.post("/selectwithperms", body, { headers });

  if (res.status === 200) {
    return res.data.reverse();
  }
};

export const updateItemJson = async (table, columns, values, where) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table,
    column: "data",
    objectCol: columns,
    values,
    where,
  };
  const res = await ltApi.post("/jsonUpdate", body, { headers });

  if (res.status === 200 && res.data) {
    return res.data;
  }
};

export const updateItem = async (table, columns, values, where) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table,
    columns,
    values,
    where,
  };
  const res = await ltApi.post("/update", body, { headers });

  if (res.status === 200 && res.data) {
    return res.data;
  }
};

export const deleteItem = async (table, where) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table,
    where,
  };
  const res = await ltApi.post("/deleteit", body, { headers });

  if (res.status === 200 && res.data) {
    return res.data;
  }
};

export const addShipment = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "inbound",
    columns: ["sponsor_id", "data"],
    values: [data.sponsorId, data],
  };
  ltApi.post("/insert", body, { headers }).then((res) => {
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      const event = new Event("network_fail");
      event.msg = `failed to add shipment`;
      window.dispatchEvent(event);
      return false;
    }
  });
};

export const addDestruction = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "destruction",
    columns: ["data"],
    values: [data],
  };
  ltApi.post("/insert", body, { headers }).then((res) => {
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      const event = new Event("network_fail");
      event.msg = `failed to add shipment`;
      window.dispatchEvent(event);
      return false;
    }
  });
};

export const updateDestruction = async (object, where) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "destruction",
    columns: ["data"],
    values: [object],
    where,
  };
  const res = await ltApi.post("/update", body, { headers });

  if (res.status === 200 && res.data) {
    return res.data;
  }
};

export const addSponsor = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "sponsor",
    columns: ["name", "data"],
    values: [data.name, data],
  };

  const res = await ltApi.post("/insert", body, { headers });

  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    const event = new Event("network_fail");
    event.msg = `failed to add shipment`;
    window.dispatchEvent(event);
    return false;
  }
};

export async function generateOrderNumber(table) {
  const arr = await getData(table, 0, 9999);
  let num;
  if (Array.isArray) {
    num = findLatestNumber(arr, table);
  }
  let prefix = "";
  switch (table) {
    case "inbound": {
      prefix = "IN";
      break;
    }
    case "returns": {
      prefix = "R";
      break;
    }
    case "orders": {
      prefix = "";
      break;
    }
    case "destruction": {
      prefix = "D";
      break;
    }

    default:
      return false;
  }

  const currentMonth = new Date().getMonth() + 1;
  return `${prefix}${String(new Date().getFullYear()).replace("20", "")}-${
    String(currentMonth).length > 2 ? "0" + currentMonth : "" + currentMonth
  }_${num}`;
}

function findLatestNumber(arr, table) {
  let max = 0;
  let currentMonth = new Date().getMonth();
  currentMonth += 1;
  arr.forEach((a) => {
    if (table === "returns") {
      const month = Number(a.order_number.slice(4, 6));
      const num = Number(a.order_number.slice(7, 9));
      if (!isNaN(num) && !isNaN(month)) {
        if (month === currentMonth && num > max) {
          max = num;
        }
      }
    } else if (a.order_number && table !== "inbound") {
      const month = Number(a.order_number.slice(3, 5));
      const num = Number(a.order_number.slice(6, 8));
      if (!isNaN(num) && !isNaN(month)) {
        if (month === currentMonth && num > max) {
          max = num;
        }
      }
    } else if (table === "inbound") {
      const month = Number(a.inbound_id.slice(5, 7));
      const num = Number(a.inbound_id.slice(8, 10));
      if (!isNaN(num) && !isNaN(month)) {
        if (month === currentMonth && num > max) {
          max = num;
        }
      }
    } else {
      const month = Number(a.orderNumber.slice(4, 6));
      const num = Number(a.orderNumber.slice(7, 9));
      if (!isNaN(num) && !isNaN(month)) {
        if (month === currentMonth && num > max) {
          max = num;
        }
      }
    }
  });

  max++;
  max = max.toString();
  let a = "0" + max.toString();

  return max.length === 1 ? a : max;
}

export async function getNumberOfEntries(table) {
  const arr = await getData(table, 0, 9999);
  return arr.length;
}

export async function getItem(table, id) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table,
    columns: ["data"],
    where: {
      id,
    },
  };
  const res = await ltApi.post("/select", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}
export async function getAnything(table, column, id_name, id) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table,
    columns: [column],
    where: {
      [id_name]: id,
    },
  };
  const res = await ltApi.post("/select", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function getSponsorByName(name) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "sponsor",
    columns: ["data", "id"],
    where: {
      name,
    },
  };
  const res = await ltApi.post("/select", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function getSponsorById(id) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "sponsor",
    columns: ["data", "id"],
    where: {
      id,
    },
  };
  const res = await ltApi.post("/select", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function getStudyById(id) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "study",
    columns: ["data", "id"],
    where: {
      id,
    },
  };
  const res = await ltApi.post("/select", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function getStudyByStudyNumber(studyNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "study",
    columnsToView: ["data", "id"],
    column: "data",
    objectCol: ["studyNumber"],
    values: [studyNumber],
  };

  const res = await ltApi.post("/jsonSelect", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function getSite(siteNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "sites",
    columnsToView: ["data", "id"],
    column: "data",
    objectCol: ["number"],
    values: [siteNumber],
  };

  const res = await ltApi.post("/jsonSelect", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function viewFile(filename) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    filename,
  };

  const res = await ltApi.post("/uploadfile/viewfile", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export async function getSiteById(id) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "sites",
    columns: ["data", "id"],
    where: {
      id,
    },
  };

  const res = await ltApi.post("/select", body, { headers });

  if (res.status === 200) {
    return res.data;
  }
}

export const addOrder = async (data, comments) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await ltApi.post(
    "/insert",
    {
      table: "orders",
      columns: ["data", "comments"],
      values: [data, comments],
    },
    { headers }
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const addOrderArchiveFix = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await ltApi.post(
    "/insert",
    {
      table: "archive_orders",
      columns: ["order_number", "data"],
      values: ["22-06_97", data],
    },
    { headers }
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const addReturn = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await ltApi.post(
    "/insert",
    {
      table: "returns",
      columns: ["data"],
      values: [data],
    },
    { headers }
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const insertStudy = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "study",
    columns: ["sponsor_id", "name", "data"],
    values: [data.sponsorId, data.name, data],
  };
  ltApi.post("/insert", body, { headers }).then((res) => {
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      const event = new Event("network_fail");
      event.msg = `failed to add shipment`;
      window.dispatchEvent(event);
      return false;
    }
  });
};

export const insertSite = async (data) => {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: "sites",
    columns: ["name", "data"],
    values: [data.name, data],
  };
  const res = await ltApi.post("/insert", body, { headers });
  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    const event = new Event("network_fail");
    event.msg = `failed to add study`;
    window.dispatchEvent(event);
    return false;
  }
};

export async function getItemData(table, id) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    table: table,
    columns: ["data"],
    where: {
      id,
    },
  };
  ltApi.post("/select", body, { headers }).then((res) => {
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      const event = new Event("network_fail");
      event.msg = `failed to add shipment`;
      window.dispatchEvent(event);
      return false;
    }
  });
}

export async function sendEmailWithAttachment(
  files,
  subject,
  htmlTemplate,
  orderNumber
) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    files: files,
    subject,
    html: htmlTemplate,
    order_number: orderNumber,
  };

  ltApiGen
    .post("/uploadfiles/uploadpdfandemail", body, { headers })
    .then((res) => {
      if (res.status === 200 && res.data) {
        return res.data;
      } else {
        const event = new Event("network_fail");
        event.msg = `failed to add shipment`;
        window.dispatchEvent(event);
        return false;
      }
    });
}

export async function uploadDocument(fileBase64, filename) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    file: fileBase64,
    filename,
  };
  ltApiGen.post("/uploadfiles/uploadfile", body, { headers }).then((res) => {
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      const event = new Event("network_fail");
      event.msg = `failed to upload file`;
      window.dispatchEvent(event);
      return false;
    }
  });
}

// arrived vs dispatch
export async function getArrived() {
  const res = await ltApiGen.get("/ltfeatures/arrived");

  return res.data;
}

//num of dispatched

export async function getDispatched() {
  const res = await ltApiGen.get("/ltfeatures/arrived");

  return res.data;
}

export async function getPendingAor() {
  const res = await ltApiGen.get("/ltfeatures/peningaor");

  return res.data;
}

export async function todaysOrders() {
  const res = await ltApiGen.get("/ltfeatures/todaysorders");

  return res.data;
}

export async function outstandingReturns() {
  const res = await ltApiGen.get("/ltfeatures/outstandingreturns");

  return res.data;
}

export async function returnsEnroute() {
  const res = await ltApiGen.get("/ltfeatures/returnsEnroute");

  return res.data;
}

export async function enroutesInbound() {
  const res = await ltApiGen.get("/ltfeatures/enroutesinbound");

  return res.data;
}

export async function generatePdf(pdfType, orderNumber, siteName) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    pdfname: pdfType,
    headername: "logictrialstopheader",
    footername: "logictrialsfooterheader",
    fileoutputname: `${resolvePdfName(pdfType)}_${orderNumber}_${format(
      new Date(),
      "y-MM-d"
    )}`,
    bodyname: "custombody1",
    pdfformat: "A4",
    orientation: "portrait",
    headerheight: "0px",
    order_number: orderNumber,
    footerheight: "40px",
    sitename: siteName,
  };

  const res = await ltApiGen.post("/uploadfiles/genpdf", body, { headers });

  return res.data;
}
// uploadfiles/genpdf

function resolvePdfName(type) {
  switch (type) {
    case "confirmationofdestructionpdf":
      return "Confirmation_of_destruction";
    case "destructionsummerypdf":
      return "Destruction_Summary";
    case "destructionwaybillpdf":
      return "Destruction_Waybill";
    case "lineclearpdf":
      return "Line_Clearance_Checklist";
    case "logictrialsdispatchwaybillpdf":
      return "LogicTrials Waybill";
    case "logictrialscollectionpdf":
      return "LogicTrials Collection Waybill";
    default:
      return type;
  }
}

export async function inboundShipmentCompletedAlert(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    ordernumber: orderNumber,
  };
  await ltApiGen.post("/ltfeatures/inboundshipmentcompleted", body, {
    headers,
  });
}

export async function collectionWaybillAlert(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    ordernumber: orderNumber,
  };
  await ltApiGen.post("/ltfeatures/collectionwaybill", body, { headers });
}

export async function dispatchAlert(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    ordernumber: orderNumber,
  };
  await ltApiGen.post("/ltfeatures/dispatchalert", body, { headers });
}

export async function inboundFreightPreAlert(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    ordernumber: orderNumber,
  };
  await ltApiGen.post("/ltfeatures/inboundfrieghtprealert", body, { headers });
}

export async function getOrderWaybill(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    order_number: orderNumber,
  };
  const res = await ltApiGen.post("/uploadfiles/vieworderwaybill", body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function confirmInbound(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    order_number: orderNumber,
  };

  try {
    const res = await ltApi.post("/inboundcompletion", body, { headers });

    if (res.data && res.data === "Successful") {
      displayAlert(
        "success",
        "Success",
        "Inbound confirmed and moved to archives"
      );
      return;
    }
  } catch {
    displayAlert("danger", "Danger", "Inbound confirmation failed");
    return;
  }
}

export async function confirmOrder(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    order_number: orderNumber,
  };

  try {
    const res = await ltApi.post("/ordercompletion", body, { headers });

    if (res.data && res.data === "Successful") {
      displayAlert(
        "success",
        "Success",
        "Order confirmed and moved to archives"
      );
      return;
    }
  } catch {
    displayAlert("danger", "Danger", "Order confirmation failed");
    return;
  }
}

export async function confirmReturn(orderNumber, isDestruction) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    order_number: orderNumber,
  };

  try {
    const res = await ltApi.post("/returncompletion", body, { headers });

    if (res.data && res.data === "Successful") {
      if (!isDestruction)
        displayAlert(
          "success",
          "Success",
          "Return confirmed and moved to archives"
        );

      if (isDestruction)
        displayAlert(
          "success",
          "Success",
          "Return has been moved to destruction list"
        );

      return;
    }
  } catch {
    displayAlert("danger", "Danger", "Return confirmation failed");
    return;
  }
}

export async function getReturnWaybill(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    order_number: orderNumber,
  };
  const res = await ltApiGen.post("/uploadfiles/viewreturnorderwaybill", body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function getInboundWaybill(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    order_number: orderNumber,
  };
  const res = await ltApiGen.post(
    "/uploadfiles/viewinboundorderwaybill",
    body,
    { headers }
  );

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function getBoxTypes() {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const res = await ltApi.get("/boxdata", { headers });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function logIt(message, data) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const body = {
    msg: message,
    dataused: data,
  };
  const res = await ltApi.post("/logit", body, { headers });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function getOrdersManifesto() {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await ltApiGen.get("/uploadfiles/ordermanifest", { headers });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function getReceiptPdf(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    order_number: orderNumber,
  };

  const res = await ltApiGen.post("/uploadfiles/receiptdocument", body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function addUser(body) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await ltApi.post("/createuser", body, { headers });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

//returns interhouse/external

export async function globalSearch(query) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    value: query,
  };

  const res = await ltApi.post("/globalsearch", body, { headers });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function getAccessToken(token) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    refreshToken: token,
  };

  try {
    const res = await ltApiLogin.post("/refresh", body, { headers });

    if (res.data) {
      if (res.data.accessToken)
        sessionStorage.setItem("lt-access-token", res.data.accessToken);
      if (res.data.refreshToken)
        sessionStorage.setItem("lt-refresh-token", res.data.refreshToken);

      return Promise.resolve({
        accessToken: res.data.accessToken,
        refreshToken: res.data.refreshToken,
      });
      // return res.data;
    } else {
      if (res.accessToken)
        sessionStorage.setItem("lt-access-token", res.data.accessToken);
      if (res.refreshToken)
        sessionStorage.setItem("lt-refresh-token", res.data.refreshToken);
      // return res;

      return Promise.resolve({
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
      });
    }

    // window.location.reload();
  } catch {
    logout();
  }
}

// OLD
export function resetPassword(userId) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  ltApi.put(
    `/passwordreset`,
    {
      user_id: userId,
    },
    { headers }
  );
}

// NEW

export async function changePassword(id, password, confirmPassword) {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/v1/dynamiccrud/pwdchange`,
    {
      id,
      password: password,
      confirm_password: confirmPassword,
    }
  );

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function forgotPassword(email) {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/v1/dynamiccrud/forgotpwd`,
    {
      email,
    }
  );

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function passwordChangeTokenCheck(id, token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/dynamiccrud/pwdlinkcheck/${id}/${token}`
  );

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function uploadDestructionPdf(fileBase64, fileName, orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    fileArray: [
      {
        file: fileBase64,
        filename: fileName,
      },
    ],
    orderNumber: orderNumber,
  };

  const res = await ltApiGen.post(`/uploadfiles/uploaddestructionpdf`, body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function viewDestructionPdf(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    order_number: orderNumber,
  };

  const res = await ltApiGen.post(`/uploadfiles/viewdestructionpdf`, body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function getCurrencyConversion(source, convertTo, amount) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    source: source,
    convertTo: convertTo,
    amount: amount,
  };
  try {
    const res = await ltApi.post(`/getrate`, body, { headers });
    if (res && res.data && res.status && res.status === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error;
  }
}

export async function retryAramexOrders(orderNumber) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {};

  const res = await ltApi.put(`/retryorderwaybill/${orderNumber}`, body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}

export async function retryAramexReturns(orderNumber, pickupdate) {
  const accessToken = sessionStorage.getItem("lt-access-token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    pickupdate: pickupdate ? pickupdate : null,
  };

  const res = await ltApi.put(`/retryreturnwaybill/${orderNumber}`, body, {
    headers,
  });

  if (res.data) {
    return res.data;
  } else {
    return res;
  }
}
