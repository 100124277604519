import React, { Component } from 'react'

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { uploadDocument } from '../../apis/logictrials';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Our app
class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: [
      ],
      getBase64: (file, cb) => {
         
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
             
        };
    },
    hasPosted: false
    };
  }

  componentDidUpdate() {
    if(!this.state.hasPosted && this.state.files['0']) {
      this.state.getBase64(this.state.files['0'], (res) => {
        if(this.props.setFile) {
          this.props.setFile({
            base64: res,
            name: this.state.files['0']['name']
          });
        }
        if(this.props.type !== 'email') {
          uploadDocument(res.slice(28, res.length), this.state.files['0']['name'])
          this.setState({hasPosted: true})
        }
      });
    }
  }

  handleUpdateFiles = (fileItems) => {
      if(this.state.files['0']) {
        const fileName = this.state.files['0']['name'];
        if(this.props.setFileName) {
          this.props.setFileName(`https://eu.backendlessappcontent.com/DF581537-3C86-C31C-FF99-0B284E9BC100/BB672241-7A96-4330-BF71-0F536C79238F/files/docs/${fileName}?overwrite=true`)
        }
      }
    this.setState({
      files: fileItems.map(fileItem => fileItem.file)
    });
  }

  render() {
    const fileName = this.state.files.length > 0 ? this.state.files[this.state.files.length - 1].name : 'doc.pdf';

    return (
      <div style={{marginTop: '2em'}}>
        <FilePond
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          allowMultiple={true}
          allowReorder={true}
          maxFiles={3}
          server={`https://eu.backendlessappcontent.com/DF581537-3C86-C31C-FF99-0B284E9BC100/BB672241-7A96-4330-BF71-0F536C79238F/files/docs/${fileName}?overwrite=true`}
          name="docs"
          labelIdle={this.props.placeholder ? this.props.placeholder : 'Drag & Drop your SSS document or click browse to upload the document'}
          onupdatefiles={this.handleUpdateFiles}
        />
    </div>
    );
  }
}

export default FileUpload;