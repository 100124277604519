import React, { useState } from 'react';

import { IonGrid, IonCol, IonRow, IonModal, IonButton, IonInput, IonIcon } from '@ionic/react';
import { isValidDate, displayAlert } from '../../utils';
import { updateItem, uploadDestructionPdf, viewDestructionPdf } from '../../apis/logictrials';

import { cloudUploadOutline } from 'ionicons/icons';

import { eye } from 'ionicons/icons';

import DayPicker from 'react-day-picker';
import { format } from 'date-fns';

import DocumentUpload from '../document-handling/DocumentUpload';

export default function ConfirmDestructionDetails({ obj, setShowModal, fetchData }) {
    console.log(obj);

    const returnItems = obj && obj.sss && Array.isArray(obj.sss) && obj.sss;

    const [dateDestroyed, setDateDestroyed] = useState('');
    const [certificateNumber, setCertificateNumber] = useState('');
    const [returnId, setReturnId] = useState('');
    const [file, setFile] = useState('');

    const [viewReturnConfirmationDetails, setViewReturnConfirmationDetails] = useState(false);
    const currentUploads = (obj && obj.uploadedDocs && Array.isArray(obj.uploadedDocs)) ? obj.uploadedDocs : [];

    const [showSelectDate, setShowSelectDate] = useState({ showPopover: false, event: undefined });

    async function handleSubmit() {

        if (!certificateNumber) {
            displayAlert('warning', 'No certificate number has been entered', 'Please enter a certificate number to continue.');
            return;
        }

        if (!dateDestroyed) {
            displayAlert('warning', 'No date of destruction has been selected', 'Please enter the date of destruction to continue.');
            return;
        }

        if (!file) {
            displayAlert('warning', 'No certificate has been uploaded', 'Please upload a certificate to continue.');
            return;
        }

        const sendFileData = file && file.file_data;
        const sendFileName = file && file.file_name;

        uploadDestructionPdf(sendFileData, sendFileName, returnId).then(res => {

            if (res === 'fileSaved' && obj && obj.id) {
                const tempObj = obj;

                const tempCompletion = {
                    dateDestroyed: dateDestroyed && isValidDate(dateDestroyed) ? new Date(dateDestroyed).toISOString() : dateDestroyed,
                    certificateNumber,
                    returnId
                };

                tempObj.hasUploadedDocs = true;
                tempObj.uploadedDocs = (tempObj.uploadedDocs && Array.isArray(tempObj.uploadedDocs)) ? [...tempObj.uploadedDocs, returnId] : [returnId];
                tempObj.completions = (tempObj.completions && Array.isArray(tempObj.completions)) ? [...tempObj.completions, tempCompletion] : [tempCompletion];

                if (tempObj.sss && tempObj.completions && Array.isArray(tempObj.completions) && Array.isArray(tempObj.sss) && (tempObj.completions.length === tempObj.sss.length)) {
                    tempObj.confirmDestruction = {
                        btnIcon: 'reloadOutline',
                        btnColor: "danger",
                        tooltip: "Retry confirmation"
                    };
                }

                updateItem('destruction', ['data'], [tempObj], { id: tempObj.id }).then(res => {
                    //  success case
                    if (res && res.statusCode === 'Affected Rows: 1') {
                        fetchData();
                        setShowModal(false);
                    }
                });
            }
        });

        setShowModal(false);
    }

    function handleViewCertificateClick(r) {
        const returnId = getReturnId(r);

        viewDestructionPdf(returnId).then(res => {
            console.log(res);

            if (res && res[0] && res[0].fileBuffer) {
                let certificate = res[0].fileBuffer;

                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>${res[0].filename}</title>
                    </head>
                    <body style="height:100vh;width:100vw;">
                        <iframe width='100%' height='100%' src='${certificate}'></iframe>
                    </body>
                    </html>`
                );
                window.open(certificate);
            }
        });
    }

    function getReturnId(r) {
        if (r && (typeof r === 'string')) {
            if (r.includes(':')) return r.split(':')[0];

            if (!r.includes(':')) return r;

        }

        return '';
    }

    function onViewDataClick(r) {
        const rId = getReturnId(r);

        if (rId) {
            const details = obj && obj.completions && obj.completions.find(({ returnId }) => returnId === rId);

            console.log(details, obj.completions);

            if (details && details.certificateNumber && details.returnId && details.dateDestroyed) {
                setViewReturnConfirmationDetails({ ...details, returnId: rId });

                return;
            }

            return;
        }

        return;
    }

    function handleReturnViewClick(r) {
        if (r && (typeof r === 'string')) {
            if (r.includes(':')) setReturnId(r.split(':')[0]);

            if (!r.includes(':')) setReturnId(r);

        }

        return '';
    }

    function getReturnId(r) {
        if (r && (typeof r === 'string')) {
            if (r.includes(':')) return r.split(':')[0];

            if (!r.includes(':')) return r;

        }

        return '';
    }

    return (
        <>
            {!returnId && <IonGrid className="mt-10">
                {returnItems && !viewReturnConfirmationDetails && returnItems.map(r =>
                (
                    <IonRow>
                        <IonCol size="6" className="flex items-center">
                            {currentUploads.includes(getReturnId(r)) ?
                                <p onClick={() => onViewDataClick(r)} style={{ color: 'var(--ion-color-primary)' }} className="underline cursor-pointer">{getReturnId(r)}</p> :
                                <p className="text-red-500">{getReturnId(r)}</p>
                            }
                        </IonCol>
                        <IonCol size="4.6">
                            {r && obj.uploadedDocs && !obj.uploadedDocs.includes(getReturnId(r)) ? <IonButton className="m-0 p-0 float-right" color="success" onClick={() => handleReturnViewClick(r)}>
                                Add Certificate
                            </IonButton> :
                                <IonButton onClick={() => handleReturnViewClick(r)} className="m-0 p-0 float-right" color="warning">
                                    Re-Add Certificate
                                </IonButton>
                            }
                        </IonCol>
                        <IonCol size="1.4">
                            {currentUploads.includes(getReturnId(r)) && <IonButton className="m-0 p-0 float-right" color="primary" onClick={() => handleViewCertificateClick(r)}>
                                <IonIcon icon={eye} />
                            </IonButton>}
                        </IonCol>
                    </IonRow>
                )
                )}
            </IonGrid>}
            {returnId && (
                <>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={() => setReturnId(null)} color="success" size="small">
                                    Back
                                    {/* <IonIcon icon={cloudUploadOutline} slot="end" /> */}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <h2 className="font-bold">Confirm Destruction Details for {returnId}</h2>
                                <div className="h-5" />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonInput value={certificateNumber} onIonChange={e => setCertificateNumber(e.detail.value)} placeholder="Certificate Number" />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonInput onClick={(e) => setShowSelectDate({ showPopover: true, event: e })} placeholder="Date Destroyed" value={dateDestroyed ? format(new Date(dateDestroyed), 'MM d y') : ''} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <DocumentUpload setFile={setFile}>
                                    <IonButton color="success" expand="full">
                                        {!file ? <>Upload Certificate <IonIcon icon={cloudUploadOutline} slot="end" /></> : file.file_name}
                                    </IonButton>
                                </DocumentUpload>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton onClick={handleSubmit} color="success" expand="full">
                                    Submit
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonModal
                        cssClass="day-modal-picker"
                        isOpen={showSelectDate.showPopover}
                        event={showSelectDate.event}
                        onDidDismiss={() => setShowSelectDate({ showPopover: false, event: undefined })}
                    >
                        <DayPicker
                            canChangeMonth={true}
                            onDayClick={(day) => {
                                setDateDestroyed(day.toString());
                                setShowSelectDate({ showPopover: false, event: undefined });
                            }}
                        // disabledDays={[{ daysOfWeek: [0, 6] }, {after: nextWeek, before: new Date()}]} 
                        />
                        <IonButton onClick={() => setShowSelectDate({ showPopover: false, event: undefined })}>Close</IonButton>
                    </IonModal>
                </>
            )}
            {returnItems && viewReturnConfirmationDetails && !returnId && (
                <IonGrid>
                    <IonRow className="mb-10">
                        <IonCol>
                            <IonButton onClick={() => setViewReturnConfirmationDetails(false)} color="success" size="small">
                                Back
                                {/* <IonIcon icon={cloudUploadOutline} slot="end" /> */}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="w-full">
                        <div className="w-full grid grid-cols-2 justify-items-stretch">
                            {viewReturnConfirmationDetails && viewReturnConfirmationDetails.returnId && <>
                                <h4 className="font-bold">Certificate Number</h4>
                                <h4 className="font-light">{viewReturnConfirmationDetails.certificateNumber}</h4>
                                <h4 className="font-bold">Date Destroyed</h4>
                                <h4 className="font-light">{isValidDate(new Date(viewReturnConfirmationDetails.dateDestroyed)) && format(new Date(viewReturnConfirmationDetails.dateDestroyed), 'dd-MM-yyyy hh:MM')}</h4>
                            </>}
                        </div>
                        <div className="h-10" />
                        <IonButton onClick={() => handleViewCertificateClick(viewReturnConfirmationDetails && viewReturnConfirmationDetails.returnId)} expand="full" color="tertiary">
                            View Certificate
                        </IonButton>
                    </div>
                </IonGrid>
            )}
        </>
    )
}
