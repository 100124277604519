import React, { useState, useEffect } from 'react';
import { IonGrid, IonCol, IonRow, IonInput, IonSelect, IonSelectOption, IonButton } from '@ionic/react';
import currencies from '../../data/currency';

import { getCurrencyConversion } from '../../apis/logictrials';
import axios from 'axios';
import { set } from 'lodash';

export default function CurrencyConvertor({ addZarValue, zarValueIndex, setShowModal, setZarValue, updateObject, defaultValue }) {
    const [currencyFrom, setCurrencyFrom] = useState('CHF');
    const [currencyTo, setCurrencyTo] = useState('ZAR');
    const [valueFrom, setValueFrom] = useState(0);
    const [valueTo, setValueTo] = useState(defaultValue ? Number(defaultValue.replace('R', '')) : 0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [active, setActive] = useState('from');
    const [error, setError] = useState("");
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Set a new timeout
        if (valid(valueFrom)) {
            const newTimeoutId = setTimeout(() => {
                try {
                    if (active === 'from' && currencyFrom && currencyTo && valueFrom) {
                        getCurrencyConversion(currencyFrom, currencyTo, valueFrom).then(res => {
                            if (res && res.result && res.result.success && res.result.success === true) {
                                setValueTo(Math.round(res.result.result * 100) / 100);
                                setError("");
                            } else {
                                setValueTo(0);
                                setError("Error converting currency");
                            }
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
            }, 500); // 500 milliseconds delay

            // Save the new timeout reference
            setTimeoutId(newTimeoutId);

            // Cleanup function to clear timeout on unmount or when dependencies change
            return () => {
                clearTimeout(newTimeoutId);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueFrom, currencyFrom, currencyTo]);

    const valid = (value) => {
        //let numberRegex = /^[0-9,]+(\.[0-9]{1,12})?$/;
        let numberRegex = /^\d+(\.\d+)?$/;
        if (value && !value.match(numberRegex)) {
            setError("Please enter a valid number");
            return false;
        }
        // if (value && value.match(numberRegex) && Number(value) <= 0) {
        //     setError("Please enter a number greater than 0");
        //     return false;
        // }
        setError("");
        return true;
    }

    // useEffect(() => {
    //         if(active === 'to') {
    //             getCurrencyConversion(valueFrom, currencyFrom, currencyTo).then(res => {
    //                 console.log(res);
    //                 if(res && res.result) {
    //                     setValueFrom(res.result);
    //                 }
    //             });
    //         }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [valueTo, currencyFrom, currencyTo, active]);
    const uniqueCurrencies = Array.from(new Set(currencies.map(c => c.abbrev)))
        .map(abbrev => {
            return currencies.find(c => c.abbrev === abbrev);
        });

    return (
        <div style={{ marginTop: '10%' }}>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonInput onFocus={() => setActive('from')} value={valueFrom} onIonChange={e => setValueFrom(e.detail.value)} />
                    </IonCol>
                    <IonCol>
                        <IonSelect interface="popover" value={currencyFrom} onIonChange={e => setCurrencyFrom(e.detail.value)}>
                            {uniqueCurrencies.map(c => (
                                <IonSelectOption key={c.abbrev} value={c.abbrev}>{c.name}</IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonInput onFocus={() => setActive('to')} value={valueTo} />
                    </IonCol>
                    <IonCol>
                        <IonSelect interface="popover" value={currencyTo} onIonChange={e => setCurrencyTo(e.detail.value)}>
                            {uniqueCurrencies.map(c => (
                                <IonSelectOption key={c.abbrev} value={c.abbrev}>{c.name}</IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonCol>
                </IonRow>
                {error && <IonRow>
                    <IonCol>
                        <p style={{ color: 'red' }}>{error}</p>
                    </IonCol>
                </IonRow>}
                <IonRow>
                    <IonCol>
                        <IonButton
                            style={{ marginTop: '100px' }}
                            expand="full"
                            color="success"
                            onClick={() => {
                                setZarValue ? setZarValue(String('R' + valueTo.toFixed(2))) : addZarValue(updateObject, String('R' + valueTo.toFixed(2)));
                                setShowModal(false);
                            }}
                        //When we have a set api that works 
                        //disabled={error && valueFrom === 0 ? true : false}
                        >
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
