import React, { useEffect, useState } from "react";

import Fuse from "fuse.js";
import ReactPaginate from "react-paginate";
import Search from "../../components/Search/Search";
import { IonGrid, IonCol, IonRow, IonIcon, IonSpinner } from '@ionic/react';
import BaseTemplate from "../../components/base/BaseTemplate.js";
import PieChart from '../../components/PieChart.js';
import TableList from '../../components/TableList/TableList';

import { arrowUndoOutline, alertOutline } from 'ionicons/icons';

import { getArrived, getPendingAor, outstandingReturns, enroutesInbound, getData } from '../../apis/logictrials';

import './Dashboard.css';
import Loading from "../../components/Loading.js";
import useAsyncEffect from "use-async-effect";

import Modal from '../../components/Modal';
import NewSite from '../../components/NewSite';
import NewStudy from '../../components/NewStudy';

const PER_PAGE = 4;

// site

const headingsSite = [
  "sponsor", 
  "study",
  "number",
  "contact",
  "email",
  "tel",
  "investigator",
  "buildingBlockName",
  "streetName",
  "suburb",
  "city",
  "code",
  "specialDelivery",
  "view"
];

const labelsSite = [
  "Sponsor", 
  "Study",
  "Number",
  "Contact",
  "Email",
  "Tel",
  "Investigator",
  "Building/Block Name",
  "Street Name",
  "Suburb",
  "City",
  "Code",
  "Special Delivery",
  ""
];

// study

const headingsStudy = [
  "studyNumber",
  "sponsor",
  "contactName",
  "sponsorNumber",
  "contactEmail",
  "groupEmails",
  "view"
];

const labelsStudy = [
  "Study Number",
  "Sponsor",
  "Contact Name",
  "Sponsor Number",
  "Contact Email",
  "Group Emails",
  ""
];

export default function Dashboard() {
  const [arrived, setArrived] = useState();
  const [pendingAor, setPendingAor] = useState();
  const [outstanding, setOutstanding] = useState();
  const [enroutesI, setEnroutesI] = useState();

  // site vars
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResultsSite, setSearchResultsSite] = useState([]);
  const [sites, setSites] = useState([]);
  const [currentPageSite, setCurrentPageSite] = useState(0);
  const [loading, setLoading] = useState(true);
  const [editSite, setEditSite] = useState(null);
  const [showModalSite, setShowModalSite] = useState(false);

  // study vars
  const [searchResultsStudy, setSearchResultsStudy] = useState([]);
  const [studies, setStudies] = useState([]);
  const [currentPageStudy, setCurrentPageStudy] = useState(0);
  const [showModalStudy, setShowModalStudy] = useState(false);
  const [editStudy, setEditStudy] = useState(null);

  useAsyncEffect(async () => {
    fetchData();
    const a = await getArrived();

    setArrived(a);

    getPendingAor().then(res => {
      setPendingAor(res);
    });

    outstandingReturns().then(res => {
      setOutstanding(res);
    });

    enroutesInbound().then(res => {
      setEnroutesI(res);
      setLoading(false);
    });
    
  }, []);

  // fetch site & study data
  const fetchData = () => {
    getData('sites', 0, 9999).then(res => {
        const data = res.map(({ data, id }) => {
            let tempData = JSON.parse(data);
            tempData.id = id;
            return tempData;
        });
        setSites(data);
    });

    getData('study', 0, 9999).then(res => {
      const data = res.map(({ data, id }) => {
          let td = JSON.parse(data);

          td.id = id;

          return td;
      });
      setStudies(data);
    });
  }
  
  // site search
  useEffect(() => {
    if(sites && sites.length > 0) {
        const sitesFuse = new Fuse(sites, {
            keys: [
                "sponsor",
                "study",
                "number",
                "contact",
                "email",
                "tel",
                "investigator",
                "buildingBlockName",
                "streetName",
                "suburb",
                "city",
                "code",
            ],
        });
        setSearchResultsSite(sitesFuse.search(searchQuery));
    }
  }, [searchQuery, sites]);

  const revisedSites =
  searchResultsSite.length > 0 ? searchResultsSite.map((item) => item.item) : sites;

  function handlePageClickSite({ selected: selectedPage }) {
      setCurrentPageSite(selectedPage);
  }

  const offsetSite = currentPageSite * PER_PAGE;

  const currentPageDataSite = revisedSites ? revisedSites.slice(offsetSite, offsetSite + PER_PAGE) : [];

  const pageCountSite = revisedSites ?  Math.ceil(revisedSites.length / PER_PAGE) : 1;

  // study search 

  useEffect(() => {
    const studiesFuse = new Fuse(studies, {
        keys: [
            "studyNumber",
            "sponsor",
            "contactName",
            "sponsorNumber",
            "contactEmail",
            "groupEmails",
        ],
    });
    setSearchResultsStudy(studiesFuse.search(searchQuery));
  }, [searchQuery, studies]);

  const studiesFuse =
    searchResultsStudy.length > 0 ? searchResultsStudy.map((item) => item.item) : studies;

  function handlePageClickStudy({ selected: selectedPage }) {
      setCurrentPageStudy(selectedPage);
  }

  const offsetStudy = currentPageStudy * PER_PAGE;

  const currentPageDataStudy = studiesFuse.slice(offsetStudy, offsetStudy + PER_PAGE);

  const pageCountStudy = Math.ceil(studiesFuse.length / PER_PAGE);

  return (
    <BaseTemplate>
      <div className="invisible h-0 m-0 p-0">
        <Search
          setSearchQuery={setSearchQuery}
          placeholder="Search sites & studies"
        />
      </div>
      {!loading && <div className="relative w-full dashboard">
        {/* Header */}
        <div className="relative md:pt-12 pb-32 pt-2">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              {/* Card stats */}
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white-1000 rounded mb-6 xl:mb-0 shadow-lg" style={{backgroundColor: '#ffffff', opacity: 1}}>
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Awaiting Arrival
                          </h5>
                          <span className="font-semibold text-xl text-gray-800 pt-4 inline-block">
                            {arrived ? arrived.notarrived : ''}
                          </span>
                        </div>
                        <div className="relative w-auto pl-4 flex-initial">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full" style={{backgroundColor: 'var(--ion-color-danger)'}}>
                            <IonIcon icon={alertOutline} />
                          </div>
                        </div>
                      </div>
                        <p className="text-sm text-gray-500 mt-4">
                        <span className="text-red-500 mr-2">
                        </span>
                        <span className="whitespace-no-wrap">

                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            AOR's incomplete
                          </h5>
                          <span className="font-semibold text-xl text-gray-800 pt-4 inline-block">
                            {pendingAor ? (pendingAor.notdone) : <Loading loading={pendingAor ? true : false} />}
                          </span>
                        </div>
                        <div className="relative w-auto pl-4 flex-initial">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full" style={{backgroundColor: 'var(--ion-color-danger)'}}>
                            <IonIcon icon={alertOutline} />
                          </div>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500 mt-4">
                        <span className="text-red-500 mr-2">

                        </span>
                        <span className="whitespace-no-wrap">

                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Inbounds Enroute
                          </h5>
                          <span className="font-semibold text-xl text-gray-800 pt-4 inline-block">
                            {enroutesI ? enroutesI : ''}
                          </span>
                        </div>
                        <div className="relative w-auto pl-4 flex-initial">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full" style={{backgroundColor: 'var(--ion-color-success)'}}>
                            <IonIcon icon={arrowUndoOutline} />
                          </div>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500 mt-4">
                        <span className="text-orange-500 mr-2">

                        </span>
                        <span className="whitespace-no-wrap">

                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-gray-500 uppercase font-bold text-xs">
                            Outstanding Returns
                          </h5>
                          <span className="font-semibold text-xl text-gray-800 pt-4 inline-block">
                            {outstanding && typeof outstanding === 'number' ? outstanding : ''}
                          </span>
                        </div>
                        <div className="relative w-auto pl-4 flex-initial">
                          <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full" style={{backgroundColor: 'var(--ion-color-danger)'}}>
                            <IonIcon icon={alertOutline} />
                          </div>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500 mt-4">
                        <span className="text-green-500 mr-2">

                        </span>
                        <span className="whitespace-no-wrap">

                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            {arrived && pendingAor && <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <PieChart className="" pieid="1" title="Arrived" data={arrived ? [arrived.arrived, arrived.notarrived] : []} labels={['arrived', 'not arrived']} />
                </IonCol>
                <IonCol size="6">
                  <PieChart className="" pieid="2" title="Aor's Incomplete vs Complete" data={pendingAor ? [pendingAor.total, pendingAor.notdone] : []} labels={['complete', 'incomplete']}/>
                </IonCol>
              </IonRow>
            </IonGrid>}
            {showModalSite && <Modal showModal={showModalSite} setShowModal={setShowModalSite} className="sites-modal">
                <NewSite editSite={editSite} setShowModal={setShowModalSite} fetchData={fetchData} setEditSite={setEditSite} />
            </Modal>}
            {showModalStudy && <Modal showModal={showModalStudy} setShowModal={setShowModalStudy} setEditStudy={setEditStudy} >
                <NewStudy editStudy={editStudy} setEditStudy={setEditStudy} />
            </Modal>}
      </div>}
      {loading && <div style={{width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <IonSpinner />
      </div>}
    </BaseTemplate>
  );
}
