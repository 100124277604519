import React, { useState } from 'react';
import  { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonItem, IonButton } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';
import { useHistory } from 'react-router-dom';

export default function ConfirmArrivalInbound({obj, fetchData, setShowModal}) {
    const [checked, setChecked] = useState();
    const history = useHistory();

    async function submit() {
        const userName = sessionStorage.getItem('demo-current-user') ? sessionStorage.getItem('demo-current-user') : '';
        const value = {
            checked, 
            tooltip: checked ? 'Precautions have been taken' : 'Precautions have not been taken',
            label: obj.requestDate.label + ' Confirmed ' + userName,
        };
        let tempReturn = obj;

        tempReturn.requestDate = value;

        const { id } = obj;
        await updateItem('returns', ['data'], [tempReturn], { id });
        setShowModal(false);
        fetchData();
        history.push('/collections-and-returns');
    }

    return (
        <div >
            <IonGrid style={{marginTop: '2em'}}>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Confirm that Special Delivery Precautions have been taken</IonLabel>
                            <IonCheckbox value={checked} onIonChange={e => setChecked(e.detail.checked)}/>  
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{textAlign: 'right'}}>
                        <IonButton color="warning" onClick={submit}>
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
