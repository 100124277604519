import React, { useState } from 'react';
import { IonItem, IonInput, IonButton } from '@ionic/react';
import { updateItem } from '../../apis/logictrials';


export default function AddManualAramexWaybill({
    obj,
    setShowModal,
    fetchData
}) {
    const [updatedWaybill, setUpdatedWaybill] = useState('');

    async function submitReference() {
        let tempObj = obj;
        tempObj.aramexRef = {
            label: obj.aramexRef.label + ' ' + updatedWaybill,
            button1: {
                btnColor: "success",
                btnIcon: "sendOutline",
                tooltip: "Retry Aramex API",
            },
            button2: {
                btnColor: "danger",
                btnIcon: "addOutline",
                tooltip: "Add manual waybill",
            }
        };

        await updateItem('returns', ['data'], [tempObj], { id: obj.id });
        
        fetchData();
        setShowModal(false);
    }

    return (
        <div style={{ marginTop: '2em' }}>
            <IonItem>
                <IonInput placeholder="Official waybill from Aramex system" value={updatedWaybill} onIonChange={e => setUpdatedWaybill(e.detail.value)} />
                <IonButton onClick={submitReference}>Add</IonButton>
            </IonItem>
        </div>
    )
}
