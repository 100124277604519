import './FileUploadApi';

import { useState } from 'react';
import { IonGrid, IonCol, IonRow, IonButton } from '@ionic/react';
import FileUploadApi from './FileUploadApi';
import { updateItem } from '../../apis/logictrials';
const SssComplete = ({ protocol, object, setFile, setShowModal, orderNumber, fetchData, tableType }) => {
    const [upload, setUpload] = useState(false);

    let fileName = '';
    console.log(object)
    function removeFile(link) {

        let tempFiles = object && object.sss && object.sss.link && Array.isArray(object.sss.link) && object.sss.link

        if (!tempFiles) return
        tempFiles = tempFiles.filter(file => file && (file.link !== link))
        //setFiles(tempFiles);
        let updated = object
        updated.sss.link = tempFiles
        updateItem(tableType, ['data'], [updated], { id: updated.db_id }).then(() => {
            fetchData()
        })

    }
    if (object && object.sss.link && typeof object.sss.link === 'string') {
        const nameArr = object.sss.link.split('/');

        fileName = nameArr[4];
    }

    return (
        <>
            {object && object.sss.link && Array.isArray(object.sss.link) && object.sss.link.length > 0 && !upload &&
                <div className="documents-form" style={{ marginTop: '2em' }}>
                    <div className="documents-form-container">
                        <IonGrid className="upload-documents break-normal">
                            {object.sss.link.map((f, i) => (
                                <IonRow style={{ cursor: 'pointer' }} key={i} className={"document-upload-item;"} >
                                    <IonCol size-md="1" size-xs="1" className="flex-align-center" onClick={() => window.open(f.link)}>
                                        <img alt="doc_icon" className="doc-icon" src="/assets/icons/doc-icon-sml.svg" />
                                    </IonCol>
                                    <IonCol className="flex-align-center" onClick={() => window.open(f.link)}>
                                        <p className="bold margin-none">{f.fileName}</p>
                                    </IonCol>
                                    <IonCol size-md="1" size-xs="1" className="flex-align-center cursor-pointer ion-button" onClick={() => window.open(f.link)}>
                                        <p style={{ color: 'var(--ion-color-primary)', fontWeight: 'bold' }}>View</p>
                                    </IonCol>
                                    <IonCol size-md="0.5" size-xs="0.7" className="cursor-pointer ion-button">
                                        <img alt="close_icon" onClick={() => removeFile(f.link)} className="icon-16" src="/assets/icons/close.png" />
                                    </IonCol>
                                </IonRow>
                            ))}
                        </IonGrid>
                    </div>
                </div>}
            {object && object.sss.link && typeof object.sss.link === 'string' && !upload &&
                <div className="documents-form" style={{ marginTop: '2em' }}>
                    <div className="documents-form-container">
                        <IonGrid className="upload-documents">
                            <IonRow style={{ cursor: 'pointer' }} className={"document-upload-item"} onClick={() => window.open(object.sss.link)}>
                                <IonCol size-md="1" size-xs="1" className="flex-align-center">
                                    <img alt="doc_icon_small" className="doc-icon" src="/assets/icons/doc-icon-sml.svg" />
                                </IonCol>
                                <IonCol className="flex-align-center">
                                    <p className="bold margin-none">{fileName}</p>
                                </IonCol>
                                <IonCol size-md="1" size-xs="1" className="flex-align-center cursor-pointer">
                                    <p style={{ color: 'var(--ion-color-primary)', fontWeight: 'bold' }}>view</p>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>}
            {!upload && <IonButton onClick={() => setUpload(true)}>Upload document</IonButton>}
            {/* {!upload && <IonButton onClick={() => setRemoveInbound(true)}>Delete</IonButton>} */}
            {upload && <IonButton onClick={() => setUpload(false)}>Back</IonButton>}
            {upload && <FileUploadApi protocol={protocol} object={object} setFile={setFile} setShowModal={setShowModal} orderNumber={orderNumber} tableType={tableType} />}
        </>
    );
};

export default SssComplete;